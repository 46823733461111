import React from "react";

const CheckoutHeader = () => {
  return (
    <div className="row mx-0 align-items-center">
      <h1 className="mb-0">Checkout</h1>
    </div>
  );
};

export default CheckoutHeader;
