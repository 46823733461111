import {
  INCOME_RECEIVED,
  OVERDUE_ITEMS_RECEIVED,
  COMMENTS_ITEMS_RECEIVED,
} from "../types/analytics";

const AnalyticsReducer = (state, { type, payload }) => {
  switch (type) {
    case OVERDUE_ITEMS_RECEIVED:
      return { ...state, overdue: payload };
    case COMMENTS_ITEMS_RECEIVED:
      return { ...state, comments: payload };
    case INCOME_RECEIVED:
      return { ...state, income: payload };
    default:
      return { ...state };
  }
};
export default AnalyticsReducer;
