import React, { useState, useEffect } from "react";
import ProcessService from "../../services/ProcessService";

const ProcessPicker = ({ project_id, selected, modifier }) => {
  const [query, setQuery] = useState("");
  const [processes, setProcesses] = useState(null);

  useEffect(() => {
    if (query !== "") {
      fetchProcesses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setQuery("");
    setProcesses(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const fetchProcesses = () => {
    ProcessService.getProcessesProject(project_id, { query }).then((res) => {
      setProcesses(res.data.processes);
    });
  };

  const renderProcesses = () => {
    if (Array.isArray(processes)) {
      let processRender = [...processes];
      if (processRender.length === 0) {
        return (
          <ul className="dropdown-menu mw-100 w-100 show">
            <li className="dropdown-ite small text-dark">No process found.</li>
          </ul>
        );
      }
      return (
        <ul className="dropdown-menu mw-100 w-100 show">
          {processRender.map((process) => (
            <li
              className="dropdown-item small border-bottom py-2 text-dark mw-100"
              onClick={() => modifier(process)}
            >
              {process.name}
            </li>
          ))}
        </ul>
      );
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      return selected.map((process) => (
        <div className="row align-items-center my-2 py-2 bg-light border mx-0">
          <div className="col-10">
            <p className="mb-0">{process.name}</p>
          </div>
          <div className="col-2">
            <button
              className="btn btn-sm py-0"
              onClick={() => modifier(process)}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container position-relative px-0 mb-3">
      <label>Dependences</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Search process..."
        onChange={(e) => setQuery(e.target.value)}
      />
      {renderProcesses()}
      {renderSelected()}
    </div>
  );
};

export default ProcessPicker;
