import { Router } from "@reach/router";
import React from "react";
import AnalyticsIncome from "./analytics/AnalyticsIncome";
import AnalyticsOverdue from "./analytics/AnalyticsOverdue";
import AnalyticsComments from "./analytics/AnalyticsComments";

const Analytics = () => {
  return (
    <Router>
      <AnalyticsComments path="/comments" />
      <AnalyticsOverdue path="/overdue" />
      <AnalyticsIncome path="/income" default />
    </Router>
  );
};

export default Analytics;
