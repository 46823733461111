import React from "react";
import { combineComponents } from "./context";
import { PurchasesProvider } from "./context/PurchasesContext";
import { CapacitorProvider } from "./context/CapacitorContext";
import { InventoryProvider } from "./context/InventoryContext";
import { ProjectsProvider } from "./context/ProjectsContext";
import { ProductsProvider } from "./context/ProductsContext";
import { ActivityProvider } from "./context/ActivityContext";
import { UserTypeProvider } from "./context/UserTypeContext";
import { CommentsProvider } from "./context/ComentsContext";
import { ActionsProvider } from "./context/ActionsContext";
import { ProcessProvider } from "./context/ProcessContext";
import { EventsProvider } from "./context/EventsContext";
import { OrdersProvider } from "./context/OrdersContext";
import { StepsProvider } from "./context/StepsContext";
import { ModalProvider } from "./context/ModalContext";
import { UserProvider } from "./context/AuthContext";
import { MenuProvider } from "./context/MenuContext";
import { CartProvider } from "./context/CartContext";
import { setupIonicReact } from "@ionic/react";
import "@ionic/react/css/core.css";
import Main from "./Main";
import { ProjectTemplatesProvider } from "./context/ProjectTemplatesContext";
import { UsersProvider } from "./context/UsersContext";
import { FilesProvider } from "./context/FileContext";
import { InvoicesProvider } from "./context/InvoicesContext";
import { AnalyticsProvider } from "./context/AnalyticsContext";
import { PaymentsProvider } from "./context/PaymentsContext";
import { OrderTemplatesProvider } from "./context/OrderTemplatesContext";
import { BankAccountsProvider } from "./context/BankAccountsContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { PaymentSourcesProvider } from "./context/PaymentSourcesContext";
import { AppConfigProvider } from "./context/AppConfigContext";
import { ClientsProvider } from "./context/ClientsContext";
import { AddressesProvider } from "./context/AddressesContext";
import { RequisitionsProvider } from "./context/RequisitionsContext";
import { NotificationsProvider } from "./context/NotificationsContext";
import { Router } from "@reach/router";
import { UserDevicesProvider } from "./context/UserDevicesContext";

setupIonicReact();

const AppContextProvider = combineComponents([
  UserProvider,
  MenuProvider,
  CartProvider,
  FilesProvider,
  UsersProvider,
  StepsProvider,
  OrdersProvider,
  EventsProvider,
  ClientsProvider,
  ActionsProvider,
  ProcessProvider,
  AddressesProvider,
  CheckoutProvider,
  PaymentsProvider,
  InvoicesProvider,
  UserTypeProvider,
  ProductsProvider,
  CommentsProvider,
  ProjectsProvider,
  ActivityProvider,
  ProductsProvider,
  InventoryProvider,
  PurchasesProvider,
  AnalyticsProvider,
  AppConfigProvider,
  UserDevicesProvider,
  RequisitionsProvider,
  BankAccountsProvider,
  NotificationsProvider,
  PaymentSourcesProvider,
  OrderTemplatesProvider,
  ProjectTemplatesProvider,
]);

function App() {
  return (
    <CapacitorProvider>
      <ModalProvider>
        <AppContextProvider>
          <Router>
            <Main path="/*" />
          </Router>
        </AppContextProvider>
      </ModalProvider>
    </CapacitorProvider>
  );
}

export default App;
