import React, { createContext, useContext, useReducer } from "react";
import ActivityReducer from "../reducers/ActivityReducer";
import ActivityService from "../services/ActivityService";
import FilesService from "../services/FilesService";
import { HIDE_SPINNER, PERMISSION_DENIED, SHOW_SPINNER } from "../types";
import {
  CREATE_ACTIVITY,
  ACTIVITIES_RECEIVED,
  SET_SINGLE_ACTIVITY,
  SET_PROPERTY_ACTIVITY,
  TOGGLE_ACTIVITY_DEPENDENCE,
} from "../types/activity";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";

const initialState = {
  activities: null,
  activity: null,
  spinner: false,
};

export const ActivityContext = createContext(initialState);

export const ActivityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ActivityReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        return dispatch({ type: PERMISSION_DENIED });
      }
    }
    alert(error);
  };

  const getActivitiesProject = (project_id, filters) => {
    ActivityService.getActivitiesProject(project_id, filters)
      .then((res) => {
        const { activities } = res.data;
        dispatch({ type: ACTIVITIES_RECEIVED, payload: activities });
      })
      .catch(handleError);
  };

  const getActivitiesStep = (step_id) => {
    ActivityService.getActivitiesStep(step_id)
      .then((res) => {
        const { activities } = res.data;
        dispatch({ type: ACTIVITIES_RECEIVED, payload: activities });
      })
      .catch(handleError);
  };

  const getActivitiesProcess = (process_id) => {
    ActivityService.getActivitiesProcess(process_id)
      .then((res) => {
        const { activities } = res.data;
        dispatch({ type: ACTIVITIES_RECEIVED, payload: activities });
      })
      .catch(handleError);
  };

  const getSingleActivity = (activity_id) => {
    ActivityService.getSingleActivity(activity_id)
      .then((res) => {
        const { activity } = res.data;
        dispatch({ type: SET_SINGLE_ACTIVITY, payload: activity });
      })
      .catch(handleError);
  };

  const commentActivity = (activity_id, comment) => {
    ActivityService.commentActivity(activity_id, comment)
      .then(() => {
        success("Comentario agregado.");
      })
      .catch(alert);
  };

  const saveActivityFile = (activity_file) => {
    dispatch({ type: SHOW_SPINNER });
    const { activity_file_id, activity_id, file } = activity_file;
    let service = ActivityService.putActivityFile;
    if (isNaN(parseInt(activity_file_id))) {
      service = ActivityService.postActivityFile;
    }
    const promises = [];
    if (file && file !== null) {
      if (file.size) {
        promises.push(
          new Promise((resolve, reject) => {
            const formData = FilesService.getFormData(file);
            FilesService.postFile(formData)
              .then((res) => {
                const { file_id } = res.data;
                activity_file.file_id = file_id;
                resolve();
              })
              .catch(reject);
          })
        );
      }
    }
    Promise.all(promises)
      .then(() => {
        service(activity_id, activity_file).then(() => {
          dispatch({ type: HIDE_SPINNER });
          getSingleActivity(activity_id);
          success("Attachment saved.");
          clearModal();
        });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteActivityFile = (activity_id, file_id) => {
    ActivityService.deleteActivityFile(activity_id, file_id)
      .then(() => {
        clearModal();
        success("File deleted.");
        getSingleActivity(activity_id);
      })
      .catch(alert);
  };

  const setActivity = (activity) => {
    dispatch({ type: SET_SINGLE_ACTIVITY, payload: activity });
  };

  const createActivity = () => {
    dispatch({ type: CREATE_ACTIVITY });
  };

  const setPropertyActivity = (key, value) => {
    dispatch({ type: SET_PROPERTY_ACTIVITY, payload: { key, value } });
  };

  const postActivity = (activity, callback) => {
    let service = ActivityService.putActivity;
    if (isNaN(parseInt(activity.activity_id))) {
      service = ActivityService.postActivity;
    }
    service(activity)
      .then(() => {
        getSingleActivity(activity.activity_id);
        if (typeof callback === "function") {
          callback();
        }
        success("Activity saved.");
        clearModal();
      })
      .catch((error) => {
        const { response } = error;
        if (response && response !== null) {
          const { data } = response;
          if (data && data !== null) {
            return alert(data.message);
          }
        }
      });
  };

  const deleteActivity = (activity) => {
    const { project_id, step_id, activity_id } = activity;
    dispatch({ type: SHOW_SPINNER });
    ActivityService.deleteActivity(activity_id)
      .then(() => {
        navigate(`/project/${project_id}`);
        dispatch({ type: HIDE_SPINNER });
        success("Activity deleted.");
        getActivitiesStep(step_id);
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const toggleActivityDependence = (activity) => {
    dispatch({ type: TOGGLE_ACTIVITY_DEPENDENCE, payload: activity });
  };

  return (
    <ActivityContext.Provider
      value={{
        ...state,
        setActivity,
        postActivity,
        createActivity,
        deleteActivity,
        commentActivity,
        saveActivityFile,
        getActivitiesStep,
        getSingleActivity,
        deleteActivityFile,
        setPropertyActivity,
        getActivitiesProject,
        getActivitiesProcess,
        toggleActivityDependence,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
