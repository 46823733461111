import React, { useContext, useEffect, useState } from "react";
import { ProjectTemplatesContext } from "../../context/ProjectTemplatesContext";
import { ModalContext } from "../../context/ModalContext";
import { ProjectsContext } from "../../context/ProjectsContext";
import moment from "moment";

const SelectTemplate = ({ project_id }) => {
  const [selected, setSelected] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const { clearModal } = useContext(ModalContext);
  const { setInterval, getSingleProject } = useContext(ProjectsContext);
  const { spinner, templates, getProjectTemplates, applyProjectTemplate } = useContext(
    ProjectTemplatesContext
  );

  useEffect(() => {
    getProjectTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(templates) && selected === "") {
      if (templates.length > 0) {
        const current = templates[0];
        if (current && current !== null) {
          setSelected(current.project_template_id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  const handleCallback = () => {
    clearModal();
    const refreshInterval = window.setInterval(() => getSingleProject(project_id), 1000);
    setInterval(refreshInterval)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    applyProjectTemplate(
      { project_id, project_template_id: selected, start_date: startDate },
      handleCallback
    );
  };

  const renderTemplates = () => {
    if (Array.isArray(templates)) {
      if (templates.length === 0) {
        return (
          <option key="" value="">
            No templates available...
          </option>
        );
      }
      return templates.map((template) => (
        <option
          key={template.project_template_id}
          value={template.project_template_id}
        >
          {template.name}
        </option>
      ));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container px-0">
      <h3>Select a Template</h3>
      <select
        className="form-control mb-3"
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
      >
        {renderTemplates()}
      </select>
      <label>Set Project Start Date</label>
      <input
        type="date"
        value={startDate}
        className="form-control mb-3"
        onChange={(e) => setStartDate(e.target.value)}
      />
      <button type="submit" disabled={spinner} className="btn btn-primary w-100 my-3">
        {spinner ? <div className="spinner-border" /> : "Save"}
      </button>
      <button
        type="button"
        disabled={spinner}
        onClick={clearModal}
        className="btn btn-link text-secondary w-100"
      >
        Cancel
      </button>
    </form>
  );
};

export default SelectTemplate;
