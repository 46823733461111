import api from "./api";

const route = "/requisitions";

export default {
  getRequisitions: () => api.get(route),
  getSingleRequisition: (requisition_id) =>
    api.get(`${route}/${requisition_id}`),
  postRequisition: (requisition) => api.post(route, { ...requisition }),
  putRequisition: (requisition) => api.put(route, { ...requisition }),
  postRequisitionFile: (requisition_file) =>
    api.post(`${route}/files`, { ...requisition_file }),
  deleteRequisitionFile: (requisition_file_id) =>
    api.delete(`${route}/files/${requisition_file_id}`),
  deleteRequisition: (Requisition) => api.delete(`${route}/${Requisition}`),
};
