import React from "react";
import { useContext } from "react";
import { Link } from "@reach/router";
import ProductForm from "./ProductForm";
import { S3_ENDPOINT, formatMonto } from "../../utils";
import IconItem from "../global/IconItem";
import QuantityPicker from "../global/QuantityPicker";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ProductsContext } from "../../context/ProductsContext";

const ProductItem = ({
  product,
  products,
  hidePrice,
  project_id,
  hideButtons,
  disablePicker,
  appendProduct,
  setProductQuantity,
}) => {
  const { user } = useContext(AuthContext);
  const { setProduct } = useContext(ProductsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleEdit = (product) => {
    let currentProduct = { ...product };
    if(Array.isArray(currentProduct.product_prices)) {
      currentProduct = {...currentProduct, ...currentProduct.product_prices[0] }
    }
    setProduct(currentProduct);
    modalComponent(
      "Edit Product",
      <ProductForm project_id={project_id} handleCancel={clearModal} />
    );
  };

  const handleQuantity = (quantity) => {
    const current = products.find(
      ({ product_id }) => parseInt(product.product_id) === parseInt(product_id)
    );
    if (current) {
      return setProductQuantity(product.product_id, quantity);
    }
    appendProduct({ ...product, quantity });
  };

  const getSrc = () => {
    if(Array.isArray(product.files)) {
      if(product.files.length > 0) {
        const firstFile = product.files[0];
        return `${S3_ENDPOINT}/${firstFile.name}.${firstFile.type}`;
      }
    }
  }

  const getProductQuantity = () => {
    if (Array.isArray(products)) {
      let current = products.find(
        ({ product_id }) =>
          parseInt(product_id) === parseInt(product.product_id)
      );
      if (current) {
        return current.quantity;
      }
    }
  };
  const renderAmount = () => {
    if (product.amount && product.amount !== null) {
      return product.amount;
    }
  };

  const renderCost = () => {
    if (product.cost !== null) {
      return (
        <span className="small mb-0">
          - ${formatMonto(product.cost)} {product.currency}
        </span>
      );
    }
  };

  const renderEdit = () => {
    if (user.user_type_id > 3 && !hideButtons) {
      return (
        <button
          className="btn btn-sm text-accent"
          onClick={() => handleEdit(product)}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  const renderPrice = () => {
    if (user.user_type_id > 3 && !hidePrice) {
      return (
        <p className="mb-0">
          ${formatMonto(product.price)} {product.currency}
          {renderCost()}
        </p>
      );
    }
  };
  const renderView = () => {
    if (
      !window.location.pathname.includes("product/") &&
      user.user_type_id > 3
    ) {
      return (
        <Link
          to={`/project/${project_id}/product/${product.product_id}`}
          className="btn btn-sm text-primary"
        >
          <i className="fa fa-eye"></i>
        </Link>
      );
    }
  };

  const renderPicker = () => {
    if (!disablePicker) {
      return (
        <QuantityPicker
          value={getProductQuantity()}
          setQuantity={handleQuantity}
        />
      );
    }
    return product.quantity;
  };

  return (
    <div className="card px-3 py-2 mb-2 shadow-sm border-0">
      <div className="row align-items-center">
        <div className="col-10">
          <IconItem name={product.name} image_src={getSrc()}>
            {renderPrice()}
            {renderPicker()}
          </IconItem>
        </div>
        <div className="col-2">
          {renderAmount()}
          {renderEdit()}
          {renderView()}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
