import React, { useContext } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";

const BottomAction = ({ title, disabled, children, onClick }) => {
  const { platform } = useContext(CapacitorContext);
  return (
    <div
      className={`fixed-bottom container ${
        platform === "web" ? "mb-3" : "mb-4"
      }`}
    >
      <button
        disabled={disabled}
        className="btn btn-primary w-100 my-3"
        onClick={onClick}
      >
        {title && title !== null ? title : ""}
        {children}
      </button>
    </div>
  );
};

export default BottomAction;
