import React, { useContext, useState } from "react";
import { ProcessContext } from "../../context/ProcessContext";
import SelectUserType from "../global/SelectUserType";
import { getValue } from "../../utils";
import ProcessPicker from "./ProcessPicker";
import StartEndDatePicker from "../global/StartEndDatePicker";

const ProcessForm = ({ project_id, handleCancel, handleDelete }) => {
  const [error, setError] = useState(null);
  const { process, postProcess, setPropertyProcess, toggleProcessDependence } =
    useContext(ProcessContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (process.name === "") {
      return setError("Name is invalid.");
    }
    process.project_id = project_id;
    postProcess(project_id, process);
  };

  return (
    <form onSubmit={handleSubmit}>
      {error !== null ? <h4 className="text-danger">{error}</h4> : ""}
      <label>Name</label>
      <input
        type="text"
        value={getValue(process, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyProcess("name", e.target.value)}
      />
      <label>Description</label>
      <textarea
        rows="4"
        value={getValue(process, "description")}
        className="form-control mb-3"
        onChange={(e) => setPropertyProcess("description", e.target.value)}
      />
      <div className="row border-0">
        <SelectUserType
          value={process.minimum_level}
          modifier={(minimum_level) =>
            setPropertyProcess("minimum_level", minimum_level)
          }
        />
      </div>
      <StartEndDatePicker
        end_date={process.end_date}
        start_date={process.start_date}
        modifier={setPropertyProcess}
      />
      <ProcessPicker
        project_id={project_id}
        selected={process.dependences}
        modifier={toggleProcessDependence}
      />
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 my-3"
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={handleDelete}
        className="btn text-danger w-100"
      >
        Delete Process
      </button>
    </form>
  );
};

export default ProcessForm;
