import React, { useState, useContext, useEffect } from "react";
import { AddressesContext } from "../../context/AddressesContext";
import AddressForm from "./AddressForm";
import { ModalContext } from "../../context/ModalContext";
import { addressDataComplete } from "../../utils";

const AddressData = ({ address, handleCallback }) => {
  const [error, setError] = useState("");
  const { setAddress } = useContext(AddressesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    const missing_fields = addressDataComplete({ client: { address } });
    if (missing_fields.length > 0) {
      setError(`You're missing ${missing_fields.join(", ")}.`);
    } else {
      setError("");
    }
  }, [address]);

  const handleEdit = () => {
    setAddress(address);
    modalComponent(
      "Edit Address",
      <AddressForm handleCancel={clearModal} handleCallback={handleCallback} />
    );
  };

  const renderError = () => {
    if (error && error !== null) {
      return <p className="px-0 mb-2 text-danger">{error}</p>;
    }
  };

  return (
    <div className="card p-3 shadow mb-3">
      <div className="row mx-0 border-bottom mb-2 pb-2">
        <div className="col-10 px-0">
          <h2>{address.name}</h2>
        </div>
        <div className="col-2 text-end">
          <button onClick={handleEdit} className="btn btn-sm text-primary">
            <i className="fa fa-edit"></i>
          </button>
        </div>
        {renderError()}
      </div>
      <p>
        {address.address_line_1}
        {address.address_line_2 !== null
          ? ` ${address.address_line_2}`
          : ""}, {address.city}, {address.state}, {address.country},{" "}
        {address.zip_code}
      </p>
    </div>
  );
};
export default AddressData;
