import React, { useState, useEffect } from "react";

const Pagination = ({ pages, modifier }) => {
  const [limit, setLimit] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLimit(pages);
  }, [pages]);

  useEffect(() => {
    modifier(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="container-fluid text-right px-0 mt-2">
      <button
        disabled={page === 0}
        className="btn btn-sm border mx-2"
        onClick={() => {
          if (page > 1) {
            setPage(page - 1);
          }
        }}
      >
        <i className="fa fa-chevron-left"></i>
      </button>
      {page}
      <button
        disabled={page < limit}
        className="btn btn-sm border mx-2"
        onClick={() => {
          if (page < limit) {
            setPage(page + 1);
          }
        }}
      >
        <i className="fa fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default Pagination;
