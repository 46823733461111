import React, { useContext, useEffect, useState } from "react";
import OrderInvoices from "../components/order/OrderInvoices";
import OrderProducts from "../components/order/OrderProducts";
import OrderUpdates from "../components/order/OrderUpdates";
import { OrdersContext } from "../context/OrdersContext";
import OrderApprovals from "../components/order/OrderApproval";
import OrderApprovalForm from "../components/order/OrderApprovalForm";
import OrderUpdateForm from "../components/order/OrderUpdateForm";
import { InvoicesContext } from "../context/InvoicesContext";
import { ModalContext } from "../context/ModalContext";
import OrderHeader from "../components/order/OrderHeader";
import CommentFeed from "../components/comments/CommentFeed";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { setupTooltips } from "../utils";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const SingleOrder = ({ project_id, order_id }) => {
  const [view, setView] = useState("detail");

  const {
    order,
    clearApproval,
    getSingleOrder,
    getSingleOrderAdmin,
    createOrderApproval,
  } = useContext(OrdersContext);
  const { clearInvoice } = useContext(InvoicesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    fetchOrder();
    setupTooltips();
    return clearInvoice;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrder = () => {
    if (project_id && project_id !== null) {
      return getSingleOrder(project_id, order_id);
    }
    getSingleOrderAdmin(order_id);
  };

  const handleCancelApproval = () => {
    clearApproval();
    clearModal();
  };

  const handleCreateOrderUpdate = () => {
    createOrderApproval();
    modalComponent(
      "Add Update",
      <OrderUpdateForm
        order={order}
        handleCallback={fetchOrder}
        handleCancel={handleCancelApproval}
      />
    );
  };

  const handleCreateOrderApproval = () => {
    createOrderApproval();
    modalComponent(
      "Add Approval",
      <OrderApprovalForm order={order} handleCancel={handleCancelApproval} />
    );
  };

  const renderOrder = () => {
    if (order && order !== null) {
      return <OrderHeader order={order} />;
    }
    return <div className="spinner-border"></div>;
  };

  const renderView = () => {
    if (order && order !== null) {
      if (view === "detail") {
        return (
          <div>
            <OrderInvoices
              order={order}
              order_id={order_id}
              project_id={project_id}
              invoices={order.invoices}
              handleCallback={fetchOrder}
            />
            <OrderProducts
              order={order}
              project_id={project_id}
              products={order.products}
              handleCallback={fetchOrder}
            />
            <OrderApprovals
              order={order}
              project_id={project_id}
              approvals={order.order_approvals}
              handleApprove={handleCreateOrderApproval}
            />
            <OrderUpdates
              order={order}
              project_id={project_id}
              updates={order.order_updates}
              handleCallback={fetchOrder}
              handleUpdate={handleCreateOrderUpdate}
            />
          </div>
        );
      }
      return <CommentFeed order_id={order_id} />;
    }
  };

  return (
    <div className="container-fluid px-0">
      <IonPullRefresh handleCallback={fetchOrder} data={order} />
      {renderOrder()}
      <IonSegment color="accent" className="mb-3" value={view}>
        <IonSegmentButton value="detail" onClick={() => setView("detail")}>
          <IonLabel>Detail</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="comments" onClick={() => setView("comments")}>
          <IonLabel>Comments</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {renderView()}
    </div>
  );
};

export default SingleOrder;
