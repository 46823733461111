import React, { useState, useContext, useEffect } from "react";
import { InvoicesContext } from "../context/InvoicesContext";
import InvoiceItem from "../components/invoices/InvoiceItem";
import { PaymentsContext } from "../context/PaymentsContext";
import PaymentForm from "../components/payments/PaymentForm";
import PaymentCard from "../components/payments/PaymentCard";
import ProgressBar from "../components/global/ProgressBar";
import { ModalContext } from "../context/ModalContext";
import { IonActionSheet } from "@ionic/react";
import { cash, card } from "ionicons/icons";
import { navigate } from "@reach/router";
import { Link } from "@reach/router";
import BottomAction from "../components/global/BottomAction";
import { setupTooltips } from "../utils";
import IonPullRefresh from "../components/ionic/IonPullRefresh";
import InvoiceForm from "../components/invoices/InvoiceForm";
import ConfirmDeleteInvoice from "../components/invoices/ConfirmDeleteInvoice";

const SingleInvoice = ({ project_id, invoice_id }) => {
  const [showActionSheet, setShowActionSheet] = useState(false);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { invoice, setInvoice, saveInvoice, getSingleInvoice } = useContext(InvoicesContext);
  const { payments, setPayment, createPayment, getPaymentsInvoice } =
    useContext(PaymentsContext);

  useEffect(() => {
    setupTooltips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [project_id, invoice_id]);

  const fetchData = () => {
    getPaymentsInvoice(project_id, invoice_id);
    getSingleInvoice(project_id, invoice_id);
  };

  const handleResult = (event) => {
    if (event.detail) {
      const { data } = event.detail;
      if (data === "banks") {
        handleCreatePayment();
      } else {
        navigate(`/project/${project_id}/checkout/${invoice_id}`);
      }
    }
    setShowActionSheet(false);
  };

  const handleCancel = () => {
    clearModal();
    setPayment(null);
  };

  const handleEditInvoice = () => {
    modalComponent(
      "Edit Invoice",
      <InvoiceForm
        project_id={project_id}
        handleCancel={clearModal}
        handleSubmit={saveInvoice}
        handleDelete={confirmDelete}
      />
    );
  };

  const confirmDelete = (invoice) => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Delete Invoice",
        <ConfirmDeleteInvoice
          project_id={project_id}
          handleCancel={clearModal}
          handleCallback={fetchData}
          invoice_id={invoice.invoice_id}          
        />
      );
    }, 500);
  };

  const handleCreatePayment = () => {
    createPayment();
    modalComponent(
      "Add Payment",
      <PaymentForm
        total={invoice.amount}
        invoice={invoice}
        invoice_id={invoice_id}
        project_id={project_id}
        handleCancel={handleCancel}
      />
    );
  };

  const renderProgressBar = () => {
    if (invoice && invoice !== null && Array.isArray(payments)) {
      let total = 0;
      payments.forEach((payment) => {
        if (payment.status === "completed") {
          payment.amount += parseFloat(total);
        }
      });
      const progress = (parseFloat(total) / parseFloat(invoice.amount)) * 100;
      return (
        <div className="row mb-3 align-items-center">
          <div className="col-9">
            <ProgressBar progress={progress} />
          </div>
          <div className="col-3 text-end">{progress}% paid</div>
        </div>
      );
    }
  };

  const renderInvoice = () => {
    if (invoice !== null) {
      return (
        <div className="card p-3 shadow-sm my-3">
          <InvoiceItem invoice={invoice} project_id={project_id} />
        </div>
      );
    }
    return <div className="spinner-border" />;
  };

  const renderPayments = () => {
    if (Array.isArray(payments)) {
      if (payments.length === 0) {
        return <p>No payments recorded.</p>;
      }
      return payments.map((payment) => (
        <Link
          to={`/project/${project_id}/payment/${payment.payment_id}`}
          className="no-decoration text-black"
        >
          <PaymentCard
            key={payment.payment_id}
            payment={payment}
            project_id={project_id}
          />
        </Link>
      ));
    }
  };

  return (
    <div className="container py-3">
      <IonPullRefresh handleCallback={fetchData} data={invoice} />
      <div className="row mb-3 align-items-center">
        <div className="col-9">
          <h1
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="This is Agave Brand's internal Invoice ID Number."
          >
            Invoice #{invoice !== null ? invoice.invoice_id : ""}
          </h1>
        </div>
        <div className="col-3 text-end">
          <button className="btn btn-sm btn-primary" onClick={handleEditInvoice}>
            <i className="fa fa-edit" />
          </button>
        </div>
      </div>
      {renderProgressBar()}
      {renderInvoice()}
      <h2>Payments</h2>
      {renderPayments()}
      <BottomAction
        onClick={() => setShowActionSheet(true)}
        title="+ Payment"
      />
      <IonActionSheet
        isOpen={showActionSheet}
        trigger="open-action-sheet"
        header="Select Payment Method"
        buttons={[
          {
            text: "Pay Online",
            icon: card,
            data: "checkout",
          },
          {
            text: "Wire Transfer",
            icon: cash,
            data: "banks",
          },
        ]}
        onDidDismiss={handleResult}
      ></IonActionSheet>
    </div>
  );
};

export default SingleInvoice;
