import { IonLabel } from "@ionic/react";
import React from "react";

const IconItem = ({ icon, image_src, name, children }) => {

  const renderIcon = () => {
    if(image_src && image_src !== null){
      return <img src={image_src} className="mw-100 w-100 d-block m-auto" />
    }
    return <i className={`fa fa-${icon}`}></i>
  }

  return (
    <div className="row py-2 align-items-center">
      <div className="col-3 col-md-4">
        <div className={`icon-wrapper${image_src && image_src !== null ? "-lg": ""} bg-light border`}>
          {renderIcon()}
        </div>
      </div>
      <div className="col-9 col-md-8">
        <IonLabel>
          <h4 className="bold">{name}</h4>
          {children}
        </IonLabel>
      </div>
    </div>
  );
};
export default IconItem;
