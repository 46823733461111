import React from "react";
import { Link } from "@reach/router";
import Chart from "react-apexcharts";
import DueDate from "../global/DueDate";
import { S3_ENDPOINT } from "../../utils";
import ProgressBar from "../global/ProgressBar";

const ProjectCard = ({ project }) => {
  const getProgress = () => {
    let progress = 0;
    if (project && project !== null) {
      if (project.processes > 0) {
        progress = parseInt(
          (parseFloat(project.completed) / parseFloat(project.processes)) * 100
        );
      }
      if (parseInt(project.progress) > progress) {
        progress = parseInt(project.progress);
      }
      if (parseInt(project.process_progress) > progress) {
        progress = parseInt(project.process_progress);
      }
      if (parseInt(project.calculated_progress) > progress) {
        progress = parseInt(project.calculated_progress);
      }
    }
    return parseInt(progress);
  };

  const renderDueDate = () => {
    if (project.start_date !== null || project.end_date !== null) {
      return (
        <DueDate
          start_date={project.start_date}
          end_date={project.end_date}
          hideTime
        />
      );
    }
  };

  const renderImage = () => {
    let { thumbnail } = project;
    if (!thumbnail || thumbnail === null) {
      thumbnail = "icono-naranja.png";
    }
    const progress = getProgress();
    return (
      <div className="position-relative w-100">
        <div className="chart-card-wrapper">
          <Chart
            type="radialBar"
            series={[progress]}
            width={180}
            height={180}
            options={{
              chart: {
                type: "radialBar",
              },
              colors: ["#0d7575"],
              plotOptions: {
                radialBar: {
                  hollow: {
                    position: "back",
                  },
                  dataLabels: {
                    show: false,
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                lineCap: "round",
              },
              labels: [""],
            }}
          />
        </div>
        <img
          src={`${S3_ENDPOINT}/${thumbnail}`}
          alt={thumbnail}
          className="project-img"
        />
      </div>
    );
  };

  return (
    <Link
      to={`/project/${project.project_id}`}
      className="text-dark no-decoration col-12 col-md-6"
    >
      <div className="card shadow-sm p-3 mb-3">
        <div className="row">
          <div className="col-4">{renderImage()}</div>
          <div className="col-8 px-3">
            <h4>{project.name}</h4>
            <div className="row align-items-center mt-2">
              <div className="col-9">
                <ProgressBar progress={getProgress()} />
              </div>
              <div className="col-3 small">{getProgress()}%</div>
            </div>
            {renderDueDate()}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
