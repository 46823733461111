import React, { useContext } from "react";
import { InventoryContext } from "../../context/InventoryContext";
import BottomAction from "../global/BottomAction";
import { navigate } from "@reach/router";

const InventoryButton = ({ project_id }) => {
  const { inventory, products } = useContext(InventoryContext);

  const renderButton = () => {
    if (products.length > 0) {
      return (
        <BottomAction
          onClick={() =>
            navigate(
              `/project/${project_id}/inventory/${inventory.inventory_id}/products`
            )
          }
        >
          View Inventory{" "}
          <span className="bg-dark badge badge-round ms-2">
            {products.length}
          </span>
        </BottomAction>
      );
    }
  };

  return <div>{renderButton()}</div>;
};

export default InventoryButton;
