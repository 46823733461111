import React, { useContext, useEffect } from "react";
import { CapacitorContext } from "./context/CapacitorContext";
import SuccessAlert from "./components/common/SuccessAlert";
import MobileModal from "./components/global/MobileModal";
import ErrorAlert from "./components/common/ErrorAlert";
import AppUrlListener from "./views/AppUrlListener";
import { AuthContext } from "./context/AuthContext";
import { Router, navigate } from "@reach/router";
import Modal from "./components/global/Modal";
import Panel from "./views/Panel";
import Auth from "./views/Auth";

const Main = () => {
  const { user, userLoggedIn, setupInterceptors } = useContext(AuthContext);
  const { platform, setupDevice, setupPlatform } = useContext(CapacitorContext);

  useEffect(() => {
    setupInterceptors();
    userLoggedIn();
    setupDevice();
    setupPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user !== null) {
      if (window.location.pathname === "/login") {
        navigate("/");
      }
    }
  }, [user]);

  return (
    <div id="main" className="container-fluid bg-light px-0 overflow-hidden">
      <AppUrlListener />
      <Router>
        {user !== null ? <Panel path="/*" default /> : <Auth path="/*" />}
      </Router>
      {platform === "web" ? <Modal /> : <MobileModal />}
      <ErrorAlert />
      <SuccessAlert />
    </div>
  );
};

export default Main;
