import React from "react";
import { Link } from "@reach/router";
import TimeLeftBadge from "../global/TimeLeftBadge";
import ProgressBar from "../global/ProgressBar";

const ProcessCard = ({ project_id, process }) => {
  const getProgress = (completed, total) => {
    let progress = 0;
    if (total > 0) {
      if (!completed || completed === null) completed = 0;
      progress = (parseFloat(completed) / parseFloat(total)) * 100;
    }
    return progress;
  };

  const renderTimeLeft = () => {
    if (process.end_date !== null && parseInt(completed) !== parseInt(steps)) {
      return <TimeLeftBadge date={process.end_date} />;
    }
  };

  const { completed, steps } = process;
  const progress = getProgress(completed, steps);

  return (
    <Link
      to={`/project/${project_id}/process/${process.process_id}`}
      className="card process-card no-decoration text-white d-inline-block px-0 my-3 p-2 pb-3"
    >
      <div className="container-fluid mb-2 mt-1">
        <h3>{process && process !== null ? process.name : "Loading..."}</h3>
        {renderTimeLeft()}
      </div>
      <div
        className="container-fluid progress-container"
        style={{ marginTop: -16 }}
      >
        <div className="text-end">
          <p
            className="mb-1"
            style={{ visibility: steps > 0 ? "visible" : "hidden" }}
          >
            Completed {completed} / {steps}
          </p>
        </div>
        <ProgressBar progress={progress} />
      </div>
    </Link>
  );
};

export default ProcessCard;
