import moment from "moment";
import React, { useContext } from "react";
import { BASE_URL } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { CommentsContext } from "../../context/ComentsContext";

const SingleComment = ({ project_id, comment, handleDeleteCallback }) => {
  const { user } = useContext(AuthContext);
  const { deleteComment } = useContext(CommentsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleDelete = () => {
    modalComponent(
      "Delete Comment",
      <div>
        <p>
          Are you sure you want to delete this comment? This action CANNOT be
          undone.
        </p>
        <button
          onClick={() =>
            deleteComment(project_id, comment.comment_id, handleDeleteCallback)
          }
          className="btn btn-danger w-100"
        >
          <i className="fa fa-trash me-2"></i> Delete
        </button>
        <button onClick={clearModal} className="btn text-muted mt-3 w-100">
          Cancel
        </button>
      </div>
    );
  };

  const renderImage = (user) => {
    if (user && user !== null) {
      const { file } = user;
      if (file && file !== null) {
        return (
          <img
            alt="comment"
            src={`${BASE_URL}/${file.name}.${file.type}`}
            className="d-inline-block me-2"
          />
        );
      }
    }
    return <i className="fa fa-user-circle me-2"></i>;
  };

  const renderName = (user) => {
    if (user && user !== null) {
      return user.name;
    }
  };
  return (
    <div className="container-fluid mb-3">
      <div className="row mx-0 mb-2">
        <div className="col-4">
          {renderImage(comment.user)}
          <span className="small bold">{renderName(comment.user)}</span>
        </div>
        <div className="col-8 text-right">
          <p className="mb-0">
            <span className="small me-2">
              <i className="fa fa-calendar small text-primary"></i>{" "}
              {moment(comment.createdAt).format("DD/MM/YY")}
            </span>
            <span className="small">
              <i className="fa fa-clock small text-primary"></i>{" "}
              {moment(comment.createdAt).format("HH:mm")}
            </span>
          </p>
        </div>
      </div>
      <div className="card cursor-default p-2">
        <div className="card-body px-2 py-0">
          <div className="row">
            <div className="col-10">{comment.content}</div>
            <div className="col-2 text-end pe-0">
              {user.user_type_id > 3 && (
                <button
                  onClick={handleDelete}
                  className="btn btn-sm text-danger"
                >
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleComment;
