import { IonActionSheet } from "@ionic/react";
import React, { useState } from "react";

const StatusBadge = ({ status, handleSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);

  const setResult = ({ detail }) => {
    setIsOpen(false);
    if (
      typeof handleSubmit === "function" &&
      detail.data !== "cancel" &&
      detail.data
    ) {
      handleSubmit(detail.data);
    }
  };
  status = String(status).toLowerCase();

  return (
    <div>
      <span
        onClick={(e) => {
          if (typeof handleSubmit === "function") {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }
        }}
        className={`badge small w-100 text-capitalize ${
          status === "to-do" || status === "upcoming"
            ? "status-badge text-dark"
            : status === "in-progress" ||
              status === "ongoing" ||
              status === "ready"
            ? "bg-primary text-dark"
            : status === "completed" ||
              status === "done" ||
              status === "approved" ||
              status === "confirmed" ||
              status === "successful" ||
              status === "confirmed"
            ? "bg-accent text-white"
            : status === "quoted" || status === "reschedule"
            ? "bg-warning text-dark"
            : status === "rejected" ||
              status === "cancelled" ||
              status === "failed"
            ? "bg-danger text-white"
            : "bg-secondary"
        }`}
      >
        {status}
      </span>
      <IonActionSheet
        isOpen={isOpen}
        trigger="open-action-sheet"
        header="Change Status"
        buttons={[
          {
            text: "To Do",
            data: "to-do",
          },
          {
            text: "In Progress",
            data: "in-progress",
          },
          {
            text: "Completed",
            data: "completed",
          },
          {
            text: "Cancel",
            role: "cancel",
            data: "cancel",
          },
        ]}
        onDidDismiss={setResult}
      ></IonActionSheet>
    </div>
  );
};
export default StatusBadge;
