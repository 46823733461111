import React from "react";
import { useContext } from "react";
import { Link } from "@reach/router";
import IconItem from "../global/IconItem";
import ProductInputSelect from "./ProductInputSelect";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ProductsContext } from "../../context/ProductsContext";

const ProductInputItem = ({ product, parent_id, project_id }) => {
  const { user } = useContext(AuthContext);
  const { setInput, getSingleProduct, clearSingleProduct } =
    useContext(ProductsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCancel = () => {
    clearModal();
  };

  const handleCallback = () => {
    getSingleProduct(parent_id);
  };

  const handleEdit = (product) => {
    setInput(product);
    modalComponent(
      "Edit Input",
      <ProductInputSelect
        parent_id={parent_id}
        project_id={project_id}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  };

  const getProductName = () => {
    if (product.input && product.input !== null) {
      return product.input.name;
    }
  };

  const renderAmount = () => {
    if (product.amount && product.amount !== null) {
      return product.amount;
    }
  };

  const renderEdit = () => {
    if (user.user_type_id > 3) {
      return (
        <button
          className="btn btn-sm text-accent"
          onClick={() => handleEdit(product)}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  const renderView = () => {
    if (user.user_type_id > 3) {
      return (
        <Link
          to={`/project/${project_id}/product/${product.input_id}`}
          className="btn btn-sm text-primary"
        >
          <i className="fa fa-eye"></i>
        </Link>
      );
    }
  };

  return (
    <div className="card px-3 py-2 mb-2 shadow-sm border-0">
      <div className="row align-items-center">
        <div className="col-8">
          <IconItem name={getProductName()}>
            <p className="mt-2 mb-0">Amount: {renderAmount()}</p>
          </IconItem>
        </div>
        <div className="col-4 text-end">
          {renderEdit()}
          {renderView()}
        </div>
      </div>
    </div>
  );
};

export default ProductInputItem;
