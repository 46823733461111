import React, { useContext, useState } from "react";
import { ActionsContext } from "../../context/ActionsContext";
import SelectUserType from "../global/SelectUserType";
import { getValue } from "../../utils";
import ActionPicker from "./ActionPicker";
import { ProjectsContext } from "../../context/ProjectsContext";

const ActionForm = ({ activity_id, handleCancel, handleDelete }) => {
  const [error, setError] = useState(null);
  const { project } = useContext(ProjectsContext);
  const { action, postAction, setPropertyAction, toggleActionDependence } =
    useContext(ActionsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (action.name === "") {
      return setError("Name is invalid.");
    }
    if (activity_id && activity_id !== null) {
      action.activity_id = activity_id;
    }
    postAction(action);
  };

  const renderStartedAt = () => {
    if (action.status !== "to-do") {
      return (
        <div className="container px-0">
          <label>Started At</label>
          <input
            type="date"
            className="form-control mb-3"
            value={getValue(action, "startedAt")}
            onChange={(e) => setPropertyAction("startedAt", e.target.value)}
          />
        </div>
      );
    }
  };

  const renderCompletedAt = () => {
    if (action.completedAt !== null) {
      return (
        <div className="container px-0">
          <label>Completed At</label>
          <input
            type="date"
            className="form-control mb-3"
            value={getValue(action, "completedAt")}
            onChange={(e) => setPropertyAction("completedAt", e.target.value)}
          />
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error !== null ? <h4 className="text-danger">{error}</h4> : ""}
      <label>Name</label>
      <input
        type="text"
        value={getValue(action, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyAction("name", e.target.value)}
      />
      <label>Description</label>
      <textarea
        rows="3"
        value={getValue(action, "description")}
        className="form-control mb-3"
        onChange={(e) => setPropertyAction("description", e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <label>Start Date</label>
          <input
            type="date"
            className="form-control mb-3"
            value={getValue(action, "start_date")}
            onChange={(e) => setPropertyAction("start_date", e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>End Date</label>
          <input
            type="date"
            className="form-control mb-3"
            value={getValue(action, "end_date")}
            onChange={(e) => setPropertyAction("end_date", e.target.value)}
          />
        </div>
      </div>
      {renderStartedAt()}
      {renderCompletedAt()}
      <div className="row mb-3 border-0">
        <SelectUserType
          value={action.minimum_level}
          modifier={(minimum_level) =>
            setPropertyAction("minimum_level", minimum_level)
          }
        />
      </div>
      <ActionPicker
        selected={action.dependences}
        project_id={project.project_id}
        modifier={toggleActionDependence}
      />
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 my-3"
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={handleDelete}
        className="btn text-danger w-100"
      >
        Delete Action
      </button>
    </form>
  );
};

export default ActionForm;
