import React, { useContext } from "react";
import moment from "moment";
import parse from "html-react-parser";
import IconItem from "../global/IconItem";
import ProgressBar from "../global/ProgressBar";
import ActivityForm from "../activities/ActivityForm";
import { ModalContext } from "../../context/ModalContext";
import { StepsContext } from "../../context/StepsContext";
import ActivityCollapse from "../activities/ActivityCollapse";
import { ActivityContext } from "../../context/ActivityContext";
import BottomAction from "../global/BottomAction";
import { AuthContext } from "../../context/AuthContext";

const StepDetail = ({ step_id, project_id }) => {
  const { user } = useContext(AuthContext);
  const { step } = useContext(StepsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { activities, createActivity } = useContext(ActivityContext);

  const handleCreate = () => {
    createActivity();
    modalComponent(
      "Add Activity",
      <ActivityForm step_id={step_id} handleCancel={clearModal} />
    );
  };

  const getActivities = (completed) => {
    if (Array.isArray(activities)) {
      let activitiesRender = [...activities];
      if (completed) {
        activitiesRender = activitiesRender.filter(
          (activity) => activity.status === "completed"
        );
      }
      return activitiesRender.length;
    }
  };

  const getProgress = (completed, activities) => {
    let progress = 0;
    if (activities > 0) {
      progress = (parseFloat(completed) / parseFloat(activities)) * 100;
    }
    return Math.ceil(progress);
  };

  const renderDeadline = () => {
    if (step.end_date !== null) {
      return (
        <div className="col-6">
          <IconItem
            name={`Deadline: ${moment(step.end_date).format("DD MMM YYYY")}`}
            icon="calendar text-primary"
          />
        </div>
      );
    }
  };

  const renderCompleted = () => {
    if (step.completedAt !== null) {
      return (
        <div className="col-6">
          <IconItem name="Completed At" icon="calendar text-accent">
            <p className="mb-0">
              {moment(step.completedAt).utc().format("MMM Do YYYY")}
            </p>
          </IconItem>
        </div>
      );
    }
  };

  const renderPreconditions = () => {
    const { dependences } = step;
    if (Array.isArray(dependences)) {
      return dependences.map((current) => (
        <div className="col-6">
          <IconItem
            name="Precondition"
            icon={
              current.status === "completed"
                ? "check text-success"
                : "lock text-secondary"
            }
          >
            <p className="mb-0">{current.name}</p>
          </IconItem>
        </div>
      ));
    }
  };

  const renderDescription = () => {
    if (step.description && step.description !== null) {
      return parse(String(step.description).replace(/(?:\r\n|\r|\n)/g, "<br>"));
    }
  };

  const renderAction = () => {
    if (user.user_type_id > 3) {
      return <BottomAction onClick={handleCreate} title="+ Add Activity" />;
    }
  };

  const completed = getActivities(true);
  const progress = getProgress(completed, getActivities());

  return (
    <div className="container-fluid px-0">
      <div className="container-fluid px-3">
        <div className="small my-3">{renderDescription()}</div>
        <div className="row">
          {renderDeadline()}
          {renderCompleted()}
        </div>
        <div className="row my-2">{renderPreconditions()}</div>
        <div className="row my-2">
          <div className="col-6">{progress}% Completed</div>
          <div className="col-6 text-end">
            Completed {completed} / {getActivities()}
          </div>
        </div>
        <ProgressBar progress={progress} />
        {/*<button className="btn btn-light w-100 my-3">Schedule Call</button>*/}
      </div>
      <ActivityCollapse step_id={step.step_id} project_id={project_id} />
      {renderAction()}
    </div>
  );
};

export default StepDetail;
