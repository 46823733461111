import React, { useState, useEffect, useContext } from "react";
import ConfirmDeleteProject from "../components/projects/ConfirmDeleteProject";
import IonPullRefresh from "../components/ionic/IonPullRefresh";
import ProjectFiles from "../components/projects/ProjectFiles";
import { ProjectsContext } from "../context/ProjectsContext";
import ProjectData from "../components/projects/ProjectData";
import AddressData from "../components/address/AddressData";
import ClientData from "../components/client/ClientData";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import { isProjectComplete } from "../utils";
import { Link } from "@reach/router";

const ProjectInfo = ({ project_id }) => {
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { project, getSingleProject, deleteProject } =
    useContext(ProjectsContext);

  useEffect(() => {
    fetchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project && project !== null) {
      const completed = isProjectComplete(project);
      if (!completed) {
        setError("You must complete the project info before continuing");
      }
    }
  }, [project]);

  const fetchProject = () => {
    getSingleProject(project_id);
  };

  const handleDeleteProject = () => {
    modalComponent(
      "Delete Project",
      <ConfirmDeleteProject
        project={project}
        handleCancel={clearModal}
        handleDelete={deleteProject}
      />
    );
  };

  const renderError = () => {
    if (error && error !== null && error !== "") {
      return <p className="text-danger mt-2">{error}</p>;
    }
  };

  const renderProject = () => {
    if (project && project !== null) {
      return <ProjectData project={project} />;
    }
  };

  const renderClient = () => {
    if (project && project !== null) {
      if (project.client && project.client !== null) {
        return (
          <ClientData client={project.client} handleCallback={fetchProject} />
        );
      }
    }
  };

  const renderAddress = () => {
    if (project && project !== null) {
      if (project.client && project.client !== null) {
        if (project.client.address && project.client.address !== null) {
          return (
            <AddressData
              address={project.client.address}
              handleCallback={fetchProject}
            />
          );
        }
      }
    }
  };

  const renderFiles = () => {
    if (project && project !== null) {
      if(project.user_type_id > 2) {
        if (Array.isArray(project.files)) {
          return (
            <div className="card p-3 shadow my-3">
              <ProjectFiles
                project_id={project_id}
                files={project.files}
                handleCallback={fetchProject}
              />
            </div>
          );
        }
      }
    }
  };

  const renderDelete = () => {
    if (user.user_type_id > 3) {
      return (
        <button
          onClick={handleDeleteProject}
          className="btn mt-4 btn-outline-danger"
        >
          <i className="fa fa-trash me-3"></i>
          Delete Project
        </button>
      );
    }
  };

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={fetchProject} data={project} />
      <div className="row">
        <div className="col-3">
          <Link
            to={`/project/${project_id}`}
            className="btn btn-sm btn-outline-secondary mb-2"
          >
            <i className="fa fa-chevron-left me-2"></i> Back
          </Link>
        </div>
        <div className="col-9">{renderError()}</div>
      </div>
      {renderProject()}
      {renderClient()}
      {renderAddress()}
      {renderFiles()}
      {renderDelete()}
    </div>
  );
};

export default ProjectInfo;
