import React, { useContext } from "react";
import DueDate from "../global/DueDate";
import StatusBadge from "../global/StatusBadge";
import { StepsContext } from "../../context/StepsContext";
import { navigate } from "@reach/router";
import ProgressBar from "../global/ProgressBar";

const StepCard = ({ step, project_id }) => {
  const { postStep } = useContext(StepsContext);

  const hasCompletedDependences = () => {
    if (step.dependences > 0) {
      return step.completed_dependences >= step.dependences;
    }
    return true;
  };

  const handleStatus = (status) => {
    postStep({ ...step, status });
  };

  const renderLock = () => {
    if (!hasCompletedDependences()) {
      return (
        <div className="icon-wrapper bg-light border">
          <i className={`fa fa-lock`}></i>
        </div>
      );
    }
  };

  const renderDueDate = () => {
    if (step.start_date !== null || step.end_date !== null) {
      return (
        <DueDate
          start_date={step.start_date}
          end_date={step.end_date}
          hideTime
        />
      );
    }
  };
  return (
    <div
      onClick={() => navigate(`/project/${project_id}/step/${step.step_id}`)}
      className="card shadow-sm text-dark no-decoration p-3 mb-3"
      style={{ opacity: hasCompletedDependences() ? 1 : 0.5 }}
    >
      <div className="row">
        <div className="col-6">
          <h4>{step.name}</h4>
          <span className="process-metadata me-4">
            <i className="fa fa-comment me-1"></i> {step.comments}
          </span>
          {renderDueDate()}
        </div>
        <div className="col-2 text-end">{renderLock()}</div>
        <div className="col-4 text-end">
          <div className="row align-items-center mb-3">
            <div className="col-8 col-md-9 ps-3 pe-0">
              <ProgressBar progress={step.progress} />
            </div>
            <div className="col-4 col-md-3 px-1 text-end">
              <span className="small">{parseInt(step.progress)}%</span>
            </div>
          </div>
          <StatusBadge status={step.status} handleSubmit={handleStatus} />
        </div>
      </div>
    </div>
  );
};

export default StepCard;
