
import api from './api';

const route = '/clients';

export default {
  getClients: () => api.get(route),
  getSingleClient: (client_id) => api.get(`${route}/${client_id}`),
  postClient: (client) => api.post(route, { ...client}),
  putClient: (client) => api.put(route, { ...client}),
  deleteClient: (Client) => api.delete(`${route}/${Client}`),
};
