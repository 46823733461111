import React from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";

const IonTabs = ({ tabs, color, selected, setSelected }) => {
  const renderTabs = () => {
    if (Array.isArray(tabs)) {
      return tabs.map((tab) => (
        <IonSegmentButton
          value={tab.value}
          onClick={() => setSelected(tab.value)}
        >
          <IonLabel>{tab.name}</IonLabel>
        </IonSegmentButton>
      ));
    }
  };
  return (
    <IonSegment color={color ? color: "accent"} className="mb-3" value={selected}>
      {renderTabs()}
    </IonSegment>
  );
};

export default IonTabs;