import {
  SET_PROPERTY_BANK_ACCOUNT,
  BANK_ACCOUNTS_RECEIVED,
  CREATE_BANK_ACCOUNT,
  SET_BANK_ACCOUNT,
} from "../types/bank_accounts";

const schema = {
  bank_account_id: "",
  name: "",
  bank_name: "",
  swift_code: "",
  wire_number: "",
  account_number: "",
};

const BankAccountsReducer = (state, { type, payload }) => {
  switch (type) {
    case BANK_ACCOUNTS_RECEIVED:
      return { ...state, bank_accounts: payload };
    case SET_BANK_ACCOUNT:
      return { ...state, bank_account: payload };
    case CREATE_BANK_ACCOUNT:
      return { ...state, bank_account: schema };
    case SET_PROPERTY_BANK_ACCOUNT:
      const { key, value } = payload;
      const bank_account = { ...state.bank_account };
      bank_account[key] = value;
      return { ...state, bank_account };
    default:
      return { ...state };
  }
};
export default BankAccountsReducer;
