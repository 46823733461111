import React, { useState, useEffect } from "react";
import ImageInputThumbnail from "./ImageInputThumbnail";

const ImageInput = ({
  value,
  base_url,
  modifier,
  multiple,
  hideImage,
  hideLabel,
}) => {
  const [src, setSrc] = useState("");
  const [files, setFiles] = useState([]);
  const [srcSet, setSrcSet] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value && files === null) {
      if (base_url && base_url !== null) {
        setSrc(`${base_url}/${value}`);
      } else {
        setSrc(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (files?.length > 0 && multiple) {
      const fileSet = [];
      const promises = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        let reader = new FileReader();

        const currentPromise = new Promise((resolve, reject) => {
          reader.readAsDataURL(file);

          reader.onload = (e) => {
            let src = e.target.result;
            fileSet.push(src);
            resolve();
          };
        });

        promises.push(currentPromise);
      }

      Promise.all(promises).then(() => {
        setSrcSet(fileSet);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const renderImages = () => {
    if (multiple && Array.isArray(value)) {
      return value.map((src, index) => (
        <ImageInputThumbnail key={index} src={src} />
      ));
    }
    if (multiple && srcSet.length > 0) {
      return srcSet.map((src, index) => (
        <ImageInputThumbnail key={index} src={src} />
      ));
    }
  };

  const handleModifier = (currentFiles) => {
    if (multiple) {
      modifier(currentFiles);
      setFiles(currentFiles);
      setLoading(true);
    } else {
      modifier(currentFiles[0]);
      setFiles(currentFiles[0]);
    }
  };

  const selectFile = () => {
    document.getElementById("fileInput").click();
  };

  const imageInputHandler = (e) => {
    if (e.target.files !== undefined) {
      handleModifier(e.target.files);
    }
  };

  const renderButtonContent = () => {
    if (loading) {
      return <div className="spinner-border" />;
    }
    if (!hideLabel) {
      return (
        <span className="small ms-2 d-inline-block">
          {src !== "" ? "Reemplazar" : "Subir"}
        </span>
      );
    }
  };

  return (
    <div className="container-fluid px-0 mb-3" style={{ position: "relative" }}>
      <div className="row align-items-center justify-content-start">
        <div className={`col-12 ${!hideImage ? "col-md-6" : ""}`}>
          <input
            className="d-none"
            style={{ cursor: "pointer" }}
            id="fileInput"
            type="file"
            accept="image/*"
            multiple={multiple}
            onChange={imageInputHandler}
          />
          <button
            type="button"
            disabled={loading}
            onClick={selectFile}
            className="btn btn-sm btn-outline-primary"
          >
            <i className="fa fa-image"></i> {renderButtonContent()}
          </button>
        </div>
        <div className="container-fluid">{renderImages()}</div>
        {!hideImage && (
          <div className="col-12 col-md-6">
            {src !== "" && (
              <img
                src={src}
                alt="subir"
                className="d-block m-auto w-100 my-3"
                style={{ maxHeight: 250, objectFit: "contain" }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageInput;
