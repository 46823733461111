import React, { useContext, useEffect, useState } from "react";
import AttachmentConfirmDelete from "../components/attachments/AttachmentConfirmDelete";
import ActivityActions from "../components/activities/ActivityActions";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import AttachmentList from "../components/attachments/AttachmentList";
import ActivityDetail from "../components/activities/ActivityDetail";
import IonPullRefresh from "../components/ionic/IonPullRefresh";
import CommentFeed from "../components/comments/CommentFeed";
import { ActivityContext } from "../context/ActivityContext";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import { ModalContext } from "../context/ModalContext";

const SingleActivity = ({ project_id, activity_id }) => {
  const [view, setView] = useState("actions");
  const {
    denied,
    spinner,
    activity,
    saveActivityFile,
    getSingleActivity,
    deleteActivityFile,
  } = useContext(ActivityContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    fetchActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchActivity = () => getSingleActivity(activity_id);

  const handleDelete = (attachment) => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Delete Attachment",
        <AttachmentConfirmDelete
          activity={activity}
          attachment={attachment}
          deleteActivityFile={deleteActivityFile}
        />
      );
    }, 500);
  };

  const renderTabs = () => {
    if (activity && activity !== null) {
      return (
        <IonSegment color="accent" value={view}>
          <IonSegmentButton value="actions" onClick={() => setView("actions")}>
            <IonLabel>Actions</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="attachments"
            onClick={() => setView("attachments")}
          >
            <IonLabel>Attachments</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="comments"
            onClick={() => setView("comments")}
          >
            <IonLabel>Comments</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      );
    }
    if (denied) {
      return <p>We're sorry, you are not allowed to see this content.</p>;
    }
  };

  const renderActivity = () => {
    if (activity && activity !== null) {
      return (
        <ActivityDetail
        project_id={project_id}
          activity_id={activity_id}
          handleCallback={fetchActivity}
        />
      );
    }
  };

  const renderTabContent = () => {
    if (activity && activity !== null) {
      switch (view) {
        case "attachments":
          return (
            <AttachmentList
              spinner={spinner}
              activity_id={activity_id}
              attachment={{ activity_id }}
              attachments={activity.files}
              handleDelete={handleDelete}
              handleSubmit={saveActivityFile}
            />
          );
        case "comments":
          return (
            <CommentFeed activity_id={activity_id} project_id={project_id} />
          );
        default:
          return (
            <ActivityActions
              activity_id={activity_id}
              project_id={project_id}
            />
          );
      }
    }
  };

  return (
    <div className="container-fluid px-0">
      <IonPullRefresh handleCallback={fetchActivity} data={activity} />
      <Breadcrumb project_id={project_id} />
      {renderActivity()}
      <div className="container-fluid my-4">{renderTabs()}</div>
      {renderTabContent()}
    </div>
  );
};

export default SingleActivity;
