import React, { createContext, useReducer } from "react";
import CartReducer from "../reducers/CartReducer";
import {
  CLEAR_CART,
  APPEND_PRODUCT,
  REMOVE_PRODUCT,
  SET_PRODUCT_QUANTITY,
} from "../types/cart";

const initialState = {
  products: [],
};

export const CartContext = createContext(initialState);

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  const setProductQuantity = (product_id, quantity) => {
    dispatch({ type: SET_PRODUCT_QUANTITY, payload: { product_id, quantity } });
  };

  const appendProduct = (product) => {
    dispatch({ type: APPEND_PRODUCT, payload: product });
  };

  const removeProduct = (product_id) => {
    dispatch({ type: REMOVE_PRODUCT, payload: product_id });
  };

  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
  };

  return (
    <CartContext.Provider
      value={{
        ...state,
        clearCart,
        appendProduct,
        removeProduct,
        setProductQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
