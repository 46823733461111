import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useState, useContext, useEffect } from "react";
import ProcessForm from "../components/process/ProcessForm";
import StepCard from "../components/steps/StepCard";
import StepForm from "../components/steps/StepForm";
import { ProcessContext } from "../context/ProcessContext";
import { StepsContext } from "../context/StepsContext";
import { AuthContext } from "../context/AuthContext";
import { ModalContext } from "../context/ModalContext";
import DeleteConfirm from "../components/global/DeleteConfirm";
import ProgressBar from "../components/global/ProgressBar";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const SingleProcess = ({ project_id, process_id }) => {
  const [view, setView] = useState("to-do");

  const { user } = useContext(AuthContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  const { spinner, denied, process, deleteProcess, getSingleProcess } =
    useContext(ProcessContext);

  const { steps, createStep, getStepsProcess } = useContext(StepsContext);

  useEffect(() => {
    fetchProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process_id]);

  const fetchProcess = () => {
    getSingleProcess(process_id);
    getStepsProcess(process_id);
  };

  const handleCreateStep = () => {
    createStep();
    modalComponent(
      "Add Step",
      <StepForm process_id={process_id} handleCancel={clearModal} />
    );
  };

  const handleDelete = () => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Delete Process",
        <DeleteConfirm
          item={process}
          spinner={spinner}
          itemName="process"
          handleDelete={deleteProcess}
          description="This will delete all steps, activities and actions from the process."
        />
      );
    }, 500);
  };

  const handleEditProcess = () => {
    modalComponent(
      "Edit Process",
      <ProcessForm
        project_id={project_id}
        handleCancel={clearModal}
        handleDelete={handleDelete}
      />
    );
  };

  const renderName = () => {
    if (process !== null) {
      return process.name;
    }
  };

  const renderActions = () => {
    if (user.user_type_id > 3 && process !== null) {
      return (
        <div className="col-4 text-end">
          <button
            className="btn btn-sm px-2 text-accent"
            onClick={handleEditProcess}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-sm px-2 text-primary"
            onClick={handleCreateStep}
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      );
    }
  };

  const renderSteps = () => {
    if (Array.isArray(steps)) {
      let stepsRender = steps.filter((step) => step.status === view);
      if (stepsRender.length === 0) {
        return <p>No {view} steps found.</p>;
      }
      return stepsRender.map((step) => (
        <StepCard key={step.step_id} project_id={project_id} step={step} />
      ));
    }
  };

  const renderTabs = () => {
    if (process !== null) {
      return (
        <IonSegment color="accent" value={view}>
          <IonSegmentButton value="to-do" onClick={() => setView("to-do")}>
            <IonLabel>To Do</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="in-progress"
            onClick={() => setView("in-progress")}
          >
            <IonLabel>In Progress</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="completed"
            onClick={() => setView("completed")}
          >
            <IonLabel>Completed</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      );
    }
    if (denied) {
      return <p>We're sorry, you're not allowed to see this content.</p>;
    }
  };

  const renderProgress = () => {
    if (process && process !== null) {
      return (
        <div className="row mt-2 mb-3 align-items-center">
          <div className="col-11">
            <ProgressBar progress={process.progress} />
          </div>
          <div className="col-1 text-end">
            <span className="small">{parseInt(process.progress)}%</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <IonPullRefresh handleCallback={fetchProcess} data={process} />
      <div className="container-fluid mb-3">
        <div className="row align-items-center mb-3">
          <div className="col-8">
            <h2 className="mb-0">{renderName()}</h2>
          </div>
          {renderActions()}
        </div>
        {renderProgress()}
        {renderTabs()}
      </div>
      <div className="container-fluid">{renderSteps()}</div>
    </div>
  );
};

export default SingleProcess;
