import React, { useContext, useState } from "react";
import { StepsContext } from "../../context/StepsContext";
import SelectUserType from "../global/SelectUserType";
import moment from "moment";
import StepPicker from "./StepPicker";
import { ProjectsContext } from "../../context/ProjectsContext";
import StartEndDatePicker from "../global/StartEndDatePicker";

const StepForm = ({ process_id, handleCancel, handleDelete }) => {
  const [error, setError] = useState(null);
  const { project } = useContext(ProjectsContext);
  const { step, postStep, setPropertyStep, toggleStepDependence } =
    useContext(StepsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step.name === "") {
      return setError("Name is invalid.");
    }
    step.process_id = process_id;
    postStep(step);
  };

  return (
    <form onSubmit={handleSubmit}>
      {error !== null ? <h4 className="text-danger">{error}</h4> : ""}
      <label>Name</label>
      <input
        type="text"
        value={step.name}
        className="form-control mb-3"
        onChange={(e) => setPropertyStep("name", e.target.value)}
      />
      <label>Description</label>
      <textarea
        rows="4"
        value={step.description}
        className="form-control mb-3"
        onChange={(e) => setPropertyStep("description", e.target.value)}
      />
      <StartEndDatePicker
        end_date={step.end_date}
        start_date={step.start_date}
        modifier={setPropertyStep}
      />
      <SelectUserType
        value={step.minimum_level}
        modifier={(minimum_level) =>
          setPropertyStep("minimum_level", minimum_level)
        }
      />

      <div className="container px-0">
        <label>Started At</label>
        <input
          type="date"
          className="form-control mb-3"
          value={
            step.startedAt !== null
              ? moment(step.startedAt).utc().format("YYYY-MM-DD")
              : ""
          }
          onChange={(e) => setPropertyStep("startedAt", e.target.value)}
        />
      </div>
      <StepPicker
        selected={step.dependences}
        project_id={project.project_id}
        modifier={toggleStepDependence}
      />
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 my-3"
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={handleDelete}
        className="btn text-danger w-100"
      >
        Delete Step
      </button>
    </form>
  );
};

export default StepForm;
