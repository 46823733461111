import React from "react";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import ProgressBar from "../global/ProgressBar";

const InvoicesProject = ({ project }) => {
  return (
    <div className="card p-3 shadow-sm mb-3">
      <div className="row align-items-center mb-2">
        <div className="col-10">
          <h3>{project.project_name}</h3>
        </div>
        <div className="col-2 text-end">
          <Link to={`/project/${project.project_id}/invoices`}>
            <i className="fa fa-chevron-right text-accent"></i>
          </Link>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-8">
          <p className="mb-1">
            ${formatMonto(project.paid)} {project.currency} / $
            {formatMonto(project.due)} {project.currency}
          </p>
        </div>
        <div className="col-4 text-end">
          <p className="mb-1">
            {project.paid_invoices} / {project.invoices}
          </p>
        </div>
      </div>
      <div className="my-2">
        <ProgressBar
          progress={(project.paid_invoices / project.invoices) * 100}
        />
      </div>
      <div className="col-12 col-md-auto"></div>
    </div>
  );
};
export default InvoicesProject;
