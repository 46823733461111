import api from "./api";
import { getArgs } from "../utils";

const route = "/actions";

const ActionsService = {
  getActionsProject: (project_id, filters) =>
    api.get(`${route}/project/${project_id}?${getArgs(filters)}`),
  getActionsActivity: (activity_id) =>
    api.get(`${route}/activity/${activity_id}`),
  completeAction: (action) => api.post(`${route}/complete`, { ...action }),
  uncompleteAction: (action) => api.post(`${route}/uncomplete`, { ...action }),
  postAction: (action) => api.post(route, { ...action }),
  putAction: (action) => api.put(route, { ...action }),
  deleteAction: (action_id) => api.delete(`${route}/${action_id}`),
};
export default ActionsService;
