export const CREATE_ORDER_APPROVAL = "CREATE_ORDER_APPROVAL";
export const SET_PROPERTY_INVOICE = "SET_PROPERTY_INVOICE";
export const ORDER_STEPS_RECEIVED = "ORDER_STEPS_RECEIVED";
export const SET_PROPERTY_ORDER = "SET_PROPERTY_ORDER";
export const ORDERS_RECEIVED = "ORDERS_RECEIVED";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const CLEAR_APPROVAL = "CLEAR_APPROVAL";
export const CREATE_ORDER = "CREATE_ORDER";
export const SET_INVOICE = "SET_INVOICE";
export const SET_ORDER = "SET_ORDER";
