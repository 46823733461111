import moment from "moment";
import React, { useState, useContext } from "react";
import DueDate from "../global/DueDate";
import StatusBadge from "../global/StatusBadge";
import { EventsContext } from "../../context/EventsContext";
import { IonActionSheet } from "@ionic/react";
import { ModalContext } from "../../context/ModalContext";
import EventCancelConfirm from "./EventCancelConfirm";
import EventForm from "./EventForm";

const EventCard = ({ event, project_id, handleCallback }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setEvent, postEvent } = useContext(EventsContext);
  const [isOpen, setIsOpen] = useState(false);
  let eventValid = event && event !== null;

  if (eventValid) {
    eventValid = moment(event.start_date).isValid();
  }

  const setResult = ({ detail }) => {
    setIsOpen(false);
    if (detail.data) {
      if (detail.data === "cancel") {
        return confirmDelete();
      }
      postEvent({ ...event, status: detail.data });
    }
  };

  const handleEdit = () => {
    setEvent(event);
    modalComponent(
      "Edit Event",
      <EventForm project_id={project_id} handleCancel={clearModal} />
    );
  };

  const confirmDelete = () => {
    modalComponent(
      "Cancel Event",
      <EventCancelConfirm
        event={event}
        project_id={project_id}
        handleCancel={clearModal}
        handleCallback={handleCallback}
      />
    );
  };

  const getBorderColor = () => {
    const { event_type } = event;
    if (event_type && event_type !== null) {
      return event_type.color;
    }
  };

  const isDueDateClose = () => {
    return moment(event.start_date).diff(moment().local, "days") < 3;
  };

  const renderDueDate = () => {
    if (isDueDateClose()) {
      return <p className="text-primary bold mb-1">Due date soon</p>;
    }
  };

  const renderDates = () => {
    if (event.start_date === null && event.end_date === null) {
      return <p className="mb-0">No date added</p>;
    }
    return <DueDate start_date={event.start_date} end_date={event.end_date} />;
  };

  return (
    <div
      className={`card p-3 mb-3 shadow-sm due-date-card`}
      style={{ borderLeftColor: getBorderColor() }}
    >
      {renderDueDate()}
      <div className="row">
        <div className="col-8">
          <h4>
            {event.name}{" "}
            <button
              onClick={handleEdit}
              className="btn btn-sm px-0 py-0 ms-2 text-primary"
            >
              <i className="fa fa-edit"></i>
            </button>
          </h4>
        </div>
        <div className="col-4">
          <div onClick={() => setIsOpen(!isOpen)}>
            <StatusBadge status={event.status} />
          </div>
        </div>
      </div>
      {renderDates()}
      <IonActionSheet
        isOpen={isOpen}
        trigger="open-action-sheet"
        header="Change Status"
        buttons={[
          {
            text: "Pending",
            data: "pending",
          },
          {
            text: "Confirmed",
            data: "confirmed",
          },
          {
            text: "Reschedule",
            data: "reschedule",
          },
          {
            text: "Cancel Event",
            data: "cancel",
          },
        ]}
        onDidDismiss={setResult}
      ></IonActionSheet>
    </div>
  );
};
export default EventCard;
