import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { cardStyle } from "../../utils";
import CheckoutService from "../../services/CheckoutService";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import { ProjectsContext } from "../../context/ProjectsContext";

const StripeCreditCard = ({ element_id }) => {
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { user } = useContext(AuthContext);
  const { project } = useContext(ProjectsContext);
  const { alert, success } = useContext(ModalContext);

  const handleError = (error) => {
    console.log(error);
    let message = error;
    if (typeof message === "object") {
      message = error.toString();
    }
    alert(`Hubo un error al procesar tu compra: ${message}`);
    setProcessing(false);
  };

  const handleSuccess = (invoice_id) => {
    setProcessing(false);
    success("Payment registered.");
    if (project && project !== null) {
      navigate(`/project/${project.project_id}/invoice/${invoice_id}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const card = elements.getElement(CardElement);
    const payment_method = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });
    CheckoutService.attempt(element_id, payment_method)
      .then(async (res) => {
        const { clientSecret, invoice_id } = res.data;
        if (clientSecret) {
          const payload = await stripe
            .confirmCardPayment(clientSecret, {
              payment_method: {
                card,
              },
            })
            .catch(handleError);
          if (payload.error) {
            handleError(payload.error.message);
          } else {
            handleSuccess(invoice_id);
          }
        } else {
          handleSuccess(invoice_id);
        }
      })
      .catch((error) => {
        setProcessing(false);
        let message =
          "Hubo un error al procesar tu pago. Ponte en contacto con nosotros.";
        if (error.response) {
          if (error.response.status === 412) {
            message =
              "We're sorry, you cannot complete the purchase at this time.";
          } else if (error.response.status === 409) {
            message =
              "We're sorry, you cannot complete the purchase at this time.";
          } else if (error.response.status === 402) {
            message =
              "We're sorry, your card was declined due to insufficient funds.";
          } else {
            message =
              "We're sorry, your card was declined. Please try with another payment method.";
          }
        }
        return alert(message);
      });
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          options={cardStyle}
          className="form-control pt-2 my-2"
          onChange={handleChange}
        />

        <button
          className="btn btn-secondary w-100 bold mt-2"
          disabled={processing || disabled}
        >
          {processing ? <div className="spinner-border"></div> : "Pay Now"}
        </button>
      </form>
    </div>
  );
};

export default StripeCreditCard;
