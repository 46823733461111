import React, { useContext } from "react";
import DynamicForm from "../global/DynamicForm";
import { ClientsContext } from "../../context/ClientsContext";

const questions = [
  {
    id: "name",
    order: 1,
    type: "text",
    label: "Name",
    required: true,
  },
  {
    id: "email",
    order: 2,
    type: "email",
    label: "Email",
    required: true,
  },
  {
    id: "phone",
    order: 3,
    type: "tel",
    label: "Phone",
    required: true,
  },
  {
    id: "tax_id",
    order: 4,
    type: "text",
    label: "TAX ID",
    required: true,
  },
  {
    id: "country",
    order: 5,
    type: "text",
    label: "Country",
    required: true,
  },
];

const ClientForm = ({ handleCancel, handleCallback }) => {
  const { client, saveClient, setPropertyClient } = useContext(ClientsContext);

  const handleSubmit = (currentClient) => {
    saveClient(currentClient, handleCallback);
  };

  return (
    <DynamicForm
      object={client}
      questions={questions}
      saveAction={handleSubmit}
      handleCancel={handleCancel}
      modifier={setPropertyClient}
    />
  );
};

export default ClientForm;
