import React, { useState, useContext, useEffect } from "react";
import ActivityCard from "../../components/activities/ActivityCard";
import { AnalyticsContext } from "../../context/AnalyticsContext";
import ActionCard from "../../components/actions/ActionCard";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import IonPullRefresh from "../../components/ionic/IonPullRefresh";

const AnalyticsOverdue = () => {
  const [view, setView] = useState("activities");
  const { overdue, getOverdueItems } = useContext(AnalyticsContext);

  useEffect(() => {
    getOverdueItems();
  }, []);

  const renderContent = () => {
    if (view === "activities") {
      return renderActivities();
    }
    return renderActions();
  };

  const renderActivities = () => {
    if (overdue && overdue !== null) {
      const { activities } = overdue;
      if (Array.isArray(activities)) {
        return activities.map((activity) => (
          <ActivityCard key={activity.activity_id} activity={activity} />
        ));
      }
    }
  };

  const renderActions = () => {
    if (overdue && overdue !== null) {
      const { actions } = overdue;
      if (Array.isArray(actions)) {
        return actions.map((action) => (
          <ActionCard key={action.action_id} action={action} />
        ));
      }
    }
  };

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={getOverdueItems} data={overdue} />
      <h1>Overdue Items</h1>
      <IonSegment color="accent" className="mb-3" value={view}>
        <IonSegmentButton
          value="activities"
          onClick={() => setView("activities")}
        >
          <IonLabel>Activities</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="actions" onClick={() => setView("actions")}>
          <IonLabel>Actions</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {renderContent()}
    </div>
  );
};

export default AnalyticsOverdue;
