import React, { useState, useContext, useEffect } from "react";
import AttachmentItem from "../attachments/AttachmentItem";
import AttachmentForm from "../attachments/AttachmentForm";
import { ModalContext } from "../../context/ModalContext";
import { ProjectsContext } from "../../context/ProjectsContext";
import { projectFilesCompleted } from "../../utils";

const ProjectFiles = ({ project_id, files, handleCallback }) => {
  const [error, setError] = useState("");
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { spinner, saveProjectFile, deleteProjectFile } =
    useContext(ProjectsContext);

  useEffect(() => {
    const missing_fields = projectFilesCompleted({ files });
    if (missing_fields.length > 0) {
      setError(`You're missing ${missing_fields.join(", ")}.`);
    }
  }, [files]);

  const handleSubmit = (project_file) => {
    saveProjectFile(project_id, project_file);
  };

  const handleResubmit = (project_file, new_project_file) => {
    saveProjectFile(project_id, {
      file_id: project_file.file_id,
      ...new_project_file,
    });
  };

  const confirmDelete = (attachment) => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Delete File",
        <div>
          <p>
            Are you sure you want to delete the file {attachment.name}? This
            action CANNOT be reversed.
          </p>
          <button
            onClick={() =>
              deleteProjectFile(
                project_id,
                attachment.file.file_id,
                handleCallback
              )
            }
            className="btn btn-danger w-100 mb-3"
          >
            Delete File
          </button>
          <button onClick={clearModal} className="btn text-muted w-100">
            Cancel
          </button>
        </div>
      );
    }, 500);
  };

  const handleCreate = () => {
    modalComponent(
      "Add Attachment",
      <AttachmentForm
        spinner={spinner}
        handleCancel={clearModal}
        handleSubmit={handleSubmit}
      />
    );
  };

  const handleEdit = (attachment) => {
    modalComponent(
      "Edit Attachment",
      <AttachmentForm
        spinner={spinner}
        attachment={attachment}
        handleCancel={clearModal}
        handleSubmit={handleSubmit}
        handleDelete={confirmDelete}
      />
    );
  };

  const renderError = () => {
    if (error && error !== null) {
      return <p className="px-0 mb-2 text-danger">{error}</p>;
    }
  };

  const renderFiles = () => {
    if (Array.isArray(files)) {
      return files.map((file) => (
        <AttachmentItem
          key={file.file_id}
          handleEdit={handleEdit}
          handleSubmit={(new_file) => handleResubmit(file, new_file)}
          attachment={{ name: file.project_file.name, file }}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mx-0 border-bottom align-items-center">
        <div className="col-10 px-0">
          <h2>Files</h2>
        </div>
        <div className="col-2 text-end">
          <button onClick={handleCreate} className="btn btn-sm text-primary">
            <i className="fa fa-plus"></i>
          </button>
        </div>
        {renderError()}
      </div>
      {renderFiles()}
    </div>
  );
};

export default ProjectFiles;
