import React, { useState, useContext, useEffect } from "react";
import BankAccountForm from "../components/bank_accounts/BankAccountForm";
import BankAccountCard from "../components/bank_accounts/BankAccountCard";
import { BankAccountsContext } from "../context/BankAccountsContext";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";

const BankAccounts = () => {
  const { user } = useContext(AuthContext);
  const [currency, setCurrency] = useState("");
  const { modalComponent } = useContext(ModalContext);
  const { bank_accounts, getBankAccounts, createBankAccount } =
    useContext(BankAccountsContext);

  useEffect(() => {
    getBankAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    createBankAccount();
    modalComponent("Add Bank Account", <BankAccountForm />);
  };

  const renderBankAccounts = () => {
    if (Array.isArray(bank_accounts)) {
      if (bank_accounts.length === 0) {
        return <p>No bank accounts available.</p>;
      }
      let bankAccountsRender = [...bank_accounts];
      if (currency !== "") {
        bankAccountsRender = bankAccountsRender.filter(
          (account) => account.currency === currency
        );
      }
      if (bankAccountsRender.length === 0 && currency !== "") {
        return <p>No accounts available for this currency.</p>;
      }
      return bankAccountsRender.map((account) => (
        <BankAccountCard key={account.bank_account_id} bank_account={account} />
      ));
    }
    return <div className="spinner-border"></div>;
  };
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-8">
          <h1 className="mb-0">Bank Accounts</h1>
        </div>
        <div className="col-4 px-0 text-end">
          {user.user_type_id > 2 && (
            <button onClick={handleCreate} className="btn text-primary">
              <i className="fa fa-plus"></i>
            </button>
          )}
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-8">Filter by Currency</div>
        <div className="col-4">
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="form-control py-1 px-2"
          >
            <option value="">All</option>
            <option>MXN</option>
            <option>USD</option>
            <option>GBP</option>
            <option>EUR</option>
          </select>
        </div>
      </div>
      {renderBankAccounts()}
    </div>
  );
};

export default BankAccounts;
