import React from "react";
import moment from "moment";
import StatusBadge from "../global/StatusBadge";
import { formatMonto } from "../../utils";

const PaymentCard = ({ payment }) => {
  return (
    <div className="card p-3 shadow-sm mb-3">
      <div className="row mb-3 align-items-center">
        <div className="col-4">#{payment.payment_id}</div>
        <div className="col-4">${formatMonto(payment.amount)}</div>
        <div className="col-4">{payment.currency}</div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-4">
          <StatusBadge status={payment.status} />
        </div>
        <div className="col-4 small">
          <span className="small bold d-block">Created At</span>
          {moment(payment.recordedAt).format("MMM Do YYYY")}{" "}
        </div>
        <div className="col-4 small">
          <span className="small bold d-block">Confirmed At</span>
          {payment.confirmedAt !== null
            ? moment(payment.confirmedAt).format("MMM Do YYYY")
            : "Not Confirmed"}
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
