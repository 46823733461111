import React, { useState, useContext, useEffect } from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import ProductInventory from "../components/products/ProductInventory";
import ProductPrices from "../components/products/ProductPrices";
import ProductInputs from "../components/products/ProductInputs";
import IonPullRefresh from "../components/ionic/IonPullRefresh";
import ProductItem from "../components/products/ProductItem";
import { ProductsContext } from "../context/ProductsContext";
import { OrdersContext } from "../context/OrdersContext";
import OrderList from "../components/order/OrderList";

const SingleProduct = ({ project_id, product_id }) => {
  const [view, setView] = useState("inventory");
  const { orders, getAllOrders } = useContext(OrdersContext);
  const { product, getSingleProduct } = useContext(ProductsContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  const fetchData = () => {
    getAllOrders({ product_id });
    getSingleProduct(product_id);
  };

  const renderProduct = () => {
    if (product && product !== null) {
      return <ProductItem product={product} disablePicker />;
    }
  };

  const renderView = () => {
    if (product && product !== null) {
      if (view === "prices") {
        return <ProductPrices product_prices={product.product_prices} />;
      } else if (view === "inventory") {
        return (
          <div>
            <ProductInventory product={product} />
            <h3 className="bold">Pending Orders</h3>
            <OrderList orders={orders} />
          </div>
        );
      }
      return (
        <ProductInputs
          project_id={project_id}
          parent_id={product_id}
          inputs={product.inputs}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container">
      <IonPullRefresh handleCallback={fetchData} data={product} />
      <h1>Product #{product_id}</h1>
      {renderProduct()}
      <IonSegment value={view} className="mt-3">
        <IonSegmentButton
          value="inventory"
          onClick={() => setView("inventory")}
        >
          <IonLabel>Inventory</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="prices" onClick={() => setView("prices")}>
          <IonLabel>Prices</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="inputs" onClick={() => setView("inputs")}>
          <IonLabel>Inputs</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {renderView()}
    </div>
  );
};

export default SingleProduct;
