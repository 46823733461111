import React, { useContext, useEffect } from "react";
import { AnalyticsContext } from "../../context/AnalyticsContext";
import InvoicesProject from "../../components/invoices/InvoicesProject";
import InvoiceFilters from "../../components/invoices/InvoiceFilters";
import BalanceChart from "../../components/finances/BalanceChart";
import { InvoicesContext } from "../../context/InvoicesContext";
import moment from "moment";
import IonPullRefresh from "../../components/ionic/IonPullRefresh";

const AnalyticsIncome = () => {
  const { filters, setFilter } = useContext(InvoicesContext);
  const { income, getTotalIncome } = useContext(AnalyticsContext);

  useEffect(() => {
    setFilter("end_date", moment().utc().endOf("month").format("YYYY-MM-DD"));
    setFilter(
      "start_date",
      moment().utc().startOf("month").format("YYYY-MM-DD")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = () => {
    if (filters.start_date && filters.end_date) {
      getTotalIncome({ ...filters });
    }
  };

  const renderProjectInvoices = () => {
    if (income && income !== null) {
      const { invoices } = income;
      if (Array.isArray(invoices)) {
        if (invoices.length === 0) {
          return <p>No available information to display.</p>;
        }
        return invoices.map((invoice) => (
          <InvoicesProject key={invoice.project_id} project={invoice} />
        ));
      }
    }
  };

  const renderBalanceCharts = () => {
    if (income && income !== null) {
      if (Array.isArray(income.payments)) {
        if (income.payments.length === 0) {
          return <p>No available information to display.</p>;
        }
        return income.payments.map((account) => (
          <BalanceChart key={account.currency} account={account} />
        ));
      }
    }
  };

  return (
    <div className="container pb-5">
      <IonPullRefresh handleCallback={fetchData} data={income} />
      <h1>Income</h1>
      <InvoiceFilters />
      <h2>Balance</h2>
      {renderBalanceCharts()}
      <h2>Projects</h2>
      {renderProjectInvoices()}
    </div>
  );
};

export default AnalyticsIncome;
