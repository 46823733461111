import React from "react";
import { getValue } from "../../utils";

const QuantityPicker = ({ value, setQuantity }) => {
  return (
    <div className="row mt-2 align-items-center">
      <div className="col-4 col-md-2">
        <button
          disabled={value === 0}
          className="btn border btn-sm text-primary"
          onClick={() => {
            if (value > 0) setQuantity(value ? value - 1 : 0);
          }}
        >
          <i className="fa fa-minus"></i>
        </button>
      </div>
      <div className="col-4 col-md-8 px-0 text-center">
        <input
          type="number"
          className="form-control py-1"
          value={getValue({ value }, "value")}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </div>
      <div className="col-4 col-md-2">
        <button
          className="btn border btn-sm text-primary"
          onClick={() => setQuantity(value ? value + 1 : 1)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
};

export default QuantityPicker;
