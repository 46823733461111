import { navigate } from "@reach/router";

const SetupStepCard = ({
  id,
  title,
  link,
  checked,
  disabled,
  project_id,
  description,
}) => {
  // const checked = false;

  const renderCheckbox = (checked) => {
    if (checked) {
      return (
        <div style={{ marginRight: ".5rem" }} className="col-1">
          <input
            type="checkbox"
            className="form-check-input mt-0 bg-accent fs-2 border-0"
            checked={checked}
            disabled={false}
            onChange={() => {}}
          />
        </div>
      );
    }
  };

  const renderDescription = (checked) => {
    if (!checked) {
      return <p className="mb-0 text-muted small">{description}</p>;
    }
  };

  return (
    <div
      onClick={() => {
        if (!disabled) {
          navigate(`/project/${project_id}/${link}`);
        }
      }}
      disabled={disabled}
      className="card text-dark no-decoration shadow-sm p-3 mb-3"
    >
      <div className="container-fluid px-0">
        <div className="row align-items-center">
          {renderCheckbox(checked)}
          <div className="col-1">
            <p className="mb-0 text-primary fs-1 fw-bold">{id}</p>
          </div>
          <div className="col-8">
            <h3 className="mb-0 fs-6 fw-bold">{title}</h3>
            {renderDescription(checked)}
          </div>
          <div className={`col-${checked ? "1" : "3"} text-end`}>
            {!disabled && (
              <button className="btn btn-light btn-sm btn-round px-2">
                <i className="fa fa-chevron-right mx-1 text-secondary"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupStepCard;
