import { Link } from "@reach/router";
import React, { useContext } from "react";
import UserData from "../components/user/UserData";
import { AuthContext } from "../context/AuthContext";
import NavbarLink from "../components/navbar/NavbarLink";
import UserHeader from "../components/user/UserHeader";

const Profile = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="container-fluid pt-4">
      <UserHeader user={user} />
      <NavbarLink link="/" name="Addresses" icon="map-pin" />
      <NavbarLink link="/" name="Payment Methods" icon="credit-card" />
      <UserData user={user} />
      <div className="mt-5 pb-4">
        <Link to="/profile/edit" className="btn btn-light border w-100">
          Edit Profile
        </Link>
      </div>
    </div>
  );
};

export default Profile;
