import React, { useState, useEffect, useContext } from "react";
import { parse } from "query-string";
import { useLocation } from "@reach/router";
import { ProjectsContext } from "../context/ProjectsContext";
import BottomAction from "../components/global/BottomAction";

const ImportProject = () => {
  const location = useLocation();
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState("");
  const [authCode, setAuthCode] = useState(null);
  const { deals, postProject, getZohoDeals } = useContext(ProjectsContext);

  useEffect(() => {
    handleURLParams();
  }, []);

  useEffect(() => {
    if (authCode) {
      getZohoDeals(authCode);
    }
  }, [authCode]);

  const handleURLParams = () => {
    const searchParams = parse(location.search);
    const { code } = searchParams;
    if (code) {
      setAuthCode(code);
    }
  };

  const handleImport = () => {
    const currentProjet = deals.find((deal) => deal.id === selected);
    postProject(currentProjet);
  };

  const renderProjects = () => {
    if (Array.isArray(deals)) {
      if (deals.length === 0) {
        return <p>No deals found</p>;
      }
      let dealsRender = [...deals];
      if (query && query !== "") {
        dealsRender = dealsRender.filter((deal) =>
          String(deal.Deal_Name)
            .toLowerCase()
            .includes(String(query).toLowerCase())
        );
      }
      return (
        <div>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Search project..."
            onChange={(e) => setQuery(e.target.value)}
          />
          {dealsRender.map((deal) => (
            <div
              key={deal.id}
              onClick={(e) => {
                if (e.target.checked) {
                  setSelected(deal.id);
                }
              }}
              className={`card shadow-sm mb-3 p-3 ${
                selected === deal.id ? "border border-primary" : ""
              }`}
            >
              <div className="row align-items-center">
                <div className="col-10">
                  <h4 className="mb-0">{deal.Deal_Name}</h4>
                </div>
                <div className="col-2 text-end">
                  <input type="checkbox" checked={selected === deal.id} />
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderButton = () => {
    if (selected !== "" && selected !== null) {
      return <BottomAction onClick={handleImport} title="Import Project" />;
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="border-bottom pb-2 mb-2">Import from Zoho</h1>
      {renderProjects()}
      {renderButton()}
    </div>
  );
};

export default ImportProject;
