import api from "./api";
import { getArgs } from "../utils";

const route = "/orders";

const OrdersService = {
  getAllOrders: (filters) => api.get(`${route}/admin/all?${getArgs(filters)}`),
  getProjectOrders: (project_id) => api.get(`${route}/${project_id}`),
  getSingleOrder: (project_id, order_id) =>
    api.get(`${route}/${project_id}/order/${order_id}`),
  getSingleOrderAdmin: (order_id) => api.get(`${route}/order/${order_id}`),
  getOrderSteps: (project_id, order_id) =>
    api.get(`${route}/${project_id}/order/${order_id}/steps`),
  postOrder: (project_id, order) =>
    api.post(`${route}/${project_id}`, { ...order }),
  putOrder: (project_id, order) =>
    api.put(`${route}/${project_id}`, { ...order }),
  postOrderAdmin: (order) => api.post(route, { ...order }),
  putOrderAdmin: (order) => api.put(route, { ...order }),
  postOrderApproval: (project_id, order_id) =>
    api.post(`${route}/${project_id}/order/${order_id}/approve`),
  postOrderUpdate: (project_id, order_id, order_update) =>
    api.post(`${route}/${project_id}/order/${order_id}/update`, {
      ...order_update,
    }),
  putOrderProduct: (project_id, order_product) =>
    api.put(`${route}/${project_id}/products`, { ...order_product }),
  deleteOrderApproval: (project_id, order_id) =>
    api.delete(`${route}/${project_id}/order/${order_id}/approve`),
  deleteOrderUpdate: (project_id, order_update_id) =>
    api.delete(`${route}/${project_id}/update/${order_update_id}`),
};
export default OrdersService;
