import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const UserData = ({ user }) => {
  const { recoverPassword } = useContext(AuthContext);
  return (
    <div className="container-fluid mt-4">
      <div className="row mb-2">
        <p className="px-0 text-muted">About</p>
      </div>
      <div className="row py-3 border-bottom">
        <div className="col-6 ps-0">Full Name</div>
        <div className="col-6 pe-0 text-end text-accent">
          {user.name} {user.last_name}
        </div>
      </div>
      <div className="row py-3 border-bottom">
        <div className="col-6 ps-0">Your Email</div>
        <div className="col-6 pe-0 text-end text-accent small">
          {user.email}
        </div>
      </div>
      <div className="row align-items-center py-3 border-bottom">
        <div className="col-6 ps-0">Reset Password</div>
        <div className="col-6 pe-0 text-end">
          <button
            className="btn p-0"
            onClick={() => recoverPassword(user.email)}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserData;
