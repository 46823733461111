import {
  CLEAR_CART,
  APPEND_PRODUCT,
  REMOVE_PRODUCT,
  SET_PRODUCT_QUANTITY,
} from "../types/cart";
import {
  SET_INVENTORY,
  CREATE_INVENTORY,
  INVENTORIES_RECEIVED,
  SET_PROPERTY_INVENTORY,
  INVENTORY_REMINDERS_RECEIVED,
  SET_INVENTORY_REMINDER,
  CREATE_REMINDER,
} from "../types/inventories";
import { PRODUCTS_RECEIVED } from "../types/products";

const schema = {
  inventory_id: "",
};

const reminderSchema = {
  sendAt: "",
};

const InventoryReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_INVENTORY_REMINDER:
      return { ...state, reminder: payload };
    case CREATE_REMINDER:
      return { ...state, reminder: reminderSchema };
    case INVENTORY_REMINDERS_RECEIVED:
      return { ...state, reminders: payload };
    case PRODUCTS_RECEIVED: {
      const products = [...state.products, ...payload];
      return { ...state, products };
    }
    case INVENTORIES_RECEIVED:
      return { ...state, inventories: payload };
    case SET_INVENTORY:
      return { ...state, inventory: payload };
    case SET_PROPERTY_INVENTORY:
      const { key, value } = payload;
      const inventory = { ...state.inventory };
      inventory[key] = value;
      return { ...state, inventory };
    case CREATE_INVENTORY:
      return { ...state, inventory: schema };
    case CLEAR_CART:
      return { ...state, products: [] };
    case APPEND_PRODUCT: {
      const products = [...state.products];
      const { product_id, quantity } = payload;
      const index = products.findIndex(
        (product) => product.product_id === product_id
      );
      if (index === -1) {
        products.push(payload);
      } else {
        products[index].quantity =
          parseInt(products[index].quantity) + parseInt(quantity);
      }
      return { ...state, products };
    }
    case SET_PRODUCT_QUANTITY: {
      const products = [...state.products];
      const { product_id, quantity } = payload;
      const index = products.findIndex(
        (product) => product.product_id === product_id
      );
      if (index !== -1) {
        products[index].quantity = quantity;
      }
      return { ...state, products };
    }
    case REMOVE_PRODUCT: {
      const products = [...state.products];
      const product_id = payload;
      const index = products.findIndex(
        (product) => product.product_id === product_id
      );
      if (index !== -1) {
        products.splice(index, 1);
      }
      return { ...state, products };
    }
    default:
      return { ...state };
  }
};
export default InventoryReducer;
