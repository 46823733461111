import api from "./api";
import { getArgs } from "../utils";

const route = "/steps";

const StepsService = {
  getStepsProject: (project_id, filters) =>
    api.get(`${route}/project/${project_id}?${getArgs(filters)}`),
  getStepsProcess: (process_id) => api.get(`${route}/process/${process_id}`),
  getSingleStep: (step_id) => api.get(`${route}/${step_id}`),
  commentStep: (step_id, comment) =>
    api.post(`${route}/${step_id}/comment`, { ...comment }),
  postStepFile: (step_id, file) =>
    api.post(`${route}/${step_id}/file`, { ...file }),
  putStepFile: (step_id, file) =>
    api.put(`${route}/${step_id}/file`, {
      ...file,
    }),
  deleteStepFile: (step_id, file_id) =>
    api.delete(`${route}/${step_id}/file/${file_id}`),
  postStep: (step) => api.post(route, { ...step }),
  putStep: (step) => api.put(route, { ...step }),
  deleteStep: (step_id) => api.delete(`${route}/${step_id}`),
};

export default StepsService;
