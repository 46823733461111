import React, { useContext } from "react";
import { UsersContext } from "../../context/UsersContext";

const UserDeleteConfirm = ({ user, handleCancel }) => {
  const { deleteUser } = useContext(UsersContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    deleteUser(user.user_id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="bold">
        Are you sure you want to delete the user {user.email}?
      </h3>
      <p>The user will lose access to the app immediately.</p>
      <p>This action cannot be reversed.</p>
      <button type="submit" className="btn btn-danger w-100 mb-3">
        Delete User
      </button>
      <button
        type="button"
        className="btn btn-link text-secondary w-100"
        onClick={handleCancel}
      >
        Cancel
      </button>
    </form>
  );
};

export default UserDeleteConfirm;
