import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { StepsContext } from "../../context/StepsContext";
import { ModalContext } from "../../context/ModalContext";
import DeleteConfirm from "../global/DeleteConfirm";
import StepForm from "./StepForm";
import StatusBadge from "../global/StatusBadge";
import TimeLeftBadge from "../global/TimeLeftBadge";

const StepName = ({ step, project_id }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { spinner, deleteStep } = useContext(StepsContext);
  const { user } = useContext(AuthContext);

  const handleDelete = () => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Delete Step",
        <DeleteConfirm
          itemName="step"
          spinner={spinner}
          handleDelete={deleteStep}
          item={{ ...step, project_id }}
          description="This will delete all activities and actions from this step."
        />
      );
    }, 500);
  };

  const handleEditStep = () => {
    modalComponent(
      "Edit Step",
      <StepForm handleCancel={clearModal} handleDelete={handleDelete} />
    );
  };

  const renderTimeLeft = () => {
    if (step.end_date !== null && step.status !== "completed") {
      return <TimeLeftBadge date={step.end_date} />;
    }
  };

  const renderButton = () => {
    if (user.user_type_id > 3) {
      return (
        <button
          className="btn btn-sm px-2 ms-2 text-accent"
          onClick={handleEditStep}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-8">
          <h3>{step.name}</h3>
        </div>
        <div className="col-4 text-end">{renderButton()}</div>
      </div>
      <div className="row">
        <div className="col-4">
          <StatusBadge status={step.status} />
        </div>
        <div className="col-8 text-end">{renderTimeLeft()}</div>
      </div>
    </div>
  );
};

export default StepName;
