import React from "react";
import Chart from "react-apexcharts";
import { formatMonto } from "../../utils";

const BalanceChart = ({ account }) => {
  const renderPaymentsChart = () => {
    if (account && account !== null) {
      const total = parseFloat(account.paid) + parseFloat(account.due);
      const series = [
        parseFloat(account.paid) / total,
        parseFloat(account.due) / total,
      ];
      return (
        <div>
          <p className="mb-1">
            ${formatMonto(account.paid)} / ${formatMonto(total)}
          </p>
          <Chart
            type="donut"
            height="415"
            width="100%"
            options={{
              labels: ["Paid", "Due"],
              title: {
                text: account.currency
              }
            }}
            series={series}
          />
        </div>
      );
    }
  };
  return <div className="card p-3 shadow-sm mb-3">{renderPaymentsChart()}</div>;
};

export default BalanceChart;
