import React, { useReducer, createContext } from "react";
import OrderTemplatesReducer from "../reducers/OrderTemplatesReducer";
import OrderTemplatesService from "../services/OrderTemplatesService";
import { ORDER_TEMPLATES_RECEIVED } from "../types/order_templates";

const initialState = {
  order_templates: null,
};

export const OrderTemplatesContext = createContext(initialState);

export const OrderTemplatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrderTemplatesReducer, initialState);

  const getOrderTemplates = () => {
    OrderTemplatesService.getOrderTemplates().then((res) => {
      const { order_templates } = res.data;
      dispatch({ type: ORDER_TEMPLATES_RECEIVED, payload: order_templates });
    });
  };

  return (
    <OrderTemplatesContext.Provider value={{ ...state, getOrderTemplates }}>
      {children}
    </OrderTemplatesContext.Provider>
  );
};
