import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import UploadSheet from "../components/xlsx/UploadSheet";
import SelectSheet from "../components/xlsx/SelectSheet";
import { getProductsFromWorkbook } from "../utils/products";
import PreviewProductsTable from "../components/products/PreviewProductsTable";

const UploadProductsForm = ({ project_id }) => {
  const [file, setFile] = useState(null);
  const [products, setProducts] = useState(null);
  const [workbook, setWorkbook] = useState(null);
  const [sheetName, setSheetName] = useState("");
  const [sheetNames, setSheetNames] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [productsFields, setProductsFields] = useState(null);

  useEffect(() => {
    if (file !== null) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        processProductsFile(e.target.result);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (workbook && workbook !== null && sheetName !== "") {
      extractProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workbook, sheetName]);

  const resetSheet = () => {
    setSheetName("");
    setProductsFields(null);
  };

  const processProductsFile = (bufferArray) => {
    const current = XLSX.read(bufferArray, { type: "buffer" });
    setSheetNames(current.SheetNames);
    setWorkbook(current);
  };

  const extractProducts = () => {
    setShowSpinner(true);
    const parsedProducts = getProductsFromWorkbook(workbook, sheetName);
    setProducts(parsedProducts);
    setShowSpinner(false);
  };

  const renderProducts = () => {
    if (Array.isArray(products)) {
      return (
        <PreviewProductsTable project_id={project_id} products={products} />
      );
    }
  };

  return (
    <div>
      <h1>Import Products</h1>
      <UploadSheet file={file} setFile={setFile} />
      <SelectSheet
        sheetName={sheetName}
        sheetNames={sheetNames}
        resetSheet={resetSheet}
        setSheetName={setSheetName}
      />
      {showSpinner && <div className="spinner-border" />}
      {renderProducts()}
    </div>
  );
};

export default UploadProductsForm;
