import React from "react";
import UserItem from "../user/UserItem";

const MenuHeader = ({ user, hideButton }) => {
  const renderUser = () => {
    if (user !== null) {
      return (
        <UserItem user={user}>
          <p className="mb-0 text-muted text-capitalize">
            {user.user_type.name}
          </p>
        </UserItem>
      );
    }
  };

  return (
    <div className="container-fluid ps-0">
      {!hideButton ? (
        <div className="row align-items-center">
          <div className="col-10">{renderUser()}</div>
          <div className="col-2 pe-0 text-end">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
        </div>
      ) : (
        renderUser()
      )}
    </div>
  );
};
export default MenuHeader;
