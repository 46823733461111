import React, { useEffect, useState } from "react";

const AttachmentForm = ({
  attachment,
  handleCancel,
  handleSubmit,
  handleDelete,
}) => {
  const [name, setName] = useState("");
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null);
  const [type, setType] = useState("link");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (attachment && attachment !== null) {
      if (attachment.name && attachment.name !== null && name === "") {
        setName(attachment.name);
      }
      if (attachment.type !== null && type === null) {
        setType(attachment.type);
      }
      if (attachment.src !== null && src === null) {
        setSrc(attachment.src);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      file,
      name,
    };
    if (attachment && attachment !== null) {
      payload = { ...attachment, ...payload };
    }
    handleSubmit(payload);
  };

  const renderSource = () => {
    if (type !== "file") {
      return (
        <div>
          <input
            type="text"
            value={src}
            placeholder="URL (https)"
            className="form-control mb-3"
            onChange={(e) => setSrc(e.target.value)}
          />
        </div>
      );
    }
    if (type === "file" || type === null) {
      return (
        <div>
          <label className="small">
            Leave blank to complete by another user
          </label>
          <input
            type="file"
            className="form-control mb-3"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <label>Name</label>
      <input
        type="text"
        value={name}
        className="form-control mb-3"
        onChange={(e) => setName(e.target.value)}
      />
      <label>Type</label>
      <select
        value={type}
        className="form-control mb-3"
        onChange={(e) => setType(e.target.value)}
      >
        <option value="link">Link</option>
        <option value="vimeo">Vimeo</option>
        <option value="youtube">YouTube</option>
        <option value="file">File / Image</option>
      </select>
      <label className="d-block">Source</label>
      {renderSource()}
      <button
        type="submit"
        disabled={loading}
        className="btn btn-primary w-100 my-3"
      >
        {loading ? <div className="spinner-border"></div> : "Save Attachment"}
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn btn-link text-secondary w-100"
      >
        Cancel
      </button>
      {typeof handleDelete === "function" && (
        <button
          type="button"
          onClick={() => handleDelete(attachment)}
          className="btn btn-link text-danger mt-3 text-secondary w-100"
        >
          Delete
        </button>
      )}
    </form>
  );
};

export default AttachmentForm;
