import React from "react";
import IconItem from "../global/IconItem";

const AccordionItem = ({ action }) => {
  const renderDependences = () => {
    if (Array.isArray(action.dependences)) {
      if (action.dependences.length > 0) {
        return (
          <div>
            <h4>Dependences</h4>
            {action.dependences.map((dependence) => (
              <IconItem icon="lock" name={dependence.name} />
            ))}
          </div>
        );
      }
    }
  };
  return (
    <li key={action.name} className="mb-1">
      {action.name} {renderDependences()}
    </li>
  );
};

const ActionsAccordion = ({ actions }) => {
  const renderActions = () => {
    if (Array.isArray(actions)) {
      if (actions.length === 0) {
        return <p className="my-3">No actions found for this step.</p>;
      }
      return (
        <ol className="ps-3">
          {actions.map((action) => (
            <AccordionItem key={action.action_id} action={action} />
          ))}
        </ol>
      );
    }
  };
  return <div>{renderActions()}</div>;
};

export default ActionsAccordion;
