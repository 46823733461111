import api from "./api";

const route = "/users";

const UsersService = {
  getUsers: () => api.get(route),
  getProjectUsers: (project_id) => api.get(`${route}/project/${project_id}`),
  getSingleUser: (user_id) => api.get(`${route}/${user_id}`),
  postUser: (user) => api.post(route, { ...user }),
  putUser: (user) => api.put(route, { ...user }),
  deleteUser: (user_id) => api.delete(`${route}/${user_id}`),
};
export default UsersService;
