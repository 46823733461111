import React from "react";
import { getValue } from "../../utils";

const CalendarFilters = ({
  users,
  user_id,
  setFilter,
  event_types,
  event_type_id,
}) => {
  const renderEventTypes = () => {
    if (Array.isArray(event_types)) {
      return [
        <option key="" value="">
          Showing All
        </option>,
        ...event_types.map((event_type) => (
          <option
            key={event_type.event_type_id}
            value={event_type.event_type_id}
          >
            {event_type.name}
          </option>
        )),
      ];
    }
  };

  const renderProjectUsers = () => {
    if (Array.isArray(users)) {
      return [
        <option key="" value="">
          Showing All
        </option>,
        ...users.map((user) => (
          <option key={user.user_id} value={user.user_id}>
            {user.name} - {user.email}
          </option>
        )),
      ];
    }
  };
  return (
    <div className="row mb-3">
      <div className="col-6">
        <label>Event Type</label>
        <select
          onChange={(e) => setFilter("event_type_id", e.target.value)}
          value={getValue({ event_type_id }, "event_type_id")}
          className="form-control"
        >
          {renderEventTypes()}
        </select>
      </div>
      <div className="col-6">
        <label>Users</label>
        <select
          onChange={(e) => setFilter("user_id", e.target.value)}
          value={getValue({ user_id }, "user_id")}
          className="form-control"
        >
          {renderProjectUsers()}
        </select>
      </div>
    </div>
  );
};

export default CalendarFilters;
