import React, { useState, useContext, useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";
import ProjectForm from "./ProjectForm";
import { projectDataComplete } from "../../utils";
import { AuthContext } from "../../context/AuthContext";

const ProjectData = ({ project }) => {
  const [error, setError] = useState("");
  const { user } = useContext(AuthContext)
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    const missing_fields = projectDataComplete(project);
    if (missing_fields.length > 0) {
      setError(`You're missing ${missing_fields.join(", ")}.`);
    } else {
      setError("");
    }
  }, [project]);

  const handleEdit = () => {
    modalComponent("Edit Project", <ProjectForm handleCancel={clearModal} />);
  };

  const renderError = () => {
    if (error && error !== null) {
      return <p className="px-0 mb-2 text-danger">{error}</p>;
    }
  };

  const renderProjectInfo = () => {
    if (user.user_type_id > 2) {
      return [
        <div key="grade" className="row pb-2 mb-2 border-bottom">
          <div className="col-8 px-0">Grade:</div>
          <div className="col-4">{project.grade}</div>
        </div>,
        <div key="order_size" className="row pb-2 mb-2 border-bottom">
          <div className="col-8 px-0">Order Size:</div>
          <div className="col-4">{project.order_size}</div>
        </div>,
      ];
    }
  };

  return (
    <div className="card p-3 shadow mb-3">
      <div className="row mx-0 mb-3 border-bottom align-items-center">
        <div className="col-10 px-0">
          <h1>{project.name}</h1>
        </div>
        <div className="col-2 text-end">
          <button onClick={handleEdit} className="btn btn-sm text-primary">
            <i className="fa fa-edit"></i>
          </button>
        </div>
        {renderError()}
        {project.description !== null && (
          <p className="px-0 mb-2">{project.description}</p>
        )}
      </div>
      <div className="container-fluid">
        {renderProjectInfo()}
        <div className="row pb-2 mb-2 border-bottom">
          <div className="col-8 px-0">Type of Product:</div>
          <div className="col-4">{project.product_development_plan}</div>
        </div>
      </div>
    </div>
  );
};
export default ProjectData;
