import React, { useContext } from "react";
import { OrdersContext } from "../../context/OrdersContext";
import SelectUserType from "../global/SelectUserType";
import { getValue } from "../../utils";
import ProjectPicker from "../projects/ProjectPicker";

const OrderFilters = () => {
  const { filters, setFilter } = useContext(OrdersContext);
  return (
    <div className="container-fluid my-3 px-0">
      <div className="row">
        <div className="col-4">
          <label>Status</label>
          <select
            className="form-control"
            value={getValue(filters, "status")}
            onChange={(e) => {
              let { value } = e.target;
              if (value === "null") value = null;
              setFilter("status", e.target.value);
            }}
          >
            <option value="null">All</option>
            <option value="created">Created</option>
            <option value="quoted">Quoted</option>
            <option value="ready">Ready</option>
            <option value="approved">Approved</option>
            <option value="in-progress">In-Progress</option>
            <option value="delivered">Delivered</option>
          </select>
        </div>
        <div className="col-4">
          <SelectUserType
            value={getValue(filters, "minimum_level")}
            modifier={(value) => setFilter("minimum_level", value)}
          />
        </div>
        <div className="col-4">
          <ProjectPicker
            selected={getValue(filters, "project")}
            modifier={(project) => {
              if (project !== null) {
                setFilter("project_id", project.project_id);
              } else {
                setFilter("project_id", null);
              }
              setFilter("project", project);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <label>From</label>
          <input
            type="date"
            value={filters.start_date}
            className="form-control"
            onChange={(e) => setFilter("start_date", e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>To</label>
          <input
            type="date"
            value={filters.end_date}
            className="form-control"
            onChange={(e) => setFilter("end_date", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderFilters;
