import React, { useContext, useEffect } from "react";
import moment from "moment";
import ProcessCard from "./ProcessCard";
import ProcessForm from "./ProcessForm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ProcessContext } from "../../context/ProcessContext";
import { ProjectsContext } from "../../context/ProjectsContext";

const ProjectProcesses = ({ project_id }) => {
  const { processes, clearProcess, createProcess, getProcessesProject } =
    useContext(ProcessContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { project } = useContext(ProjectsContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getProcessesProject(project_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const handleCloseModal = () => {
    clearModal();
    clearProcess();
  };

  const handleCreate = () => {
    createProcess();
    modalComponent(
      "Add Process",
      <ProcessForm project_id={project_id} handleCancel={handleCloseModal} />
    );
  };

  const renderButton = () => {
    if (user.user_type_id > 3) {
      return (
        <button
          onClick={handleCreate}
          className="btn py-0 px-2 btn-sm text-primary"
        >
          <i className="fa fa-plus"></i>
        </button>
      );
    }
  };

  const renderProcesses = () => {
    if (Array.isArray(processes)) {
      let currentProcesses = processes.sort((a, b) =>
        moment(a).isAfter(moment(b)) ? -1 : 1
      );
      if (currentProcesses.length === 0) {
        return <p>No processes found for this project.</p>;
      }
      return (
        <div
          className="process-wrapper"
          style={{ width: `${currentProcesses.length * 366}px` }}
        >
          <div className="row mx-0">
            {currentProcesses.map((process) => (
              <ProcessCard
                project_id={project_id}
                key={process.process_id}
                process={process}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="row border-bottom pb-2 mb-2 mx-0">
        <div className="col-8 px-0">
          <h2 className="mb-0">Processes</h2>
        </div>
        <div className="col-4 px-0 text-end">{renderButton()}</div>
      </div>
      <div className="process-container">{renderProcesses()}</div>
    </div>
  );
};

export default ProjectProcesses;
