import React from "react";
import pattern from "../assets/images/bg-pattern-secondary.png";
import logo_white from "../assets/images/logo-white.png";
import { navigate } from "@reach/router";

const Loading = () => {
  return (
    <div
      className="container-fluid bg-image vh-100"
      style={{ backgroundImage: `url("${pattern}")` }}
    >
      <div className="row h-100 align-items-center">
        <div className="container-fluid text-center">
          <img
            src={logo_white}
            style={{ maxWidth: 300 }}
            className="m-auto d-block w-100 mb-4"
          />
          <h1 className="text-white">From the agave to the brand experience</h1>
          <button
            className="btn btn-primary mt-5 px-0 py-0 text-white btn-round shadow"
            style={{ width: 50, height: 50 }}
            onClick={() => navigate("/login")}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Loading;
