import api from "./api";

const route = "/process";

const ProcessService = {
  getProcessesProject: (project_id) =>
    api.get(`${route}/project/${project_id}`),
  getSingleProcess: (process_id) => api.get(`${route}/${process_id}`),
  commentProcess: (process_id, comment) =>
    api.post(`${route}/${process_id}/comment`, { ...comment }),
  postProcessFile: (process_id, file) =>
    api.post(`${route}/${process_id}/file`, { ...file }),
  putProcessFile: (process_id, process_file_id, file) =>
    api.post(`${route}/${process_id}/file/${process_file_id}`, { ...file }),
  deleteProcessFile: (process_id, file_id) =>
    api.delete(`${route}/${process_id}/file/${file_id}`),
  postProcess: (project_id, process) =>
    api.post(`${route}/project/${project_id}`, { ...process }),
  putProcess: (project_id, process) =>
    api.put(`${route}/project/${project_id}`, {
      ...process,
    }),
  deleteProcess: (project_id, process_id) =>
    api.delete(`${route}/${project_id}/${process_id}`),
};
export default ProcessService;
