import api from "./api";

const route = "/user";

const userService = {
  getCurrentUser: () => api.get(route),
  postSignUp: (data) => api.post(`${route}/signup`, { ...data }),
  postUserDevice: (data) => api.post(`${route}/device`, { ...data }),
  updateCurrentUser: (data) => api.put(route, { ...data }),
  putUserDevice: (data) => api.put(`${route}/device`, { ...data }),
  recoverPassword: (email) => api.post(`${route}/recover`, { email }),
  deleteCurrentUser: () => api.delete(route),
  deleteUserDevice: (user_device_id) =>
    api.delete(`${route}/device/${user_device_id}`),
};

export default userService;
