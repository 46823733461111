import React from "react";

const OrderStatusBadge = ({ status }) => {
  return (
    <div className="small bold text-capitalize">
      {["revoked", "cancelled", "refunded"].includes(status) ? (
        <span className="badge badge-pill bg-danger">{status}</span>
      ) : ["active", "success", "completed"].includes(status) ? (
        <span className="badge badge-pill bg-success">{status}</span>
      ) : status === "pending" ? (
        <span className="badge badge-pull bg-warning text-dark">{status}</span>
      ) : (
        <span className="badge badge-pull bg-secondary">{status}</span>
      )}
    </div>
  );
};
export default OrderStatusBadge;
