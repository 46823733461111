import api from "./api";

const route = "/user_devices";

export default {
  getUserDevices: () => api.get(route),
  getSingleUserDevice: (user_device_id) =>
    api.get(`${route}/${user_device_id}`),
  postUserDevice: (user_device) => api.post(route, { ...user_device }),
  putUserDevice: (user_device) => api.put(route, { ...user_device }),
  deleteUserDevice: (UserDevice) => api.delete(`${route}/${UserDevice}`),
};
