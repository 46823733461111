import { NotificationsContext } from "../../context/NotificationsContext";
import React, { useContext } from "react";
import { navigate } from "@reach/router";
import moment from "moment";

const NotificationCard = ({ notification }) => {
  const { readNotification } = useContext(NotificationsContext);

  const renderRead = () => {
    if (notification.readAt !== null) {
      return <i className="fa fa-check-circle text-accent" />;
    }
    return <i className="fa fa-exclamation-circle text-primary" />;
  };

  return (
    <div
      key={notification.notification_id}
      onClick={() => {
        readNotification(notification);
        navigate(notification.url);
      }}
      className={`card py-2 px-3 shadow-sm mb-3 border ${
        notification.readAt === null ? "bg-light" : ""
      }`}
    >
      <p className="mb-1">{notification.content}</p>
      <div className="row">
        <div className="col-10">
          <p className="small mb-0">
            {moment(notification.sentAt).format("Do MMM YYYY HH:mm")}
          </p>
        </div>
        <div className="col-2 text-end">{renderRead()}</div>
      </div>
    </div>
  );
};

export default NotificationCard;
