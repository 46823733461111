import React, { useState, useContext, useEffect } from "react";
import { RequisitionsContext } from "../context/RequisitionsContext";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import RequisitionDetails from "../components/requisitions/RequisitionDetails";
import RequisitionPayments from "../components/requisitions/RequisitionPayments";
import CommentFeed from "../components/comments/CommentFeed";
import { ModalContext } from "../context/ModalContext";
import RequisitionForm from "../components/requisitions/RequisitionForm";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const SingleRequisition = ({ project_id, requisition_id }) => {
  const [view, setView] = useState("details");
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { requisition, getSingleRequisition } = useContext(RequisitionsContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getSingleRequisition(requisition_id);
  };

  const handleCallback = () => getSingleRequisition(requisition_id);

  const handleEdit = () => {
    modalComponent(
      "Edit Requisition",
      <RequisitionForm
        handleCancel={clearModal}
        handleCallback={handleCallback}
      />
    );
  };

  const renderContent = () => {
    if (requisition && requisition !== null) {
      switch (view) {
        case "payments":
          return (
            <RequisitionPayments
              project_id={project_id}
              handleCallback={handleCallback}
              requisition_id={requisition_id}
              payments={requisition.payments}
            />
          );
        case "comments":
          return <CommentFeed requisition_id={requisition_id} />;
        default:
          return (
            <RequisitionDetails
              project_id={project_id}
              requisition={requisition}
              handleCallback={handleCallback}
            />
          );
      }
    }
  };

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={fetchData} data={requisition} />
      <div className="row align-items-center pb-2 mb-2">
        <div className="col-9">
          <h1 className="mb-0">Requisition #{requisition_id}</h1>
        </div>
        <div className="col-3 text-end">
          <button onClick={handleEdit} className="btn text-primary">
            <i className="fa fa-edit"></i>
          </button>
        </div>
      </div>
      <IonSegment className="mb-3" value={view}>
        <IonSegmentButton value="details" onClick={() => setView("details")}>
          <IonLabel>Details</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="payments" onClick={() => setView("payments")}>
          <IonLabel>Payments</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="comments" onClick={() => setView("comments")}>
          <IonLabel>Comments</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {renderContent()}
    </div>
  );
};

export default SingleRequisition;
