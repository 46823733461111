import api from "./api";

const route = "/checkout";

const CheckoutService = {
  attempt: (invoice_id, payment_method) =>
    api.post(`${route}/attempt`, {
      invoice_id,
      payment_method,
      payment_method_id: 1,
    }),
  postCheckout: (invoice_id, payment_source_id) =>
    api.post(`${route}/attempt`, {
      invoice_id,
      payment_source_id,
      payment_method_id: 1,
    }),
  postPayPal: (invoice_id) =>
    api.post(`${route}/paypal`, { invoice_id, payment_method_id: 2 }),
  capturePayPal: ({ orderID }) => api.post(`${route}/capture`, { orderID }),
};

export default CheckoutService;
