import moment from "moment";

const paypal_dev_key =
  "AdZEW5Ru6MrKRVBoQ_8KPIUPB_832eOzEOpMOKY4VoDI8qh-7NqLqF-NNiF4DZeymBUaDynLVs0Mu6ao";

const paypal_live_key =
  "AdZEW5Ru6MrKRVBoQ_8KPIUPB_832eOzEOpMOKY4VoDI8qh-7NqLqF-NNiF4DZeymBUaDynLVs0Mu6ao";

const paypal_client_id =
  process.env.NODE_ENV === "development" ? paypal_dev_key : paypal_live_key;

export const PAYPAL_URL = `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}&vault=true&intent=subscription`;

export const stripe_test_key =
  "pk_test_51MZGAwLjvZkVdiPYtylnl9iK8lVzsvEhmgzAz76Tn7Qt8h9cMi0MzdguGU0CEtqdyRujqpK0oJfUGxZiJdw0wixe00ENgPYPGN";

export const stripe_live_key =
  "pk_live_51MZGAwLjvZkVdiPYPrtsHTNB4IQYTIPzw8o97Rf0yJozYVN0FupMB1fS0T7ZswPyEdkEegCVQomcXp7lBzpznE1X004WclTAHo";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_test_key : stripe_live_key;

export const BASE_URL =
  (process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://agavebrandsapp.com") + "/api";

export const S3_ENDPOINT = "https://agavebrands.s3.us-west-1.amazonaws.com";

export const searchRows = (query, rows) => {
  if (!rows) return [];
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (String(subcolumn).toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (
          args[key] &&
          args[key] !== null &&
          args[key] !== "" &&
          args[key] !== "null"
        ) {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  if (monto === null) return "-";
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  let button = document.getElementById("main-button");
  const modal = document.getElementById("modal");
  if (modal.style.display !== "none") {
    if (!button || button === null) {
      button = createModalButton();
    }
    button.click();
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  const modal = document.getElementById("modal");
  if (button && button !== null) {
    button.click();
  } else if (modal && modal !== null) {
    const newButton = createModalButton();
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const createModalButton = () => {
  const newButton = document.createElement("button");
  newButton.attributes.href = "#modal";
  newButton.id = "main-button";
  newButton.setAttribute("data-toggle", "modal");
  newButton.setAttribute("data-target", "#modal");
  newButton.setAttribute("data-bs-toggle", "modal");
  newButton.setAttribute("data-bs-target", "#modal");
  newButton.style.visibility = "hidden";
  return newButton;
};

export const cardStyle = {
  style: {
    base: {
      color: "#000",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#000",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";

export const isManager = (user) => {
  return user.isManager !== null && user.isManager;
};

export const isAdmin = (user) => {
  return user.isAdmin !== null && user.isAdmin;
};

export const getLocalMoment = (datetime) => {
  return moment(
    moment(datetime).utc().format("YYYY-MM-DD HH:mm:ss"),
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const getClassAvailableSpaces = (singleClass) => {
  return singleClass.capacity - singleClass.class_reservations.length;
};

export const userInformatinCompleted = (user) => {
  let valid = true;
  if (String(user.phone).length < 10) valid = false;
  if (user.last_name === "" || user.last_name === null) valid = false;
  return valid;
};

export const getLocalMomentDiff = (datetime, unitOfTime) => {
  return moment().diff(getLocalMoment(datetime), unitOfTime);
};

export const hasNotch = (device) => {
  let current = String(device);
  if (current.includes("iPhone")) {
    return (
      current.includes("X") ||
      current.includes("11") ||
      current.includes("12") ||
      current.includes("13") ||
      current.includes("14") ||
      current.includes("15")
    );
  }
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const getBilling = (class_package) => {
  const { is_subscription, subscription_period, subscription_interval } =
    class_package;
  let period = subscription_period;
  let interval = subscription_interval;
  if (is_subscription) {
    if (subscription_interval > 1) {
      period =
        subscription_period === "month"
          ? "meses"
          : subscription_period === "year"
          ? "años"
          : "días";
    } else {
      period =
        subscription_period === "month"
          ? "mes"
          : subscription_period === "year"
          ? "año"
          : "día";
    }
  }
  let frequency = "cada ";
  if (interval === 1) {
    interval = "";
  }
  return `${frequency} ${interval} ${period}`;
};

export const showOffcanvas = () => {
  let offcanvas = document.getElementsByClassName("offcanvas")[0];

  if (offcanvas && offcanvas !== null) {
    offcanvas.classList.add("show");
  }
};

export const hideOffcanvas = () => {
  let offcanvas = document.getElementsByClassName("offcanvas")[0];
  let backdrop = document.getElementsByClassName("offcanvas-backdrop")[0];

  if (offcanvas && offcanvas !== null) {
    offcanvas.classList.remove("show");
    document.body.style = "";
    if (backdrop && backdrop !== null) {
      backdrop.remove();
    }
  }
};

export const getValue = (object, key, type) => {
  if (object && object !== null) {
    const value = object[key];
    if (value && value !== null && value !== "") {
      if (type === "boolean" || type === true) {
        return value === true || value === 1;
      } else if (type === "date") {
        return moment(value).utc().format("YYYY-MM-DD HH:mm");
      }
      return value;
    }
  }
  if (type === "boolean") return false;
  return "";
};

export const projectFilesCompleted = (project) => {
  let missing_files = [];
  const { files } = project;
  if (Array.isArray(files)) {
    ["Cost Breakdown"].forEach((name) => {
      const currentFile = files.find(
        (file) => file.name === name || file.project_file.name === name
      );
      if (!currentFile) missing_files.push(name);
    });
  }
  return missing_files;
};

export const projectDataComplete = (project) => {
  let missing_fields = [];
  ["grade", "description", "order_size", "product_development_plan"].forEach(
    (key) => {
      if (project[key] === "" || project[key] === null) {
        missing_fields.push(key);
      }
    }
  );
  return missing_fields;
};

export const clientDataComplete = (project) => {
  const fields = ["name", "country"];
  const { client } = project;
  if (!client || client === null) {
    return fields;
  }
  let missing_fields = [];
  fields.forEach((key) => {
    if (client[key] === "" || client[key] === null) {
      missing_fields.push(key);
    }
  });
  return missing_fields;
};

export const addressDataComplete = (project) => {
  const fields = ["address_line_1", "city", "state", "country", "zip_code"];
  if (project.client === null) {
    return fields;
  }
  const { address } = project.client;
  if (!address || address === null) {
    return fields;
  }
  let missing_fields = [];
  fields.forEach((key) => {
    if (address[key] === "" || address[key] === null) {
      missing_fields.push(key);
    }
  });
  return missing_fields;
};

export const isProjectComplete = (project) => {
  const missing_fields = projectDataComplete(project);
  if (missing_fields.length > 0) return false;
  const missing_files = projectFilesCompleted(project);
  if (missing_files.length > 0) return false;
  const missing_client_fields = clientDataComplete(project);
  if (missing_client_fields.length > 0) return false;
  const missing_address_fields = addressDataComplete(project);
  if (missing_address_fields.length > 0) return false;
  return true;
};

export const setupTooltips = () => {
  window.$('[data-bs-toggle="tooltip"]').tooltip({
    trigger: "hover",
  });
  window.$('[rel="tooltip"]').on("click", function () {
    window.$(this).tooltip("hide");
  });
};
