import React, { createContext, useReducer, useContext } from "react";
import ClientsService from "../services/ClientsService";
import ClientsReducer from "../reducers/ClientsReducer";
import {
  CLIENTS_RECEIVED,
  SET_CLIENT,
  CREATE_CLIENT,
  SET_PROPERTY_CLIENT,
} from "../types/clients";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  clients: null,
  client: null,
};

export const ClientsContext = createContext(initialState);

export const ClientsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClientsReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getClients = () => {
    ClientsService.getClients()
      .then((response) => {
        const { clients } = response.data;
        dispatch({ type: CLIENTS_RECEIVED, payload: clients });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleClient = (client_id) => {
    ClientsService.getSingleClients(client_id)
      .then((response) => {
        const { client } = response.data;
        dispatch({ type: SET_CLIENT, payload: client });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setClient = (client) => {
    dispatch({ type: SET_CLIENT, payload: client });
  };

  const createClient = () => {
    dispatch({ type: CREATE_CLIENT });
  };

  const setPropertyClient = (key, value) => {
    dispatch({ type: SET_PROPERTY_CLIENT, payload: { key, value } });
  };

  const saveClient = (client, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = ClientsService.putClient;
    if (isNaN(parseInt(client.client_id))) {
      service = ClientsService.postClient;
    }
    service(client)
      .then(() => {
        success("Client saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteClient = (client_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    ClientsService.deleteClient(client_id)
      .then(() => {
        success("Client deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <ClientsContext.Provider
      value={{
        ...state,
        getClients,
        getSingleClient,
        setClient,
        createClient,
        setPropertyClient,
        saveClient,
        deleteClient,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
