import api from "./api";
import { getArgs } from "../utils";

const route = "/events";

const EventService = {
  getAdminEvents: (filters) => api.get(`${route}/admin?${getArgs(filters)}`),
  getEventTypes: () => api.get(`${route}/types`),
  getEventsStep: (step_id, date) =>
    api.get(`${route}/step/${step_id}?date=${date}`),
  getEventsProcess: (process_id, date) =>
    api.get(`${route}/process/${process_id}?date=${date}`),
  getEventsProject: (project_id, filters) =>
    api.get(`${route}/project/${project_id}?${getArgs(filters)}`),
  getSingleEvent: (event_id) => api.get(`${route}/${event_id}`),
  attendEvent: (event_id) => api.post(`${route}/${event_id}/attend`),
  cancelEvent: (event_id) => api.post(`${route}/${event_id}/cancel`),
  postEvent: (project_id, event) =>
    api.post(`${route}/project/${project_id}`, { ...event }),
  putEvent: (project_id, event) =>
    api.put(`${route}/project/${project_id}`, { ...event }),
  commentEvent: (event_id, comment) =>
    api.post(`${route}/${event_id}/comment`, { ...comment }),
  submitEventFile: (event_id, file_id) =>
    api.post(`${route}/${event_id}/file`, { file_id }),
  deleteEventFile: (event_id, file_id) =>
    api.delete(`${route}/${event_id}/file/${file_id}`),
  deleteEvent: (project_id, event_id) =>
    api.delete(`${route}/project/${project_id}/event/${event_id}`),
};

export default EventService;
