import { USER_TYPES_RECEIVED } from "../types/user_types";

const UseTypeReducer = (state, { type, payload }) => {
  switch (type) {
    case USER_TYPES_RECEIVED:
      return { ...state, user_types: payload };
    default:
      return { ...state };
  }
};
export default UseTypeReducer;
