import React, { useContext, useEffect } from "react";
import { ProjectTemplatesContext } from "../context/ProjectTemplatesContext";
import ProcessesAccordion from "../components/templates/ProcessesAccordion";
import { ModalContext } from "../context/ModalContext";
import TemplateForm from "../components/templates/TemplateForm";

const SingleProjectTemplate = ({ project_template_id }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { template, deleteProjectTemplate, getSingleProjectTemplate } =
    useContext(ProjectTemplatesContext);

  useEffect(() => {
    getSingleProjectTemplate(project_template_id);
  }, [project_template_id]);

  const handleEdit = () => {
    modalComponent("Edit Template", <TemplateForm handleCancel={clearModal} />);
  };

  const handleDelete = () => {
    modalComponent(
      "Delete Template",
      <div>
        <p>
          Are you sure you want to delete this template? This action CANNOT be
          reversed.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancel
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() =>
                deleteProjectTemplate(template.project_template_id)
              }
              className="btn btn-sm w-100 btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    if (template && template !== null) {
      return <h1 className="h2">{template.name}</h1>;
    }
  };
  const renderSingleTemplate = () => {
    if (template && template !== null) {
      const { processes } = template;
      return <ProcessesAccordion processes={processes} />;
    }
    return <div className="spinner-border"></div>;
  };

  const renderDescription = () => {
    if (template && template !== null) {
      return <p>{template.description}</p>;
    }
  };

  const renderButton = () => {
    if (template && template !== null) {
      return (
        <button
          onClick={handleDelete}
          className="btn btn-sm btn-outline-danger my-5"
        >
          <i className="fa fa-trash me-2"></i>
          Delete Template
        </button>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mb-2 mx-0 border-bottom align-items-center">
        <div className="col-8 px-0">{renderTitle()}</div>
        <div className="col-4 px-0 text-end">
          <button onClick={handleEdit} className="btn btn-sm text-primary">
            <i className="fa fa-edit" />
          </button>
        </div>
      </div>
      {renderDescription()}
      {renderSingleTemplate()}
      {renderButton()}
    </div>
  );
};

export default SingleProjectTemplate;
