import {
  CREATE_PAYMENT,
  PAYMENTS_RECEIVED,
  SET_PAYMENT,
  SET_PROPERTY_PAYMENT,
} from "../types/payments";

const schema = {
  payment_id: "",
  status: "",
  file: null,
  createdBy: null,
};

const PaymentsReducer = (state, { type, payload }) => {
  switch (type) {
    case PAYMENTS_RECEIVED:
      return { ...state, payments: payload };
    case SET_PAYMENT:
      return { ...state, payment: payload };
    case CREATE_PAYMENT:
      return { ...state, payment: schema };
    case SET_PROPERTY_PAYMENT:
      const { key, value } = payload;
      const payment = { ...state.payment };
      payment[key] = value;
      console.log(JSON.parse(JSON.stringify(payment)));
      return { ...state, payment };
    default:
      return { ...state };
  }
};
export default PaymentsReducer;
