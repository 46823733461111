import React from "react";

const UserProjectDeleteConfirm = ({ user, project, handleCancel, deleteUserProject }) => {
    const { project_id } = project;
    return (
        <div className="pb-2">
            <p>
                Are you sure you want to delete the user {user.email} from the
                project {project.name}?
            </p>
            <button
                onClick={() => deleteUserProject(project_id, user.user_id)}
                className="btn btn-sm btn-outline-danger w-100"
            >
                <i className="fa fa-trash me-2"></i>Remove User
            </button>
            <button onClick={handleCancel} className="btn my-2 text-muted w-100">
                Cancel
            </button>
        </div>
    )
}
export default UserProjectDeleteConfirm