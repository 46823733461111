import React, { useState, useContext } from "react";
import { PaymentsContext } from "../../context/PaymentsContext";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";

const RejectPaymentForm = ({ project_id, handleCallback }) => {
  const [comment, setComment] = useState("");
  const { payment, savePayment } = useContext(PaymentsContext);
  const { alert, clearModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const handleReject = () => {
    if (String(comment).length < 6) {
      return alert("You must include a reject reason to reject a payment.");
    }
    payment.comment = comment;
    payment.rejectedBy = user.user_id;
    payment.rejectedAt = moment().local().format("YYYY-MM-DD HH:mm");
    savePayment(project_id, payment, handleCallback);
  };

  return (
    <div>
      <p>Are you sure you want to reject this payment?</p>
      <label>
        Why are you rejecting this payment? This WILL be visible to the client.
      </label>
      <input
        type="text"
        value={comment}
        className="form-control mb-3"
        onChange={(e) => setComment(e.target.value)}
      />
      <button onClick={handleReject} className="btn btn-danger w-100">
        <i className="fa fa-times me-2"></i> Reject Payment
      </button>
      <button onClick={clearModal} className="btn text-muted w-100">
        Cancel
      </button>
    </div>
  );
};

export default RejectPaymentForm;
