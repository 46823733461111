import moment from "moment";
import React from "react";

const TimeLeftBadge = ({ date }) => {
  const diff = moment(date).utc().diff(moment().local(), "days");

  const getDaysLeft = () => {
    if (diff === 0) {
      return "Today";
    }
    if (diff < 0) {
      return `${diff * -1} days overdue`;
    }
    return `${diff} days left`;
  };

  return (
    <span
      className={`badge fw-light rounded-pill bg-${
        diff < 0 ? "danger" : "dark"
      } mb-2 pe-2`}
    >
      <i className="fa fa-clock me-1"></i>
      {getDaysLeft()}
    </span>
  );
};

export default TimeLeftBadge;
