import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  CREATE_FILE,
  FILES_RECEIVED,
  SET_FILE,
  SET_PROPERTY_FILE,
} from "../types/files";

const schema = {
  file_id: "",
  name: "",
};

const FilesReducer = (state, { type, payload }) => {
  switch (type) {
    case FILES_RECEIVED:
      return { ...state, files: payload };
    case SET_PROPERTY_FILE:
      const file = { ...state.file };
      const { key, value } = payload;
      file[key] = value;
      return { ...state, file };
    case CREATE_FILE:
      return { ...state, schema };
    case SET_FILE:
      return { ...state, file: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default FilesReducer;
