import moment from "moment";
import React from "react";

const DueDate = ({ start_date, end_date, hideTime }) => {
  const renderEndTime = (start_date, end_date) => {
    if (end_date && end_date !== null && !hideTime) {
      end_date = moment(end_date).utc().local();
      start_date = moment(start_date).utc().local();
      if (end_date.isValid() && !start_date.isSame(end_date)) {
        return (
          <div className="col-4">
            <span className="small">
              <i className="fa fa-clock text-primary me-2"></i>
              {end_date.format("HH:mm")}
            </span>
          </div>
        );
      }
    }
    if (start_date !== null && !hideTime) {
      return (
        <div className="col-4">
          <span className="small">
            <i className="fa fa-clock text-primary me-2"></i>
            {moment(start_date).utc().local().format("HH:mm")}
          </span>
        </div>
      );
    }
  };

  const renderEndDate = (start_date, end_date) => {
    if (end_date && end_date !== null) {
      end_date = moment(end_date).utc().local();
      start_date = moment(start_date).utc().local();
      if (
        end_date.isValid() &&
        start_date.format("YYYY-MM-DD") !== end_date.format("YYYY-MM-DD")
      ) {
        return ` - ${end_date.format("DD MMM YYYY")}`;
      }
    }
  };

  const isDateValid = (date) => {
    date = moment(date);
    return date.isValid();
  };

  const renderDate = (date) => {
    if (isDateValid(date)) {
      return moment(date).utc().format("DD MMM YYYY");
    }
  };

  return (
    <div className="container-fluid px-0 mt-2">
      <div className="row">
        <div className="col small">
          <i className="fa fa-calendar text-primary me-2"></i>{" "}
          {renderDate(start_date)}
          {renderEndDate(start_date, end_date)}
        </div>
        {renderEndTime(start_date, end_date)}
      </div>
    </div>
  );
};

export default DueDate;
