import React, { useContext } from "react";
import { CartContext } from "../../context/CartContext";
import BottomAction from "../global/BottomAction";
import { navigate } from "@reach/router";

const CartButton = ({ project_id }) => {
  const { products } = useContext(CartContext);

  const renderButton = () => {
    if (products.length > 0) {
      return (
        <BottomAction
          onClick={() =>
            navigate(project_id ? `/project/${project_id}/cart` : "/cart")
          }
        >
          View Cart{" "}
          <span className="bg-dark badge badge-round ms-2">
            {products.length}
          </span>
        </BottomAction>
      );
    }
  };

  return <div>{renderButton()}</div>;
};

export default CartButton;
