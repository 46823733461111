import { HIDE_SPINNER, SET_INTERVAL, SHOW_SPINNER } from "../types";
import {
  CREATE_PROJECT,
  PROJECTS_RECEIVED,
  SET_SINGLE_PROJECT,
  SET_PROPERTY_PROJECT,
  SET_SINGLE_PROJECT_FILES,
  DEALS_RECEIVED,
} from "../types/projects";
import { USERS_RECEIVED } from "../types/users";

const schema = {
  project_id: "nuevo",
  name: "",
  description: "",
};

const ProjectsReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_INTERVAL:
      const interval = state.interval;
      if(payload === null || !payload) {
        if(interval !== null) window.clearInterval(interval);
      } 
      return { ...state, interval: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case DEALS_RECEIVED:
      return { ...state, deals: payload };
    case PROJECTS_RECEIVED:
      return { ...state, projects: payload };
    case SET_SINGLE_PROJECT:
      return { ...state, project: payload };
    case CREATE_PROJECT:
      return { ...state, project: schema };
    case SET_PROPERTY_PROJECT:
      const { key, value } = payload;
      const project = { ...state.project };
      project[key] = value;
      return { ...state, project };
    case USERS_RECEIVED:
      return { ...state, project_users: payload };
    case SET_SINGLE_PROJECT_FILES:
      return { ...state, files: payload };
    default:
      return { ...state };
  }
};
export default ProjectsReducer;
