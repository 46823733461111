import {
  SET_REQUISITION,
  CREATE_REQUISITION,
  REQUISITIONS_RECEIVED,
  SET_PROPERTY_REQUISITION,
} from "../types/requisitions";

const schema = {
  requisition_id: "",
};

const requisitionsReducer = (state, { type, payload }) => {
  switch (type) {
    case REQUISITIONS_RECEIVED:
      return { ...state, requisitions: payload };
    case SET_REQUISITION:
      return { ...state, requisition: payload };
    case CREATE_REQUISITION:
      return { ...state, requisition: schema };
    case SET_PROPERTY_REQUISITION: {
      const { key, value } = payload;
      const requisition = { ...state.requisition };
      requisition[key] = value;
      return { ...state, requisition };
    }
    default:
      return { ...state };
  }
};

export default requisitionsReducer;
