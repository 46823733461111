import React, { useState, useEffect } from "react";
import ActionsService from "../../services/ActionsService";

const ActionPicker = ({ project_id, selected, modifier }) => {
  const [query, setQuery] = useState("");
  const [actions, setActions] = useState(null);

  useEffect(() => {
    if (query !== "") {
      fetchActions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (selected !== null) {
      setQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const fetchActions = () => {
    ActionsService.getActionsProject(project_id, { query }).then((res) => {
      setActions(res.data.actions);
    });
  };

  const renderActions = () => {
    if (Array.isArray(actions) && query !== "") {
      let actionsRender = [...actions];
      if (actionsRender.length === 0) {
        return (
          <ul className="dropdown-menu mw-100 w-100 show">
            <li className="dropdown-ite small text-dark">No actions found.</li>
          </ul>
        );
      }
      return (
        <ul className="dropdown-menu mw-100 w-100 show">
          {actionsRender.map((action) => (
            <li
              className="dropdown-item small border-bottom py-2 text-dark mw-100"
              onClick={() => modifier(action)}
            >
              {action.name}
            </li>
          ))}
        </ul>
      );
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      return selected.map((action) => (
        <div className="row align-items-center my-2 py-2 bg-light border mx-0">
          <div className="col-10">
            <p className="mb-0">{action.name}</p>
          </div>
          <div className="col-2">
            <button
              className="btn btn-sm py-0"
              onClick={() => modifier(action)}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container position-relative px-0 mb-3">
      <label>Dependences</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Search actions..."
        onChange={(e) => setQuery(e.target.value)}
      />
      {renderActions()}
      {renderSelected()}
    </div>
  );
};

export default ActionPicker;
