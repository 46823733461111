import React, { useContext } from "react";
import { OrdersContext } from "../../context/OrdersContext";
import { formatMonto } from "../../utils";

const OrderApprovalForm = ({ order, handleCancel }) => {
  const { postOrderApproval } = useContext(OrdersContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postOrderApproval(order.project_id, order.order_id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Approve Order #{order.order_id}</h2>
      <h3 className="mb-0">${formatMonto(order.amount)}</h3>
      <p>This will move the order into the next step of the process.</p>
      <button type="submit" className="btn btn-primary mb-4 w-100">
        Approve
      </button>
      <button
        type="button"
        className="btn btn-sm text-accent h6 text-center d-block w-100"
        onClick={handleCancel}
      >
        Cancel
      </button>
    </form>
  );
};

export default OrderApprovalForm;
