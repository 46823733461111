import { Router } from "@reach/router";
import React from "react";
import Login from "./Login";
import Recovery from "./Recovery";
import Loading from "./Loading";

const Auth = () => {
  return (
    <div id="auth">
      <Router>
        <Loading path="/*" default />
        <Login path="/login" />
        <Recovery path="/recovery" />
      </Router>
    </div>
  );
};

export default Auth;
