import React, { useContext, useEffect } from "react";
import { EventsContext } from "../../context/EventsContext";

const SelectEventType = ({ value, modifier }) => {
  const { event_types, getEventTypes } = useContext(EventsContext);

  useEffect(() => {
    getEventTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEventTypes = () => {
    if (Array.isArray(event_types)) {
      return event_types.map((event_type) => (
        <option key={event_type.event_type_id} value={event_type.event_type_id}>
          {event_type.name}
        </option>
      ));
    }
  };

  return (
    <div className="container px-0">
      <label>Event Type</label>
      <select
        value={value && value !== null ? value : ""}
        onChange={(e) => modifier(e.target.value)}
        className="form-control mb-3"
      >
        {renderEventTypes()}
      </select>
    </div>
  );
};

export default SelectEventType;
