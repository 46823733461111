import React, { useContext } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import { getValue } from "../../utils";
import ProductPicker from "./ProductPicker";

const ProductInputSelect = ({ parent_id, handleCancel, handleCallback }) => {
  const { input, setInput, saveProductInput, setPropertyInput } =
    useContext(ProductsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input !== null) {
      saveProductInput(
        parent_id,
        input.product_id,
        input.amount,
        handleCallback
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ProductPicker selected={input} modifier={setInput} />
      <label>Amount</label>
      <input
        type="number"
        value={getValue(input, "amount")}
        className="form-control mb-3"
        onChange={(e) => setPropertyInput("amount", e.target.value)}
      />
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 mt-3"
      >
        Cancel
      </button>
    </form>
  );
};

export default ProductInputSelect;
