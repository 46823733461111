import React, { createContext } from "react";

const initialState = {
  menus: [
    {
      items: [
        {
          name: "Projects",
          link: "/",
          icon: "folder",
        },
      ],
    },
    {
      level: 4,
      items: [
        {
          name: "Templates",
          link: "/templates",
          icon: "folder",
        },
      ],
    },
    {
      name: "Project",
      scoped: true,
      base_url: "project",
      max_level: 2,
      items: [
        {
          name: "Progress",
          link: "/",
          icon: "tasks",
        },
        {
          name: "Files",
          link: "/files",
          icon: "file",
        },
        {
          name: "Orders",
          link: "/orders",
          icon: "receipt",
        },
        {
          name: "Calendar",
          link: "/calendar",
          icon: "calendar",
        },
        {
          name: "Invoices",
          link: "/invoices",
          icon: "file-invoice",
        },
      ],
    },
    {
      name: "Project",
      scoped: true,
      base_url: "project",
      level: 3,
      items: [
        {
          name: "Dashboard",
          link: "/",
          icon: "tasks",
        },
        {
          name: "Files",
          link: "/files",
          icon: "file",
        },
        {
          name: "Calendar",
          link: "/calendar",
          icon: "calendar",
        },
        {
          name: "Invoices",
          link: "/invoices",
          icon: "file-invoice",
        },
        {
          name: "Users",
          link: "/users",
          icon: "users",
        },
      ],
    },
    {
      name: "Project",
      scoped: true,
      base_url: "project",
      level: 4,
      items: [
        {
          name: "Progress",
          link: "/",
          icon: "tasks",
        },

        {
          name: "Files",
          link: "/files",
          icon: "file",
        },
        {
          name: "Orders",
          link: "/orders",
          icon: "receipt",
        },
        {
          name: "Calendar",
          link: "/calendar",
          icon: "calendar",
        },
        {
          name: "Products",
          link: "/products",
          icon: "box",
        },
        {
          name: "Inventory",
          link: "/inventory",
          icon: "boxes",
        },
        {
          name: "Invoices",
          link: "/invoices",
          icon: "file-invoice",
        },
        {
          name: "Requisitions",
          link: "/requisitions",
          icon: "file",
        },
        {
          name: "Users",
          link: "/users",
          icon: "users",
        },
      ],
    },
    {
      level: 4,
      scoped: true,
      items: [
        {
          name: "Templates",
          link: "/templates",
          icon: "folder",
        },
      ],
    },
    {
      name: "Management",
      level: 4,
      scoped: false,
      items: [
        {
          name: "Inventory",
          link: "/inventory",
          icon: "box",
        },
        {
          name: "Users",
          link: "/admin/users",
          icon: "users",
        },
      ],
    },
    {
      name: "Finances",
      level: 5,
      items: [
        {
          name: "Orders",
          link: "/orders",
          icon: "receipt",
        },
        {
          name: "Invoices",
          link: "/invoices",
          icon: "file-invoice",
        },
        {
          name: "Income",
          link: "/analytics/income",
          icon: "file-invoice",
        },
      ],
    },
    {
      name: "Finances",
      level: 5,
      scoped: true,
      items: [
        {
          name: "Invoices",
          link: "/invoices",
          icon: "file-invoice",
        },
        {
          name: "Requisitions",
          link: "/requisitions",
          icon: "file",
        },
      ],
    },
    {
      name: "Admin",
      level: 6,
      scoped: false,
      items: [
        {
          name: "Income",
          link: "/analytics/income",
          icon: "chart-pie",
        },
        {
          name: "Overdue",
          link: "/analytics/overdue",
          icon: "exclamation",
        },
        {
          name: "Calendar",
          link: "/admin/calendar",
          icon: "calendar",
        },
        {
          name: "Comments",
          link: "/analytics/comments",
          icon: "comments",
        },
        {
          name: "Users",
          link: "/admin/users",
          icon: "users",
        },
        {
          name: "Notifications",
          link: "/admin/notifications",
          icon: "comment",
        },
      ],
    },
    {
      name: "About",
      level: 1,
      scoped: false,
      items: [
        {
          name: "My Profile",
          link: "/profile",
          icon: "user",
        },
      ],
    },
  ],
};

export const MenuContext = createContext(initialState);

export const MenuProvider = ({ children }) => {
  return (
    <MenuContext.Provider value={{ ...initialState }}>
      {children}
    </MenuContext.Provider>
  );
};
