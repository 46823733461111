import React, { useState, useContext, useEffect } from "react";
import { OrdersContext } from "../../context/OrdersContext";
import { AuthContext } from "../../context/AuthContext";

const OrderUpdateForm = ({ order, handleCallback, handleCancel }) => {
  const [comment, setComment] = useState("");
  const [orderStepID, setOrderStepID] = useState("");
  const [orderSubStepId, setOrderSubStepId] = useState("");
  const [orderSteps, setOrderSteps] = useState(null);
  const { order_steps, getOrderSteps, postOrderUpdate } =
    useContext(OrdersContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getOrderSteps(order.project_id, order.order_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (order && order !== null && Array.isArray(order_steps)) {
      setOrderSteps(order_steps);
      handleCurrentUpdates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, order_steps]);

  useEffect(() => {
    if (Array.isArray(orderSteps)) {
      if (orderSteps.length === 1) {
        handleFirstStep(orderSteps);
      }
    }
  }, [orderSteps]);

  useEffect(() => {
    if (orderStepID !== "") {
      const orderStep = order_steps.find(
        (step) => parseInt(step.order_step_id) === parseInt(orderStepID)
      );
      if (orderStep) {
        const { order_substeps } = orderStep;
        const first_step = order_substeps[0];
        if (first_step) {
          setOrderSubStepId(first_step.order_substep_id);
        }
      } else {
        setOrderSubStepId("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStepID]);

  const handleSubmit = (e) => {
    e.preventDefault();
    order.comment = comment;
    order.order_step_id = orderStepID;
    order.order_substep_id = orderSubStepId !== "" ? orderSubStepId : null;
    postOrderUpdate(order, handleCallback);
  };

  const handleFirstStep = (order_steps) => {
    if (Array.isArray(order_steps)) {
      const first_step = order_steps[0];
      if (first_step) {
        setOrderStepID(first_step.order_step_id);
      }
    }
  };

  const handleCurrentUpdates = () => {
    handleFirstStep(order_steps);
    if (order.order_updates.length === 0) {
      return setOrderSteps(order_steps.slice(0, 1));
    }
    let orderStepsRender = [...order_steps];
    if (user.user_type_id > 3) {
      orderStepsRender = orderStepsRender.filter(
        (order) => !String(order.name).toLowerCase().includes("feedback")
      );
    } else {
      orderStepsRender = orderStepsRender.filter((order) =>
        String(order.name).toLowerCase().includes("feedback")
      );
    }
    orderStepsRender = orderStepsRender.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    const orderUpdates = order.order_updates.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    const lastUpdate = orderUpdates[orderUpdates.length - 1];
    if (lastUpdate) {
      const { order_step } = lastUpdate;
      orderStepsRender = orderStepsRender.filter(
        (orderStep) => orderStep.order - 1 <= order_step.order
      );
    }
    setOrderSteps(orderStepsRender);
  };

  const renderOptions = () => {
    if (Array.isArray(orderSteps)) {
      return orderSteps.map((order_step) => (
        <option key={order_step.order_step_id} value={order_step.order_step_id}>
          #{order_step.order} - {order_step.name}
        </option>
      ));
    }
  };

  const renderSubstep = () => {
    if (orderStepID !== "") {
      const orderStep = order_steps.find(
        (step) => parseInt(step.order_step_id) === parseInt(orderStepID)
      );
      if (orderStep) {
        const { order_substeps } = orderStep;
        if (Array.isArray(order_substeps) && order_substeps.length > 0) {
          return (
            <div>
              <label>Sub Step</label>
              <select
                value={orderSubStepId}
                className="form-control mb-3"
                onChange={(e) => setOrderSubStepId(e.target.value)}
              >
                {order_substeps.map((order_step) => (
                  <option
                    key={order_step.order_substep_id}
                    value={order_step.order_substep_id}
                  >
                    #{order_step.order} - {order_step.name}
                  </option>
                ))}
              </select>
            </div>
          );
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Update for Order #{order.order_id}</h2>
      <label>Comment</label>
      <textarea
        rows="2"
        value={comment}
        className="form-control mb-3"
        onChange={(e) => setComment(e.target.value)}
      />
      <label>Order Step</label>
      <select
        value={orderStepID}
        className="form-control mb-3"
        onChange={(e) => setOrderStepID(e.target.value)}
      >
        {renderOptions()}
      </select>
      {renderSubstep()}
      <button
        type="submit"
        disabled={orderStepID === ""}
        className="btn btn-primary mb-4 w-100"
      >
        Save Update
      </button>
      <button
        type="button"
        className="btn btn-sm text-accent h6 text-center d-block w-100"
        onClick={handleCancel}
      >
        Cancel
      </button>
    </form>
  );
};

export default OrderUpdateForm;
