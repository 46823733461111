import { HIDE_SPINNER, PERMISSION_DENIED, SHOW_SPINNER } from "../types";
import {
  CREATE_STEP,
  SET_PROPERTY_STEP,
  SET_SINGLE_STEP,
  STEPS_RECEIVED,
  TOGGLE_STEP_DEPENDENCE,
} from "../types/steps";

const schema = {
  step_id: "nuevo",
  name: "",
  description: "",
  hide_client: false,
};

const StepsReducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_STEP_DEPENDENCE:
      let dependences = state.step.dependences;
      if (!Array.isArray(dependences)) {
        dependences = [];
      }
      dependences = [...dependences];
      const index = dependences.findIndex(
        ({ step_id }) => step_id === payload.step_id
      );
      if (index === -1) {
        dependences.push(payload);
      } else {
        dependences.splice(index, 1);
      }
      return { ...state, step: { ...state.step, dependences } };
    case PERMISSION_DENIED:
      return { ...state, denied: true };
    case STEPS_RECEIVED:
      return { ...state, steps: payload, denied: false };
    case SET_SINGLE_STEP:
      return { ...state, step: payload, denied: false };
    case CREATE_STEP:
      return { ...state, step: schema };
    case SET_PROPERTY_STEP:
      const step = { ...state.step };
      const { key, value } = payload;
      step[key] = value;
      return { ...state, step };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default StepsReducer;
