import React, { useEffect, useState } from "react";
import { IonRefresher, IonRefresherContent } from "@ionic/react";

const IonPullRefresh = ({ data, handleCallback }) => {
  const [fetchEvent, setFetchEvent] = useState(null);

  useEffect(() => {
    if (fetchEvent !== null) {
      fetchEvent.detail.complete();
    }
  }, [data]);

  const handleRefresh = (e) => {
    setFetchEvent(e);
    handleCallback();
  };
  return (
    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
  );
};

export default IonPullRefresh;
