import React, { useReducer, createContext, useContext } from "react";
import { SET_PROPERTY_PRODUCT, SET_SINGLE_PRODUCT } from "../types/products";
import InvoicesService from "../services/InvoicesService";
import OrdersReducer from "../reducers/OrdersReducer";
import OrdersService from "../services/OrdersService";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import {
  CREATE_ORDER_APPROVAL,
  ORDER_STEPS_RECEIVED,
  SET_PROPERTY_INVOICE,
  SET_PROPERTY_ORDER,
  ORDERS_RECEIVED,
  CLEAR_APPROVAL,
  CREATE_INVOICE,
  SET_INVOICE,
  CREATE_ORDER,
  SET_ORDER,
} from "../types/orders";
import { SET_FILTER } from "../types";

const initialState = {
  order_steps: null,
  approval: null,
  invoice: null,
  product: null,
  update: null,
  orders: null,
  order: null,
  filters: {},
};

export const OrdersContext = createContext(initialState);

export const OrdersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdersReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getAllOrders = (filters) => {
    OrdersService.getAllOrders(filters).then((res) => {
      const { orders } = res.data;
      dispatch({ type: ORDERS_RECEIVED, payload: orders });
    });
  };

  const getSingleOrder = (project_id, order_id) => {
    OrdersService.getSingleOrder(project_id, order_id).then((res) => {
      const { order } = res.data;
      dispatch({ type: SET_ORDER, payload: order });
    });
  };

  const getSingleOrderAdmin = (order_id) => {
    OrdersService.getSingleOrderAdmin(order_id).then((res) => {
      const { order } = res.data;
      dispatch({ type: SET_ORDER, payload: order });
    });
  };

  const getOrderSteps = (project_id, order_id) => {
    OrdersService.getOrderSteps(project_id, order_id).then((res) => {
      const { order_steps } = res.data;
      dispatch({ type: ORDER_STEPS_RECEIVED, payload: order_steps });
    });
  };

  const getProjectOrders = (project_id) => {
    OrdersService.getProjectOrders(project_id).then((res) => {
      const { orders } = res.data;
      dispatch({ type: ORDERS_RECEIVED, payload: orders });
    });
  };

  const createOrder = () => {
    dispatch({ type: CREATE_ORDER });
  };

  const setPropertyOrder = (key, value) => {
    dispatch({ type: SET_PROPERTY_ORDER, payload: { key, value } });
  };

  const saveOrderAdmin = (order) => {
    let service = OrdersService.putOrderAdmin;
    let { order_id } = order;
    if (!order_id) {
      service = OrdersService.postOrderAdmin;
    }
    service(order).then((res) => {
      if (!order_id) {
        order_id = res.data.order.order_id;
      }
      navigate(`/order/${order_id}`);
      success("Order created");
      clearModal();
    });
  };

  const saveOrder = (project_id, order) => {
    let service = OrdersService.putOrder;
    let { order_id } = order;
    if (!order_id) {
      service = OrdersService.postOrder;
    }
    service(project_id, order).then((res) => {
      if (!order_id) {
        order_id = res.data.order.order_id;
      }
      navigate(`/project/${project_id}/order/${order_id}`);
      success("Order created");
      clearModal();
    });
  };

  const setProduct = (product) => {
    dispatch({ type: SET_SINGLE_PRODUCT, payload: product });
  };

  const setPropertyProduct = (key, value) => {
    dispatch({ type: SET_PROPERTY_PRODUCT, payload: { key, value } });
  };

  const createOrderApproval = () => {
    dispatch({ type: CREATE_ORDER_APPROVAL });
  };

  const postOrderApproval = (project_id, order_id) => {
    OrdersService.postOrderApproval(project_id, order_id)
      .then(() => {
        getSingleOrder(project_id, order_id);
        success("Order Approved");
        clearModal();
      })
      .catch(alert);
  };

  const postOrderUpdate = (order, callback) => {
    const { order_id, project_id } = order;
    OrdersService.postOrderUpdate(project_id, order_id, order).then(() => {
      if (typeof callback === "function") {
        callback();
      }
      success("Order updated.");
      getSingleOrder(order_id);
      clearModal();
    });
  };

  const updateOrderProduct = (project_id, order_product, callback) => {
    OrdersService.putOrderProduct(project_id, order_product).then(() => {
      if (typeof callback === "function") {
        callback();
      }
      success("Product saved.");
      clearModal();
    });
  };

  const deleteOrderApproval = (project_id, order_id) => {
    OrdersService.deleteOrderApproval(project_id, order_id).then(() => {
      getSingleOrder(project_id, order_id);
      success("Order approval canceled.");
      clearModal();
    });
  };

  const deleteOrderUpdate = (project_id, order_update_id, callback) => {
    OrdersService.deleteOrderUpdate(project_id, order_update_id).then(() => {
      if (typeof callback === "function") {
        callback();
      }
      success("Order update deleted.");
      clearModal();
    });
  };

  const createInvoice = () => {
    dispatch({ type: CREATE_INVOICE });
  };

  const setInvoice = (invoice) => {
    dispatch({ type: SET_INVOICE, payload: invoice });
  };

  const clearInvoice = () => {
    dispatch({ type: SET_INVOICE, payload: null });
  };

  const clearApproval = () => {
    dispatch({ type: CLEAR_APPROVAL });
  };

  const setPropertyInvoice = (key, value) => {
    dispatch({ type: SET_PROPERTY_INVOICE, payload: { key, value } });
  };

  const postInvoice = (project_id, invoice) => {
    let service = InvoicesService.putInvoice;
    if (isNaN(invoice.invoice_id) || invoice.invoice_id === "") {
      delete invoice.invoice_id;
      service = InvoicesService.postInvoice;
    }
    service(project_id, invoice)
      .then(() => {
        getSingleOrder(project_id, invoice.order_id);
        success("Invoice saved.");
      })
      .catch(alert);
  };

  const deleteInvoice = (project_id, invoice_id, order_id) => {
    InvoicesService.deleteInvoice(project_id, invoice_id).then(() => {
      getSingleOrder(project_id, order_id);
      success("Invoice saved.");
    });
  };

  const setFilter = (key, value) => {
    dispatch({ type: SET_FILTER, payload: { key, value } });
  };

  return (
    <OrdersContext.Provider
      value={{
        ...state,
        setFilter,
        saveOrder,
        setProduct,
        setInvoice,
        postInvoice,
        createOrder,
        getAllOrders,
        clearInvoice,
        clearApproval,
        deleteInvoice,
        createInvoice,
        getOrderSteps,
        getSingleOrder,
        saveOrderAdmin,
        postOrderUpdate,
        setPropertyOrder,
        getProjectOrders,
        postOrderApproval,
        deleteOrderUpdate,
        setPropertyProduct,
        updateOrderProduct,
        setPropertyInvoice,
        getSingleOrderAdmin,
        deleteOrderApproval,
        createOrderApproval,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
