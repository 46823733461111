import {
  APPEND_PRODUCT,
  CLEAR_CART,
  REMOVE_PRODUCT,
  SET_PRODUCT_QUANTITY,
} from "../types/cart";

const CartReducer = (state, { type, payload }) => {
  switch (type) {
    case CLEAR_CART:
      return { ...state, products: [] };
    case APPEND_PRODUCT: {
      const products = [...state.products];
      const { product_id, quantity } = payload;
      const index = products.findIndex(
        (product) => product.product_id === product_id
      );
      if (index === -1) {
        products.push(payload);
      } else {
        products[index].quantity =
          parseInt(products[index].quantity) + parseInt(quantity);
      }
      return { ...state, products };
    }
    case SET_PRODUCT_QUANTITY: {
      const products = [...state.products];
      const { product_id, quantity } = payload;
      const index = products.findIndex(
        (product) => product.product_id === product_id
      );
      if (index !== -1) {
        products[index].quantity = quantity;
      }
      return { ...state, products };
    }
    case REMOVE_PRODUCT: {
      const products = [...state.products];
      const product_id = payload;
      const index = products.findIndex(
        (product) => product.product_id === product_id
      );
      if (index !== -1) {
        products.splice(index, 1);
      }
      return { ...state, products };
    }
    default:
      return { ...state };
  }
};
export default CartReducer;
