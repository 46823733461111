import moment from "moment";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import IconItem from "../global/IconItem";
import { ModalContext } from "../../context/ModalContext";
import { OrdersContext } from "../../context/OrdersContext";

const OrderApprovals = ({ order, project_id, approvals, handleApprove }) => {
  const { user } = useContext(AuthContext);
  const { modalComponent } = useContext(ModalContext);
  const { deleteOrderApproval } = useContext(OrdersContext);

  const handleDeleteApproval = () => {
    modalComponent(
      "Cancel Approval",
      <div>
        <p>Are you sure you want to cancel this order approval?</p>
        <button
          onClick={() => deleteOrderApproval(project_id, order.order_id)}
          className="btn btn-outline-danger w-100"
        >
          Cancel Approval
        </button>
      </div>
    );
  };

  const renderButton = () => {
    if (order !== null) {
      const { order_approvals } = order;
      if (Array.isArray(order_approvals)) {
        if (order_approvals.length === 0) {
          if (user.user_type_id >= 4) {
            return (
              <button
                className="btn btn-sm text-primary"
                onClick={handleApprove}
              >
                <i className="fa fa-plus"></i>
              </button>
            );
          }
        } else {
          let userApproval = order_approvals.find(
            (approval) =>
              approval.user_id === user.user_id ||
              approval.level === user.user_type_id
          );
          if (!userApproval) {
            if (
              (["quoted", "proposal"].includes(order.status) &&
                user.user_type_id === 2) ||
              (order.status === "quoted" && user.user_type_id > 3)
            ) {
              return (
                <button
                  className="btn btn-sm text-primary"
                  onClick={handleApprove}
                >
                  <i className="fa fa-plus"></i>
                </button>
              );
            }
          }
        }
      }
    }
  };
  const renderLevel = (approval) => {
    if (approval.user_type && approval.user_type !== null) {
      return approval.user_type.name;
    }
  };
  const renderUser = (approval) => {
    if (approval.user && approval.user !== null) {
      if (approval.user.name !== null) {
        return approval.user.name;
      }
      return approval.user.email;
    }
  };
  const renderApprovals = () => {
    if (Array.isArray(approvals)) {
      if (approvals.length === 0) {
        return <p>No approvals for this order jet.</p>;
      }
      return approvals.map((approval) => (
        <div
          key={approval.user_id}
          className="row align-items-center py-2 border-top"
        >
          <div className="col-10">
            <p className="mb-0 bold">{renderUser(approval)}</p>
            <p className="mb-0 text-muted text-capitalize">
              {renderLevel(approval)} -{" "}
              {moment(approval.approvedAt).format("DD MMM YYYY")}
            </p>
          </div>
          <div className="col-2 px-0 text-center">
            {user.user_id === approval.user_id &&
              user.user_type_id > 2 &&
              order.status !== "in-progress" && (
                <button
                  onClick={handleDeleteApproval}
                  className="btn btn-sm text-danger"
                >
                  <i className="fa fa-times"></i>
                </button>
              )}
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="card pt-2 px-3 pb-0 mb-3 shadow-sm">
      <div className="row align-items-center">
        <div className="col-10">
          <IconItem name="Approvals" icon="check" />
        </div>
        <div className="col-2">{renderButton()}</div>
      </div>
      {renderApprovals()}
    </div>
  );
};

export default OrderApprovals;
