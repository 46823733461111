import moment from "moment";
import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { OrdersContext } from "../../context/OrdersContext";
import { AuthContext } from "../../context/AuthContext";

const OrderUpdateCard = ({ project_id, order_update, handleCallback }) => {
  const { user } = useContext(AuthContext);
  const { deleteOrderUpdate } = useContext(OrdersContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleDelete = () => {
    modalComponent(
      "Delete Update",
      <div>
        <p>Are you sure you want to delete this order update?</p>
        <button
          onClick={() =>
            deleteOrderUpdate(
              project_id,
              order_update.order_update_id,
              handleCallback
            )
          }
          className="btn btn-danger my-3 w-100"
        >
          Delete
        </button>
        <button onClick={clearModal} className="btn text-muted w-100">
          Cancel
        </button>
      </div>
    );
  };

  const renderOrderStep = (order_update) => {
    if (order_update.order_step !== null) {
      return <h4 className="my-1">{order_update.order_step.name}</h4>;
    }
  };

  const renderOrderSubStep = (order_update) => {
    if (order_update.order_substep !== null) {
      return <p className="mb-1">{order_update.order_substep.name}</p>;
    }
  };

  const renderComment = () => {
    if (String(order_update.comment).includes("http")) {
      return (
        <a href={order_update.comment} target="_blank" rel="noreferrer">
          {order_update.comment}
        </a>
      );
    }
    return order_update.comment;
  };

  return (
    <div className="row py-2 border-top">
      <div className="col-10">
        <p className="text-muted mb-0">
          #{order_update.order_update_id} -{" "}
          {moment(order_update.createdAt).format("DD MMM YYYY")}
        </p>
        {renderOrderStep(order_update)}
        {renderOrderSubStep(order_update)}
        <p className="mb-0">{renderComment()}</p>
      </div>
      <div className="col-2">
        {order_update.user_id === user.user_id && user.user_type_id > 2 && (
          <button onClick={handleDelete} className="btn btn-sm text-danger">
            <i className="fa fa-times"></i>
          </button>
        )}
      </div>
    </div>
  );
};
export default OrderUpdateCard;
