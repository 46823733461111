import {
  CREATE_USER,
  SET_PROPERTY_USER,
  SET_USER,
  USERS_RECEIVED,
} from "../types/users";

const schema = {
  user_id: "nuevo",
  name: "",
  last_name: "",
  email: "",
  phone: "",
  user_type_id: 1,
};

const UsersReducer = (state, { type, payload }) => {
  switch (type) {
    case USERS_RECEIVED:
      return { ...state, users: payload };
    case SET_PROPERTY_USER:
      const user = { ...state.user };
      const { key, value } = payload;
      user[key] = value;
      return { ...state, user };
    case CREATE_USER:
      return { ...state, user: schema };
    case SET_USER:
      return { ...state, user: payload };
    default:
      return { ...state };
  }
};
export default UsersReducer;
