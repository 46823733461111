import React, { useContext } from "react";
import { InvoicesContext } from "../../context/InvoicesContext";

const ConfirmDeleteInvoice = ({
  project_id,
  invoice_id,
  handleCancel,
  handleCallback,
}) => {
  const { deleteInvoice } = useContext(InvoicesContext);
  return (
    <div>
      <p>Are you sure you want to delete Invoice #{invoice_id}?</p>
      <p>All payments will be subtracted from the project's paid amount.</p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() =>
              deleteInvoice(project_id, invoice_id, handleCallback)
            }
            className="btn btn-danger w-100"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteInvoice;
