import React, { createContext, useContext, useReducer } from "react";
import ActionsReducer from "../reducers/ActionsReducer";
import ActionsService from "../services/ActionsService";
import { HIDE_SPINNER, PERMISSION_DENIED, SHOW_SPINNER } from "../types";
import {
  ACTIONS_RECEIVED,
  CREATE_ACTION,
  SET_PROPERTY_ACTION,
  SET_SINGLE_ACTION,
  TOGGLE_ACTION_DEPENDENCE,
} from "../types/actions";
import { ModalContext } from "./ModalContext";

const initialState = {
  action: null,
  actions: null,
  spinner: false,
};

export const ActionsContext = createContext(initialState);

export const ActionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ActionsReducer, initialState);
  const { alert, success, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        return dispatch({ type: PERMISSION_DENIED });
      }
    }
    alert(error);
  };

  const getActionsActivity = (activity_id) => {
    ActionsService.getActionsActivity(activity_id)
      .then((res) => {
        const { actions } = res.data;
        dispatch({ type: ACTIONS_RECEIVED, payload: actions });
      })
      .catch(handleError);
  };

  const completeAction = (action, callback) => {
    ActionsService.completeAction(action).then(callback).catch(alert);
  };
  const uncompleteAction = (action, callback) => {
    ActionsService.uncompleteAction(action).then(callback).catch(alert);
  };

  const setAction = (action) => {
    dispatch({ type: SET_SINGLE_ACTION, payload: action });
  };
  const createAction = () => {
    dispatch({ type: CREATE_ACTION });
  };

  const setPropertyAction = (key, value) => {
    dispatch({ type: SET_PROPERTY_ACTION, payload: { key, value } });
  };

  const postAction = (action) => {
    let service = ActionsService.putAction;
    if (isNaN(parseInt(action.action_id))) {
      service = ActionsService.postAction;
    }
    service(action)
      .then(() => {
        const { activity_id } = action;
        getActionsActivity(activity_id);
        success("Action saved");
        clearModal();
      })
      .catch((error) => {
        const { response } = error;
        if (response && response !== null) {
          const { data } = response;
          if (data && data !== null) {
            return alert(data.message);
          }
        }
      });
  };

  const deleteAction = (action) => {
    const { activity_id, action_id } = action;
    dispatch({ type: SHOW_SPINNER });
    ActionsService.deleteAction(action_id)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        getActionsActivity(activity_id);
        success("Action deleted.");
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const toggleActionDependence = (action) => {
    dispatch({ type: TOGGLE_ACTION_DEPENDENCE, payload: action });
  };

  return (
    <ActionsContext.Provider
      value={{
        ...state,
        setAction,
        postAction,
        deleteAction,
        createAction,
        completeAction,
        uncompleteAction,
        setPropertyAction,
        getActionsActivity,
        toggleActionDependence,
      }}
    >
      {children}
    </ActionsContext.Provider>
  );
};
