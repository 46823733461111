import React from "react";

const UserPicker = ({ label, users, value, modifier }) => {
  const renderUsers = () => {
    if (Array.isArray(users)) {
      return [
        <option key="null" value={null}>
          No user selected
        </option>,
        ...users.map((user) => (
          <option key={user.user_id} value={user.user_id}>
            {user.name}: {user.email}
          </option>
        )),
      ];
    }
  };
  return (
    <div>
      <label>{label ? label : "Select a User"}</label>
      <select
        value={value}
        className="form-control mb-3"
        onChange={(e) => {
          if (isNaN(parseInt(e.target.value))) {
            return modifier(null);
          }
          modifier(e.target.value);
        }}
      >
        {renderUsers()}
      </select>
    </div>
  );
};

export default UserPicker;
