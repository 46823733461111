import {
  ORDER_TEMPLATES_RECEIVED,
  SET_ORDER_TEMPLATE,
} from "../types/order_templates";

const OrderTemplatesReducer = (state, { type, payload }) => {
  switch (type) {
    case ORDER_TEMPLATES_RECEIVED:
      return { ...state, order_templates: payload };
    case SET_ORDER_TEMPLATE:
      return { ...state, order_template: payload };
    default:
      return { ...state };
  }
};
export default OrderTemplatesReducer;
