import { SET_FILTER } from "../types";
import {
  CLEAR_APPROVAL,
  CREATE_INVOICE,
  CREATE_ORDER,
  CREATE_ORDER_APPROVAL,
  ORDERS_RECEIVED,
  ORDER_STEPS_RECEIVED,
  SET_INVOICE,
  SET_ORDER,
  SET_PROPERTY_INVOICE,
  SET_PROPERTY_ORDER,
} from "../types/orders";
import { SET_PROPERTY_PRODUCT, SET_SINGLE_PRODUCT } from "../types/products";

const invoiceSchema = {
  invoice_id: "",
  amount: null,
  currency: null,
  min_date: null,
  max_date: null,
  status: "pending",
};

const schema = {
  status: "created",
  minimum_level: 1,
  expiration_date: "",
  order_template_id: 1,
};

const approvalSchema = {
  level: "",
};

const OrdersReducer = (state, { type, payload }) => {
  switch (type) {
    case CREATE_ORDER:
      return { ...state, order: schema };
    case SET_PROPERTY_ORDER:
      const { key, value } = payload;
      const order = { ...state.order };
      order[key] = value;
      return { ...state, order };
    case SET_PROPERTY_PRODUCT: {
      const { key, value } = payload;
      const product = { ...state.product };
      product[key] = value;
      return { ...state, product };
    }
    case SET_SINGLE_PRODUCT:
      return { ...state, product: payload };
    case SET_PROPERTY_INVOICE: {
      const invoice = { ...state.invoice };
      const { key, value } = payload;
      invoice[key] = value;
      return { ...state, invoice };
    }
    case SET_FILTER: {
      const { key, value } = payload;
      const filters = { ...state.filters };
      filters[key] = value;
      return { ...state, filters };
    }
    case CREATE_ORDER_APPROVAL:
      return { ...state, approval: approvalSchema };
    case ORDERS_RECEIVED:
      return { ...state, orders: payload };
    case CLEAR_APPROVAL:
      return { ...state, approval: null };
    case CREATE_INVOICE:
      return { ...state, invoice: invoiceSchema };
    case SET_INVOICE:
      return { ...state, invoice: payload };
    case SET_ORDER:
      return { ...state, order: payload };
    case ORDER_STEPS_RECEIVED:
      return { ...state, order_steps: payload };
    default:
      return { ...state };
  }
};
export default OrdersReducer;
