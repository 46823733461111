import api from "./api";
import { getArgs } from "../utils";

const route = "/comments";

const CommentsService = {
  getComments: (filters) => api.get(`${route}?${getArgs(filters)}`),
  postComment: (comment) => api.post(route, { ...comment }),
  deleteComment: (project_id, comment_id) =>
    api.delete(`${route}/${project_id}/${comment_id}`),
};
export default CommentsService;
