import React, { useContext, useEffect } from "react";
import ProjectProcesses from "../components/process/ProjectProcesses";
import UpcomingEvents from "../components/events/UpcomingEvents";
import { ProjectsContext } from "../context/ProjectsContext";
import Greeting from "../components/home/Greeting";
import { ModalContext } from "../context/ModalContext";
import SelectTemplate from "../components/templates/SelectTemplate";
import CriticalActivities from "../components/activities/CriticalActivities";
import ProjectInvoices from "../components/invoices/ProjectInvoices";
import { Link } from "@reach/router";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

// Project Dashboard
const ProjectDashboard = ({ project_id }) => {
  const { modalComponent } = useContext(ModalContext);
  const { project, getSingleProject } = useContext(ProjectsContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    getSingleProject(project_id);
  };

  const handleTemplate = () => {
    modalComponent(
      "Apply Template",
      <SelectTemplate project_id={project_id} />
    );
  };

  const renderApplyTemplate = () => {
    if (project && project !== null) {
      if (Array.isArray(project.processes)) {
        if (project.processes.length === 0) {
          return (
            <div className="col-6">
              <button
                className="btn btn-sm btn-light small w-100"
                onClick={handleTemplate}
              >
                Apply Template
              </button>
            </div>
          );
        }
      }
    }
  };

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={fetchData} data={project} />
      <Greeting />
      <div className="row align-items-center mb-3">
        {renderApplyTemplate()}
        <div className="col-6">
          <Link
            to={`/project/${project_id}/info`}
            className="btn btn-sm btn-outline-accent w-100"
          >
            <i className="fa fa-info-circle me-2"></i> Info
          </Link>
        </div>
      </div>
      <ProjectProcesses project_id={project_id} />
      <div className="mt-4">
        <h2 className="border-bottom pb-2">Critical Activities</h2>
        <CriticalActivities project_id={project_id} />
      </div>
      <div className="mt-4">
        <h2 className="border-bottom pb-2">Events</h2>
        <UpcomingEvents project_id={project_id} />
      </div>
      <div className="mt-4">
        <h2 className="border-bottom pb-2">Invoices</h2>
        <ProjectInvoices project_id={project_id} />
      </div>
    </div>
  );
};

export default ProjectDashboard;
