import React, { useContext, useEffect } from "react";
import ActivityCard from "../../components/activities/ActivityCard";
import { AnalyticsContext } from "../../context/AnalyticsContext";
import IonPullRefresh from "../../components/ionic/IonPullRefresh";

const AnalyticsComments = () => {
  const { comments, getCommentedItems } = useContext(AnalyticsContext);

  useEffect(() => {
    getCommentedItems();
  }, []);

  const renderActivities = () => {
    if (Array.isArray(comments)) {
      if (comments.length === 0) {
        return <p>No commented activities available.</p>;
      }
      return comments.map((activity) => (
        <ActivityCard
          key={activity.activity_id}
          project_id={activity.project_id}
          activity={activity}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={getCommentedItems} data={comments} />
      <h1>Commented Activities</h1>
      {renderActivities()}
    </div>
  );
};

export default AnalyticsComments;
