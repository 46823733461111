import React from "react";
import NavbarLink from "./NavbarLink";

const MenuBody = ({ user, menus, project_id, signOut }) => {
  const renderMenu = () => {
    if (Array.isArray(menus) && user !== null) {
      let menusRender = menus;
      menusRender = menusRender.filter(
        (menu) =>
          (!menu.level || menu.level <= user.user_type_id) &&
          (!menu.max_level || menu.max_level >= user.user_type_id) &&
          (!menu.base_url || window.location.pathname.includes(menu.base_url))
      );
      if (project_id === null) {
        menusRender = menusRender.filter((menu) => !menu.scoped);
      } else {
        menusRender = menusRender.filter((menu) => menu.scoped);
      }
      return menusRender.map((menu, index) => (
        <div key={`${menu.name}-${index}`} className="mb-3">
          <p className="mb-2 text-muted">{menu.name}</p>
          {menu.items.map((item, index) => (
            <NavbarLink
              key={`${item.name}-${index}`}
              scoped={menu.scoped}
              project_id={project_id}
              {...item}
            />
          ))}
        </div>
      ));
    }
  };

  return (
    <div>
      {renderMenu()}
      <div className="my-4"></div>
      <NavbarLink name="Logout" link="/" icon="sign-out-alt" action={signOut} />
    </div>
  );
};

export default MenuBody;
