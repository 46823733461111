import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  CREATE_EVENT,
  EVENTS_RECEIVED,
  EVENT_TYPES_RECEIVED,
  SET_PROPERTY_EVENT,
  SET_SINGLE_EVENT,
} from "../types/events";

const schema = {
  name: "",
  event_id: "",
  end_date: "",
  start_date: "",
  event_type_id: 1,
  minimum_level: 1,
};

const EventReducer = (state, { type, payload }) => {
  switch (type) {
    case CREATE_EVENT:
      return { ...state, event: schema };
    case EVENTS_RECEIVED: {
      let events = state.events;
      if(!Array.isArray(events)) events = [];
      events = [...events, ...payload];
      let eventsSet = new Set();
      events.forEach(current => eventsSet.add(parseInt(current.event_id)));
      let eventsResult = [];
      eventsSet.forEach(event_id => {
        let current = events.find(currentEvent => parseInt(currentEvent.event_id) === parseInt(event_id));
        if(current) eventsResult.push(current);
      })
      return { ...state, events: eventsResult };
    }
    case SET_SINGLE_EVENT:
      return { ...state, event: payload };
    case SET_PROPERTY_EVENT:
      const event = { ...state.event };
      const { key, value } = payload;
      event[key] = value;
      return { ...state, event };
    case EVENT_TYPES_RECEIVED:
      return { ...state, event_types: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default EventReducer;
