import React, { useContext } from "react";
import { EventsContext } from "../../context/EventsContext";

const EventCancelConfirm = ({
  event,
  project_id,
  handleCancel,
  handleCallback,
}) => {
  const { deleteEvent } = useContext(EventsContext);
  return (
    <div>
      <p>
        Are you sure you want to cancel this event? This action CANNOT be
        undone.
      </p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn btn-sm text-muted">
            Nevermind
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() =>
              deleteEvent(project_id, event.event_id, handleCallback)
            }
            className="btn btn-sm btn-danger w-100"
          >
            Cancel Event
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventCancelConfirm;
