import React, { useContext } from "react";
import { navigate } from "@reach/router";
import PreviewProduct from "./PreviewProduct";
import { productsOptions } from "../../utils/products";
import { ProductsContext } from "../../context/ProductsContext";

const PreviewProductsTable = ({ project_id, products }) => {
  const { spinner, postProducts } = useContext(ProductsContext);

  const handleSubmitCallback = () => {
    navigate(`/project/${project_id}/products`);
  };

  const handleSubmitProducts = () => {
    products = products.map((product) => ({ ...product, project_id }));
    postProducts(products, handleSubmitCallback);
  };

  const renderConceptos = () => {
    if (Array.isArray(products)) {
      let productsRender = [...products];
      productsRender = productsRender.filter(
        (product) => String(product.product_id)[0] !== "d"
      );
      return (
        <div className="card p-3 my-3 shadow">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr className="border bg-light bold">
                  {productsOptions.map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {productsRender.map((product) => (
                  <PreviewProduct key={product.sku} product={product} />
                ))}
              </tbody>
            </table>
          </div>
          <div className="container-fluid px-0 my-3">
            <button
              className="btn btn-primary"
              onClick={handleSubmitProducts}
              disabled={spinner}
            >
              <i className="fa fa-save me-1"></i>{" "}
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                "Save Products"
              )}
            </button>
          </div>
        </div>
      );
    }
  };
  return <div>{renderConceptos()}</div>;
};

export default PreviewProductsTable;
