import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import SwipeCalendar from "../components/calendar/SwipeCalendar";
import EventCard from "../components/events/EventCard";
import EventForm from "../components/events/EventForm";
import { EventsContext } from "../context/EventsContext";
import { ModalContext } from "../context/ModalContext";
import { getValue } from "../utils";
import { AuthContext } from "../context/AuthContext";
import CalendarFilters from "../components/calendar/CalendarFilters";
import { UsersContext } from "../context/UsersContext";
import { ProjectsContext } from "../context/ProjectsContext";
import BottomAction from "../components/global/BottomAction";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const Calendar = ({ project_id }) => {
  const [userId, setUserId] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [eventTypeId, setEventTypeId] = useState(null);

  const {
    events,
    event_types,
    createEvent,
    getEventTypes,
    getAdminEvents,
    getEventsProject,
  } = useContext(EventsContext);
  const { project } = useContext(ProjectsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { users, getUsers, getProjectUsers } = useContext(UsersContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setupWeek();
    getEventTypes();
    if (!project_id) {
      getUsers();
    } else {
      getProjectUsers(project_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, project_id, eventTypeId, currentDay, startDate, endDate]);

  const isDisabled = () => {
    if (project && project !== null) {
      return project.project_users.length === 1;
    }
    return true;
  };

  const fetchEvents = () => {
    if (!project_id && user.user_type_id > 2) {
      getAdminEvents({
        event_type_id: eventTypeId,
        start_date: startDate,
        end_date: endDate,
        date: currentDay,
        user_id: userId,
      });
    } else {
      getEventsProject(project_id, {
        event_type_id: eventTypeId,
        start_date: startDate,
        end_date: endDate,
        date: currentDay,
        user_id: userId,
      });
    }
  };

  const handleFilter = (key, value) => {
    if (key === "user_id") {
      setUserId(value);
    } else if (key === "event_type_id") {
      setEventTypeId(value);
    }
  };

  const setupWeek = () => {
    const start = moment().local().startOf("isoweek").format("YYYY-MM-DD");
    const end = moment().local().endOf("isoweek").format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);
  };

  const handleCreate = () => {
    createEvent();
    modalComponent(
      "Add Event",
      <EventForm
        project_id={project_id}
        currentDate={currentDay}
        handleCancel={clearModal}
      />
    );
  };

  const renderCalendar = () => {
    if (startDate !== null && endDate !== null) {
      return (
        <SwipeCalendar
          startDate={startDate}
          endDate={endDate}
          currentDay={currentDay}
          setCurrentDay={setCurrentDay}
        />
      );
    }
  };

  const renderEvents = () => {
    if (Array.isArray(events)) {
      if (events.length === 0) {
        return <p>No events for this day.</p>;
      }
      return events.map((event) => (
        <EventCard
          key={event.event_id}
          event={event}
          project_id={project_id}
          handleCallback={fetchEvents}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid pb-4">
      <IonPullRefresh handleCallback={fetchEvents} data={events} />
      <h1 className="border-bottom pb-2 mb-3">Calendar</h1>
      {user.user_type_id > 2 && (
        <CalendarFilters
          users={users}
          user_id={userId}
          setFilter={handleFilter}
          event_types={event_types}
          event_type_id={eventTypeId}
        />
      )}
      <div className="row">
        <div className="col-6">
          <label>Desde</label>
          <input
            type="date"
            value={getValue({ startDate }, "startDate")}
            className="form-control"
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>Hasta</label>
          <input
            type="date"
            value={getValue({ endDate }, "endDate")}
            className="form-control"
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      {renderCalendar()}
      {renderEvents()}
      <BottomAction
        onClick={handleCreate}
        disabled={isDisabled()}
        title="+ Add Event"
      />
    </div>
  );
};

export default Calendar;
