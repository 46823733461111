import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import CartButton from "../components/cart/CartButton";
import { ModalContext } from "../context/ModalContext";
import ProductForm from "../components/products/ProductForm";
import ProductItem from "../components/products/ProductItem";
import { ProductsContext } from "../context/ProductsContext";
import { InventoryContext } from "../context/InventoryContext";
import InventoryButton from "../components/inventory/InventoryButton";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { Link } from "@reach/router";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const ProductList = ({
  project_id,
  appendProduct,
  disablePicker,
  selectedProducts,
  setProductQuantity,
}) => {
  const [view, setView] = useState("products");
  const [query, setQuery] = useState("");
  const { user } = useContext(AuthContext);
  const { inventory } = useContext(InventoryContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const {
    inputs,
    products,
    createProduct,
    getProductInputs,
    getProjectProducts,
    clearSingleProduct,
    exportProductsToFile,
  } = useContext(ProductsContext);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const fetchProducts = () => {
    if (view === "products" && project_id && project_id !== null) {
      getProjectProducts(project_id, { query });
    } else {
      getProductInputs({ query });
    }
  };

  const handleCancel = () => {
    clearSingleProduct();
    clearModal();
  };

  const handleCreate = () => {
    createProduct();
    modalComponent(
      "Add Product",
      <ProductForm
        project_id={project_id}
        handleCancel={handleCancel}
        handleCallback={fetchProducts}
      />
    );
  };

  const handleExport = () => {
    exportProductsToFile(project_id, { query });
  };

  const renderButton = () => {
    if (user.user_type_id > 3) {
      return [
        <button
          key="export"
          className="btn btn-sm btn-secondary me-2"
          onClick={handleExport}
        >
          <i className="fa fa-upload" />
        </button>,
        <Link
          key="import"
          className="btn btn-sm btn-secondary me-2"
          to={`/project/${project_id}/upload/products`}
        >
          <i className="fa fa-download" />
        </Link>,
        <button
          key="add"
          className="btn btn-sm btn-primary"
          onClick={handleCreate}
        >
          + Add
        </button>,
      ];
    }
  };

  const renderProducts = () => {
    let productsRender = products;
    if (view === "inputs" || !project_id || project_id === null) {
      productsRender = inputs;
    }
    if (Array.isArray(productsRender)) {
      if (productsRender.length === 0) {
        return <p>No available products.</p>;
      }
      productsRender = productsRender.filter((product) =>
        String(product.name).toLowerCase().includes(String(query).toLowerCase())
      );
      return productsRender.map((product) => (
        <ProductItem
          product={product}
          project_id={project_id}
          key={product.product_id}
          products={selectedProducts}
          disablePicker={disablePicker}
          appendProduct={appendProduct}
          setProductQuantity={setProductQuantity}
        />
      ));
    }
  };

  const renderCart = () => {
    if (window.location.pathname.includes("inventory") && inventory !== null) {
      return <InventoryButton project_id={project_id} />;
    }
    return <CartButton project_id={project_id} />;
  };

  const renderSwitch = () => {
    if (user.user_type_id > 3 && project_id && project_id !== null) {
      return (
        <IonSegment color="accent" className="mb-3" value={view}>
          <IonSegmentButton
            value="products"
            onClick={() => setView("products")}
          >
            <IonLabel>Products</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="inputs" onClick={() => setView("inputs")}>
            <IonLabel>Inputs</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      );
    }
  };

  return (
    <div className="container-fluid py-4 pe-0">
      <IonPullRefresh handleCallback={fetchProducts} data={products} />
      <div className="row mb-3 align-items-center">
        <div className="col-6">
          <h1 className="mb-0">Products</h1>
        </div>
        <div className="col-6 text-end">{renderButton()}</div>
      </div>
      <input
        type="text"
        value={query}
        className="form-control mb-3"
        placeholder="Search a product..."
        onChange={(e) => setQuery(e.target.value)}
      />
      {renderSwitch()}
      {renderProducts()}
      {renderCart()}
    </div>
  );
};

export default ProductList;
