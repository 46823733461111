import api from "./api";
import { getArgs } from "../utils";
const route = "/analytics";

const AnalyticsService = {
  getTotalIncome: (filters) => api.get(`${route}/income?${getArgs(filters)}`),
  getProjectFinances: (project_id) =>
    api.get(`${route}/income/project/${project_id}`),
  getOverdueItems: (filters) => api.get(`${route}/overdue?${getArgs(filters)}`),
  getCommentedItems: (filters) =>
    api.get(`${route}/comments?${getArgs(filters)}`),
};

export default AnalyticsService;
