import React, { useState, useEffect } from "react";
import ActivityService from "../../services/ActivityService";

const ActivityPicker = ({ project_id, selected, modifier }) => {
  const [query, setQuery] = useState("");
  const [activities, setActivities] = useState(null);

  useEffect(() => {
    if (query !== "") {
      fetchActivities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setQuery("");
    setActivities(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const fetchActivities = () => {
    ActivityService.getActivitiesProject(project_id, { query }).then((res) => {
      setActivities(res.data.activities);
    });
  };

  const renderActivities = () => {
    if (Array.isArray(activities) && query !== "") {
      let activitiesRender = [...activities];
      if (activitiesRender.length === 0) {
        return (
          <ul className="dropdown-menu mw-100 w-100 show">
            <li className="dropdown-ite small text-dark">
              No activities found.
            </li>
          </ul>
        );
      }
      return (
        <ul className="dropdown-menu mw-100 w-100 show">
          {activitiesRender.map((activity) => (
            <li
              className="dropdown-item small border-bottom py-2 text-dark mw-100"
              onClick={() => modifier(activity)}
            >
              {activity.name}
            </li>
          ))}
        </ul>
      );
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      return selected.map((current) => (
        <div className="row align-items-center my-2 py-2 bg-light border mx-0">
          <div className="col-10">
            <p className="mb-0">{current.name}</p>
          </div>
          <div className="col-2">
            <button
              className="btn btn-sm py-0"
              onClick={() => modifier(current)}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container position-relative px-0 mb-3">
      <label>Dependences</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Search activities..."
        onChange={(e) => setQuery(e.target.value)}
      />
      {renderActivities()}
      {renderSelected()}
    </div>
  );
};

export default ActivityPicker;
