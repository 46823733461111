import React, { useReducer, createContext } from "react";
import AnalyticsReducer from "../reducers/AnalyticsReducer";
import AnalyticsService from "../services/AnalyticsService";
import {
  COMMENTS_ITEMS_RECEIVED,
  INCOME_RECEIVED,
  OVERDUE_ITEMS_RECEIVED,
} from "../types/analytics";

const initialState = {
  income: null,
};

export const AnalyticsContext = createContext(initialState);

export const AnalyticsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnalyticsReducer, initialState);

  const getTotalIncome = (filters) => {
    AnalyticsService.getTotalIncome(filters).then((res) => {
      dispatch({ type: INCOME_RECEIVED, payload: res.data });
    });
  };

  const getProjectFinances = (project_id) => {
    AnalyticsService.getProjectFinances(project_id).then((res) => {
      dispatch({ type: INCOME_RECEIVED, payload: res.data });
    });
  };

  const getOverdueItems = (filters) => {
    AnalyticsService.getOverdueItems(filters).then((res) => {
      const { activities, actions } = res.data;
      dispatch({
        type: OVERDUE_ITEMS_RECEIVED,
        payload: { activities, actions },
      });
    });
  };

  const getCommentedItems = (filters) => {
    AnalyticsService.getCommentedItems(filters).then((res) => {
      const { activities } = res.data;
      dispatch({ type: COMMENTS_ITEMS_RECEIVED, payload: activities });
    });
  };

  return (
    <AnalyticsContext.Provider
      value={{
        ...state,
        getTotalIncome,
        getProjectFinances,
        getCommentedItems,
        getOverdueItems,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
