import React, { useContext } from "react";
import moment from "moment";
import parse from "html-react-parser";
import IconItem from "../global/IconItem";
import ActivityForm from "./ActivityForm";
import ProgressBar from "../global/ProgressBar";
import StatusBadge from "../global/StatusBadge";
import TimeLeftBadge from "../global/TimeLeftBadge";
import DeleteConfirm from "../global/DeleteConfirm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ActivityContext } from "../../context/ActivityContext";

const ActivityDetail = ({ project_id, handleCallback }) => {
  const { spinner, activity, postActivity, deleteActivity } =
    useContext(ActivityContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const handleStatus = (status) => {
    postActivity({ ...activity, status }, handleCallback);
  };

  const handleEditActivity = () => {
    modalComponent(
      "Edit Activity",
      <ActivityForm
        step_id={activity.step_id}
        handleCancel={clearModal}
        handleDelete={handleDelete}
      />
    );
  };

  const handleDelete = () => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Delete Activity",
        <DeleteConfirm
          itemName="activity"
          spinner={spinner}
          handleDelete={deleteActivity}
          item={{ ...activity, project_id }}
          description="This will delete all actions from the activity."
        />
      );
    }, 500);
  };

  const getProgress = (actions, completed) => {
    let progress = 0;
    if (actions > 0 && actions !== null) {
      progress = parseInt((parseFloat(completed) / parseFloat(actions)) * 100);
    }
    return progress;
  };

  const renderButton = () => {
    if (user.user_type_id > 3) {
      return (
        <button
          className="btn btn-sm px-2 text-accent"
          onClick={handleEditActivity}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  const renderCompleted = () => {
    if (activity.completedAt !== null) {
      return (
        <div className="col-6">
          <IconItem name="Completed At" icon="calendar text-accent">
            <p className="mb-0">
              {moment(activity.completedAt).utc().format("MMM Do YYYY")}
            </p>
          </IconItem>
        </div>
      );
    }
  };
  const renderStarted = () => {
    if (activity.startedAt !== null) {
      return (
        <div className="col-6">
          <IconItem name="Started At" icon="calendar text-primary">
            <p className="mb-0">
              {moment(activity.startedAt).utc().format("MMM Do YYYY")}
            </p>
          </IconItem>
        </div>
      );
    }
  };

  const renderDeadline = () => {
    if (activity.end_date !== null) {
      return (
        <div className="col-6">
          <IconItem name="Deadline" icon="calendar text-primary">
            <p className="mb-0">
              {moment(activity.end_date).utc().format("MMM Do YYYY")}
            </p>
          </IconItem>
        </div>
      );
    }
  };

  const renderResponsible = () => {
    const { responsible } = activity;
    if (responsible && responsible !== null) {
      return (
        <div className="col-6">
          <IconItem name="Responsible" icon="user text-accent">
            <p className="mb-0">
              {responsible.name} {responsible.last_name}
            </p>
          </IconItem>
        </div>
      );
    }
  };

  const renderTimeLeft = (progress) => {
    if (activity.end_date !== null && progress < 100) {
      return <TimeLeftBadge date={activity.end_date} />;
    }
  };

  const renderPreconditions = () => {
    const { dependences } = activity;
    if (Array.isArray(dependences)) {
      return dependences.map((currentActivity) => (
        <div key={currentActivity.activity_id} className="col-6">
          <IconItem
            name="Precondition"
            icon={
              currentActivity.completedAt !== null
                ? "check text-success"
                : "lock text-secondary"
            }
          >
            <p className="mb-0">{currentActivity.name}</p>
          </IconItem>
        </div>
      ));
    }
  };

  const renderDescription = () => {
    if (activity.description && activity.description !== null) {
      return parse(
        String(activity.description).replace(/(?:\r\n|\r|\n)/g, "<br>")
      );
    }
  };

  const { actions, completed } = activity;
  const progress = getProgress(actions, completed);

  return (
    <div className="container-fluid px-0">
      <div className="container-fluid px-3">
        <div className="row align-items-center">
          <div className="col-9">
            <h3>{activity.name}</h3>
            <div className="row">
              <div className="col-6">
                <StatusBadge
                  status={activity.status}
                  handleSubmit={handleStatus}
                />
              </div>
              <div className="col-6 text-end">{renderTimeLeft()}</div>
            </div>
          </div>
          <div className="col-3 text-end">{renderButton()}</div>
        </div>
        <div className="small my-3">{renderDescription()}</div>
        <div className="row">
          {renderStarted()}
          {renderDeadline()}
          {renderCompleted()}
          {renderResponsible()}
          {renderPreconditions()}
        </div>
        <div className="row mt-3 mb-1">
          <div className="col-6">{progress}% Completed</div>
          <div className="col-6 text-end">
            Completed {completed} / {actions}
          </div>
        </div>
        <ProgressBar progress={progress} />
      </div>
    </div>
  );
};

export default ActivityDetail;
