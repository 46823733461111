import React, { createContext, useContext, useReducer } from "react";
import ProjectTemplatesReducer from "../reducers/ProjectTemplatesReducer";
import ProjectTemplatesService from "../services/ProjectTemplatesService";
import {
  PROJECT_TEMPLATES_RECEIVED,
  SET_PROJECT_TEMPLATE,
  SET_PROPERTY_TEMPLATE,
} from "../types/project_templates";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  templates: null,
  template: null,
  spinner: false,
};

export const ProjectTemplatesContext = createContext(initialState);

export const ProjectTemplatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProjectTemplatesReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getProjectTemplates = () => {
    ProjectTemplatesService.getProjectTemplates().then((res) => {
      const { project_templates } = res.data;
      dispatch({
        type: PROJECT_TEMPLATES_RECEIVED,
        payload: project_templates,
      });
    });
  };
  const getSingleProjectTemplate = (project_template_id) => {
    ProjectTemplatesService.getSingleProjectTemplate(project_template_id).then(
      (res) => {
        const { project_template } = res.data;
        dispatch({
          type: SET_PROJECT_TEMPLATE,
          payload: project_template,
        });
      }
    );
  };

  const setPropertyTemplate = (key, value) => {
    dispatch({ type: SET_PROPERTY_TEMPLATE, payload: { key, value } });
  };

  const postProjectTemplate = (project_id, name) => {
    ProjectTemplatesService.postProjectTemplate(project_id, name).then(
      (res) => {
        success("Project template saved.");
        getProjectTemplates();
        clearModal();
      }
    );
  };

  const updateProjectTemplate = (project_template) => {
    ProjectTemplatesService.putProjectTemplate(project_template).then(() => {
      getSingleProjectTemplate(project_template.project_template_id);
      success("Project template saved.");
      clearModal();
    });
  };

  const applyProjectTemplate = (
    { project_id, start_date, project_template_id },
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER})
    ProjectTemplatesService.applyProjectTemplate({
      project_id,
      start_date,
      project_template_id,
    }).then(() => {
      success("Template applied successfully! Please wait up to 1 minute for items to show up.");
      if (typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      alert("There was an error applying this template.");
      dispatch({ type: HIDE_SPINNER})
    })
  };

  const deleteProjectTemplate = (project_template_id) => {
    ProjectTemplatesService.deleteProjectTemplate(project_template_id).then(
      () => {
        success("Project template deleted.");
        navigate("/templates");
        clearModal();
      }
    );
  };

  return (
    <ProjectTemplatesContext.Provider
      value={{
        ...state,
        setPropertyTemplate,
        getProjectTemplates,
        postProjectTemplate,
        applyProjectTemplate,
        updateProjectTemplate,
        deleteProjectTemplate,
        getSingleProjectTemplate,
      }}
    >
      {children}
    </ProjectTemplatesContext.Provider>
  );
};
