import React, { useState, useContext, useEffect } from "react";
import ImageInput from "../common/ImageInput";
import { S3_ENDPOINT } from "../../utils";
import { ProjectsContext } from "../../context/ProjectsContext";
import { FilesContext } from "../../context/FileContext";

const ProjectImageForm = ({ handleCancel }) => {
  const [src, setSrc] = useState("");
  const { project, postProject } = useContext(ProjectsContext);
  const { spinner, file, setFile, uploadFile } = useContext(FilesContext);

  useEffect(() => {
    if (project && project !== null) {
      const { file } = project;
      if (file && file !== null) {
        setSrc(`${S3_ENDPOINT}/${file.name}.${file.type}`);
      }
    }
  }, [project]);

  const handleSubmit = (e) => {
    e.preventDefault();
    uploadFile(file, handleCallback);
  };

  const handleCallback = (file_id) => {
    project.file_id = file_id;
    postProject(project);
  };

  return (
    <form onSubmit={handleSubmit}>
      <ImageInput value={src} modifier={setFile} />
      <button
        type="submit"
        disabled={file === null || spinner}
        className="btn btn-sm btn-primary w-100 my-3"
      >
        {spinner ? <div className="spinner-border"></div> : "Save"}
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-secondary w-100"
      >
        Cancel
      </button>
    </form>
  );
};
export default ProjectImageForm;
