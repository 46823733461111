import React, { useContext, useEffect } from "react";
import Cart from "./Cart";
import Orders from "./Orders";
import Invoices from "./Invoices";
import Calendar from "./Calendar";
import Checkout from "./Checkout";
import Products from "./Products";
import ProjectInfo from "./ProjectInfo";
import SingleStep from "./SingleStep";
import AdminUsers from "./AdminUsers";
import SingleOrder from "./SingleOrder";
import Requisitions from "./Requisitions";
import SingleInvoice from "./SingleInvoice";
import SinglePayment from "./SinglePayment";
import SingleProcess from "./SingleProcess";
import InventoryList from "./InventoryList";
import SingleProduct from "./SingleProduct";
import SingleActivity from "./SingleActivity";
import ProjectTemplates from "./ProjectTemplates";
import ProjectDashboard from "./ProjectDashboard";
import SingleRequisition from "./SingleRequisition";
import SingleProjectFile from "./SingleProjectFile";
import { Router, useLocation } from "@reach/router";
import CreateProjectSteps from "./CreateProjectSteps";
import UploadProductsForm from "./UploadProductsForm";
import { ProjectsContext } from "../context/ProjectsContext";
import IonPullRefresh from "../components/ionic/IonPullRefresh";
import { AuthContext } from "../context/AuthContext";

const SingleProject = ({ project_id }) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { spinner, project, clearProject, getSingleProject } =
    useContext(ProjectsContext);

  useEffect(() => {
    return clearProject;
  }, []);

  useEffect(() => {
    fetchProject();
  }, [project_id, location]);

  const fetchProject = () => getSingleProject(project_id);

  const renderProject = () => {
    if (project && project !== null) {
      //if (project.status === "in-progress") {
      return (
        <Router>
          <CreateProjectSteps path="/steps" />
          <Cart path="/cart" />
          <Orders path="/orders" />
          <ProjectInfo path="/info" />
          <AdminUsers path="/users" />
          <Invoices path="/invoices" />
          <ProjectDashboard path="/" />
          <Calendar path="/calendar" />
          <Products path="/products" />
          {user.user_type_id > 2 && <SingleProjectFile path="/files" />}
          <InventoryList path="/inventory" />
          <SingleStep path="/step/:step_id" />
          <SingleOrder path="/order/:order_id" />
          <Checkout path="/checkout/:invoice_id" />
          <Requisitions path="/requisitions" />
          <ProjectTemplates path="/templates" />
          <SingleProduct path="/product/:product_id" />
          <SinglePayment path="/payment/:payment_id" />
          <SingleInvoice path="/invoice/:invoice_id" />
          <SingleProcess path="/process/:process_id" />
          <UploadProductsForm path="/upload/products" />
          <SingleActivity path="/activity/:activity_id" />
          <SingleRequisition path="/requisitions/:requisition_id" />
        </Router>
      );
    }
    if (spinner) return <div className="spinner-border" />;
  };

  return (
    <div>
      <IonPullRefresh handleCallback={fetchProject} data={project} />
      {renderProject()}
    </div>
  );
};

export default SingleProject;
