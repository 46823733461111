import React, { useState, useEffect } from "react";
import ProjectsService from "../../services/ProjectsService";

const ProjectPicker = ({ selected, modifier }) => {
  const [query, setQuery] = useState("");
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    if (query !== "") {
      fetchProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (selected !== null) {
      setQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const fetchProjects = () => {
    ProjectsService.getProjects({ query }).then((res) => {
      setProjects(res.data.projects);
    });
  };

  const renderProjects = () => {
    if (Array.isArray(projects) && query !== "") {
      let projectsRender = [...projects];
      if (query && query !== "") {
        projectsRender = projectsRender.filter(({ name }) =>
          String(name).toLowerCase().includes(String(query).toLowerCase())
        );
      }
      if (projectsRender.length === 0) {
        return (
          <ul className="dropdown-menu mw-100 w-100 show">
            <li className="dropdown-ite small text-dark">No projects found.</li>
          </ul>
        );
      }
      return (
        <ul className="dropdown-menu mw-100 w-100 show">
          {projectsRender.map((project) => (
            <li
              className="dropdown-item small border-bottom py-2 text-dark mw-100"
              onClick={() => modifier(project)}
            >
              {project.name}
            </li>
          ))}
        </ul>
      );
    }
  };

  const renderSelected = () => {
    if (selected && selected !== null) {
      return (
        <div className="row align-items-center mb-2 py-2 bg-light border mx-0">
          <div className="col-10">
            <p className="mb-0">{selected.name}</p>
          </div>
          <div className="col-2">
            <button className="btn btn-sm py-0" onClick={() => modifier(null)}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      );
    }
    return (
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Search projects..."
        onChange={(e) => setQuery(e.target.value)}
      />
    );
  };

  return (
    <div className="container position-relative px-0 mb-3">
      <label>Project</label>

      {renderProjects()}
      {renderSelected()}
    </div>
  );
};

export default ProjectPicker;
