import React, { useContext, useEffect } from "react";
import UserData from "../components/user/UserData";
import UserForm from "../components/user/UserForm";
import UserHeader from "../components/user/UserHeader";
import { UsersContext } from "../context/UsersContext";
import { ModalContext } from "../context/ModalContext";
import UserDeleteConfirm from "../components/user/UserDeleteConfirm";

const AdminSingleUser = ({ user_id }) => {
  const { user, getSingleUser } = useContext(UsersContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getSingleUser(user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const handleEdit = () => {
    modalComponent("Edit User", <UserForm handleCancel={clearModal} />);
  };

  const handleDelete = () => {
    modalComponent(
      "Delete User",
      <UserDeleteConfirm handleCancel={clearModal} />
    );
  };

  const renderProfile = () => {
    if (user && user !== null) {
      return (
        <div>
          <UserHeader user={user} />
          <UserData user={user} />
          <div className="mt-5 pb-4">
            <button className="btn btn-light border w-100" onClick={handleEdit}>
              Edit User
            </button>
          </div>
          <button
            className="btn btn-outline-danger w-100 my-3"
            onClick={handleDelete}
          >
            Delete User
          </button>
        </div>
      );
    }
  };
  return <div className="container-fluid pt-4">{renderProfile()}</div>;
};

export default AdminSingleUser;
