import React, { createContext, useContext, useReducer } from "react";
import UsersReducer from "../reducers/UsersReducer";
import UsersService from "../services/UsersService";
import {
  CREATE_USER,
  SET_PROPERTY_USER,
  SET_USER,
  USERS_RECEIVED,
} from "../types/users";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";

const initialState = {
  users: null,
  user: null,
};

export const UsersContext = createContext(initialState);

export const UsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UsersReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  const getUsers = () => {
    UsersService.getUsers().then((res) => {
      const { users } = res.data;
      dispatch({ type: USERS_RECEIVED, payload: users });
    });
  };

  const getProjectUsers = (project_id) => {
    UsersService.getProjectUsers(project_id).then((res) => {
      const { users } = res.data;
      dispatch({ type: USERS_RECEIVED, payload: users });
    });
  };

  const getSingleUser = (user_id) => {
    UsersService.getSingleUser(user_id).then((res) => {
      const { user } = res.data;
      dispatch({ type: SET_USER, payload: user });
    });
  };

  const setUser = (user) => {
    dispatch({ type: SET_USER, payload: user });
  };

  const createUser = () => {
    dispatch({ type: CREATE_USER });
  };

  const setPropertyUser = (key, value) => {
    dispatch({ type: SET_PROPERTY_USER, payload: { key, value } });
  };

  const saveUser = (user) => {
    let service = UsersService.putUser;
    if (isNaN(user.user_id)) {
      service = UsersService.postUser;
    }
    service(user)
      .then(() => {
        getUsers();
        clearModal();
        success("User saved.");
      })
      .catch(alert);
  };

  const deleteUser = (user_id) => {
    UsersService.deleteUser(user_id)
      .then(() => {
        getUsers();
        clearModal();
        success("User deleted.");
      })
      .catch(alert);
  };

  return (
    <UsersContext.Provider
      value={{
        ...state,
        setUser,
        getUsers,
        saveUser,
        createUser,
        deleteUser,
        getSingleUser,
        getProjectUsers,
        setPropertyUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
