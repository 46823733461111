import React, { useReducer, createContext, useContext } from "react";
import InventoryService from "../services/InventoryService";
import InventoryReducer from "../reducers/InventoryReducer";
import {
  SET_INVENTORY,
  CREATE_REMINDER,
  CREATE_INVENTORY,
  INVENTORIES_RECEIVED,
  SET_PROPERTY_INVENTORY,
  SET_INVENTORY_REMINDER,
  INVENTORY_REMINDERS_RECEIVED,
} from "../types/inventories";
import { ModalContext } from "./ModalContext";
import {
  CLEAR_CART,
  APPEND_PRODUCT,
  REMOVE_PRODUCT,
  SET_PRODUCT_QUANTITY,
} from "../types/cart";
import { PRODUCTS_RECEIVED } from "../types/products";

const initialState = {
  inventories: null,
  inventory: null,
  reminders: null,
  reminder: null,
  products: [],
};

export const InventoryContext = createContext(initialState);

export const InventoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InventoryReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getInventories = () => {
    InventoryService.getInventories().then((res) => {
      const { inventories } = res.data;
      dispatch({ type: INVENTORIES_RECEIVED, payload: inventories });
    });
  };

  const getSingleInventory = (inventory_id) => {
    InventoryService.getSingleInventory(inventory_id).then((res) => {
      const { inventory } = res.data;
      dispatch({ type: SET_INVENTORY, payload: inventory });
      dispatch({ type: PRODUCTS_RECEIVED, payload: inventory.products });
    });
  };

  const getInventoryReminders = (project_id) => {
    InventoryService.getInventoryReminders(project_id).then((res) => {
      const { inventory_notifications } = res.data;
      dispatch({
        type: INVENTORY_REMINDERS_RECEIVED,
        payload: inventory_notifications,
      });
    });
  };

  const setInventory = (inventory) => {
    dispatch({ type: SET_INVENTORY, payload: inventory });
  };

  const setReminder = (reminder) => {
    dispatch({ type: SET_INVENTORY_REMINDER, payload: reminder });
  };

  const createInventory = () => {
    dispatch({ type: CREATE_INVENTORY });
  };

  const createReminder = () => {
    dispatch({ type: CREATE_REMINDER });
  };

  const setPropertyInventory = (key, value) => {
    dispatch({ type: SET_PROPERTY_INVENTORY, payload: { key, value } });
  };

  const saveInventory = (inventory) => {
    let service = InventoryService.putInventory;
    if (isNaN(parseInt(inventory.inventory_id))) {
      service = InventoryService.postInventory;
    }
    service(inventory).then(() => {
      success("Inventory saved.");
      getInventories();
      clearModal();
    });
  };

  const saveInventoryReminder = (reminder, callback) => {
    let service = InventoryService.putInventoryReminder;
    if (isNaN(parseInt(reminder.inventory_notification_id))) {
      service = InventoryService.postInventoryReminder;
    }
    service(reminder).then(() => {
      getInventoryReminders(reminder.project_id);
      if (typeof callback === "function") {
        callback();
      }
      success("Reminder saved.");
      clearModal();
    });
  };

  const deleteInventory = (inventory_id) => {
    InventoryService.deleteInventory(inventory_id).then(() => {
      success("Inventory deleted.");
      clearModal();
    });
  };

  const deleteInventoryReminder = (inventory_notification_id, callback) => {
    InventoryService.deleteInventoryReminder(inventory_notification_id).then(
      () => {
        if (typeof callback === "function") {
          callback();
        }
        success("Reminder deleted.");
        clearModal();
      }
    );
  };

  const setProductQuantity = (product_id, quantity) => {
    dispatch({ type: SET_PRODUCT_QUANTITY, payload: { product_id, quantity } });
  };

  const appendProduct = (product) => {
    dispatch({ type: APPEND_PRODUCT, payload: product });
  };

  const removeProduct = (product_id) => {
    dispatch({ type: REMOVE_PRODUCT, payload: product_id });
  };

  const clearProducts = () => {
    dispatch({ type: CLEAR_CART });
  };

  return (
    <InventoryContext.Provider
      value={{
        ...state,
        setReminder,
        setInventory,
        saveInventory,
        appendProduct,
        removeProduct,
        clearProducts,
        createReminder,
        getInventories,
        createInventory,
        deleteInventory,
        getSingleInventory,
        setProductQuantity,
        setPropertyInventory,
        getInventoryReminders,
        saveInventoryReminder,
        deleteInventoryReminder,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
