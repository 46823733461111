import { HIDE_SPINNER, PERMISSION_DENIED, SHOW_SPINNER } from "../types";
import {
  CREATE_PROCESS,
  PROCESSES_RECEIVED,
  SET_PROPERTY_PROCESS,
  SET_SINGLE_PROCESS,
  TOGGLE_PROCESS_DEPENDENCE,
} from "../types/process";

const schema = {
  process_id: "",
  name: "",
  description: "",
  hide_client: false,
};

const ProcessReducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_PROCESS_DEPENDENCE:
      let dependences = state.process.dependences;
      if (!Array.isArray(dependences)) {
        dependences = [];
      }
      dependences = [...dependences];
      const index = dependences.findIndex(
        ({ process_id }) => process_id === payload.process_id
      );
      if (index === -1) {
        dependences.push(payload);
      } else {
        dependences.splice(index, 1);
      }
      return { ...state, process: { ...state.process, dependences } };
    case PERMISSION_DENIED:
      return { ...state, denied: true };
    case CREATE_PROCESS:
      return { ...state, process: schema };
    case PROCESSES_RECEIVED:
      return { ...state, processes: payload, denied: false };
    case SET_SINGLE_PROCESS:
      return { ...state, process: payload, denied: false };
    case SET_PROPERTY_PROCESS:
      const process = { ...state.process };
      const { key, value } = payload;
      process[key] = value;
      return { ...state, process };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default ProcessReducer;
