import * as XLSX from "xlsx";

export const productsOptions = [
  "sku",
  "name",
  "description",
  "unit",
  "price",
  "cost",
  "currency",
  "picture",
];

export const getProductsFromWorkbook = (workbook, sheetName) => {
  let parsedProducts = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
  parsedProducts = parsedProducts.map((product) => {
    Object.keys(product).forEach((key) => {
      let lowerCaseKey = String(key).toLowerCase();
      product[lowerCaseKey] = product[key];
      delete product[key];
    });
    return product;
  });
  return parsedProducts;
};
