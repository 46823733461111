import React, { useContext } from "react";
import { formatMonto, getValue } from "../../utils";
import { ProductsContext } from "../../context/ProductsContext";

const PreviewProduct = ({ product }) => {
  const { deleteProducto } = useContext(ProductsContext);

  return (
    <tr>
      <td>{getValue(product, "sku")}</td>
      <td>{getValue(product, "name")}</td>
      <td>{getValue(product, "description")}</td>
      <td>{getValue(product, "unit")}</td>
      <td>${formatMonto(getValue(product, "price"))}</td>
      <td>${formatMonto(getValue(product, "cost"))}</td>
      <td>{getValue(product, "currency")}</td>
      <td>
        <img src={getValue(product, "currency")} className="thumbnail" />
      </td>
      <td>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => deleteProducto(product.idProducto)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default PreviewProduct;
