import api from "./api";

const route = "/projects";

const ProjectsService = {
  getProjects: () => api.get(route),
  getZohoDeals: (code) => api.get(`${route}/zoho/${code}`),
  getSingleProject: (project_id) => api.get(`${route}/${project_id}`),
  getSingleProjectFile: (project_id) => api.get(`${route}/${project_id}/file`),
  getProjectUsers: (project_id) => api.get(`${route}/${project_id}/users`),
  putProject: (project) =>
    api.put(`${route}/${project.project_id}`, { ...project }),
  postProject: (project) => api.post(route, { ...project }),
  deleteProject: (project_id) => api.delete(`${route}/${project_id}`),
  postUserProject: (project_id, user) =>
    api.post(`${route}/${project_id}/user`, { ...user }),
  deleteUserProject: (project_id, user_id) =>
    api.delete(`${route}/${project_id}/user/${user_id}`),
  deleteProjectFile: (project_id, file_id) =>
    api.delete(`${route}/${project_id}/file/${file_id}`),
  postProjectFile: (project_id, project_file_id) =>
    api.post(`${route}/${project_id}/files`, { ...project_file_id }),
  putProjectFile: (project_id, project_file) =>
    api.put(`${route}/${project_id}/files`, { ...project_file }),
};
export default ProjectsService;
