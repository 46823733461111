import React, { useContext, useEffect } from "react";
import { ActivityContext } from "../../context/ActivityContext";
import ActivityCard from "./ActivityCard";

const ActivityCollapse = ({ project_id, step_id }) => {
  const { activities, getActivitiesStep } = useContext(ActivityContext);

  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step_id]);

  const fetchActivities = () => {
    getActivitiesStep(step_id);
  };

  const renderActivities = () => {
    if (Array.isArray(activities)) {
      if (activities.length > 0) {
        return activities.map((activity) => (
          <ActivityCard
            handleCallback={fetchActivities}
            key={activity.activity_id}
            project_id={project_id}
            activity={activity}
          />
        ));
      }
    }
    return <p>No activites available.</p>;
  };

  return (
    <div className="container-fluid my-3">
      <h3 className="font-header border-bottom pb-2 mb-3">Activities</h3>
      {renderActivities()}
    </div>
  );
};

export default ActivityCollapse;
