import React from "react";
import moment from "moment";

const StartEndDatePicker = ({ start_date, end_date, modifier }) => {
  return (
    <div className="row">
      <div className="col-6">
        <label>Start Date</label>
        <input
          type="date"
          className="form-control mb-3"
          value={
            start_date !== null
              ? moment(start_date).utc().format("YYYY-MM-DD")
              : ""
          }
          onChange={(e) => modifier("start_date", e.target.value)}
        />
      </div>
      <div className="col-6">
        <label>End Date</label>
        <input
          type="date"
          className="form-control mb-3"
          value={
            end_date !== null ? moment(end_date).utc().format("YYYY-MM-DD") : ""
          }
          onChange={(e) => modifier("end_date", e.target.value)}
        />
      </div>
    </div>
  );
};

export default StartEndDatePicker;
