import React, { useContext, useEffect } from "react";
import { ActionsContext } from "../../context/ActionsContext";
import ActionCard from "./ActionCard";

const ActionCollapse = ({ disabled, project_id, activity_id }) => {
  const { actions, getActionsActivity } = useContext(ActionsContext);

  useEffect(() => {
    getActionsActivity(activity_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity_id]);

  const renderActions = () => {
    if (Array.isArray(actions)) {
      if (actions.length > 0) {
        return actions.map((action) => (
          <ActionCard
            action={action}
            disabled={disabled}
            key={action.action_id}
            project_id={project_id}
            activity_id={activity_id}
          />
        ));
      }
    }
    return <p>No actions available.</p>;
  };

  return (
    <div className="container-fluid my-3">
      <h3 className="font-header border-bottom pb-2">Actions</h3>
      <div className="py-2 bg-light">{renderActions()}</div>
    </div>
  );
};

export default ActionCollapse;
