import axios from "axios";
import { BASE_URL } from "../utils";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { config, message } = error;
    if (!config || !config.retry) {
      return Promise.reject(error);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
      return Promise.reject(error);
    }
    config.retry -= 1;
    if (error.response.data) {
      if (error.response.data.code) {
        if (error.response.data.code === "auth/id-token-expired") {
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;
