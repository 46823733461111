import React from "react";
import { S3_ENDPOINT } from "../../utils";

const UserItem = ({ size, user, children, handleEdit, handleDelete }) => {
  const renderImage = () => {
    if (user && user !== null) {
      const { file } = user;
      if (file && file !== null) {
        return (
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            className="user-img m-auto round"
            alt={user.name}
          />
        );
      }
    }
    return (
      <i className={`fa fa-user-circle ${size === "sm" ? "" : "fa-3x"}`}></i>
    );
  };

  const renderName = () => {
    let name = "";
    if (user && user !== null) {
      if (user.role && user.role !== null) {
        name += user.role + ": ";
      } else if (user.user_type && user.user_type !== null) {
        name += user.user_type.name + ": ";
      }
      name += user.name;
    }
    return name;
  };
  return (
    <div className="row align-items-center">
      <div className="col-3">{renderImage()}</div>
      <div
        className={
          typeof handleEdit === "function" || typeof handleDelete === "function"
            ? "col-7"
            : "col-9"
        }
      >
        <h4 className="mb-1 text-capitalize">{renderName()}</h4>
        {children}
      </div>
      <div className="col-2">
        {typeof handleEdit === "function" && (
          <button
            className="btn btn-sm text-primary"
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(user);
            }}
          >
            <i className="fa fa-edit"></i>
          </button>
        )}
        {typeof handleDelete === "function" && (
          <button
            className="btn btn-sm text-danger"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(user);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default UserItem;
