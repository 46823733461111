import moment from "moment";
import React from "react";

const ProductPrices = ({ product_prices }) => {
  const renderPrices = () => {
    if (Array.isArray(product_prices)) {
      return product_prices.map((product_price) => (
        <div
          key={product_price.product_price_id}
          className="card mb-2 shadow-sm p-3"
        >
          <div className="row border-bottom pb-2">
            <div className="col-4">
              <p className="bold mb-0">Price</p>
            </div>
            <div className="col-4">
              <p className="bold mb-0">Cost</p>
            </div>
            <div className="col-4">
              <p className="bold mb-0">Currency</p>
            </div>
          </div>
          <div className="row py-2">
            <div className="col-4">
              <p>{product_price.price}</p>
            </div>
            <div className="col-4">
              <p>{product_price.cost}</p>
            </div>
            <div className="col-4">
              <p>{product_price.currency}</p>
            </div>
            <div className="col-12">
              <p className="mb-0">
                <b>Date:</b>{" "}
                {moment(product_price.createdAt).format("MM/DD/YYYY")}
              </p>
            </div>
          </div>
        </div>
      ));
    }
  };
  return (
    <div className="container px-0 my-3">
      <h2 className="border-bottom pb-2 mb-2">Price History</h2>
      {renderPrices()}
    </div>
  );
};

export default ProductPrices;
