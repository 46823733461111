import React, { useState, useContext, useEffect } from "react";
import { PaymentsContext } from "../../context/PaymentsContext";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";
import { getValue } from "../../utils";

const ConfirmPaymentForm = ({ project_id, handleCallback }) => {
  const [comment, setComment] = useState("");
  const { alert, clearModal } = useContext(ModalContext);
  const { payment, savePayment, setPropertyPayment } =
    useContext(PaymentsContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setPropertyPayment("confirmedAmount", payment.amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = (checked) => {
    if (payment.amount !== payment.confirmedAmount) {
      if (comment === "") {
        return alert("You must add a comment on why the amount has changed");
      }
      payment.comment = comment;
    }
    if (checked) {
      payment.confirmedBy = user.user_id;
      payment.confirmedAt = moment().local().format("YYYY-MM-DD HH:mm");
    } else {
      payment.confirmedAt = null;
      payment.confirmedBy = null;
    }
    savePayment(project_id, payment, handleCallback);
  };

  const renderCommentForm = () => {
    if (payment.amount !== payment.confirmedAmount) {
      return (
        <div>
          <label>Reason for Amount Change</label>
          <input
            type="text"
            value={comment}
            className="form-control mb-3"
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <p>Are you sure you want to confirm this payment?</p>
      <label>Amount Received</label>
      <input
        step=".01"
        type="number"
        className="form-control mb-3"
        value={getValue(payment, "confirmedAmount")}
        onChange={(e) => setPropertyPayment("confirmedAmount", e.target.value)}
      />
      <label>Currency</label>
      <select
        className="form-control mb-3"
        value={getValue(payment, "currency")}
        onChange={(e) => setPropertyPayment("currency", e.target.value)}
      >
        <option>MXN</option>
        <option>USD</option>
        <option>GBP</option>
        <option>EUR</option>
      </select>
      {renderCommentForm()}
      <button onClick={handleConfirm} className="btn btn-secondary w-100">
        <i className="fa fa-check me-2"></i> Confirm Payment
      </button>
      <button onClick={clearModal} className="btn text-muted w-100">
        Cancel
      </button>
    </div>
  );
};

export default ConfirmPaymentForm;
