import React, { createContext, useReducer, useContext } from "react";
import PaymentsReducer from "../reducers/PaymentsReducer";
import PaymentsService from "../services/PaymentsService";
import {
  SET_PAYMENT,
  CREATE_PAYMENT,
  PAYMENTS_RECEIVED,
  SET_PROPERTY_PAYMENT,
} from "../types/payments";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  payments: null,
  payment: null,
};

export const PaymentsContext = createContext(initialState);

export const PaymentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PaymentsReducer, initialState);

  const { clearModal, success, alert } = useContext(ModalContext);

  const getPaymentsInvoice = (project_id, invoice_id) => {
    PaymentsService.getPaymentsInvoice(project_id, invoice_id).then((res) => {
      const { payments } = res.data;
      dispatch({ type: PAYMENTS_RECEIVED, payload: payments });
    });
  };

  const getSinglePayment = (project_id, payment_id) => {
    PaymentsService.getSinglePayment(project_id, payment_id).then((res) => {
      const { payment } = res.data;
      dispatch({ type: SET_PAYMENT, payload: payment });
    });
  };

  const setPayment = (payment) => {
    dispatch({ type: SET_PAYMENT, payload: payment });
  };

  const createPayment = () => {
    dispatch({ type: CREATE_PAYMENT });
  };

  const setPropertyPayment = (key, value) => {
    console.log(key, value);
    dispatch({ type: SET_PROPERTY_PAYMENT, payload: { key, value } });
  };

  const savePayment = (project_id, payment, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = PaymentsService.putPayment;
    if (payment.payment_id === "") {
      service = PaymentsService.postPayment;
    }
    service(project_id, payment)
      .then(() => {
        if (typeof callback === "function") {
          callback();
        } else {
          getPaymentsInvoice(project_id, payment.invoice_id);
        }
        dispatch({ type: HIDE_SPINNER });
        success("Payment recorded.");
        clearModal();
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  return (
    <PaymentsContext.Provider
      value={{
        ...state,
        setPayment,
        savePayment,
        createPayment,
        getSinglePayment,
        getPaymentsInvoice,
        setPropertyPayment,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};
