import React, { useContext, useEffect } from "react";
import { UsersContext } from "../context/UsersContext";
import UserCard from "../components/user/UserCard";
import { ModalContext } from "../context/ModalContext";
import UserForm from "../components/user/UserForm";
import { ProjectsContext } from "../context/ProjectsContext";
import UserProjectPicker from "../components/user/UserProjectPicker";
import BottomAction from "../components/global/BottomAction";
import UserProjectDeleteConfirm from "../components/user/UserProjectDeleteConfirm";
import UserDeleteConfirm from "../components/user/UserDeleteConfirm";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const AdminUsers = ({ project_id }) => {
  const { users, getUsers, setUser, createUser } = useContext(UsersContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const {
    project,
    project_users,
    getProjectUsers,
    getSingleProject,
    deleteUserProject,
  } = useContext(ProjectsContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_id]);

  const fetchData = () => {
    if (project_id && project_id !== null) {
      if (project === null) {
        getSingleProject(project_id);
      }
      getProjectUsers(project_id);
    } else {
      getUsers();
    }
  };

  const handleCreate = () => {
    if (project_id && project_id !== null) {
      return modalComponent(
        "Add User to Project",
        <UserProjectPicker project_id={project_id} handleCancel={clearModal} />
      );
    }
    createUser();
    modalComponent(
      "Add User",
      <UserForm project_id={project_id} handleCancel={handleCancel} />
    );
  };

  const handleCancel = () => {
    clearModal();
    setUser(null);
  };

  const handleEdit = (user) => {
    setUser(user);
    modalComponent("Edit User", <UserForm handleCancel={handleCancel} />);
  };

  const handleDelete = (user) => {
    if (project_id && project_id !== null && project !== null) {
      return modalComponent(
        "Remove User",
        <UserProjectDeleteConfirm
          user={user}
          project={project}
          handleCancel={clearModal}
          deleteUserProject={deleteUserProject}
        />
      );
    }
    modalComponent(
      "Delete User",
      <UserDeleteConfirm user={user} handleCancel={clearModal} />
    );
  };

  const renderUsers = () => {
    let usersRender = project_id && project_id !== null ? project_users : users;
    if (Array.isArray(usersRender)) {
      if (usersRender.length === 0) {
        return <p>No users found.</p>;
      }
      return usersRender.map((user) => (
        <UserCard
          key={user.user_id}
          user={user}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        >
          <p className="mb-1 small">{user.email}</p>
          <p className="mb-0 text-muted text-capitalize">
            {user.user_type.name}
          </p>
        </UserCard>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container px-2">
      <IonPullRefresh handleCallback={fetchData} data={users} />
      <div className="row align-items-center mb-2">
        <h1>Users</h1>
      </div>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Search for a user..."
      />
      {renderUsers()}
      <BottomAction onClick={handleCreate} title="+ Add User" />
    </div>
  );
};

export default AdminUsers;
