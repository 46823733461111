import React from "react";
import ActionsAccordion from "./ActionsAccordion";
import IconItem from "../global/IconItem";

const AccordionItem = ({ activity }) => {
  const renderDependences = () => {
    if (Array.isArray(activity.dependences)) {
      if (activity.dependences.length > 0) {
        return (
          <div>
            <h4>Dependences</h4>
            {activity.dependences.map((dependence) => (
              <IconItem
                icon="lock"
                key={dependence.name}
                name={dependence.name}
              />
            ))}
          </div>
        );
      }
    }
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target={`#activity-${activity.activity_id}`}
          aria-controls={`activity-${activity.activity_id}`}
        >
          {activity.name}
        </button>
      </h2>
      <div
        id={`activity-${activity.activity_id}`}
        className="accordion-collapse collapse"
        data-bs-parent="#activities"
      >
        <div className="accordion-body">
          <p>{activity.description}</p>
          {renderDependences()}
          <ActionsAccordion actions={activity.actions} />
        </div>
      </div>
    </div>
  );
};

const ActivitiesAccordion = ({ activities }) => {
  const renderActivities = () => {
    if (Array.isArray(activities)) {
      if (activities.length === 0) {
        return <p className="my-3">No activities found for this step.</p>;
      }
      return activities.map((activity) => (
        <AccordionItem key={activity.activity_id} activity={activity} />
      ));
    }
  };
  return (
    <div className="accordion" id="activities">
      {renderActivities()}
    </div>
  );
};

export default ActivitiesAccordion;
