import React, { useState, useContext, useEffect } from "react";
import { PaymentsContext } from "../context/PaymentsContext";
import PaymentCard from "../components/payments/PaymentCard";
import PaymentForm from "../components/payments/PaymentForm";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import PaymentDetails from "../components/payments/PaymentDetails";
import CommentFeed from "../components/comments/CommentFeed";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const SinglePayment = ({ project_id, payment_id }) => {
  const [view, setView] = useState("details");
  const { user } = useContext(AuthContext);
  const { modalComponent } = useContext(ModalContext);
  const { payment, getSinglePayment } = useContext(PaymentsContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => getSinglePayment(project_id, payment_id);

  const handleEdit = () => {
    modalComponent("Edit Payment", <PaymentForm />);
  };

  const rendeEdit = () => {
    if (payment.createdBy === user.user_id || user.user_type_id > 2) {
      return (
        <button onClick={handleEdit} className="btn px-0 btn-sm text-primary">
          <i className="fa fa-edit" />
        </button>
      );
    }
  };

  const renderContent = () => {
    if (view === "details") {
      return (
        <PaymentDetails
          project_id={project_id}
          handleComments={() => setView("comments")}
        />
      );
    }
    return <CommentFeed payment_id={payment_id} />;
  };

  const renderDisclaimer = () => {
    if (payment.payment_method_id === 2) {
      return (
        <div className="bg-accent-light border br-10 text-muted p-3 mb-3">
          <p className="mb-2 bold">
            <i className="fa fa-question-circle" /> Why is this payment higher
            than the invoice?
          </p>
          <p className="mb-1">
            The online payment processor charges a 5% fee that is not included
            in your invoice.
          </p>
        </div>
      );
    }
    if (payment.payment_method_id === 2) {
      return (
        <div className="bg-accent-light border br-10 text-muted p-3 mb-3">
          <p className="mb-2 bold">
            <i className="fa fa-question-circle" /> Why is this payment higher
            than the invoice?
          </p>
          <p className="mb-1">
            PayPal charges a 6% fee that is not included in your invoice.
          </p>
        </div>
      );
    }
  };

  const renderPayment = () => {
    if (payment && payment !== null) {
      return (
        <div>
          <div className="row">
            <div className="col-8">
              <h1>Payment #{payment.payment_id}</h1>
            </div>
            <div className="col-4 text-end">{rendeEdit()}</div>
          </div>
          <PaymentCard payment={payment} />
          {renderDisclaimer()}
          <IonSegment color="accent" value={view} className="mb-3">
            <IonSegmentButton
              value="details"
              onClick={() => setView("details")}
            >
              <IonLabel>Details</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="comments"
              onClick={() => setView("comments")}
            >
              <IonLabel>Comments</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {renderContent()}
        </div>
      );
    }
  };

  return (
    <div className="container">
      <IonPullRefresh handleCallback={fetchData} data={payment} />
      {renderPayment()}
    </div>
  );
};

export default SinglePayment;
