import React, { useReducer, createContext } from "react";
import UseTypeReducer from "../reducers/UserTypeReducer";
import UserTypeService from "../services/UserTypeService";
import { USER_TYPES_RECEIVED } from "../types/user_types";

const initialState = {
  user_types: null,
};

export const UserTypeContext = createContext(initialState);

export const UserTypeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UseTypeReducer, initialState);
  const getUserTypes = () => {
    UserTypeService.getUserTypes().then((res) => {
      const { user_types } = res.data;
      dispatch({ type: USER_TYPES_RECEIVED, payload: user_types });
    });
  };
  return (
    <UserTypeContext.Provider value={{ ...state, getUserTypes }}>
      {children}
    </UserTypeContext.Provider>
  );
};
