import moment from "moment";
export const invoicestabs = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Completed",
    value: "completed",
  }
]
export const filterInvoices = (invoices, filters) => {
  let invoicesRender = [...invoices];
  const { start_date, end_date } = filters;
  if (start_date && moment(start_date).isValid()) {
    invoicesRender = invoicesRender.filter((invoice) =>
      moment(invoice.createdAt)
        .utc()
        .local()
        .startOf("day")
        .isAfter(moment(start_date).utc().local().startOf("day"))
    );
  }
  if (end_date && moment(end_date).isValid()) {
    invoicesRender = invoicesRender.filter((invoice) =>
      moment(invoice.createdAt)
        .utc()
        .local()
        .startOf("day")
        .isBefore(moment(end_date).utc().local().startOf("day"))
    );
  }
  return invoicesRender;
};
