import React, { useContext, useState } from "react";
import { ProjectsContext } from "../../context/ProjectsContext";
import { getValue } from "../../utils";
import { AuthContext } from "../../context/AuthContext";

const ProjectForm = ({ handleCancel }) => {
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext)
  const { project, postProject, setPropertyProject } =
    useContext(ProjectsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (project.name === "") {
      return setError("Name is invalid.");
    }
    postProject(project);
  };

  const renderInternalFields = () => {
    if (project.user_type_id > 2 || user.user_type_id > 2) {
      return (
        <div>
          <label>Grade</label>
          <select
            className="form-control mb-3"
            value={getValue(project, "grade")}
            onChange={(e) => setPropertyProject("grade", e.target.value)}
          >
            <option value="">Select Grade</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
          <label>Order Size</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(project, "order_size")}
            onChange={(e) => setPropertyProject("order_size", e.target.value)}
          />
          <label>Product Development Plan</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(project, "product_development_plan")}
            onChange={(e) =>
              setPropertyProject("product_development_plan", e.target.value)
            }
          />
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error !== null ? <h4 className="text-danger">{error}</h4> : ""}
      <label>Name</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(project, "name")}
        onChange={(e) => setPropertyProject("name", e.target.value)}
      />
      {renderInternalFields()}
      <label>Description</label>
      <textarea
        rows="4"
        className="form-control mb-3"
        value={getValue(project, "description")}
        onChange={(e) => setPropertyProject("description", e.target.value)}
      />
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 mt-3"
      >
        Cancel
      </button>
    </form>
  );
};

export default ProjectForm;
