import React, { useState, useEffect } from "react";
import ProductsService from "../../services/ProductsServices";

const ProductPicker = ({ selected, modifier }) => {
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState(null);

  useEffect(() => {
    if (query !== "") {
      fetchProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (selected !== null) {
      setQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const fetchProducts = () => {
    ProductsService.getProducts({ query }).then((res) => {
      setProducts(res.data.products);
    });
  };

  const renderProducts = () => {
    if (Array.isArray(products) && query !== "") {
      let productsRender = [...products];
      if (productsRender.length === 0) {
        return (
          <ul className="dropdown-menu mw-100 w-100 show">
            <li className="dropdown-ite small text-dark">No products found.</li>
          </ul>
        );
      }
      return (
        <ul className="dropdown-menu mw-100 w-100 show">
          {productsRender.map((product) => (
            <li
              className="dropdown-item small py-2 text-dark mw-100"
              onClick={() => modifier(product)}
              key={product.product_id}
            >
              {product.name}
            </li>
          ))}
        </ul>
      );
    }
  };

  const renderSelected = () => {
    if (selected && selected !== null) {
      return (
        <div className="row align-items-center my-2 py-2 bg-light border mx-0">
          <div className="col-10">
            <p className="mb-0">{selected.name}</p>
          </div>
          <div className="col-2">
            <button className="btn btn-sm py-0" onClick={() => modifier(null)}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      );
    }
    return (
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Search products..."
        onChange={(e) => setQuery(e.target.value)}
      />
    );
  };

  return (
    <div className="container position-relative px-0 mb-3">
      <label>Products</label>
      {renderSelected()}
      {renderProducts()}
    </div>
  );
};

export default ProductPicker;
