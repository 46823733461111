import React from "react";

const ConfirmDeleteAttachment = ({
  attachment,
  handleCancel,
  handleDelete,
}) => {
  return (
    <div>
      <p>
        Are you sure you want to delete the attachment "{attachment.name}"? This
        action CANNOT be undone.
      </p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button onClick={handleDelete} className="btn btn-danger w-100">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteAttachment;
