import React, { createContext, useReducer, useContext } from "react";
import StepsReducer from "../reducers/StepsReducer";
import FilesService from "../services/FilesService";
import StepsService from "../services/StepsService";
import { HIDE_SPINNER, PERMISSION_DENIED, SHOW_SPINNER } from "../types";
import {
  CREATE_STEP,
  SET_PROPERTY_STEP,
  SET_SINGLE_STEP,
  STEPS_RECEIVED,
  TOGGLE_STEP_DEPENDENCE,
} from "../types/steps";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";

const initialState = {
  steps: null,
  step: null,
  denied: false,
  spinner: false,
};

export const StepsContext = createContext(initialState);

export const StepsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(StepsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        return dispatch({ type: PERMISSION_DENIED });
      }
    }
    alert(error);
  };

  const getStepsProcess = (process_id) => {
    StepsService.getStepsProcess(process_id)
      .then((res) => {
        const { steps } = res.data;
        dispatch({ type: STEPS_RECEIVED, payload: steps });
      })
      .catch(handleError);
  };

  const getSingleStep = (step_id) => {
    StepsService.getSingleStep(step_id)
      .then((res) => {
        const { step } = res.data;
        dispatch({ type: SET_SINGLE_STEP, payload: step });
      })
      .catch(handleError);
  };

  const commentStep = (step_id, comment) => {
    StepsService.commentStep(step_id, comment)
      .then(() => {
        success("Comentario agregado.");
      })
      .catch(alert);
  };

  const saveStepFile = (step_file) => {
    dispatch({ type: SHOW_SPINNER });
    const { step_file_id, step_id, file } = step_file;
    let service = StepsService.putStepFile;
    if (isNaN(step_file_id)) {
      service = StepsService.postStepFile;
    }
    const promises = [];
    if (file && file !== null) {
      if (file.size) {
        promises.push(
          new Promise((resolve, reject) => {
            const formData = FilesService.getFormData(file);
            FilesService.postFile(formData)
              .then((res) => {
                const { file_id } = res.data;
                step_file.file_id = file_id;
                resolve();
              })
              .catch(reject);
          })
        );
      }
    }
    Promise.all(promises)
      .then(() => {
        service(step_id, step_file).then(() => {
          dispatch({ type: HIDE_SPINNER });
          success("Attachment saved.");
          getSingleStep(step_id);
          clearModal();
        });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteStepFile = (step_id, file_id) => {
    StepsService.deleteStepFile(step_id, file_id)
      .then(() => {
        clearModal();
        success("File deleted.");
        getSingleStep(step_id);
      })
      .catch(alert);
  };

  const setStep = (step) => {
    dispatch({ type: SET_SINGLE_STEP, payload: step });
  };

  const createStep = () => {
    dispatch({ type: CREATE_STEP });
  };

  const setPropertyStep = (key, value) => {
    dispatch({ type: SET_PROPERTY_STEP, payload: { key, value } });
  };

  const postStep = (step) => {
    let service = StepsService.putStep;
    if (isNaN(parseInt(step.step_id))) {
      service = StepsService.postStep;
    }
    service(step)
      .then(() => {
        clearModal();
        success("Step saved.");
        const { process_id } = step;
        getStepsProcess(process_id);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            alert(error.response.data.message);
          }
        }
      });
  };

  const deleteStep = (step) => {
    const { project_id, process_id, step_id } = step;
    dispatch({ type: SHOW_SPINNER });
    StepsService.deleteStep(step_id)
      .then(() => {
        navigate(`/project/${project_id}/process/${process_id}`);
        dispatch({ type: HIDE_SPINNER });
        getStepsProcess(process_id);
        success("Step deleted.");
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const toggleStepDependence = (step) => {
    dispatch({ type: TOGGLE_STEP_DEPENDENCE, payload: step });
  };

  return (
    <StepsContext.Provider
      value={{
        ...state,
        setStep,
        postStep,
        deleteStep,
        createStep,
        commentStep,
        saveStepFile,
        getSingleStep,
        deleteStepFile,
        getStepsProcess,
        setPropertyStep,
        toggleStepDependence,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};
