import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";

const BankAccountCard = ({ bank_account }) => {
  const [copied, setCopied] = useState(false);
  const { success } = useContext(ModalContext);

  useEffect(() => {
    if (copied) {
      success("Copied to clipboard.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copied]);

  const handleCopy = (text) => {
    let input = document.createElement("input");
    input.value = text;
    input.id = "copy-input";
    document.body.appendChild(input);
    var copyText = document.getElementById("copy-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    navigator.clipboard.writeText(copyText.value).then(() => {
      setCopied(true);
    });
    input.remove();
  };

  return (
    <div className="card p-3 shadow-sm">
      <h3 className="border-bottom pb-2 mb-3">{bank_account.name}</h3>
      <p>Currency: {bank_account.currency} </p>
      <p>
        Bank Name: {bank_account.bank_name}{" "}
        <button
          onClick={() => handleCopy(bank_account.bank_name)}
          className="btn px-1 py-0 btn-sm ms-2"
        >
          <i className="far fa-copy"></i>
        </button>
      </p>
      <p>
        Swift Code: {bank_account.swift_code}{" "}
        <button
          onClick={() => handleCopy(bank_account.swift_code)}
          className="btn px-1 py-0 btn-sm ms-2"
        >
          <i className="far fa-copy"></i>
        </button>
      </p>
      <p>
        Wire Number: {bank_account.wire_number}{" "}
        <button
          onClick={() => handleCopy(bank_account.wire_number)}
          className="btn px-1 py-0 btn-sm ms-2"
        >
          <i className="far fa-copy"></i>
        </button>
      </p>
      <p>
        Account Number: {bank_account.account_number}{" "}
        <button
          onClick={() => handleCopy(bank_account.account_number)}
          className="btn px-1 py-0 btn-sm ms-2"
        >
          <i className="far fa-copy"></i>
        </button>
      </p>
    </div>
  );
};

export default BankAccountCard;
