import React, { createContext, useContext, useReducer } from "react";
import CommentsReducer from "../reducers/CommentsReducer";
import CommentsService from "../services/CommentsService";
import { COMMENTS_RECEIVED } from "../types/comments";
import { ModalContext } from "./ModalContext";

const initialState = {
  comments: null,
  comment: null,
};

export const CommentsContext = createContext(initialState);

export const CommentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CommentsReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getComments = (filters) => {
    CommentsService.getComments(filters).then((res) => {
      const { comments } = res.data;
      dispatch({ type: COMMENTS_RECEIVED, payload: comments });
    });
  };

  const clearComments = () => {
    dispatch({ type: COMMENTS_RECEIVED, payload: null });
  };

  const postComment = (comment, callback) => {
    CommentsService.postComment(comment).then(() => {
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const deleteComment = (project_id, comment_id, handleDeleteCallback) => {
    CommentsService.deleteComment(
      project_id,
      comment_id,
      handleDeleteCallback
    ).then(() => {
      success("Comment deleted.");
      handleDeleteCallback();
      clearModal();
    });
  };

  return (
    <CommentsContext.Provider
      value={{
        ...state,
        getComments,
        postComment,
        clearComments,
        deleteComment,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};
