import React, { useContext, useEffect } from "react";
import { formatMonto } from "../../utils";
import { InvoicesContext } from "../../context/InvoicesContext";

const CheckoutInvoice = ({ project_id, invoice_id }) => {
  const { invoice, getSingleInvoice } = useContext(InvoicesContext);

  useEffect(() => {
    getSingleInvoice(project_id, invoice_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderInvoice = () => {
    if (invoice && invoice !== null) {
      return (
        <div>
          <h3>Invoice #{invoice.invoice_id}</h3>
          <p>{invoice.description}</p>
          <h5>
            Total: {"$"}
            {formatMonto(invoice.amount)} {invoice.currency}
          </h5>
        </div>
      );
    }
  };

  return (
    <div>
      <h4>You're paying</h4>
      <div className="mb-3 card border p-3">{renderInvoice()}</div>
    </div>
  );
};

export default CheckoutInvoice;
