import React, { useContext, useEffect } from "react";
import { getValue } from "../../utils";
import { OrdersContext } from "../../context/OrdersContext";
import { CartContext } from "../../context/CartContext";
import { OrderTemplatesContext } from "../../context/OrderTemplatesContext";
import SelectUserType from "../global/SelectUserType";
import { AuthContext } from "../../context/AuthContext";

const OrderForm = ({ project_id, handleCancel }) => {
  const { user } = useContext(AuthContext);
  const { products } = useContext(CartContext);
  const { order, saveOrder, saveOrderAdmin, setPropertyOrder } =
    useContext(OrdersContext);
  const { order_templates, getOrderTemplates } = useContext(
    OrderTemplatesContext
  );

  useEffect(() => {
    getOrderTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (order.order_template_id === null && Array.isArray(order_templates)) {
      let template = order_templates[0];
      if (template) {
        setPropertyOrder("order_template_id", template.order_template_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_templates]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (project_id && project_id !== null) {
      return saveOrder(project_id, { ...order, products });
    }
    saveOrderAdmin({ ...order, products });
  };

  const renderOrderTemplates = () => {
    if (Array.isArray(order_templates)) {
      return order_templates.map((order_template) => (
        <option
          key={order_template.order_template_id}
          value={order_template.order_template_id}
        >
          {order_template.name}
        </option>
      ));
    }
  };

  const renderFields = () => {
    if (user.user_type_id > 3) {
      return (
        <div>
          {!["approved", "in-progress", "completed"].includes(order.status) && (
            <div>
              <label>Expiration Date</label>
              <input
                type="date"
                className="form-control mb-3"
                value={getValue(order, "expiration_date")}
                onChange={(e) =>
                  setPropertyOrder("expiration_date", e.target.value)
                }
              />
            </div>
          )}
          <label>Order Template</label>
          <select
            className="form-control mb-3"
            value={getValue(order, "order_template_id")}
            onChange={(e) =>
              setPropertyOrder("order_template_id", e.target.value)
            }
          >
            {renderOrderTemplates()}
          </select>
          <SelectUserType
            value={getValue(order, "minimum_level")}
            modifier={(user_type_id) =>
              setPropertyOrder("minimum_level", user_type_id)
            }
          />
        </div>
      );
    }
    return <p>This order will be sent for approval by a project manager.</p>;
  };

  return (
    <form onSubmit={handleSubmit}>
      {renderFields()}
      <button type="submit" className="btn btn-secondary my-3 w-100">
        Save Order
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn w-100 text-muted"
      >
        Cancel
      </button>
    </form>
  );
};

export default OrderForm;
