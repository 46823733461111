import React from "react";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import StatusBadge from "../global/StatusBadge";

const RequisitionCard = ({ project_id, requisition }) => {
  const { requisition_id, amount, product, currency, status } = requisition;

  const renderProduct = () => {
    if (product && product !== null) {
      return product.name;
    }
  };
  return (
    <Link
      to={`/project/${project_id}/requisitions/${requisition_id}`}
      className="card no-decoration text-dark shadow-sm p-3 mb-3"
    >
      <div className="row">
        <div className="col-9">
          <h3 className="mb-1">Requisition #{requisition_id}</h3>
          <p className="mb-0">{renderProduct()}</p>
        </div>
        <div className="col-3">
          <StatusBadge status={status} />
          <p className="mt-2 mb-0">
            ${formatMonto(amount)} - {currency}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default RequisitionCard;
