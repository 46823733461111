
import {
  SET_CLIENT,
  CREATE_CLIENT,
  CLIENTS_RECEIVED,
  SET_PROPERTY_CLIENT,
} from "../types/clients";

const schema = {

}

const clientsReducer = (state, { type, payload }) => {
  switch (type) {
    case CLIENTS_RECEIVED:
      return { ...state, clients: payload };
    case SET_CLIENT:
      return { ...state, client: payload };
    case CREATE_CLIENT:
      return { ...state, client: schema };
    case SET_PROPERTY_CLIENT: {
      const { key, value } = payload;
      const client = { ...state.client };
      client[key] = value;
      return { ...state, client };
    }
    default:
      return { ...state};
  }
};

export default clientsReducer;
