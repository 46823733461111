import React, { createContext, useContext, useReducer } from "react";
import EventsReducer from "../reducers/EventsReducer";
import EventsService from "../services/EventsService";
import FilesService from "../services/FilesService";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  CREATE_EVENT,
  EVENTS_RECEIVED,
  SET_SINGLE_EVENT,
  SET_PROPERTY_EVENT,
  EVENT_TYPES_RECEIVED,
} from "../types/events";
import { ModalContext } from "./ModalContext";

const initialState = {
  event_types: null,
  events: null,
  event: null,
};

export const EventsContext = createContext(initialState);

export const EventsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EventsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    alert(error);
  };

  const getAdminEvents = (filters) => {
    EventsService.getAdminEvents(filters).then((res) => {
      const { events } = res.data;
      dispatch({ type: EVENTS_RECEIVED, payload: events });
    });
  };

  const getEventTypes = () => {
    EventsService.getEventTypes().then((res) => {
      const { event_types } = res.data;
      dispatch({ type: EVENT_TYPES_RECEIVED, payload: event_types });
    });
  };

  const getEventsStep = (step_id) => {
    dispatch({ type: SHOW_SPINNER });
    EventsService.getEventsStep(step_id)
      .then((res) => {
        const { events } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: EVENTS_RECEIVED, payload: events });
      })
      .catch(handleError);
  };

  const getEventsProcess = (process_id, date) => {
    dispatch({ type: SHOW_SPINNER });
    EventsService.getEventsProcess(process_id, date)
      .then((res) => {
        const { events } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: EVENTS_RECEIVED, payload: events });
      })
      .catch(handleError);
  };

  const getEventsProject = (project_id, filters) => {
    dispatch({ type: SHOW_SPINNER });
    EventsService.getEventsProject(project_id, filters)
      .then((res) => {
        const { events } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: EVENTS_RECEIVED, payload: events });
      })
      .catch(handleError);
  };

  const getSingleEvent = (event_id) => {
    EventsService.getSingleEvent(event_id).then((res) => {
      const { event } = res.data;
      dispatch({ type: SET_SINGLE_EVENT, payload: event });
    });
  };

  const setEvent = (event) => {
    dispatch({ type: SET_SINGLE_EVENT, payload: event });
  };

  const attendEvent = (event_id) => {
    EventsService.attendEvent(event_id)
      .then(() => {
        success("Evento aceptado.");
      })
      .catch(alert);
  };

  const cancelEvent = (event_id) => {
    EventsService.cancelEvent(event_id)
      .then(() => {
        success("Evento cancelado.");
      })
      .catch(alert);
  };

  const updateEvent = (event) => {
    EventsService.updateEvent(event)
      .then(() => {
        getSingleEvent(event.event_id);
        success("Evento actualizado.");
      })
      .catch(alert);
  };

  const commentEvent = (event_id, comment) => {
    EventsService.commentEvent(event_id, comment)
      .then(() => {
        success("Comentario agregado.");
      })
      .catch(alert);
  };

  const submitEventFile = (event_id, file) => {
    const formData = FilesService.getFormData(file);
    FilesService.postFile(formData)
      .then((res) => {
        const { file_id } = res.data;
        EventsService.submitEventFile(event_id, file_id);
      })
      .catch(alert);
  };

  const deleteEventFile = (event_id, file_id) => {
    EventsService.deleteEventFile(event_id, file_id)
      .then(() => {
        getSingleEvent(event_id);
      })
      .catch(alert);
  };

  const createEvent = () => {
    dispatch({ type: CREATE_EVENT });
  };

  const setPropertyEvent = (key, value) => {
    dispatch({ type: SET_PROPERTY_EVENT, payload: { key, value } });
  };

  const postEvent = (event, current_date) => {
    const { project_id } = event;
    let service = EventsService.putEvent;
    if (isNaN(parseInt(event.event_id))) {
      service = EventsService.postEvent;
    }
    service(project_id, event)
      .then(() => {
        getEventsProject(project_id, current_date);
        success("Event saved.");
        clearModal();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            return alert(error.response.data.message);
          }
        }
        alert(error);
      });
  };

  const deleteEvent = (project_id, event_id, callback) => {
    EventsService.deleteEvent(project_id, event_id).then((res) => {
      success("Event deleted");
      if (typeof callback === "function") {
        callback();
      }
      clearModal();
    });
  };

  return (
    <EventsContext.Provider
      value={{
        ...state,
        setEvent,
        postEvent,
        deleteEvent,
        attendEvent,
        cancelEvent,
        updateEvent,
        createEvent,
        commentEvent,
        getEventTypes,
        getEventsStep,
        getAdminEvents,
        getSingleEvent,
        submitEventFile,
        deleteEventFile,
        getEventsProcess,
        getEventsProject,
        setPropertyEvent,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};
