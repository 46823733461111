import React, { useContext, useEffect } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import { MenuContext } from "../../context/MenuContext";
import { AuthContext } from "../../context/AuthContext";
import { hasNotch, S3_ENDPOINT } from "../../utils";
import MenuProject from "./MenuProject";
import MenuHeader from "./MenuHeader";
import { Link } from "@reach/router";
import MenuBody from "./MenuBody";
import { ProjectsContext } from "../../context/ProjectsContext";

const Navbar = () => {
  const { device, platform, setupStatusBar } = useContext(CapacitorContext);
  const { user, signOut } = useContext(AuthContext);
  const { project } = useContext(ProjectsContext);
  const { menus } = useContext(MenuContext);

  const project_id = project !== null ? project.project_id : null;

  useEffect(() => {
    if (platform !== null && platform !== "web") {
      setupStatusBar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  return (
    <nav
      id="navbar"
      className={"navbar navbar-dark pb-2"}
      style={{
        paddingTop:
          platform === "ios" && hasNotch(device)
            ? 60
            : platform !== "web"
            ? 10
            : 10,
      }}
    >
      <div className="container pb-2 border-bottom show-tablet">
        <button
          className="btn p-2"
          style={{ visibility: user !== null ? "visible" : "hidden" }}
          data-bs-toggle="offcanvas"
          data-bs-target="#staticBackdrop"
          aria-controls="staticBackdrop"
        >
          <i className="fa fa-bars"></i>
        </button>
        <Link to="/">
          <img
            src={`${S3_ENDPOINT}/agavebrands-logo.png`}
            alt="logo"
            className="logo-navbar"
          />
        </Link>
        <Link
          to="/notifications"
          className="btn text-accent p-2"
          style={{ visibility: user !== null ? "visible" : "hidden" }}
        >
          <i className="fa fa-bell"></i>
        </Link>
        <div
          tabIndex="-1"
          id="staticBackdrop"
          data-bs-backdrop="static"
          style={{ paddingTop: hasNotch(device) ? 64 : 32 }}
          className="offcanvas offcanvas-start"
          aria-labelledby="staticBackdropLabel"
        >
          <div className="offcanvas-header">
            <MenuHeader user={user} />
          </div>
          <div className="px-3">
            <MenuProject user={user} />
          </div>
          <div className="offcanvas-body">
            <MenuBody
              user={user}
              menus={menus}
              signOut={signOut}
              project_id={project_id}
            />
          </div>
        </div>
      </div>
      {user !== null && (
        <div className="sidebar hide-mobile p-3 bg-white">
          <Link to="/" className="d-block mt-3 mb-5">
            <img
              alt="logo"
              src={`${S3_ENDPOINT}/agavebrands-logo.png`}
              className="logo-navbar"
            />
          </Link>
          <MenuHeader user={user} hideButton />
          <div className="my-3">
            <MenuProject user={user} />
          </div>
          <MenuBody
            user={user}
            menus={menus}
            signOut={signOut}
            project_id={project_id}
          />
        </div>
      )}
    </nav>
  );
};

export default Navbar;
