import React, { useContext } from "react";
import UserImageForm from "./UserImageForm";
import { S3_ENDPOINT } from "../../utils";
import { ModalContext } from "../../context/ModalContext";

const UserHeader = ({ user }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleUpload = () => {
    modalComponent("Add File", <UserImageForm handleCancel={clearModal} />);
  };

  const renderImage = () => {
    const { file } = user;
    if (file && file !== null) {
      return (
        <div className="position-relative w-auto">
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            alt={file.name}
            className="project-img"
          />
          <button
            onClick={handleUpload}
            className="btn px-0 py-0 text-primary"
            style={{ position: "absolute", right: 5, bottom: -5 }}
          >
            <i className="fa fa-edit"></i>
          </button>
        </div>
      );
    }

    return (
      <div onClick={handleUpload} className="icon-wrapper-lg border bg-light">
        <i className="fa fa-image fa-3x"></i>
      </div>
    );
  };

  return (
    <div className="container-fluid text-center mb-4">
      <div className="row justify-content-center mb-3">{renderImage()}</div>
      <h2>
        {user.name} {user.last_name}
      </h2>
      <p className="text-capitalize">{user.user_type.name}</p>
    </div>
  );
};

export default UserHeader;
