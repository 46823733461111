import React, { useContext, useEffect } from "react";
import { ProjectTemplatesContext } from "../context/ProjectTemplatesContext";
import moment from "moment";
import { Link } from "@reach/router";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const ProjectTemplates = () => {
  const { templates, getProjectTemplates } = useContext(
    ProjectTemplatesContext
  );

  useEffect(() => {
    getProjectTemplates();
  }, []);

  const renderTemplates = () => {
    if (Array.isArray(templates)) {
      if (templates.length === 0) {
        return <p>You don't have any saved templates.</p>;
      }
      return templates.map((template) => (
        <div key={template.project_template_id} className="card shadow-sm mb-2">
          <div className="row align-items-center">
            <div className="col-10">
              <h4 className="mb-1">{template.name}</h4>
              <p className="small text-muted mb-0">
                Created at {moment(template.createdAt).format("DD MMM YYYY")}
              </p>
            </div>
            <div className="col-2 text-right">
              <Link
                to={`/template/${template.project_template_id}`}
                className="btn btn-sm btn-light"
              >
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={getProjectTemplates} data={templates} />
      <h1 className="pb-2 mb-3 border-bottom">Templates</h1>
      {renderTemplates()}
    </div>
  );
};

export default ProjectTemplates;
