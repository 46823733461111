import React from "react";

import AttachmentItem from "./AttachmentItem";

const AttachmentCard = ({
  attachment,
  handleEdit,
  hideButtons,
  handleSubmit,
}) => {
  return (
    <div className="card p-3 shadow-sm text-dark no-decoration mb-3">
      <AttachmentItem
        attachment={attachment}
        handleEdit={handleEdit}
        hideButtons={hideButtons}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default AttachmentCard;
