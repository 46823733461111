import React, { createContext, useReducer, useContext } from "react";
import BankAccountsReducer from "../reducers/BankAccountsReducer";
import {
  BANK_ACCOUNTS_RECEIVED,
  CREATE_BANK_ACCOUNT,
  SET_BANK_ACCOUNT,
  SET_PROPERTY_BANK_ACCOUNT,
} from "../types/bank_accounts";
import BankAccountsService from "../services/BankAccountsService";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  bank_accounts: null,
  bank_account: null,
};

export const bankAccountFields = [
  { label: "Name", type: "text", id: "name", order: 1, required: true },
  {
    label: "Bank Name",
    type: "text",
    id: "bank_name",
    order: 2,
    required: true,
  },
  {
    label: "Swift Code",
    type: "text",
    id: "swift_code",
    order: 2,
    required: true,
  },
  {
    label: "Wire Number",
    type: "text",
    id: "wire_number",
    order: 2,
    required: true,
  },
  {
    label: "Account Number",
    type: "text",
    id: "account_number",
    order: 2,
    required: true,
  },
  {
    label: "Currency",
    type: "select",
    id: "currency",
    order: 3,
    required: true,
    options: [
      {
        label: "MXN",
        value: "MXN",
      },
      {
        label: "USD",
        value: "USD",
      },
      {
        label: "GBP",
        value: "GBP",
      },
      {
        label: "EUR",
        value: "EUR",
      },
    ],
  },
];

export const BankAccountsContext = createContext(initialState);

export const BankAccountsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BankAccountsReducer, initialState);
  const { alert, success } = useContext(ModalContext);

  const getBankAccounts = () => {
    BankAccountsService.getBankAccounts().then((res) => {
      const { bank_accounts } = res.data;
      dispatch({ type: BANK_ACCOUNTS_RECEIVED, payload: bank_accounts });
    });
  };

  const setBankAccount = (bank_account) => {
    dispatch({ type: SET_BANK_ACCOUNT, payload: bank_account });
  };

  const createBankAccount = () => {
    dispatch({ type: CREATE_BANK_ACCOUNT });
  };

  const setPropertyBankAccount = (key, value) => {
    dispatch({ type: SET_PROPERTY_BANK_ACCOUNT, payload: { key, value } });
  };

  const saveBankAccount = (bank_account) => {
    dispatch({ type: SHOW_SPINNER });
    let service = BankAccountsService.putBankAccount;
    if (!bank_account.bank_account_id) {
      service = BankAccountsService.postBankAccount;
    }
    service(bank_account)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Bank account saved.");
        getBankAccounts();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <BankAccountsContext.Provider
      value={{
        ...state,
        setBankAccount,
        saveBankAccount,
        getBankAccounts,
        createBankAccount,
        setPropertyBankAccount,
      }}
    >
      {children}
    </BankAccountsContext.Provider>
  );
};
