import React, { useContext } from "react";
import DueDate from "../global/DueDate";
import StatusBadge from "../global/StatusBadge";
import moment from "moment";
import { navigate } from "@reach/router";
import { ActivityContext } from "../../context/ActivityContext";
import UserItem from "../user/UserItem";

const ActivityCard = ({ project_id, activity, handleCallback }) => {
  const { postActivity } = useContext(ActivityContext);

  const handleStatus = (status) => {
    postActivity({ ...activity, status }, handleCallback);
  };

  const renderDueDate = () => {
    if (activity.start_date !== null || activity.end_date !== null) {
      return (
        <DueDate
          start_date={activity.start_date}
          end_date={activity.end_date}
        />
      );
    }
  };

  const renderCompleted = () => {
    if (activity.completedAt && activity.completedAt !== null) {
      return (
        <span>
          <i className="fa fa-check text-success me-2"></i>
          {moment(activity.completedAt).format("DD MMM YYYY HH:mm")}
        </span>
      );
    }
  };

  const renderProject = () => {
    if (activity.project_name) {
      return <p className="mb-0 h5">{activity.project_name}</p>;
    }
  };

  const renderMetafields = () => {
    if (activity.comments !== undefined && activity.comments !== null) {
      return (
        <p className="mb-0 mt-2">
          <span className="process-metadata me-3">
            <i className="fa fa-comment me-1"></i> {parseInt(activity.comments)}
          </span>
          <span className="process-metadata me-3">
            <i className="fa fa-tasks me-1"></i> {parseInt(activity.actions)}
          </span>
        </p>
      );
    }
  };

  const renderUser = () => {
    if(activity.user_name && activity.user_name !== null) {
      return <UserItem size="sm" user={{ name: activity.user_name }} />
    }
  }

  return (
    <div
      onClick={() =>
        navigate(`/project/${project_id}/activity/${activity.activity_id}`)
      }
      className="card shadow-sm text-dark no-decoration p-3 mb-3"
    >
      <div className="row align-items-center">
        <div className="col-8">
          {renderProject()}
          <h4>{activity.name}</h4>
          {renderCompleted()}
        </div>
        <div className="col-4">
          <StatusBadge status={activity.status} handleSubmit={handleStatus} />
          {renderMetafields()}
          <div className="mt-3">{renderUser()}</div>
        </div>
      </div>
      {renderDueDate()}
    </div>
  );
};

export default ActivityCard;
