import React, { useContext } from "react";
import ProductList from "./ProductList";
import { CartContext } from "../context/CartContext";

const Products = ({ project_id }) => {
  const { products, appendProduct, setProductQuantity } =
    useContext(CartContext);

  return (
    <div className="container px-0">
      <ProductList
        project_id={project_id}
        selectedProducts={products}
        appendProduct={appendProduct}
        setProductQuantity={setProductQuantity}
      />
    </div>
  );
};

export default Products;
