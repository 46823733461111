import React from "react";
import { useContext } from "react";
import { CartContext } from "../../context/CartContext";
import { formatMonto } from "../../utils";
import IconItem from "../global/IconItem";
import { AuthContext } from "../../context/AuthContext";

const CartItem = ({ product }) => {
  const { user } = useContext(AuthContext);
  const { setProductQuantity } = useContext(CartContext);

  const renderPrice = () => {
    if (user.user_type_id > 3) {
      return (
        <p>
          ${formatMonto(product.price)} {product.currency}- $
          {formatMonto(product.cost)} {product.currency}
        </p>
      );
    }
  };

  return (
    <div className="card px-3 py-2 mb-2 shadow-sm border-0">
      <IconItem name={product.name}>
        {renderPrice()}
        <div className="row mt-2">
          <div className="col-3">
            <button
              className="btn btn-sm py-0 px-2 text-primary"
              onClick={() => setProductQuantity(product.quantity - 1)}
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
          <div className="col-6">
            <input
              type="number"
              value={product.quantity}
              className="form-control py-0"
              onChange={(e) =>
                setProductQuantity(product.product_id, e.target.value)
              }
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-sm py-0 px-2 text-primary"
              onClick={() => setProductQuantity(product.quantity + 1)}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </IconItem>
    </div>
  );
};

export default CartItem;
