import React from "react";

const ConfirmDeleteProject = ({ project, handleCancel, handleDelete }) => {
  return (
    <div>
      <p>Are you sure you want to delete the project {project.name}?</p>
      <p>This will delete all tasks, orders and products from this project.</p>
      <p className="bold text-danger">
        {" "}
        This action has IMMEDIATE effect and CANNOT be reversed
      </p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => handleDelete(project.project_id)}
            className="btn btn-danger w-100"
          >
            Delete Project
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteProject;