import React, { useState, useEffect } from "react";
import StepsService from "../../services/StepsService";

const StepPicker = ({ project_id, selected, modifier }) => {
  const [query, setQuery] = useState("");
  const [steps, setSteps] = useState(null);

  useEffect(() => {
    if (query !== "") {
      fetchSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (selected !== null) {
      setQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const fetchSteps = () => {
    StepsService.getStepsProject(project_id, { query }).then((res) => {
      setSteps(res.data.steps);
    });
  };

  const renderSteps = () => {
    if (Array.isArray(steps) && query !== "") {
      let stepsRender = [...steps];
      if (stepsRender.length === 0) {
        return (
          <ul className="dropdown-menu mw-100 w-100 show">
            <li className="dropdown-ite small text-dark">No steps found.</li>
          </ul>
        );
      }
      return (
        <ul className="dropdown-menu mw-100 w-100 show">
          {stepsRender.map((step) => (
            <li
              className="dropdown-item small border-bottom py-2 text-dark mw-100"
              onClick={() => modifier(step)}
            >
              {step.name}
            </li>
          ))}
        </ul>
      );
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      if (selected.length > 0) {
        return selected.map((step) => (
          <div className="row align-items-center my-2 py-2 bg-light border mx-0">
            <div className="col-10">
              <p className="mb-0">{step.name}</p>
            </div>
            <div className="col-2">
              <button
                className="btn btn-sm py-0"
                onClick={() => modifier(step)}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        ));
      }
    }
  };

  return (
    <div className="container position-relative px-0 mb-3">
      <label>Dependences</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Search steps..."
        onChange={(e) => setQuery(e.target.value)}
      />
      {renderSteps()}
      {renderSelected()}
    </div>
  );
};

export default StepPicker;
