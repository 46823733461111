import React, { useContext } from "react";
import { ProjectTemplatesContext } from "../../context/ProjectTemplatesContext";
import { getValue } from "../../utils";

const TemplateForm = ({ handleCancel }) => {
  const { template, setPropertyTemplate, updateProjectTemplate } = useContext(
    ProjectTemplatesContext
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProjectTemplate(template);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Template Name</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(template, "name")}
        onChange={(e) => setPropertyTemplate("name", e.target.value)}
      />
      <label>Description</label>
      <textarea
        rows="3"
        className="form-control mb-3"
        value={getValue(template, "description")}
        onChange={(e) => setPropertyTemplate("description", e.target.value)}
      />
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 mt-3"
      >
        Cancel
      </button>
    </form>
  );
};

export default TemplateForm;
