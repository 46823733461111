import React, { useContext, useEffect } from "react";
import { EventsContext } from "../../context/EventsContext";
import EventCard from "./EventCard";
import { ProjectsContext } from "../../context/ProjectsContext";

const UpcomingEvents = ({ step_id, project_id, process_id }) => {
  const { events, getEventsStep, getEventsProcess, getEventsProject } =
    useContext(EventsContext);
  const { project } = useContext(ProjectsContext);

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, step_id, process_id]);

  const fetchEvents = () => {
    if (!isNaN(step_id)) {
      getEventsStep(step_id);
    } else if (!isNaN(process_id)) {
      getEventsProcess(process_id);
    } else {
      getEventsProject(project_id);
    }
  };

  const renderEvents = () => {
    if (Array.isArray(events)) {
      let eventsRender = events;
      if (eventsRender.length === 0) {
        return <p>No upcoming events.</p>;
      }
      eventsRender = eventsRender.slice(0, 5);
      return eventsRender.map((event) => (
        <EventCard
          key={event.event_id}
          event={event}
          project_id={project_id}
          handleCallback={fetchEvents}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="container-fluid px-0">{renderEvents()}</div>;
};

export default UpcomingEvents;
