import { SET_FILTER } from "../types";
import {
  SET_INVOICE,
  CREATE_INVOICE,
  INVOICES_RECEIVED,
  SET_PROPERTY_INVOICE,
} from "../types/invoices";

const schema = {
  invoice_id: "",
  status: "pending",
  currency: "MXN",
  min_date: null,
  max_date: null,
  amount: "",
};

const InvoicesReducer = (state, { type, payload }) => {
  switch (type) {
    case INVOICES_RECEIVED:
      return { ...state, invoices: payload };
    case SET_PROPERTY_INVOICE:
      const { key, value } = payload;
      const invoice = { ...state.invoice };
      invoice[key] = value;
      return { ...state, invoice };
    case CREATE_INVOICE:
      return { ...state, invoice: schema };
    case SET_INVOICE:
      return { ...state, invoice: payload };
    case SET_FILTER: {
      const { key, value } = payload;
      const filters = { ...state.filters };
      filters[key] = value;
      return { ...state, filters };
    }
    default:
      return { ...state };
  }
};

export default InvoicesReducer;
