import React, { useContext } from "react";
import SetupStepCard from "../components/projects/SetupStepCard";
import { isProjectComplete } from "../utils";
import { ProjectsContext } from "../context/ProjectsContext";
import BottomAction from "../components/global/BottomAction";

const steps = [
  {
    id: 1,
    link: "info",
    title: "Complete Project Details",
    description: "Client, Billing Address, Files",
  },
  {
    id: 2,
    link: "invoices",
    title: "Create First Invoice",
    description: "Initial setup fee",
  },
  {
    id: 3,
    link: "invoices",
    title: "Invoice Payment Confirmed",
    description: "This will be completed by finances",
  },
  {
    id: 4,
    link: "users",
    title: "Assign Project Manager",
    description: "See all details before kick-off",
  },
  {
    id: 5,
    link: "calendar",
    title: "Schedule Kick-Off Meeting",
  },
];

const CreateProjectSteps = ({ project_id }) => {
  const { project, postProject } = useContext(ProjectsContext);

  const handleProject = () => {
    postProject({ ...project, status: "in-progress" });
  };

  const getCompletedSteps = () => {
    let completed = 0;
    const step1 = isProjectComplete(project);
    if (step1) completed++;
    const step2 = project.invoices.length > 0;
    if (step2) completed++;
    const step3 =
      project.invoices.filter(({ status }) => status === "completed").length >
      0;
    if (step3) completed++;
    const step4 = project.project_users.length > 1;
    if (step4) completed++;
    const step5 = project.events.length > 0;
    if (step5) completed++;
    return completed;
  };

  const renderButton = () => {
    if (completed >= steps.length) {
      return <BottomAction onClick={handleProject} title="Continue" />;
    }
  };

  const completed = getCompletedSteps();

  return (
    <div className="container-fluid px-0">
      <h1>Initial Setup</h1>
      {steps.map((step, index) => (
        <SetupStepCard
          checked={completed >= index + 1}
          project_id={project_id}
          key={step.id}
          {...step}
        />
      ))}
      {renderButton(completed)}
    </div>
  );
};

export default CreateProjectSteps;
