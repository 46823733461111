import api from "./api";

const route = "/bank_accounts";

const BankAccountsService = {
  getBankAccounts: () => api.get(route),
  postBankAccount: (bank_account) => api.post(route, { ...bank_account }),
  putBankAccount: (bank_account) => api.put(route, { ...bank_account }),
  deleteBankAccount: (bank_account_id) =>
    api.delete(`${route}/${bank_account_id}`),
};
export default BankAccountsService;
