import React, { createContext, useReducer, useContext } from "react";
import AddressesService from "../services/AddressesService";
import AddressesReducer from "../reducers/AddressesReducer";
import {
  ADDRESSS_RECEIVED,
  SET_ADDRESS,
  CREATE_ADDRESS,
  SET_PROPERTY_ADDRESS,
} from "../types/addresses";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  addresses: null,
  address: null,
};

export const AddressesContext = createContext(initialState);

export const AddressesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AddressesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getAddresses = () => {
    AddressesService.getAddresses()
      .then((response) => {
        const { addresses } = response.data;
        dispatch({ type: ADDRESSS_RECEIVED, payload: addresses });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleAddress = (address_id) => {
    AddressesService.getSingleAddresses(address_id)
      .then((response) => {
        const { address } = response.data;
        dispatch({ type: SET_ADDRESS, payload: address });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setAddress = (address) => {
    dispatch({ type: SET_ADDRESS, payload: address });
  };

  const createAddress = () => {
    dispatch({ type: CREATE_ADDRESS });
  };

  const setPropertyAddress = (key, value) => {
    dispatch({ type: SET_PROPERTY_ADDRESS, payload: { key, value } });
  };

  const saveAddress = (address, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = AddressesService.putAddress;
    if (isNaN(parseInt(address.address_id))) {
      service = AddressesService.postAddress;
    }
    service(address)
      .then(() => {
        success("Address saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteAddress = (address_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    AddressesService.deleteAddress(address_id)
      .then(() => {
        success("Address deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <AddressesContext.Provider
      value={{
        ...state,
        getAddresses,
        getSingleAddress,
        setAddress,
        createAddress,
        setPropertyAddress,
        saveAddress,
        deleteAddress,
      }}
    >
      {children}
    </AddressesContext.Provider>
  );
};
