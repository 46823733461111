import React, { useContext, useEffect } from "react";
import { InvoicesContext } from "../../context/InvoicesContext";
import InvoiceItem from "./InvoiceItem";
import moment from "moment";

const ProjectInvoices = ({ project_id }) => {
  const { invoices, getProjectInvoices } = useContext(InvoicesContext);

  useEffect(() => {
    getProjectInvoices(project_id, {
      min_date: moment().utc().format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_id]);

  const renderInvoices = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return <p>No pending invoices.</p>;
      }
      return invoices.map((invoice) => (
        <div key={invoice.invoice_id} className="card p-3 shadow-sm mb-3">
          <InvoiceItem invoice={invoice} project_id={project_id} />
        </div>
      ));
    }
  };
  return <div className="container-fluid px-0 pb-5">{renderInvoices()}</div>;
};

export default ProjectInvoices;
