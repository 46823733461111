import React from "react";
import StepsAccordion from "./StepsAccordion";
import IconItem from "../global/IconItem";

const AccordionItem = ({ process }) => {
  const renderDependences = () => {
    if (Array.isArray(process.dependences)) {
      if (process.dependences.length > 0) {
        return (
          <div>
            <h4>Dependences</h4>
            {process.dependences.map((dependence) => (
              <IconItem
                icon="lock"
                key={dependence.name}
                name={dependence.name}
              />
            ))}
          </div>
        );
      }
    }
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target={`#process-${process.process_id}`}
          aria-controls={`process-${process.process_id}`}
        >
          {process.name}
        </button>
      </h2>
      <div
        id={`process-${process.process_id}`}
        className="accordion-collapse collapse"
        data-bs-parent="#processes"
      >
        <div className="accordion-body">
          <p>{process.description}</p>
          {renderDependences()}
          <StepsAccordion steps={process.steps} />
        </div>
      </div>
    </div>
  );
};

const ProcessesAccordion = ({ processes }) => {
  return (
    <div className="accordion" id="processes">
      {processes.map((process) => (
        <AccordionItem key={process.process_id} process={process} />
      ))}
    </div>
  );
};

export default ProcessesAccordion;
