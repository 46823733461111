import React, { useContext, useEffect, useState } from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import AttachmentList from "../components/attachments/AttachmentList";
import CommentFeed from "../components/comments/CommentFeed";
import StepDetail from "../components/steps/StepDetail";
import { StepsContext } from "../context/StepsContext";
import { ModalContext } from "../context/ModalContext";
import StepName from "../components/steps/StepName";
import ConfirmDeleteAttachment from "../components/attachments/ConfirmDeleteAttachment";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const SingleStep = ({ project_id, step_id }) => {
  const [view, setView] = useState("detail");
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { spinner, denied, step, getSingleStep, saveStepFile, deleteStepFile } =
    useContext(StepsContext);

  useEffect(() => {
    fetchStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStep = () => getSingleStep(step_id);

  const handleDelete = (attachment) => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Delete Attachment",
        <ConfirmDeleteAttachment
          attachment={attachment}
          handleCancel={clearModal}
          handleDelete={() =>
            deleteStepFile(step.step_id, attachment.step_file_id)
          }
        />
      );
    }, 500);
  };

  const renderStep = () => {
    if (step && step !== null) {
      switch (view) {
        case "attachments":
          return (
            <AttachmentList
              spinner={spinner}
              attachment={{ step_id }}
              attachments={step.files}
              handleDelete={handleDelete}
              handleSubmit={saveStepFile}
            />
          );
        case "comments":
          return <CommentFeed step_id={step_id} project_id={project_id} />;
        default:
          return <StepDetail step_id={step_id} project_id={project_id} />;
      }
    }
  };

  const renderTabs = () => {
    if (step !== null) {
      return (
        <IonSegment color="accent" value={view}>
          <IonSegmentButton value="detail" onClick={() => setView("detail")}>
            <IonLabel>Detail</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="attachments"
            onClick={() => setView("attachments")}
          >
            <IonLabel>Attachments</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="comments"
            onClick={() => setView("comments")}
          >
            <IonLabel>Comments</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      );
    }
    if (denied) {
      return <p>We're sorry, you are not allowed to see this content.</p>;
    }
  };

  const renderStepName = () => {
    if (step && step !== null) {
      return <StepName step={step} project_id={project_id} />;
    }
  };

  return (
    <div className="container-fluid px-0">
      <IonPullRefresh handleCallback={fetchStep} data={step} />
      {renderStepName()}
      <div className="container-fluid my-4">{renderTabs()}</div>
      {renderStep()}
    </div>
  );
};

export default SingleStep;
