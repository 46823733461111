import React, { useContext, useEffect, useState } from "react";
import IconItem from "../global/IconItem";
import { S3_ENDPOINT } from "../../utils";
import PDFViewer from "../global/PDFViewer";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";

const AttachmentItem = ({
  attachment,
  disabled,
  handleEdit,
  hideButtons,
  handleSubmit,
}) => {
  const { user } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const { modalComponent } = useContext(ModalContext);
  useEffect(() => {
    if (file !== null) {
      if (typeof handleSubmit === "function") {
        handleSubmit({ ...attachment, file });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleView = (e) => {
    e.stopPropagation();
    if (attachment && attachment !== null) {
      const { file } = attachment;
      if (file !== null) {
        let CustomComponent;
        const src = `${S3_ENDPOINT}/${encodeURI(file.name)}.${file.type}`;
        if (
          ["jpg", "jpeg", "png", "gif"].includes(
            String(file.type).toLowerCase()
          )
        ) {
          CustomComponent = ({ src }) => (
            <img
              src={src}
              alt={attachment.name}
              className="mw-100 w-100 m-auto d-block"
            />
          );
        } else if (String(file.type).toLowerCase() === "pdf") {
          CustomComponent = PDFViewer;
        } else {
          CustomComponent = () => (
            <p>This file cannot be previewed, please download to view.</p>
          );
        }
        modalComponent(
          file.name,
          <div>
            <CustomComponent src={src} />
            <a
              href={src}
              target="_blank"
              rel="noreferrer"
              className="btn btn-secondary w-100 my-3 d-block m-auto"
            >
              Download File
            </a>
          </div>
        );
      }
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    let input = document.getElementById(`input-${attachment.name}`);
    if (input) input.click();
  };

  const renderUpload = () => {
    if (attachment && attachment !== null && !disabled && !hideButtons) {
      return (
        <button onClick={handleClick} className="btn p-1 px-2 btn-sm btn-light">
          <i
            className={`fas fa-${
              attachment.file_id === null ? "upload" : "redo"
            }`}
          ></i>
          <input
            type="file"
            id={`input-${attachment.name}`}
            onChange={(e) => setFile(e.target.files[0])}
            style={{ visibility: "hidden", position: "absolute" }}
          />
        </button>
      );
    }
  };

  const renderEdit = () => {
    if (user.user_type_id > 3 && !disabled && !hideButtons) {
      return (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(attachment);
          }}
          className="btn p-1 me-2 btn-link text-primary"
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  const renderDescription = () => {
    const { description } = attachment;
    if (description && description !== null && description !== "") {
      return (
        <p className="text-muted mb-0">
          {String(description).replace(/(?:\r\n|\r|\n)/g, "<br>")}
        </p>
      );
    }
  };

  const renderFile = () => {
    if (attachment && attachment !== null) {
      if (attachment.file_id === null) {
        return (
          <IconItem icon="file text-muted" name={attachment.name}>
            {renderDescription()}
          </IconItem>
        );
      }
      return (
        <IconItem icon="file text-primary" name={attachment.name}>
          {renderDescription()}
        </IconItem>
      );
    }
  };

  return (
    <div onClick={handleView} className="container-fluid px-0">
      <div className="row align-items-center">
        <div className="col-8">{renderFile()}</div>
        <div className="col-4 text-end">
          {renderEdit()}
          {renderUpload()}
        </div>
      </div>
    </div>
  );
};

export default AttachmentItem;
