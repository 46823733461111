import api from "./api";

const route = "/project_templates";

const ProjectTemplatesService = {
  getProjectTemplates: () => api.get(route),
  getSingleProjectTemplate: (project_template_id) =>
    api.get(`${route}/${project_template_id}`),
  applyProjectTemplate: ({ project_id, start_date, project_template_id }) =>
    api.post(`${route}/${project_id}/apply`, {
      project_id,
      start_date,
      project_template_id,
    }),
  postProjectTemplate: (project_id, name) =>
    api.post(`${route}/${project_id}`, { name }),
  putProjectTemplate: (project_template) =>
    api.put(route, { ...project_template }),
  deleteProjectTemplate: (project_template_id) =>
    api.delete(`${route}/${project_template_id}`),
};
export default ProjectTemplatesService;
