import React, { createContext, useReducer, useContext } from "react";
import UserDevicesService from "../services/UserDevicesService";
import UserDevicesReducer from "../reducers/UserDevicesReducer";
import {
  SET_USER_DEVICE,
  CREATE_USER_DEVICE,
  USER_DEVICES_RECEIVED,
  SET_PROPERTY_USER_DEVICE,
} from "../types/user_devices";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  user_devices: null,
  user_device: null,
};

export const UserDevicesContext = createContext(initialState);

export const UserDevicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserDevicesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getUserDevices = () => {
    UserDevicesService.getUserDevices()
      .then((response) => {
        const { user_devices } = response.data;
        dispatch({ type: USER_DEVICES_RECEIVED, payload: user_devices });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleUserDevice = (user_device_id) => {
    UserDevicesService.getSingleUserDevices(user_device_id)
      .then((response) => {
        const { user_device } = response.data;
        dispatch({ type: SET_USER_DEVICE, payload: user_device });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setUserDevice = (user_device) => {
    dispatch({ type: SET_USER_DEVICE, payload: user_device });
  };

  const createUserDevice = () => {
    dispatch({ type: CREATE_USER_DEVICE });
  };

  const setPropertyUserDevice = (key, value) => {
    dispatch({ type: SET_PROPERTY_USER_DEVICE, payload: { key, value } });
  };

  const saveUserDevice = (user_device, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = UserDevicesService.putUserDevice;
    if (isNaN(parseInt(user_device.user_device_id))) {
      service = UserDevicesService.postUserDevice;
    }
    service(user_device)
      .then(() => {
        success("UserDevice saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteUserDevice = (user_device_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    UserDevicesService.deleteUserDevice(user_device_id)
      .then(() => {
        success("UserDevice deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <UserDevicesContext.Provider
      value={{
        ...state,
        getUserDevices,
        getSingleUserDevice,
        setUserDevice,
        createUserDevice,
        setPropertyUserDevice,
        saveUserDevice,
        deleteUserDevice,
      }}
    >
      {children}
    </UserDevicesContext.Provider>
  );
};
