import React, { useContext } from "react";
import { formatMonto } from "../../utils";
import moment from "moment";
import StatusBadge from "../global/StatusBadge";
import OrderForm from "./OrderForm";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";

const OrderHeader = ({ order }) => {
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleEdit = () => {
    modalComponent(
      "Edit Order",
      <OrderForm project_id={order.project_id} handleCancel={clearModal} />
    );
  };

  const renderEdit = () => {
    if (
      !["ready", "approved"].includes(order.status) &&
      user.user_type_id > 3
    ) {
      return (
        <button onClick={handleEdit} className="btn text-primary">
          <i className="fa fa-edit" />
        </button>
      );
    }
  };

  const renderExpiration = () => {
    if (
      !["approved", "ready", "in-progress", "completed"].includes(
        order.status
      ) &&
      order.expiration_date !== null
    ) {
      return (
        <p className="mb-0 mt-3 text-danger">
          Expires on {moment(order.expiration_date).format("MMM Do YYYY")}
        </p>
      );
    }
  };

  return (
    <div className="row mb-3 align-items-center">
      <div className="container-fluid">
        <div className="row mb-3 align-items-center">
          <div className="col-10">
            <h1
              className="mb-0"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="This is Agave Brand's internal Order ID Number"
            >
              Order #{order !== null ? order.order_id : ""}
            </h1>
          </div>
          <div className="col-2">{renderEdit()}</div>
        </div>
      </div>
      {order.status !== "created" && (
        <div className="col-6">
          <h3 className="mb-0">${formatMonto(order.amount)}</h3>
        </div>
      )}
      <div className="col-6">
        <StatusBadge status={order.status} />
      </div>
      {renderExpiration()}
      <div className="container-fluid mt-4">
        <p className="mb-0">
          Orders are not dispatched until they have been approved by the client
          and the first invoice has been paid and confirmed.
        </p>
      </div>
    </div>
  );
};

export default OrderHeader;
