import React from "react";
import UserItem from "./UserItem";
import { navigate } from "@reach/router";

const UserCard = ({ user, children, handleEdit, handleDelete }) => {
  return (
    <div className="card p-3 shadow-sm mb-2 cursor-pointer hover-border-primary">
      <UserItem user={user} handleEdit={handleEdit} handleDelete={handleDelete}>
        {children}
      </UserItem>
    </div>
  );
};

export default UserCard;
