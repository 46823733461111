import React, { useContext } from "react";
import DynamicForm from "../global/DynamicForm";
import {
  BankAccountsContext,
  bankAccountFields,
} from "../../context/BankAccountsContext";
import { ModalContext } from "../../context/ModalContext";

const BankAccountForm = () => {
  const { clearModal } = useContext(ModalContext);
  const { bank_account, saveBankAccount, setPropertyBankAccount } =
    useContext(BankAccountsContext);

  return (
    <div className="container px-0">
      <DynamicForm
        object={bank_account}
        handleCancel={clearModal}
        saveAction={saveBankAccount}
        questions={bankAccountFields}
        modifier={setPropertyBankAccount}
      />
    </div>
  );
};

export default BankAccountForm;
