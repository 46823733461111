import { COMMENTS_RECEIVED, SET_COMMENT } from "../types/comments";

const CommentsReducer = (state, { type, payload }) => {
  switch (type) {
    case COMMENTS_RECEIVED:
      return { ...state, comments: payload };
    case SET_COMMENT:
      return { ...state, comment: payload };
    default:
      return { ...state };
  }
};
export default CommentsReducer;
