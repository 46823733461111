import {
  IonLabel,
  IonSegment,
  IonActionSheet,
  IonSegmentButton,
} from "@ionic/react";
import IonPullRefresh from "../components/ionic/IonPullRefresh";
import React, { useState, useContext, useEffect } from "react";
import BottomAction from "../components/global/BottomAction";
import { ProjectsContext } from "../context/ProjectsContext";
import ProjectCard from "../components/projects/ProjectCard";
import ProjectForm from "../components/projects/ProjectForm";
import { ModalContext } from "../context/ModalContext";
import { AUTH_URL } from "../utils/zoho";

const SelectProject = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState("ongoing");

  const { clearModal, modalComponent } = useContext(ModalContext);

  const { projects, getProjects, clearProject, createProject } =
    useContext(ProjectsContext);

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProjects = () => {
    console.log("refreshing");
    clearProject();
    getProjects();
  };

  const setResult = ({ detail }) => {
    setIsOpen(false);
    if (detail.data) {
      if (detail.data === "zoho") {
        window.open(AUTH_URL, "_self");
      } else if (detail.data === "manually") {
        handleCreate();
      }
    }
  };

  const handleCreate = () => {
    createProject();
    modalComponent("Create Project", <ProjectForm handleCancel={clearModal} />);
  };

  const renderProjects = () => {
    if (Array.isArray(projects)) {
      let projectsRender = projects.filter(
        (project) => project.status === view
      );
      if (projectsRender.length === 0) {
        return <p>No {view} projects found.</p>;
      }
      return projectsRender.map((project) => (
        <ProjectCard key={project.project_id} project={project} />
      ));
    }
  };

  return (
    <div>
      <div className="container-fluid mb-3">
        <IonPullRefresh handleCallback={fetchProjects} data={projects} />
        <h2 className="text-center mb-3">Projects</h2>
        <IonSegment color="accent" value={view}>
          <IonSegmentButton value="ongoing" onClick={() => setView("ongoing")}>
            <IonLabel>Ongoing</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="completed"
            onClick={() => setView("completed")}
          >
            <IonLabel>Completed</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
      <div id="projects"  style={{paddingBottom: "80px"}} className="row mx-0">{renderProjects()}</div>
      <BottomAction onClick={() => setIsOpen(true)} title="Create Project" />
      <IonActionSheet
        isOpen={isOpen}
        trigger="open-action-sheet"
        header="Create Project"
        buttons={[
          {
            text: "Import from Zoho",
            data: "zoho",
          },
          {
            text: "Create Manually",
            data: "manually",
          },
        ]}
        onDidDismiss={setResult}
      ></IonActionSheet>
    </div>
  );
};

export default SelectProject;
