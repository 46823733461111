import React, { useContext } from "react";
import moment from "moment";
import UserCard from "../user/UserCard";
import RejectPaymentForm from "./RejectPaymentForm";
import ConfirmPaymentForm from "./ConfirmPaymentForm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import AttachmentCard from "../attachments/AttachmentCard";
import { PaymentsContext } from "../../context/PaymentsContext";
import { CapacitorContext } from "../../context/CapacitorContext";

const PaymentDetails = ({ project_id, handleComments }) => {
  const { user } = useContext(AuthContext);
  const { platform } = useContext(CapacitorContext);
  const { modalComponent } = useContext(ModalContext);
  const { payment, getSinglePayment } = useContext(PaymentsContext);

  const handleCallback = () => getSinglePayment(project_id, payment.payment_id);

  const handleConfirmModal = () => {
    modalComponent(
      "Confirm Payment",
      <ConfirmPaymentForm
        project_id={project_id}
        handleCallback={handleCallback}
      />
    );
  };

  const handleRejectModal = () => {
    modalComponent(
      "Reject Payment",
      <RejectPaymentForm
        project_id={project_id}
        handleCallback={handleCallback}
      />
    );
  };

  const renderApprover = () => {
    if (payment.approver !== null) {
      return (
        <UserCard user={{ ...payment.approver, name: "" }}>
          <span className="small bold d-block">Confirmed Payment</span>
          {moment(payment.confirmedAt).format("MMM Do YYYY HH:mm")}
        </UserCard>
      );
    }
  };

  const renderRejecter = () => {
    if (payment.rejecter !== null) {
      return (
        <UserCard user={{ ...payment.rejecter, name: "" }}>
          <span className="small bold d-block">Rejected Payment</span>
          {moment(payment.rejectedAt).format("MMM Do YYYY HH:mm")}
          <p
            className="text-primary text-underline small mb-0"
            onClick={handleComments}
          >
            See comments for details
          </p>
        </UserCard>
      );
    }
  };

  const renderButton = () => {
    if (user.user_type_id > 4) {
      return (
        <div
          className={`fixed-bottom container ${
            platform === "web" ? "mb-3 mw-100 w-50 me-0 ms-auto" : "mb-4"
          }`}
        >
          <div className="row">
            {payment.rejectedAt === null && (
              <div className="col-6">
                <button
                  onClick={handleRejectModal}
                  className="btn btn-outline-danger w-100 my-3"
                >
                  <i className="fa fa-times me-2" />
                  Reject
                </button>
              </div>
            )}
            {payment.confirmedAt === null && (
              <div className="col-6">
                <button
                  onClick={handleConfirmModal}
                  className="btn btn-primary w-100 my-3"
                >
                  <i className="fa fa-check me-2" />
                  Confirm
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  const renderEvidence = () => {
    const { file } = payment;
    if (file !== null) {
      return (
        <AttachmentCard
          attachment={{ name: "Evidence", file }}
          hideButtons={payment.createdBy !== user.user_id}
          disabled={
            payment.confirmedAt !== null || user.user_id !== payment.createdBy
          }
        />
      );
    }
  };
  return (
    <div>
      {renderEvidence()}
      <UserCard user={payment.creator}>
        <span className="small bold d-block">Created Payment</span>
        {moment(
          payment.recordedAt !== null ? payment.recordedAt : payment.createdAt
        ).format("MMM Do YYYY HH:mm")}
      </UserCard>
      {renderApprover()}
      {renderRejecter()}
      {renderButton()}
    </div>
  );
};

export default PaymentDetails;
