import React, { useContext, useEffect } from "react";
import { ActivityContext } from "../../context/ActivityContext";
import { ProcessContext } from "../../context/ProcessContext";
import { StepsContext } from "../../context/StepsContext";
import { Link } from "@reach/router";

const Breadcrumb = ({ project_id }) => {
  const { process, getSingleProcess } = useContext(ProcessContext);
  const { step, getSingleStep } = useContext(StepsContext);
  const { activity } = useContext(ActivityContext);

  const isValid = (object) => object && object !== null;

  useEffect(() => {
    if (isValid(activity) && !isValid(step)) {
      getSingleStep(activity.step_id);
    }
  }, [activity]);

  useEffect(() => {
    if (isValid(step) && !isValid(process)) {
      getSingleProcess(step.process_id);
    }
  }, [step]);

  const renderBreadcrumb = () => {
    let components = [];
    if (
      process &&
      process !== null &&
      !window.location.pathname.includes("process")
    ) {
      components.push(
        <Link
          key="process"
          className="text-accent"
          to={`/project/${project_id}/process/${process.process_id}`}
        >
          {process.name}
        </Link>
      );
    }
    if (step && step !== null && !window.location.pathname.includes("step")) {
      components.push(
        <Link
          key="step"
          className="text-accent"
          to={`/project/${project_id}/step/${step.step_id}`}
        >
          {step.name}
        </Link>
      );
    }
    if (
      activity &&
      activity !== null &&
      !window.location.pathname.includes("activity")
    ) {
      components.push(
        <Link
          key="activity"
          className="text-accent"
          to={`/project/${project_id}/activity/${activity.activity_id}`}
        >
          {activity.name}
        </Link>
      );
    }
    return components.map((component, index) =>
      index > 0
        ? [<span key={`separator-${index}`}>{" > "}</span>, component]
        : component
    );
  };

  return <div className="px-3 mb-3">{renderBreadcrumb()}</div>;
};

export default Breadcrumb;
