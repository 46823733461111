import React, { useState, useContext, useEffect } from "react";
import CartItem from "../components/cart/CartItem";
import OrderButton from "../components/order/OrderButton";
import { CartContext } from "../context/CartContext";
import { Link } from "@reach/router";

const Cart = ({ project_id }) => {
  const [error, setError] = useState(null);
  const { products, setProductQuantity } = useContext(CartContext);

  useEffect(() => {
    let error = false;
    if (Array.isArray(products)) {
      products.forEach((product) => {
        if (parseInt(product.quantity) % 6 !== 0) error = true;
      });
    }
    if (error) setError("Product quantities must be a multiple of 6");
    else setError(null);
  }, [products]);

  const renderError = () => {
    if (error !== null) {
      return (
        <p className="text-danger">
          {error}
          <button
            className="btn btn-sm btn-secondary my-2 w-100"
            onClick={handleAdjustQuantities}
          >
            Adjust Quantities
          </button>
        </p>
      );
    }
  };

  const handleAdjustQuantities = () => {
    products.forEach(({ product_id, quantity }) => {
      if (quantity < 1002) {
        quantity = 1002;
      } else {
        while (quantity % 6 !== 0) {
          quantity++;
        }
      }
      setProductQuantity(product_id, quantity);
    });
  };

  const renderProducts = () => {
    if (Array.isArray(products)) {
      if (products.length === 0) {
        return (
          <div>
            <p>No products selected to order.</p>
            <Link
              to={`/project/${project_id}/products`}
              className="btn btn-secondary w-100"
            >
              Create Order
            </Link>
          </div>
        );
      }
      return products.map((product) => (
        <CartItem key={product.product_id} product={product} />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <h1>Cart</h1>
      {renderError()}
      {renderProducts()}
      <OrderButton project_id={project_id} disabled={error !== null} />
    </div>
  );
};

export default Cart;
