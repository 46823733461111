import React, { useState, useContext } from "react";
import { ProductsContext } from "../../context/ProductsContext";

const ProductDamageForm = ({ product_id, handleCancel }) => {
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState("");

  const { postDamagedProduct } = useContext(ProductsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postDamagedProduct(product_id, amount, comment);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Amount</label>
      <input
        type="number"
        value={amount}
        className="form-control mb-3"
        onChange={(e) => setAmount(e.target.value)}
      />
      <label>Comment</label>
      <input
        type="text"
        value={comment}
        className="form-control mb-3"
        onChange={(e) => setComment(e.target.value)}
      />
      <button className="btn btn-primary my-3 w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100"
      >
        Cancel
      </button>
    </form>
  );
};

export default ProductDamageForm;
