import React, { useContext, useEffect } from "react";
import InventoryCard from "../components/inventory/InventoryCard";
import { ProductsContext } from "../context/ProductsContext";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const InventoryList = ({ project_id }) => {
  const { products, getProductInventory, getProjectProductInventory } =
    useContext(ProductsContext);

  useEffect(() => {
    fetchInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInventory = () => {
    if (project_id && project_id !== null) {
      getProjectProductInventory(project_id);
    } else {
      getProductInventory();
    }
  };

  const renderInventoryList = () => {
    if (Array.isArray(products)) {
      if (products.length === 0) {
        return <p>No products available.</p>;
      }
      return products.map((product) => (
        <InventoryCard
          key={product.product_id}
          project_id={project_id}
          product={product}
        />
      ));
    }
  };

  return (
    <div className="container-fluid pb-4">
      <IonPullRefresh handleCallback={fetchInventory} data={products} />
      <h1 className="border-bottom mb-2 pb-2">
        {project_id && project_id !== null ? "Project" : ""} Inventory
      </h1>
      {renderInventoryList()}
    </div>
  );
};

export default InventoryList;
