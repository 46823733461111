import { Router } from "@reach/router";
import React from "react";
import AdminUsers from "./AdminUsers";
import AdminSingleUser from "./AdminSingleUser";
import Calendar from "./Calendar";

const Admin = () => {
  return (
    <Router>
      <AdminUsers path="/users" />
      <Calendar path="/calendar" />
      <AdminSingleUser path="/users/:user_id" />
    </Router>
  );
};

export default Admin;
