import React, { useState, useContext, useEffect } from "react";
import { ProjectsContext } from "../context/ProjectsContext";
import AttachmentCard from "../components/attachments/AttachmentCard";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const SingleProjectFile = ({ project_id }) => {
  const [query, setQuery] = useState("");
  const { files, project, getSingleProjectFile } = useContext(ProjectsContext);

  useEffect(() => {
    fetchData();
  }, [project_id]);

  const fetchData = () => {
    getSingleProjectFile(project_id);
  };

  const renderFiles = () => {
    if (Array.isArray(files)) {
      if (files.length === 0) {
        return <p>No files available for this project.</p>;
      }
      let filesRender = [...files];
      if (query !== "") {
        let currentQuery = String(query).toLowerCase();
        filesRender = filesRender.filter(({ name }) =>
          String(name).toLowerCase().includes(currentQuery)
        );
      }
      return filesRender.map((file, index) => (
        <AttachmentCard
          key={index}
          attachment={{ ...file, file }}
          hideButtons
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  const renderName = () => {
    if (project && project !== null) {
      return project.name;
    }
    return "Project File";
  };

  return (
    <div className="container px-0">
      <IonPullRefresh handleCallback={fetchData} data={files} />
      <h1 className="my-3">{renderName()}</h1>
      <input
        type="text"
        value={query}
        placeholder="Search file..."
        className="form-control mb-3"
        onChange={(e) => setQuery(e.target.value)}
      />
      {renderFiles()}
    </div>
  );
};

export default SingleProjectFile;
