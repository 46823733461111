import React, { useContext, useEffect } from "react";
import { ActivityContext } from "../../context/ActivityContext";
import ActivityCard from "./ActivityCard";
import { ProjectsContext } from "../../context/ProjectsContext";

const CriticalActivities = ({ project_id, process_id }) => {
  const { activities, getActivitiesProject } = useContext(ActivityContext);
  const { project } = useContext(ProjectsContext);

  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process_id, project]);

  const fetchActivities = () => {
    getActivitiesProject(project_id, { critical: true });
  };

  const renderActivities = () => {
    if (Array.isArray(activities)) {
      if (activities.length === 0) {
        return <p>You don't have any pending critical activities.</p>;
      }
      return activities.map((activity) => (
        <ActivityCard
          handleCallback={fetchActivities}
          key={activity.activity_id}
          project_id={project_id}
          activity={activity}
        />
      ));
    }
  };

  return <div className="container-fluid px-0">{renderActivities()}</div>;
};

export default CriticalActivities;
