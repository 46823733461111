import React, { useContext, useEffect } from "react";
import { RequisitionsContext } from "../context/RequisitionsContext";
import RequisitionCard from "../components/requisitions/RequisitionCard";
import RequisitionForm from "../components/requisitions/RequisitionForm";
import { ModalContext } from "../context/ModalContext";
import BottomAction from "../components/global/BottomAction";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const Requisitions = ({ project_id }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { requisitions, getRequisitions, clearRequisition, createRequisition } =
    useContext(RequisitionsContext);

  useEffect(() => {
    getRequisitions();
  }, []);

  const handleCancel = () => {
    clearRequisition();
    clearModal();
  };

  const handleCreate = () => {
    createRequisition();
    modalComponent(
      "Create Requisition",
      <RequisitionForm
        project_id={project_id}
        handleCancel={handleCancel}
        handleCallback={getRequisitions}
      />
    );
  };

  const renderRequisitions = () => {
    if (Array.isArray(requisitions)) {
      if (requisitions.length === 0) {
        return <p>No requisitions available.</p>;
      }
      return requisitions.map((requisition) => (
        <RequisitionCard
          key={requisition.requisition_id}
          requisition={requisition}
          project_id={project_id}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={getRequisitions} data={requisitions} />
      <h1>Requisitions</h1>
      {renderRequisitions()}
      <BottomAction onClick={handleCreate} title="+ Requisition" />
    </div>
  );
};

export default Requisitions;
