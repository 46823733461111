import api from "./api";
import { getArgs } from "../utils";

const route = "/activities";

const ActivityService = {
  getActivitiesStep: (step_id) => api.get(`${route}/step/${step_id}`),
  getActivitiesProcess: (process_id) =>
    api.get(`${route}/process/${process_id}`),
  getActivitiesProject: (project_id, filters) =>
    api.get(`${route}/project/${project_id}?${getArgs(filters)}`),
  getSingleActivity: (activity_id) => api.get(`${route}/${activity_id}`),
  commentActivity: (activity_id, comment) =>
    api.post(`${route}/${activity_id}/comment`, { ...comment }),
  postActivityFile: (activity_id, activity_file) =>
    api.post(`${route}/${activity_id}/file`, { ...activity_file }),
  putActivityFile: (activity_id, activity_file) =>
    api.put(`${route}/${activity_id}/file`, {
      ...activity_file,
    }),
  deleteActivityFile: (activity_id, file_id) =>
    api.delete(`${route}/${activity_id}/file/${file_id}`),
  postActivity: (activity) => api.post(route, { ...activity }),
  putActivity: (activity) => api.put(route, { ...activity }),
  deleteActivity: (activity_id) => api.delete(`${route}/${activity_id}`),
};

export default ActivityService;
