import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import ProductDamageForm from "./ProductDamageForm";
import BottomAction from "../global/BottomAction";

const ProductInventory = ({ product }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleDamage = () => {
    modalComponent(
      "Add Damage",
      <ProductDamageForm
        product_id={product.product_id}
        handleCancel={clearModal}
      />
    );
  };

  return (
    <div className="container px-0 my-3">
      <h2 className="border-bottom pb-2 mb-2">Inventory</h2>
      <div className="card mb-2 shadow-sm p-3">
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Ordered</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">{product.ordered}</p>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Requested</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">{product.requested}</p>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Damaged</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">{product.damaged}</p>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Allocated</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">{product.allocated}</p>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Stock</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">{product.stock}</p>
          </div>
        </div>
        <div
          className={`row border-bottom py-2 ${
            product.pending > 0 ? "text-danger" : ""
          }`}
        >
          <div className="col-6">
            <p className="bold mb-0">Pending</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">{product.pending}</p>
          </div>
        </div>
      </div>
      <BottomAction onClick={handleDamage} title="+ Damage" />
    </div>
  );
};

export default ProductInventory;
