import { HIDE_SPINNER, PERMISSION_DENIED, SHOW_SPINNER } from "../types";
import {
  ACTIVITIES_RECEIVED,
  CREATE_ACTIVITY,
  SET_PROPERTY_ACTIVITY,
  SET_SINGLE_ACTIVITY,
  TOGGLE_ACTIVITY_DEPENDENCE,
} from "../types/activity";

const schema = {
  activity_id: "",
  name: "",
  description: "",
  hide_client: false,
};

const ActivityReducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_ACTIVITY_DEPENDENCE:
      let dependences = state.activity.dependences;
      if (!Array.isArray(dependences)) {
        dependences = [];
      }
      dependences = [...dependences];
      const index = dependences.findIndex(
        ({ activity_id }) => activity_id === payload.activity_id
      );
      if (index === -1) {
        dependences.push(payload);
      } else {
        dependences.splice(index, 1);
      }
      return { ...state, activity: { ...state.activity, dependences } };
    case PERMISSION_DENIED:
      return { ...state, denied: true };
    case ACTIVITIES_RECEIVED:
      return { ...state, activities: payload, denied: false };
    case SET_SINGLE_ACTIVITY:
      return { ...state, activity: payload, denied: false };
    case CREATE_ACTIVITY:
      return { ...state, activity: schema };
    case SET_PROPERTY_ACTIVITY:
      const activity = { ...state.activity };
      const { key, value } = payload;
      activity[key] = value;
      return { ...state, activity };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default ActivityReducer;
