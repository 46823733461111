import React, { useState, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { ProductsContext } from "../../context/ProductsContext";
import ProductForm from "./ProductForm";
import { IonActionSheet } from "@ionic/react";
import ProductInputSelect from "./ProductInputSelect";
import ProductInputItem from "./ProductInputItem";

const ProductInputs = ({ project_id, parent_id, inputs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const {
    createProduct,
    getSingleProduct,
    saveProductInput,
    setPropertyProduct,
  } = useContext(ProductsContext);

  const handleCancel = () => {
    clearModal();
  };

  const handleCallback = () => {
    getSingleProduct(parent_id);
  };

  const handleSelect = () => {
    modalComponent(
      "Select Input",
      <ProductInputSelect
        parent_id={parent_id}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  };

  const handleCreate = () => {
    createProduct();
    setPropertyProduct("minimum_level", 3);
    modalComponent(
      "Add Product",
      <ProductForm
        handleCancel={handleCancel}
        handleCallback={handleInputCallback}
      />
    );
  };

  const handleInputCallback = (product_input) => {
    saveProductInput(parent_id, product_input.input_id);
  };

  const setResult = ({ detail }) => {
    setIsOpen(false);
    if (detail.data === "create") {
      handleCreate();
    } else if (detail.data === "select") {
      handleSelect();
    }
  };

  const renderInputs = () => {
    if (Array.isArray(inputs)) {
      if (inputs.length === 0) {
        return <p>No inputs registered for this product.</p>;
      }
      return inputs.map((input) => (
        <ProductInputItem
          project_id={project_id}
          parent_id={parent_id}
          key={input.input_id}
          product={input}
          disablePicker
          hideButtons
        />
      ));
    }
  };
  return (
    <div className="container px-0">
      <div className="row border-bottom align-items-center pb-2 my-3 mx-0">
        <div className="col-8 px-0">
          <h2 className="mb-0">Inputs</h2>
        </div>
        <div className="col-4 px-0 text-end">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="btn btn-sm text-primary"
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      {renderInputs()}
      <IonActionSheet
        isOpen={isOpen}
        trigger="open-action-sheet"
        header="Add Input"
        buttons={[
          {
            text: "Select Existing",
            data: "select",
          },
          {
            text: "Create Product",
            data: "create",
          },
        ]}
        onDidDismiss={setResult}
      ></IonActionSheet>
    </div>
  );
};

export default ProductInputs;
