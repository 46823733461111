import React, { useContext } from "react";
import DynamicForm from "../global/DynamicForm";
import { AddressesContext } from "../../context/AddressesContext";

const questions = [
  {
    id: "name",
    order: 1,
    type: "text",
    label: "Name",
    required: true,
  },
  {
    id: "address_line_1",
    order: 2,
    type: "text",
    label: "Address Line 1",
    required: true,
  },
  {
    id: "address_line_2",
    order: 3,
    type: "text",
    label: "Address Line 2",
  },
  {
    id: "zip_code",
    order: 4,
    type: "text",
    label: "ZIP Code",
    required: true,
  },
  {
    id: "city",
    order: 5,
    type: "text",
    label: "City",
    required: true,
  },
  {
    id: "state",
    order: 6,
    type: "text",
    label: "State",
    required: true,
  },
  {
    id: "country",
    order: 7,
    type: "text",
    label: "Country",
    required: true,
  },
];

const AddressForm = ({ handleCancel, handleCallback }) => {
  const { address, saveAddress, setPropertyAddress } =
    useContext(AddressesContext);

  const handleSubmit = (currentAddress) => {
    saveAddress(currentAddress, handleCallback);
  };

  return (
    <DynamicForm
      object={address}
      questions={questions}
      saveAction={handleSubmit}
      handleCancel={handleCancel}
      modifier={setPropertyAddress}
    />
  );
};

export default AddressForm;
