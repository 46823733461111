import React, { useReducer, createContext, useContext } from "react";
import InvoicesReducer from "../reducers/InvoicesReducer";
import {
  SET_INVOICE,
  CREATE_INVOICE,
  INVOICES_RECEIVED,
  SET_PROPERTY_INVOICE,
} from "../types/invoices";
import InvoicesService from "../services/InvoicesService";
import { ModalContext } from "./ModalContext";
import { SET_FILTER } from "../types";

const initialState = {
  invoices: null,
  invoice: null,
  filters: {},
};

export const InvoicesContext = createContext(initialState);

export const InvoicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InvoicesReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getInvoices = () => {
    InvoicesService.getInvoices().then((res) => {
      const { invoices } = res.data;
      dispatch({ type: INVOICES_RECEIVED, payload: invoices });
    });
  };

  const getSingleInvoice = (project_id, invoice_id) => {
    InvoicesService.getSingleInvoice(project_id, invoice_id).then((res) => {
      const { invoice } = res.data;
      dispatch({ type: SET_INVOICE, payload: invoice });
    });
  };

  const getProjectInvoices = (project_id, filters) => {
    InvoicesService.getProjectInvoices(project_id, filters).then((res) => {
      const { invoices } = res.data;
      dispatch({ type: INVOICES_RECEIVED, payload: invoices });
    });
  };

  const createInvoice = () => {
    dispatch({ type: CREATE_INVOICE });
  };

  const setInvoice = (invoice) => {
    dispatch({ type: SET_INVOICE, payload: invoice });
  };

  const setPropertyInvoice = (key, value) => {
    dispatch({ type: SET_PROPERTY_INVOICE, payload: { key, value } });
  };

  const saveInvoice = (project_id, invoice, callback) => {
    let service = InvoicesService.putInvoice;
    if (isNaN(parseInt(invoice.invoice_id))) {
      service = InvoicesService.postInvoice;
    }
    service(project_id, invoice).then(() => {
      getProjectInvoices(project_id);
      if (typeof callback === "function") {
        callback();
      }
      success("Invoice saved.");
      clearModal();
    });
  };

  const setFilter = (key, value) => {
    dispatch({ type: SET_FILTER, payload: { key, value } });
  };

  const clearInvoice = () => {
    dispatch({ type: SET_INVOICE, payload: null });
  };

  const deleteInvoice = (project_id, invoice_id, callback) => {
    InvoicesService.deleteInvoice(project_id, invoice_id).then(() => {
      success("Invoice deleted.");
      clearModal();
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  return (
    <InvoicesContext.Provider
      value={{
        ...state,
        setFilter,
        setInvoice,
        getInvoices,
        saveInvoice,
        clearInvoice,
        createInvoice,
        deleteInvoice,
        getSingleInvoice,
        getProjectInvoices,
        setPropertyInvoice,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};
