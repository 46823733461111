import api from "./api";
import { getArgs } from "../utils";

const route = "/products";

const ProductsService = {
  getProducts: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getProductInventory: () => api.get(`${route}/inventory`),
  getProjectProductInventory: (project_id) =>
    api.get(`${route}/project/${project_id}/inventory`),
  getProductInputs: (filters) => api.get(`${route}/inputs?${getArgs(filters)}`),
  getProjectProducts: (project_id, filters) =>
    api.get(`${route}/project/${project_id}?${getArgs(filters)}`),
  downloadProjectProducts: (project_id, filters) =>
    api.get(`${route}/project/${project_id}/excel?${getArgs(filters)}`, {
      responseType: "blob",
    }),
  getSingleProduct: (product_id) => api.get(`${route}/${product_id}`),
  postProduct: (product) => api.post(route, { ...product }),
  putProduct: (product) => api.put(route, { ...product }),
  postProductDamage: (product_id, quantity, comment) =>
    api.post(`${route}/damaged`, {
      product_id,
      quantity,
      comment,
    }),
  postProductInput: (parent_id, input_id, amount) =>
    api.post(`${route}/input`, { parent_id, input_id, amount }),
  deleteProduct: (product_id) => api.delete(`${route}/product/${product_id}`),
};
export default ProductsService;
