import React, { useContext } from "react";
import { InvoicesContext } from "../../context/InvoicesContext";

const InvoiceFilters = () => {
  const { filters, setFilter } = useContext(InvoicesContext);
  return (
    <div className="container-fluid px-0">
      <div className="row mb-3">
        <div className="col-6">
          <label>From</label>
          <input
            type="date"
            value={filters.start_date}
            className="form-control"
            onChange={(e) => setFilter("start_date", e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>To</label>
          <input
            type="date"
            value={filters.end_date}
            className="form-control"
            onChange={(e) => setFilter("end_date", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceFilters;
