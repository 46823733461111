import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { CheckoutContext } from "../context/CheckoutContext";
import { InvoicesContext } from "../context/InvoicesContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutInvoice from "../components/checkout/CheckoutInvoice";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";

const Checkout = ({ project_id, invoice_id }) => {
  const { user } = useContext(AuthContext);
  const { invoice, getSingleInvoice } = useContext(InvoicesContext);

  //Checkout
  const { setPayPal, createOrder, setPaymentSource, payment_source_id } =
    useContext(CheckoutContext);

  useEffect(() => {
    getSingleInvoice(project_id, invoice_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoice !== null) {
      setPayPal(invoice_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  useEffect(() => {
    const paypalButton = document.getElementById("paypal-button");
    if (user !== null && invoice !== null && paypalButton.innerHTML === "") {
      setPayPal(invoice_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, invoice]);

  const handleSubmit = (payment_source_id) => {
    createOrder(invoice_id, payment_source_id);
  };

  return (
    <div id="checkout" className="container-fluid px-0 py-2">
      <CheckoutHeader />
      <div className="container px-0">
        <div className="row py-4">
          <div className="col-12 col-md-4 my-2 ps-0">
            <CheckoutInvoice project_id={project_id} invoice_id={invoice_id} />
          </div>
          <div className="col-12 col-md-8 my-2 pe-0">
            <CheckoutPaymentMethods
              invoice_id={invoice_id}
              paymentMethod={payment_source_id}
              setPaymentMethod={setPaymentSource}
              handleSubmitCheckout={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
