import React from "react";
import OrderStatusCard from "./OrderStatusCard";

const OrderList = ({ orders, project_id }) => {
  const renderOrders = () => {
    if (Array.isArray(orders)) {
      let ordersRender = orders;
      if (ordersRender.length === 0) {
        return <p>No orders available.</p>;
      }
      return ordersRender.map((order) => (
        <OrderStatusCard
          order={order}
          key={order.order_id}
          project_id={project_id}
        />
      ));
    }
  };
  return (
    <div className="container-fluid px-0 bg-light pb-5">{renderOrders()}</div>
  );
};

export default OrderList;
