import React, { useState, useContext, useEffect } from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import InvoiceFilters from "../components/invoices/InvoiceFilters";
import BottomAction from "../components/global/BottomAction";
import { InvoicesContext } from "../context/InvoicesContext";
import InvoiceForm from "../components/invoices/InvoiceForm";
import ProgressBar from "../components/global/ProgressBar";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import IonPullRefresh from "../components/ionic/IonPullRefresh";
import InvoicesList from "../components/invoices/InvoicesList";

const Invoices = ({ project_id }) => {
  const [view, setView] = useState("all");
  const [showFilters, setShowFilters] = useState(false);
  const {
    filters,
    invoices,
    getInvoices,
    saveInvoice,
    createInvoice,
    getProjectInvoices,
  } = useContext(InvoicesContext);
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInvoices = () => {
    if (project_id && project_id !== null) {
      getProjectInvoices(project_id);
    } else {
      getInvoices();
    }
  };

  const handleCreate = () => {
    createInvoice();
    modalComponent(
      "Add Invoice",
      <InvoiceForm
        project_id={project_id}
        handleCancel={clearModal}
        handleSubmit={saveInvoice}
      />
    );
  };


  const getInvoicesProgress = () => {
    let paid = 0.0;
    let total = 0.0;
    if (Array.isArray(invoices)) {
      invoices.forEach((invoice) => {
        total += parseFloat(invoice.amount);
        if (invoice.status === "completed") paid += parseFloat(invoice.amount);
      });
    }
    return { total, paid };
  };

  const renderAction = () => {
    if (user.user_type_id > 2) {
      return <BottomAction onClick={handleCreate} title="+ Invoice" />;
    }
  };


  const { total, paid } = getInvoicesProgress();

  const progress = total > 0 ? parseInt((paid / total) * 100) : 0;

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={fetchInvoices} data={invoices} />
      <div className="row mx-0 pb-2 mb-2 border-bottom align-items-center">
        <div className="col-8 px-0">
          <h1>Invoices</h1>
        </div>
        <div className="col-4 px-0 text-end">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="btn btn-sm text-secondary"
          >
            <i className="fas fa-filter"></i>
          </button>
        </div>
      </div>
      {showFilters && <InvoiceFilters />}
      <div className="row my-3 align-items-center">
        <div className="col-2">Pagado</div>
        <div className="col-8">
          <ProgressBar progress={progress} />
        </div>
        <div className="col-2 text-end">{progress}%</div>
      </div>      
      <InvoicesList invoices={invoices} project_id={project_id} filters={filters} />
      {renderAction()}
    </div>
  );
};

export default Invoices;
