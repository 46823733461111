import React, { createContext, useContext, useReducer } from "react";
import FilesReducer from "../reducers/FilesReducer";
import { CREATE_FILE, SET_FILE, SET_PROPERTY_FILE } from "../types/files";
import FilesService from "../services/FilesService";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { CapacitorContext } from "./CapacitorContext";

const initialState = {
  spinner: false,
  files: null,
  file: null,
};

export const FilesContext = createContext(initialState);

export const FilesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FilesReducer, initialState);

  const { platform } = useContext(CapacitorContext);

  const createFile = () => {
    dispatch({ type: CREATE_FILE });
  };

  const setFile = (file) => {
    dispatch({ type: SET_FILE, payload: file });
  };

  const setPropertyFile = (key, value) => {
    dispatch({ type: SET_PROPERTY_FILE, payload: { key, value } });
  };

  const uploadFile = (file, callback) => {
    if (platform !== "web") {
      if (typeof file === "string" && file.includes("base64")) {
        return uploadFileBase64(file, callback);
      }
    }
    dispatch({ type: SHOW_SPINNER });
    const formData = FilesService.getFormData(file);
    FilesService.postFile(formData)
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        const { file_id } = res.data;
        if (typeof callback === "function") {
          callback(file_id);
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const uploadFileBase64 = (base64image, callback) => {
    FilesService.postFileBase64(base64image)
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        const { file_id } = res.data;
        if (typeof callback === "function") {
          callback(file_id);
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <FilesContext.Provider
      value={{
        ...state,
        setFile,
        createFile,
        uploadFile,
        setPropertyFile,
        uploadFileBase64,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};
