import React, { useContext } from "react";
import AttachmentCard from "./AttachmentCard";
import AttachmentForm from "./AttachmentForm";
import { ModalContext } from "../../context/ModalContext";
import BottomAction from "../global/BottomAction";

const AttachmentList = ({
  spinner,
  attachment,
  attachments,
  handleSubmit,
  handleDelete,
}) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCreate = () => {
    modalComponent(
      "Add Attachment",
      <AttachmentForm
        spinner={spinner}
        attachment={attachment}
        handleCancel={clearModal}
        handleSubmit={handleSubmit}
      />
    );
  };

  const handleEdit = (attachment) => {
    modalComponent(
      "Edit Attachment",
      <AttachmentForm
        spinner={spinner}
        attachment={attachment}
        handleCancel={clearModal}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
      />
    );
  };

  const renderAttachments = () => {
    if (Array.isArray(attachments)) {
      if (attachments.length > 0) {
        return attachments.map((attachment, index) => (
          <AttachmentCard
            key={index}
            handleEdit={handleEdit}
            attachment={attachment}
            handleSubmit={handleSubmit}
          />
        ));
      }
    }
    return <p>No attachments found.</p>;
  };
  return (
    <div className="container-fluid bg-light py-3">
      {renderAttachments()}
      <BottomAction onClick={handleCreate} title="+ Add Attachment" />
    </div>
  );
};

export default AttachmentList;
