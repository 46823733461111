import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import ConfirmTemplate from "../templates/ConfirmTemplate";
import { ProjectsContext } from "../../context/ProjectsContext";

const MenuProject = ({ user }) => {
  const { project } = useContext(ProjectsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleSaveTemplate = () => {
    modalComponent(
      "Save Template",
      <ConfirmTemplate project={project} handleCancel={clearModal} />
    );
  };

  const renderButton = () => {
    if (user !== null) {
      if (user.user_type_id >= 3) {
        if (window.location.pathname.includes("project")) {
          return (
            <button
              className="btn btn-light w-100"
              onClick={() => handleSaveTemplate(project)}
            >
              Save Project as Template
            </button>
          );
        }
      }
    }
  };

  const renderProject = () => {
    if (project && project !== null) {
      return <h3 className="lucida mb-0">{project.name}</h3>;
    }
  };

  return (
    <div className="row">
      <div className="container-fluid mb-3">{renderProject()}</div>
      <div className="container-fluid">{renderButton()}</div>
    </div>
  );
};

export default MenuProject;
