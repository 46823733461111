import React, { useContext, useState } from "react";
import { OrdersContext } from "../../context/OrdersContext";
import { getValue } from "../../utils";

const ProductOrderForm = ({ project_id, handleCancel, handleCallback }) => {
  const [error, setError] = useState(null);
  const { product, setPropertyProduct, updateOrderProduct } =
    useContext(OrdersContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      isNaN(product.price) ||
      product.price === "" ||
      product.price === null
    ) {
      return setError("Price is invalid");
    }
    if (product.name === "") {
      return setError("Name is invalid");
    }
    product.project_id = project_id;
    updateOrderProduct(project_id, product, handleCallback);
  };
  return (
    <form onSubmit={handleSubmit}>
      {error !== null ? <h4 className="text-danger">{error}</h4> : ""}
      <h3>{product.name}</h3>
      <p>{product.SKU}</p>
      <div className="row mb-3">
        <div className="col-6">
          <label>Price</label>
          <input
            step=".01"
            type="number"
            value={getValue(product, "price")}
            className="form-control"
            onChange={(e) => setPropertyProduct("price", e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>Cost</label>
          <input
            step=".01"
            type="number"
            value={getValue(product, "cost")}
            className="form-control"
            onChange={(e) => setPropertyProduct("cost", e.target.value)}
          />
        </div>
      </div>
      <label>Currency</label>
      <select
        value={getValue(product, "currency")}
        className="form-control mb-3"
        onChange={(e) => setPropertyProduct("currency", e.target.value)}
      >
        <option>USD</option>
        <option>MXN</option>
        <option>GBP</option>
        <option>EUR</option>
      </select>
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 mt-3"
      >
        Cancel
      </button>
    </form>
  );
};

export default ProductOrderForm;
