import React, { useContext } from "react";
import BottomAction from "../global/BottomAction";
import ActionCollapse from "../actions/ActionCollapse";
import { ModalContext } from "../../context/ModalContext";
import { ActionsContext } from "../../context/ActionsContext";
import { ActivityContext } from "../../context/ActivityContext";
import ActionForm from "../actions/ActionForm";
import { AuthContext } from "../../context/AuthContext";

const ActivityActions = ({ activity_id, project_id }) => {
  const { user } = useContext(AuthContext);
  const { activity } = useContext(ActivityContext);
  const { createAction } = useContext(ActionsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCreateAction = () => {
    createAction();
    modalComponent(
      "Add Action",
      <ActionForm activity_id={activity_id} handleCancel={clearModal} />
    );
  };

  const renderAction = () => {
    if (user.user_type_id > 3) {
      return <BottomAction onClick={handleCreateAction} title="+ Add Action" />;
    }
  };

  return (
    <div>
      <ActionCollapse
        project_id={project_id}
        activity_id={activity.activity_id}
        disabled={
          activity.dependence !== null &&
          activity.dependence.completedAt === null
        }
      />
      {renderAction()}
    </div>
  );
};

export default ActivityActions;
