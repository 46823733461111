import { Link } from "@reach/router";
import React, { useState, useContext, useEffect } from "react";
import OrderStatusCard from "../components/order/OrderStatusCard";
import { OrdersContext } from "../context/OrdersContext";
import OrderFilters from "../components/order/OrderFilters";
import OrderList from "../components/order/OrderList";
import BottomAction from "../components/global/BottomAction";
import { navigate } from "@reach/router";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const Orders = ({ project_id }) => {
  const [showFilters, setShowFilters] = useState(false);
  const { orders, filters, getAllOrders, getProjectOrders } =
    useContext(OrdersContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const fetchData = () => {
    if (isNaN(parseInt(project_id))) {
      getAllOrders(filters);
    } else {
      getProjectOrders(project_id);
    }
  };

  return (
    <div className="container-fluid pb-5 px-0">
      <IonPullRefresh handleCallback={fetchData} data={orders} />
      <div className="row align-items-center pb-2 mb-2 border-bottom">
        <div className="col-8">
          <h1>Orders</h1>
        </div>
        <div className="col-4 text-end">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="btn btn-sm text-secondary"
          >
            <i className="fas fa-filter"></i>
          </button>
        </div>
      </div>
      {showFilters && <OrderFilters />}
      <OrderList project_id={project_id} orders={orders} />
      <BottomAction
        onClick={() =>
          navigate(project_id ? `/project/${project_id}/products` : "/products")
        }
        title="Create Order"
      />
    </div>
  );
};

export default Orders;
