import React, { useContext, useEffect, useState } from "react";
import { EventsContext } from "../../context/EventsContext";
import DateTimePicker from "../common/DateTimePicker";
import SelectUserType from "../global/SelectUserType";
import SelectEventType from "./SelectEventType";
import { getValue } from "../../utils";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";

const EventForm = ({ project_id, currentDate, handleCancel }) => {
  const { event, postEvent, setPropertyEvent } = useContext(EventsContext);
  const { alert } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const [minDate, setMinDate] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isNaN(parseInt(event.event_id))) {
      const minDateString = moment()
        .endOf("day")
        .add(2, "day")
        .format("YYYY-MM-DD");
      setMinDate(minDateString);
    }
  }, []);

  useEffect(() => {
    if (currentDate && currentDate !== null) {
      setPropertyEvent(
        "start_date",
        moment(currentDate).format("YYYY-MM-DD HH:mm")
      );
    }
  }, [currentDate]);

  const getMinTime = () => {
    if (event.event_type_id === 4) {
      return "09:00";
    }
    return "11:00";
  };

  const getMaxTime = () => {
    if (event.event_type_id === 4) {
      return "09:00";
    }
    return "15:00";
  };

  const getMinEndTime = () => {
    if (event.event_type_id === 4) {
      return "13:00";
    }
    return moment(minDate).format("YYYY-MM-DD");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (event.name === "") {
      return setError("Name is invalid.");
    }
    event.project_id = project_id;
    postEvent(event, currentDate);
  };

  return (
    <form onSubmit={handleSubmit}>
      {error !== null ? <h4 className="text-danger">{error}</h4> : ""}
      <label>Name</label>
      <input
        type="text"
        value={getValue(event, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyEvent("name", e.target.value)}
      />
      <label>Description</label>
      <textarea
        rows="4"
        value={getValue(event, "description")}
        className="form-control mb-3"
        onChange={(e) => setPropertyEvent("description", e.target.value)}
      />
      <label>Start</label>
      <DateTimePicker
        notWeekends
        alert={alert}
        minDate={minDate}
        //minTime={getMinTime()}
        maxTime={getMaxTime()}
        value={getValue(event, "start_date")}
        modifier={(start_date) => setPropertyEvent("start_date", start_date)}
      />
      <label>End (optional)</label>
      <DateTimePicker
        notWeekends
        alert={alert}
        value={getValue(event, "end_date")}
        minDate={getMinEndTime()}
        modifier={(end_date) => setPropertyEvent("end_date", end_date)}
      />
      <SelectEventType
        value={event.event_type_id}
        modifier={(event_type_id) =>
          setPropertyEvent("event_type_id", event_type_id)
        }
      />
      {user.user_type_id > 2 && (
        <SelectUserType
          value={event.minimum_level}
          modifier={(minimum_level) =>
            setPropertyEvent("minimum_level", minimum_level)
          }
        />
      )}
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 mt-3"
      >
        Cancel
      </button>
    </form>
  );
};

export default EventForm;
