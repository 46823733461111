import React, { useState } from "react";
import { Link } from "@reach/router";
import InvoiceItem from "./InvoiceItem";
import { filterInvoices, invoicestabs } from "../../utils/invoices";
import IonTabs from "../global/IonTabs";

const InvoicesList = ({ project_id, invoices, filters }) => {
  const [view, setView] = useState("all");

  const getInvoiceLink = (invoice) => {
    let projectId = project_id;
    if (!projectId || projectId === null) {
      projectId = invoice.project_id;
    }
    return `/project/${projectId}/invoice/${invoice.invoice_id}`;
  };

  const renderInvoices = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return <p>No invoices available.</p>;
      }
      let invoicesRender = filterInvoices(invoices, filters);
      if (view !== "all") {
        invoicesRender = invoicesRender.filter(
          (invoice) => invoice.status === view
        );
      }
      if (invoicesRender.length === 0) {
        return <p>No invoices available for your query.</p>;
      }
      return invoicesRender.map((invoice) => (
        <Link
        key={invoice.invoice_id}
          to={getInvoiceLink(invoice)}
          className="card p-3 shadow-sm mb-3 text-dark no-decoration"
        >
          <InvoiceItem invoice={invoice} project_id={project_id} />
        </Link>
      ));
    }
  };
  return (
    <div>
      <IonTabs tabs={invoicestabs} selected={view} setSelected={setView} />
      {renderInvoices()}
    </div>
  );
};

export default InvoicesList;
