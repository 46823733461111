
import api from './api';

const route = '/notifications';

export default {
  getNotifications: () => api.get(route),
  getSingleNotification: (notification_id) => api.get(`${route}/${notification_id}`),
  postNotification: (notification) => api.post(route, { ...notification}),
  putNotification: (notification) => api.put(route, { ...notification}),
  deleteNotification: (Notification) => api.delete(`${route}/${Notification}`),
};
