import React, { useState, useContext, useEffect } from "react";
import NotificationCard from "../components/notifications/NotificationCard";
import { NotificationsContext } from "../context/NotificationsContext";
import IonPullRefresh from "../components/ionic/IonPullRefresh";

const Notifications = () => {
  const [selected, setSelected] = useState("unread");
  const { notifications, getNotifications, readNotification } =
    useContext(NotificationsContext);

  useEffect(() => {
    getNotifications();
  }, []);

  const handleReadAll = () => {
    notifications.forEach(readNotification);
    setTimeout(() => {
      getNotifications();
    }, 1000);
  };

  const renderNotifications = () => {
    if (Array.isArray(notifications)) {
      let notificationsRender = [...notifications];
      if (selected === "unread") {
        notificationsRender = notificationsRender.filter(
          (notification) => notification.readAt === null
        );
      }
      if (notificationsRender.length === 0) {
        return <p>No available notifications.</p>;
      }
      return notificationsRender.map((notification) => (
        <NotificationCard
          key={notification.notification_id}
          notification={notification}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <IonPullRefresh handleCallback={getNotifications} data={notifications} />
      <div className="row mb-2 mx-0 border-bottom align-items-center">
        <div className="col-8 px-0">
          <h1>Notifications</h1>
        </div>
        <div className="col-4 px-0 text-end">
          <button className="btn btn-sm text-primary">
            <i className="fa fa-cog" />
          </button>
        </div>
      </div>
      <div className="my-3">
        <div className="row">
          <div className="col-6">
            <button
              onClick={() => setSelected("unread")}
              className={`btn btn-sm badge badge-pill me-2 ${
                selected === "unread"
                  ? "btn-secondary"
                  : "btn-outline-secondary text-secondary"
              }`}
            >
              Unread
            </button>
            <button
              onClick={() => setSelected("all")}
              className={`btn btn-sm badge badge-pill ${
                selected === "all"
                  ? "btn-secondary"
                  : "btn-outline-secondary text-secondary"
              }`}
            >
              All
            </button>
          </div>
          <div className="col-6 text-end">
            <button
              onClick={handleReadAll}
              className={`btn btn-sm badge badge-pill btn-outline-primary`}
            >
              Read All
            </button>
          </div>
        </div>
      </div>
      {renderNotifications()}
    </div>
  );
};

export default Notifications;
