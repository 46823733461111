import React, { createContext, useReducer, useContext } from "react";
import ProcessReducer from "../reducers/ProcessReducer";
import FilesService from "../services/FilesService";
import ProcessService from "../services/ProcessService";
import { HIDE_SPINNER, PERMISSION_DENIED, SHOW_SPINNER } from "../types";
import {
  CREATE_PROCESS,
  PROCESSES_RECEIVED,
  SET_PROPERTY_PROCESS,
  SET_SINGLE_PROCESS,
  TOGGLE_PROCESS_DEPENDENCE,
} from "../types/process";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";

const initialState = {
  processes: null,
  spinner: false,
  process: null,
  denied: false,
};

export const ProcessContext = createContext(initialState);

export const ProcessProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProcessReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        return dispatch({ type: PERMISSION_DENIED });
      }
    }
    alert(error);
  };

  const getProcessesProject = (project_id) => {
    ProcessService.getProcessesProject(project_id)
      .then((res) => {
        const { processes } = res.data;
        dispatch({ type: PROCESSES_RECEIVED, payload: processes });
      })
      .catch(handleError);
  };

  const getSingleProcess = (process_id) => {
    ProcessService.getSingleProcess(process_id)
      .then((res) => {
        const { process } = res.data;
        dispatch({ type: SET_SINGLE_PROCESS, payload: process });
      })
      .catch(handleError);
  };

  const createProcess = () => {
    dispatch({ type: CREATE_PROCESS });
  };

  const setProcess = (process) => {
    dispatch({ type: SET_SINGLE_PROCESS, payload: process });
  };

  const clearProcess = () => {
    dispatch({ type: SET_SINGLE_PROCESS, payload: null });
  };

  const setPropertyProcess = (key, value) => {
    dispatch({ type: SET_PROPERTY_PROCESS, payload: { key, value } });
  };

  const commentProcess = (process_id, comment) => {
    ProcessService.comment(process_id, comment)
      .then(() => {
        success("Comentario agregado.");
      })
      .catch(alert);
  };

  const submitProcessFile = (process_id, file) => {
    const formData = FilesService.getFormData(file);
    FilesService.postFile(formData)
      .then((res) => {
        const { file_id } = res.data;
        ProcessService.submitProcessFile(process_id, file_id);
      })
      .catch(alert);
  };

  const deleteProcessFile = (process_id, file_id) => {
    ProcessService.deleteProcessFile(process_id, file_id)
      .then(() => {
        getSingleProcess(process_id);
      })
      .catch(alert);
  };

  const postProcess = (project_id, process) => {
    dispatch({ type: SHOW_SPINNER });
    let service = ProcessService.putProcess;
    if (isNaN(parseInt(process.process_id))) {
      service = ProcessService.postProcess;
    }
    service(project_id, process)
      .then(() => {
        clearModal();
        success("Process saved.");
        dispatch({ type: HIDE_SPINNER });
        getProcessesProject(project_id);
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteProcess = (process) => {
    const { project_id, process_id } = process;
    dispatch({ type: SHOW_SPINNER });
    ProcessService.deleteProcess(project_id, process_id)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        navigate(`/project/${project_id}`);
        getProcessesProject(project_id);
        success("Process deleted.");
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const toggleProcessDependence = (process) => {
    dispatch({ type: TOGGLE_PROCESS_DEPENDENCE, payload: process });
  };

  return (
    <ProcessContext.Provider
      value={{
        ...state,
        setProcess,
        postProcess,
        clearProcess,
        createProcess,
        deleteProcess,
        commentProcess,
        getSingleProcess,
        submitProcessFile,
        deleteProcessFile,
        setPropertyProcess,
        getProcessesProject,
        toggleProcessDependence,
      }}
    >
      {children}
    </ProcessContext.Provider>
  );
};
