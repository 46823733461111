import api from "./api";
import firebase from "firebase";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD6Z8vuitT0MM_5Wvbvt0P_KwR8cZ__qyk",
  authDomain: "app-tsm.firebaseapp.com",
  projectId: "app-tsm",
  storageBucket: "app-tsm.appspot.com",
  messagingSenderId: "783324306694",
  appId: "1:783324306694:web:f476af6372ad49d0b01287",
};

// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const getToken = () => auth.currentUser.getIdToken(true);

const auth = firebase.auth();

const handleSignInError = (error) => {
  throw error;
};

const AuthService = {
  signIn: (email, password) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        return getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          return user;
        });
      })
      .catch((error) => handleSignInError(error, email, password)),
  userLoggedIn: (success, error) =>
    auth.onAuthStateChanged((user) => {
      if (user) {
        getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          if (success) success(user);
        });
      } else {
        error();
      }
    }),
  signOut: () => auth.signOut(),
  signUp: (correo, password) =>
    auth.createUserWithEmailAndPassword(correo, password),
  recoverPassword: (email) => auth.sendPasswordResetEmail(email),
  getToken: () => auth.currentUser.getIdToken(true),
  updateEmail: (email) => auth.currentUser.updateEmail(email),
  setupInterceptors: (callback) => {
    api.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const { config, message } = error;
  
        config.retry -= 1;
        if (error.response.data) {
          if (error.response.data.code) {
            if (error.response.data.code === "auth/id-token-expired") {
              return callback(error.response.data);
            }
          }
        }

        return Promise.reject(error);
      }
    );
  },
};

export default AuthService;
