import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { S3_ENDPOINT } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ProjectsContext } from "../../context/ProjectsContext";
import ProjectImageForm from "../projects/ProjectImageForm";

const Greeting = () => {
  const { user } = useContext(AuthContext);
  const { project } = useContext(ProjectsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleUpload = () => {
    modalComponent("Add File", <ProjectImageForm handleCancel={clearModal} />);
  };

  const renderName = () => {
    if (user && user !== null) {
      return <p className="text-muted mb-0">Hi, {user.name}</p>;
    }
  };

  const renderImage = () => {
    if (project && project !== null) {
      const { thumbnail } = project;
      if (thumbnail && thumbnail !== null) {
        return (
          <div className="position-relative w-100">
            <div className="chart-wrapper">
              <Chart
                type="radialBar"
                series={[project.progress]}
                width={175}
                height={175}
                options={{
                  chart: {
                    type: "radialBar",
                  },
                  colors: ["#0d7575"],
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        position: "back",
                      },
                      dataLabels: {
                        show: false,
                      },
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [""],
                }}
              />
            </div>
            <div className="image-wrapper position-relative">
              <img
                src={`${S3_ENDPOINT}/${thumbnail.name}.${thumbnail.type}`}
                alt={thumbnail.name}
                className="project-img d-block m-auto"
              />
              <button
                onClick={handleUpload}
                className="btn px-0 py-0 text-primary"
                style={{ position: "absolute", right: -5, bottom: -10 }}
              >
                <i className="fa fa-edit"></i>
              </button>
            </div>
          </div>
        );
      }
    }
    return (
      <button
        onClick={handleUpload}
        className="btn btn-sm btn-round btn-outline-primary"
      >
        <i className="fa fa-image"></i>
      </button>
    );
  };

  return (
    <div className="row py-3 mb-3 align-items-center">
      <div className="project-progress position-relative">{renderImage()}</div>
      <div className="project-name">
        <h1 className="h2 mb-1">
          {project !== null ? project.name : "Welcome Back!"}
        </h1>
        {renderName()}
      </div>
    </div>
  );
};

export default Greeting;
