import React, { useContext } from "react";
import { formatMonto } from "../../utils";
import IconItem from "../global/IconItem";
import ProductOrderForm from "./ProductOrderForm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { OrdersContext } from "../../context/OrdersContext";

const OrderProducts = ({ order, products, project_id, handleCallback }) => {
  const { user } = useContext(AuthContext);
  const { setProduct } = useContext(OrdersContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCancel = () => {
    clearModal();
    setProduct(null);
  };

  const handleEdit = (product) => {
    setProduct({ ...product, ...product.product });
    modalComponent(
      "Edit Product",
      <ProductOrderForm
        project_id={project_id}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  };

  const renderName = (product) => {
    if (product.product !== null) {
      return product.product.name;
    }
  };

  const renderPrice = (product) => {
    if (order.status !== "created" || user.user_type_id === 6) {
      if (
        (user.user_type_id > 3 &&
          !["approved", "in-progress"].includes(order.status)) ||
        user.user_type_id === 6
      ) {
        return (
          <button
            onClick={() => handleEdit(product)}
            className="btn btn-sm py-0"
          >
            <i className="fa fa-edit text-primary me-2"></i>$
            {formatMonto(product.price)} {product.currency}{" "}
          </button>
        );
      }
      return (
        <p className="mb-0">
          ${formatMonto(product.price)} {product.currency}
        </p>
      );
    }
  };

  const renderProducts = () => {
    if (Array.isArray(products)) {
      if (products.length === 0) {
        return <p>No products for this order jet.</p>;
      }
      return products.map((product) => (
        <div key={product.product_id} className="row py-2 border-top">
          <div className="col-12 pb-2 bold">{renderName(product)}</div>
          <div className="col-4">{product.product.SKU}</div>
          <div className="col-4">{renderPrice(product)}</div>
          <div className="col-4">Qty: {product.quantity}</div>
        </div>
      ));
    }
  };

  return (
    <div className="card pt-2 px-3 pb-0 mb-3 shadow-sm">
      <IconItem name="Products" icon="box" />
      {renderProducts()}
    </div>
  );
};

export default OrderProducts;
