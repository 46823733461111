import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import ProjectsReducer from "../reducers/ProjectsReducer";
import ProjectsService from "../services/ProjectsService";
import {
  CREATE_PROJECT,
  DEALS_RECEIVED,
  PROJECTS_RECEIVED,
  SET_SINGLE_PROJECT,
  SET_PROPERTY_PROJECT,
  SET_SINGLE_PROJECT_FILES,
} from "../types/projects";
import { AUTH_URL } from "../utils/zoho";
import { ModalContext } from "./ModalContext";
import { USERS_RECEIVED } from "../types/users";
import FilesService from "../services/FilesService";
import { HIDE_SPINNER, SET_INTERVAL, SHOW_SPINNER } from "../types";

const initialState = {
  spinner: false,
  projects: null,
  project: null,
  files: null,
};

export const ProjectsContext = createContext(initialState);

export const ProjectsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProjectsReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getZohoDeals = (code) => {
    ProjectsService.getZohoDeals(code)
      .then((res) => {
        const { deals } = res.data;
        dispatch({ type: DEALS_RECEIVED, payload: deals });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            window.open(AUTH_URL, "_self");
          }
        }
      });
  };

  const getProjects = () => {
    ProjectsService.getProjects().then((res) => {
      const { projects } = res.data;
      dispatch({ type: PROJECTS_RECEIVED, payload: projects });
    });
  };

  const getSingleProjectFile = (project_id) => {
    ProjectsService.getSingleProjectFile(project_id).then((res) => {
      const { project } = res.data;
      dispatch({ type: SET_SINGLE_PROJECT_FILES, payload: project.files });
    });
  };

  const getProjectUsers = (project_id) => {
    ProjectsService.getProjectUsers(project_id).then((res) => {
      const { users } = res.data;
      dispatch({ type: USERS_RECEIVED, payload: users });
    });
  };

  const clearProject = () => {
    dispatch({ type: SET_SINGLE_PROJECT, payload: null });
  };

  const getSingleProject = (project_id) => {
    dispatch({ type: SHOW_SPINNER });
    ProjectsService.getSingleProject(project_id)
      .then((res) => {
        const { project } = res.data;
        dispatch({ type: HIDE_SPINNER });
        if (project.processes.length > 0) {
          dispatch({ type: SET_INTERVAL, payload: null });
        }
        dispatch({ type: SET_SINGLE_PROJECT, payload: project });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const setProject = (project) => {
    dispatch({ type: SET_SINGLE_PROJECT, payload: project });
  };

  const createProject = () => {
    dispatch({ type: CREATE_PROJECT });
  };

  const postProject = (project) => {
    let service = ProjectsService.putProject;
    if (isNaN(parseInt(project.project_id))) {
      service = ProjectsService.postProject;
    }
    service(project).then((res) => {
      success("Project saved.");
      clearModal();
      if (res.data.project) {
        const { project_id } = res.data.project;
        return navigate(`/project/${project_id}`);
      }
      getSingleProject(project.project_id);
    });
  };

  const setPropertyProject = (key, value) => {
    dispatch({ type: SET_PROPERTY_PROJECT, payload: { key, value } });
  };

  const saveUserProject = (user) => {
    ProjectsService.postUserProject(user.project_id, user).then(() => {
      success("User added to project.");
      getProjectUsers(user.project_id);
      clearModal();
    });
  };

  const deleteUserProject = (project_id, user_id) => {
    ProjectsService.deleteUserProject(project_id, user_id).then(() => {
      success("User removed from project.");
      getProjectUsers(project_id);
      clearModal();
    });
  };

  const saveProjectFile = (project_id, project_file) => {
    dispatch({ type: SHOW_SPINNER });
    const { file_id, file } = project_file;
    let service = ProjectsService.putProjectFile;
    project_file.old_file_id = file_id;
    if (isNaN(parseInt(file_id))) {
      service = ProjectsService.postProjectFile;
    }
    const promises = [];
    if (file && file !== null) {
      if (file.size) {
        promises.push(
          new Promise((resolve, reject) => {
            const formData = FilesService.getFormData(file);
            FilesService.postFile(formData)
              .then((res) => {
                project_file.new_file_id = res.data.file_id;
                project_file.file_id = res.data.file_id;
                resolve();
              })
              .catch(reject);
          })
        );
      }
    }
    Promise.all(promises)
      .then(() => {
        service(project_id, project_file).then(() => {
          dispatch({ type: HIDE_SPINNER });
          getSingleProject(project_id);
          success("Attachment saved.");
          clearModal();
        });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteProject = (project_id) => {
    ProjectsService.deleteProject(project_id).then(() => {
      success("Project deleted.");
      navigate("/");
      clearModal();
    });
  };

  const deleteProjectFile = (project_id, file_id, callback) => {
    ProjectsService.deleteProjectFile(project_id, file_id).then(() => {
      success("File deleted.");
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const setInterval = (interval) => {
    dispatch({ type: SET_INTERVAL, payload: interval });
  };

  return (
    <ProjectsContext.Provider
      value={{
        ...state,
        setInterval,
        setProject,
        postProject,
        getProjects,
        getZohoDeals,
        clearProject,
        deleteProject,
        createProject,
        saveProjectFile,
        getProjectUsers,
        saveUserProject,
        getSingleProject,
        deleteUserProject,
        deleteProjectFile,
        setPropertyProject,
        getSingleProjectFile,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
