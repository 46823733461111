import React, { useContext } from "react";
import IconItem from "../global/IconItem";
import { AuthContext } from "../../context/AuthContext";
import OrderUpdateCard from "./OrderUpdateCard";

const OrderUpdates = ({
  order,
  updates,
  project_id,
  handleUpdate,
  handleCallback,
}) => {
  const { user } = useContext(AuthContext);

  const renderButton = () => {
    if (
      user.user_type_id > 3 &&
      ["approved", "in-progress"].includes(order.status)
    ) {
      return (
        <button onClick={handleUpdate} className="btn btn-sm text-primary">
          <i className="fa fa-plus"></i>
        </button>
      );
    }
  };

  const renderUpdates = () => {
    if (Array.isArray(updates)) {
      if (updates.length === 0) {
        return <p>No updates for this order jet.</p>;
      }
      return updates.map((order_update) => (
        <OrderUpdateCard
          key={order_update.order_update_id}
          handleCallback={handleCallback}
          order_update={order_update}
          project_id={project_id}
        />
      ));
    }
  };

  return (
    <div className="card pt-2 px-3 pb-0 mb-3 shadow-sm">
      <div className="row align-items-center">
        <div className="col-10">
          <IconItem name="Updates" icon="tasks" />
        </div>
        <div className="col-2">{renderButton()}</div>
      </div>
      {renderUpdates()}
    </div>
  );
};

export default OrderUpdates;
