import React, { useContext } from "react";
import moment from "moment";
import ActionForm from "./ActionForm";
import parse from "html-react-parser";
import DueDate from "../global/DueDate";
import IconItem from "../global/IconItem";
import DeleteConfirm from "../global/DeleteConfirm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ActionsContext } from "../../context/ActionsContext";
import { ActivityContext } from "../../context/ActivityContext";

const ActionCard = ({ disabled, project_id, activity_id, action }) => {
  const {
    spinner,
    setAction,
    deleteAction,
    completeAction,
    uncompleteAction,
    getActionsActivity,
  } = useContext(ActionsContext);
  const { getSingleActivity } = useContext(ActivityContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const handleCallback = () => {
    getSingleActivity(activity_id);
    getActionsActivity(activity_id);
  };

  const handleDelete = () => {
    clearModal();
    setTimeout(() => {
      modalComponent(
        "Delete Action",
        <DeleteConfirm
          itemName="action"
          spinner={spinner}
          handleDelete={deleteAction}
          item={{ ...action, project_id }}
        />
      );
    }, 500);
  };

  const handleEdit = () => {
    setAction(action);
    modalComponent(
      "Edit Action",
      <ActionForm
        activity_id={activity_id}
        handleCancel={clearModal}
        handleDelete={handleDelete}
      />
    );
  };

  const handleComplete = (checked) => {
    let service = completeAction;
    if (!checked) {
      service = uncompleteAction;
    }
    service(action, handleCallback);
  };

  const renderDueDate = () => {
    if (action.start_date !== null || action.end_date !== null) {
      return (
        <DueDate start_date={action.start_date} end_date={action.end_date} />
      );
    }
  };

  const renderCheckbox = () => {
    if (user && user !== null) {
      return (
        <input
          type="checkbox"
          checked={action.completedAt !== null}
          disabled={user.user_type_id < 3 || disabled}
          onChange={(e) => handleComplete(e.target.checked)}
        />
      );
    }
  };

  const renderEdit = () => {
    if (user && user !== null) {
      if (user.user_type_id > 3) {
        return (
          <button
            className="btn btn-sm btn-link text-primary me-2"
            onClick={handleEdit}
          >
            <i className="fa fa-edit"></i>
          </button>
        );
      }
    }
  };

  const renderDependences = () => {
    if (Array.isArray(action.dependences)) {
      return (
        <div className="mt-2">
          {action.dependences.map((dependence) => (
            <IconItem
              icon={
                dependence.completedAt === null ? "lock" : "check text-success"
              }
              name={dependence.name}
            />
          ))}
        </div>
      );
    }
  };

  const renderCompleted = () => {
    if (action.completedAt && action.completedAt !== null) {
      return (
        <span>
          <i className="fa fa-check text-success me-2"></i>
          {moment(action.completedAt).format("DD MMM YYYY HH:mm")}
        </span>
      );
    }
  };

  const renderDescription = () => {
    if (action.description && action.description !== null) {
      return parse(
        String(action.description).replace(/(?:\r\n|\r|\n)/g, "<br>")
      );
    }
  };

  return (
    <div className="card shadow-sm p-3 mb-3">
      <div className="row">
        <div className="col-8">
          <p className="mb-0 bold">{action.name}</p>
          <div className="small">{renderDescription()}</div>
          {renderCompleted()}
          {renderDependences()}
        </div>
        <div className="col-4 text-end">
          {renderEdit()}
          {renderCheckbox()}
        </div>
      </div>
      {renderDueDate()}
    </div>
  );
};

export default ActionCard;
