import { Link } from "@reach/router";
import React, { useContext } from "react";
import PaymentCard from "../payments/PaymentCard";
import { PaymentsContext } from "../../context/PaymentsContext";
import { ModalContext } from "../../context/ModalContext";
import PaymentForm from "../payments/PaymentForm";
import BottomAction from "../global/BottomAction";

const RequisitionPayments = ({
  requisition_id,
  project_id,
  payments,
  handleCallback,
}) => {
  const { createPayment, setPayment } = useContext(PaymentsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCancel = () => {
    clearModal();
    setPayment(null);
  };

  const handleCreatePayment = () => {
    createPayment();
    modalComponent(
      "Add Payment",
      <PaymentForm
        project_id={project_id}
        handleCancel={handleCancel}
        handleCallback={handleCallback}
        requisition_id={requisition_id}
      />
    );
  };

  const renderPayments = () => {
    if (Array.isArray(payments)) {
      if (payments.length === 0) {
        return <p>No payments recorded.</p>;
      }
      return payments.map((payment) => (
        <Link
          to={`/project/${project_id}/payment/${payment.payment_id}`}
          className="no-decoration text-black"
        >
          <PaymentCard
            key={payment.payment_id}
            payment={payment}
            project_id={project_id}
          />
        </Link>
      ));
    }
  };

  return (
    <div>
      {renderPayments()}
      <BottomAction onClick={handleCreatePayment} title="Record Payment" />
    </div>
  );
};

export default RequisitionPayments;
