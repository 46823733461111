import api from "./api";

const route = "/payments";

const PaymentsService = {
  getPaymentsInvoice: (project_id, invoice_id) =>
    api.get(`${route}/project/${project_id}/invoice/${invoice_id}`),
  getSinglePayment: (project_id, payment_id) =>
    api.get(`${route}/project/${project_id}/${payment_id}`),
  postPayment: (project_id, payment) =>
    api.post(`${route}/project/${project_id}`, { ...payment }),
  putPayment: (project_id, payment) =>
    api.put(`${route}/project/${project_id}`, { ...payment }),
};
export default PaymentsService;
