import React from "react";
import ActivitiesAccordion from "./ActivitiesAccordion";
import IconItem from "../global/IconItem";

const AccordionItem = ({ step }) => {
  const renderDependences = () => {
    if (Array.isArray(step.dependences)) {
      if (step.dependences.length > 0) {
        return (
          <div>
            <h4>Dependences</h4>
            {step.dependences.map((dependence) => (
              <IconItem
                icon="lock"
                key={dependence.name}
                name={dependence.name}
              />
            ))}
          </div>
        );
      }
    }
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target={`#step-${step.step_id}`}
          aria-controls={`step-${step.step_id}`}
        >
          {step.name}
        </button>
      </h2>
      <div
        id={`step-${step.step_id}`}
        className="accordion-collapse collapse"
        data-bs-parent="#steps"
      >
        <div className="accordion-body">
          <p>{step.description}</p>
          {renderDependences()}
          <ActivitiesAccordion activities={step.activities} />
        </div>
      </div>
    </div>
  );
};

const StepsAccordion = ({ steps }) => {
  const renderSteps = () => {
    if (Array.isArray(steps)) {
      if (steps.length === 0) {
        return <p className="my-3">No steps found for this process.</p>;
      }
      return steps.map((step) => (
        <AccordionItem key={step.step_id} step={step} />
      ));
    }
  };
  return (
    <div className="accordion" id="steps">
      {renderSteps()}
    </div>
  );
};

export default StepsAccordion;
