import {
  CREATE_PRODUCT,
  INPUTS_RECEIVED,
  PRODUCTS_RECEIVED,
  SET_PROPERTY_INPUT,
  SET_PROPERTY_PRODUCT,
  SET_SINGLE_INPUT,
  SET_SINGLE_PRODUCT,
} from "../types/products";

const schema = {
  product_id: "",
  name: "",
  SKU: "",
  description: "",
  unit: "",
  price: "",
  cost: "",
  currency: "USD",
};

const ProductsReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SINGLE_INPUT:
      return { ...state, input: payload };
    case INPUTS_RECEIVED:
      return { ...state, inputs: payload };
    case SET_PROPERTY_INPUT: {
      const { key, value } = payload;
      const input = { ...state.input };
      input[key] = value;
      return { ...state, input };
    }
    case PRODUCTS_RECEIVED:
      return { ...state, products: payload };
    case SET_SINGLE_PRODUCT:
      return { ...state, product: payload };
    case CREATE_PRODUCT:
      return { ...state, product: schema };
    case SET_PROPERTY_PRODUCT: {
      const { key, value } = payload;
      const product = { ...state.product };
      product[key] = value;
      return { ...state, product };
    }
    default:
      return { ...state };
  }
};
export default ProductsReducer;
