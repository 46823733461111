
import {
  SET_NOTIFICATION,
  CREATE_NOTIFICATION,
  NOTIFICATIONS_RECEIVED,
  SET_PROPERTY_NOTIFICATION,
} from "../types/notifications";

const schema = {

}

const notificationsReducer = (state, { type, payload }) => {
  switch (type) {
    case NOTIFICATIONS_RECEIVED:
      return { ...state, notifications: payload };
    case SET_NOTIFICATION:
      return { ...state, notification: payload };
    case CREATE_NOTIFICATION:
      return { ...state, notification: schema };
    case SET_PROPERTY_NOTIFICATION: {
      const { key, value } = payload;
      const notification = { ...state.notification };
      notification[key] = value;
      return { ...state, notification };
    }
    default:
      return { ...state};
  }
};

export default notificationsReducer;
