import api from "./api";
import { getArgs } from "../utils";

const route = "/invoices";

const InvoicesService = {
  getInvoices: () => api.get(route),
  getSingleInvoice: (project_id, invoice_id) =>
    api.get(`${route}/project/${project_id}/${invoice_id}`),
  getProjectInvoices: (project_id, filters) =>
    api.get(`${route}/project/${project_id}?${getArgs(filters)}`),
  postInvoice: (project_id, invoice) =>
    api.post(`${route}/project/${project_id}`, { ...invoice }),
  putInvoice: (project_id, invoice) =>
    api.put(`${route}/project/${project_id}`, { ...invoice }),
  deleteInvoice: (project_id, invoice_id) =>
    api.delete(`${route}/project/${project_id}/${invoice_id}`),
};

export default InvoicesService;
