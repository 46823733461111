import api from "./api";

const route = "/inventories";

const InventoryService = {
  getInventories: () => api.get(route),
  getSingleInventory: (inventory_id) => api.get(`${route}/${inventory_id}`),
  getInventoryReminders: (project_id) =>
    api.get(`${route}/notifications/all?project_id=${project_id}`),
  postInventory: (inventory) => api.post(route, { ...inventory }),
  postInventoryReminder: (reminder) =>
    api.post(`${route}/notifications`, { ...reminder }),
  putInventory: (inventory) => api.put(route, { ...inventory }),
  putInventoryReminder: (reminder) =>
    api.put(`${route}/notifications`, { ...reminder }),
  deleteInventory: (inventory_id) => api.delete(`${route}/${inventory_id}`),
  deleteInventoryReminder: (inventory_reminder_id) =>
    api.delete(`${route}/notifications/${inventory_reminder_id}`),
};
export default InventoryService;
