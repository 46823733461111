import React from "react";
import DotProgressBar from "../global/DotProgressbar";
import moment from "moment";
import { Link } from "@reach/router";
import StatusBadge from "../global/StatusBadge";

const OrderStatusCard = ({ project_id, order }) => {
  const renderEstimatedDate = () => {
    if (order && order !== null) {
      if (
        ["quoted", "ready"].includes(order.status) &&
        order.expiration_date !== null
      ) {
        return (
          <p className="mb-0 text-danger small">
            <i className="fa fa-calendar"></i>{" "}
            <span className="ms-1">
              {moment(order.expiration_date).format("MMM Do YYYY")}
            </span>
          </p>
        );
      }
      let { order_updates } = order;
      if (Array.isArray(order_updates)) {
        if (order_updates.length > 1) {
          order_updates = order_updates.sort((a, b) =>
            moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1
          );
        }
        let last_update = order_updates[0];
        if (last_update) {
          return (
            <p className="mb-0 text-primary small">
              <i className="fa fa-calendar"></i>{" "}
              <span className="ms-1">
                {moment(last_update.createdAt).format("DD MMM YYYY")}
              </span>
            </p>
          );
        }
      }
    }
  };

  const renderOrderNumber = () => {
    if (order && order !== null) {
      return order.order_id;
    }
  };

  const getProgressData = () => {
    if (order && order !== null) {
      const { order_updates } = order;
      if (Array.isArray(order_updates)) {
        let data = order_updates;
        if (data.length < 4) {
          const missing_updates = new Array(4 - data.length)
            .fill(1)
            .map(() => ({ progress: 0 }));
          data = [...data.map(() => ({ progress: 100 })), ...missing_updates];
        } else {
          data = data.map(() => ({ progress: 100 }));
        }
        return data;
      }
    }
  };

  return (
    <Link
      to={`/project/${order.project_id}/order/${order.order_id}`}
      className="card p-3 shadow-sm my-3 no-decoration text-dark"
    >
      <div className="row align-items-center">
        <div className="col-6 pe-0">
          <div className="row align-items-center">
            <div className="col-4 text-center">
              <div className="icon-wrapper bg-light border">
                <i className="fa fa-receipt fa-2x"></i>
              </div>
            </div>
            <div className="col-8">
              <h4 className="bold text-muted d-inline-block">
                Order #{renderOrderNumber()}
              </h4>
              <StatusBadge status={order.status} />
            </div>
          </div>
        </div>
        <div className="col-6 ps-0 text-end">
          <button className="btn px-0 py-0 mb-2 no-decoration">
            <i className="fa fa-ellipsis-h"></i>
          </button>
          {renderEstimatedDate()}
        </div>
      </div>
      <DotProgressBar data={getProgressData()} />
    </Link>
  );
};

export default OrderStatusCard;
