import React, { useContext, useEffect, useState } from "react";
import { CommentsContext } from "../../context/ComentsContext";
import { IonInput } from "@ionic/react";
import SingleComment from "./SingleComment";

const CommentFeed = ({
  step_id,
  order_id,
  project_id,
  payment_id,
  activity_id,
  requisition_id,
}) => {
  const [content, setContent] = useState("");
  const { comments, getComments, clearComments, postComment } =
    useContext(CommentsContext);

  useEffect(() => {
    fetchComments();
    return clearComments;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step_id, order_id, activity_id, payment_id, project_id, requisition_id]);

  const fetchComments = () => {
    getComments({ step_id, order_id, activity_id, project_id, payment_id, requisition_id });
  };

  const handleSubmitComment = (e) => {
    e.preventDefault();
    const payload = {
      requisition_id, 
      activity_id, 
      project_id, 
      payment_id, 
      order_id, 
      content, 
      step_id 
    }
    postComment(payload, fetchComments);
    setContent("");
  };

  const renderComments = () => {
    if (Array.isArray(comments)) {
      if (comments.length === 0) {
        return (
          <p className="px-3 mb-0">No comments available for this item.</p>
        );
      }
      return comments.map((comment) => (
        <SingleComment
          handleDeleteCallback={fetchComments}
          key={comment.comment_id}
          project_id={project_id}
          comment={comment}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };
  return (
    <div className="row bg-light">
      <div className="container-fluid">
        <div className="comment-feed py-3">{renderComments()}</div>
        <div className="comment-input bg-white border-top pb-5">
          <form onSubmit={handleSubmitComment} className="container px-0">
            <div className="row mx-0 align-items-center">
              <div className="col-8">
                <IonInput
                  type="text"
                  value={content}
                  className="form-control small border-0"
                  placeholder="Write a comment..."
                  onIonChange={({ detail }) => setContent(detail.value)}
                />
              </div>
              <div className="col-4 text-right">
                <button className="btn btn-clear p-2 d-inline-block me-4">
                  <i className="fa fa-plus text-primary" />
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-primary btn-sm d-inline-block"
                >
                  <i className="fa fa-paper-plane text-primary" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CommentFeed;
