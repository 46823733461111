import React, { useContext, useEffect, useState } from "react";
import { ActivityContext } from "../../context/ActivityContext";
import SelectUserType from "../global/SelectUserType";
import ReactSwitch from "react-switch";
import ActivityPicker from "./ActivityPicker";
import { ProjectsContext } from "../../context/ProjectsContext";
import moment from "moment";
import UserPicker from "../user/UserPicker";
import { UsersContext } from "../../context/UsersContext";
import StartEndDatePicker from "../global/StartEndDatePicker";
import { getValue } from "../../utils";

const ActivityForm = ({ step_id, handleCancel, handleDelete }) => {
  const [error, setError] = useState(null);
  const { project } = useContext(ProjectsContext);
  const { users, getProjectUsers } = useContext(UsersContext);
  const { activity, postActivity, setPropertyActivity } =
    useContext(ActivityContext);

  useEffect(() => {
    getProjectUsers(project.project_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activity.name === "") {
      return setError("Name is invalid.");
    }
    if (step_id && step_id !== null) {
      activity.step_id = step_id;
    }
    postActivity(activity);
  };

  const handleActivity = (currentActivity) => {
    let { dependences } = activity;
    if (!Array.isArray(dependences)) {
      setPropertyActivity("dependences", []);
    }
    dependences = activity.dependences;
    const index = dependences.findIndex(
      ({ activity_id }) => activity_id === currentActivity.activity_id
    );
    if (index === -1) {
      dependences.push(currentActivity);
    } else {
      dependences.splice(index, 1);
    }
    setPropertyActivity("dependences", dependences);
  };

  const renderCompletedAt = () => {
    if (activity.status === "completed") {
      return (
        <div className="container px-0">
          <label>Completed At</label>
          <input
            type="date"
            className="form-control mb-3"
            value={
              activity.completedAt !== null
                ? moment(activity.completedAt).utc().format("YYYY-MM-DD")
                : ""
            }
            onChange={(e) => setPropertyActivity("completedAt", e.target.value)}
          />
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error !== null ? <h4 className="text-danger">{error}</h4> : ""}
      <label>Name</label>
      <input
        type="text"
        value={getValue(activity, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyActivity("name", e.target.value)}
      />
      <label>Description</label>
      <textarea
        rows="3"
        value={getValue(activity, "description")}
        className="form-control mb-3"
        onChange={(e) => setPropertyActivity("description", e.target.value)}
      />
      <StartEndDatePicker
        end_date={activity.end_date}
        start_date={activity.start_date}
        modifier={setPropertyActivity}
      />
      <div className="container px-0">
        <label>Started At</label>
        <input
          type="date"
          className="form-control mb-3"
          value={
            activity.startedAt !== null
              ? moment(activity.startedAt).utc().format("YYYY-MM-DD")
              : ""
          }
          onChange={(e) => setPropertyActivity("startedAt", e.target.value)}
        />
      </div>
      {renderCompletedAt()}
      <SelectUserType
        value={activity.minimum_level}
        modifier={(minimum_level) =>
          setPropertyActivity("minimum_level", minimum_level)
        }
      />
      <label className="d-flex align-items-center mb-3">
        <ReactSwitch
          className="me-2"
          checked={activity.critical}
          onChange={(checked) => setPropertyActivity("critical", checked)}
        />
        This is a critical activity.
      </label>
      <ActivityPicker
        project_id={project.project_id}
        modifier={handleActivity}
        selected={activity.dependences}
      />
      <UserPicker
        users={users}
        label="Responsible"
        value={activity.user_id}
        project_id={project.project_id}
        modifier={(user_id) => {
          setPropertyActivity("user_id", user_id);
        }}
      />
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 mt-3"
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={handleDelete}
        className="btn text-danger w-100"
      >
        Delete Activity
      </button>
    </form>
  );
};

export default ActivityForm;
