import React, { useState, useContext, useEffect } from "react";
import { PaymentsContext } from "../../context/PaymentsContext";
import { getValue } from "../../utils";
import { BankAccountsContext } from "../../context/BankAccountsContext";
import { Link } from "@reach/router";
import { ModalContext } from "../../context/ModalContext";
import ReactSwitch from "react-switch";

const PaymentForm = ({
  total,
  invoice,
  project_id,
  invoice_id,
  handleCancel,
  requisition_id,
  handleCallback,
}) => {
  const [percent, setPercent] = useState("");
  const [isPercent, setIsPercent] = useState(false);

  const { clearModal } = useContext(ModalContext);
  const { bank_accounts, getBankAccounts } = useContext(BankAccountsContext);
  const { payment, savePayment, setPropertyPayment } =
    useContext(PaymentsContext);

  useEffect(() => {
    getBankAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (percent > 100 || percent === "") {
      setPercent(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]);

  useEffect(() => {
    if (isPercent && percent > 0) {
      setPropertyPayment(
        "amount",
        ((parseFloat(percent) / 100) * parseFloat(total)).toFixed(2)
      );
      if (invoice && invoice !== null) {
        setPropertyPayment("currency", invoice.currency);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPercent, percent]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (invoice_id && invoice_id !== null) {
      payment.invoice_id = invoice_id;
    }
    if (requisition_id && requisition_id !== null) {
      payment.requisition_id = requisition_id;
    }
    savePayment(project_id, payment, handleCallback);
  };

  const renderBankAccounts = () => {
    if (Array.isArray(bank_accounts) && !requisition_id) {
      return (
        <div>
          <div className="row">
            <div className="col-6">
              <label>Account</label>
            </div>
            <div className="col-6 text-end">
              <Link to={`/banks`} onClick={clearModal} className="text-accent">
                View Details
              </Link>
            </div>
          </div>
          <select
            className="form-control mb-3"
            value={getValue(payment, "bank_account_id")}
            onChange={(e) =>
              setPropertyPayment("bank_account_id", e.target.value)
            }
          >
            {bank_accounts.map((bank_account) => (
              <option
                key={bank_account.bank_account_id}
                value={bank_account.bank_account_id}
              >
                {bank_account.currency} - {bank_account.name}
              </option>
            ))}
          </select>
        </div>
      );
    } else {
      return (
        <div>
          <label>Currency</label>
          <select
            className="form-control mb-3"
            value={getValue(payment, "currency")}
            onChange={(e) => setPropertyPayment("currency", e.target.value)}
          >
            <option>MXN</option>
            <option>USD</option>
            <option>GBP</option>
            <option>EUR</option>
          </select>
        </div>
      );
    }
  };

  const renderPercent = () => {
    if (isPercent) {
      return (
        <div>
          <label>Percentage %</label>
          <input
            step=".01"
            type="number"
            value={percent}
            className="form-control mb-3"
            onChange={(e) => setPercent(e.target.value)}
          />
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {total && total !== null && (
        <label className="d-flex align-items-center mb-3">
          <ReactSwitch checked={isPercent} onChange={setIsPercent} />
          <span className="ms-2">Set on Percentage </span>
        </label>
      )}
      {renderPercent()}
      <label>Amount</label>
      <input
        type="number"
        step=".01"
        disabled={isPercent}
        value={getValue(payment, "amount")}
        className="form-control mb-3"
        onChange={(e) => setPropertyPayment("amount", e.target.value)}
      />
      <label>Currency</label>
      <select
        disabled={isPercent}
        className="form-control mb-3"
        value={getValue(payment, "currency")}
        onChange={(e) => setPropertyPayment("currency", e.target.value)}
      >
        <option>MXN</option>
        <option>USD</option>
        <option>GBP</option>
        <option>EUR</option>
      </select>
      <label>Paid At</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(payment, "recordedAt")}
        onChange={(e) => setPropertyPayment("recordedAt", e.target.value)}
      />
      <label>Evidence</label>
      <input
        type="file"
        className="form-control mb-3"
        onChange={(e) => setPropertyPayment("file", e.target.files[0])}
      />
      <button
        type="submit"
        disabled={isNaN(getValue(payment, "amount"))}
        className="btn btn-primary w-100"
      >
        Record Payment
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn btn-link mt-3 text-muted w-100"
      >
        Cancel
      </button>
    </form>
  );
};

export default PaymentForm;
