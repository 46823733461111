import React, { useState, useContext } from "react";
import { formatMonto } from "../../utils";
import AttachmentCard from "../attachments/AttachmentCard";
import ProductItem from "../products/ProductItem";
import StatusBadge from "../global/StatusBadge";
import { ModalContext } from "../../context/ModalContext";
import AttachmentForm from "../attachments/AttachmentForm";
import { RequisitionsContext } from "../../context/RequisitionsContext";

const RequisitionDetails = ({ requisition, handleCallback }) => {
  const [editMode, setEditMode] = useState(false);
  const [received, setReceived] = useState(null);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { saveRequisition, saveRequisitionFile } =
    useContext(RequisitionsContext);

  const handleSave = (e) => {
    e.preventDefault();
    setEditMode(false);
    saveRequisition(
      { ...requisition, received_amount: received },
      handleCallback
    );
  };

  const handleCancel = () => {
    setEditMode(false);
    setReceived(null);
  };

  const handleCreate = () => {
    modalComponent(
      "Add Attachment",
      <AttachmentForm
        handleCancel={clearModal}
        attachment={{ requisition_id: requisition.requisition_id }}
        handleSubmit={(file) => saveRequisitionFile(file, handleCallback)}
      />
    );
  };

  const renderButton = () => {
    if (!editMode)
      return (
        <div>
          {requisition.received_amount}
          <button
            onClick={() => setEditMode(true)}
            className="btn btn-sm text-primary"
          >
            <i className="fa fa-edit"></i>
          </button>
        </div>
      );
    return (
      <form onSubmit={handleSave}>
        <input
          type="number"
          value={received}
          onChange={(e) => setReceived(e.target.value)}
          className="form-control p-1 py-1 d-inline-block w-50"
        />
        <button type="submit" className="btn text-primary me-2">
          <i className="fa fa-save"></i>
        </button>
        <button
          type="button"
          onClick={handleCancel}
          className="btn btn-sm text-danger"
        >
          <i className="fa fa-times"></i>
        </button>
      </form>
    );
  };

  const renderFiles = () => {
    if (Array.isArray(requisition.files)) {
      if (requisition.files.length === 0) {
        return <p>No files available.</p>;
      }
      return requisition.files.map((attachment) => (
        <AttachmentCard key={attachment.file_id} attachment={attachment} />
      ));
    }
  };

  return (
    <div>
      {requisition.product_id !== null && (
        <div className="mb-3">
          <ProductItem
            product={requisition.product}
            disablePicker
            hideButtons
            hidePrice
          />
        </div>
      )}
      <div className="card shadow-sm mb-3 pt-2">
        <div className="row border-bottom py-2">
          <div className="col-9 col-lg-10">
            <p className="bold mb-0">Status</p>
          </div>
          <div className="col-3 col-lg-2 text-end">
            <StatusBadge status={requisition.status} />
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Units</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">{formatMonto(requisition.product_amount)}</p>
          </div>
        </div>
        <div className="row align-items-center border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Received</p>
          </div>
          <div className="col-6 text-end">{renderButton()}</div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Unit Price</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">${formatMonto(requisition.product_price)}</p>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Currency</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">{requisition.currency}</p>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Total</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">
              $
              {formatMonto(
                (
                  parseInt(requisition.product_amount) *
                  parseFloat(requisition.product_price)
                ).toFixed(2)
              )}{" "}
              {requisition.currency}
            </p>
          </div>
        </div>
        <div className="row border-bottom py-2">
          <div className="col-6">
            <p className="bold mb-0">Total Limit (+10%)</p>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">
              $
              {formatMonto(
                (
                  parseInt(requisition.product_amount) *
                  parseFloat(requisition.product_price) *
                  1.1
                ).toFixed(2)
              )}{" "}
              {requisition.currency}
            </p>
          </div>
        </div>
      </div>
      <div className="row border-bottom pb-2 mb-2 mx-0">
        <div className="col-8 px-0">
          <h2 className="mb-0">Attachments</h2>
        </div>
        <div className="col-4 px-0 text-end">
          <button onClick={handleCreate} className="btn btn-sm btn-primary">
            + Add
          </button>
        </div>
      </div>
      {renderFiles()}
    </div>
  );
};

export default RequisitionDetails;
