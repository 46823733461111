import React, { useState, useEffect, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import ClientForm from "./ClientForm";
import { ClientsContext } from "../../context/ClientsContext";
import { clientDataComplete, getValue } from "../../utils";

const ClientData = ({ client, handleCallback }) => {
  const [error, setError] = useState("");
  const { setClient } = useContext(ClientsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    const missing_fields = clientDataComplete({ client });
    if (missing_fields.length > 0) {
      setError(`You're missing ${missing_fields.join(", ")}.`);
    } else {
      setError("");
    }
  }, [client]);

  const handleEdit = () => {
    setClient(client);
    modalComponent(
      "Edit Client",
      <ClientForm handleCancel={clearModal} handleCallback={handleCallback} />
    );
  };

  const renderError = () => {
    if (error && error !== null) {
      return <p className="px-0 mb-2 text-danger">{error}</p>;
    }
  };

  return (
    <div className="card p-3 shadow mb-3">
      <div className="row mx-0 border-bottom mb-2 pb-2">
        <div className="col-10 px-0">
          <h2>Client</h2>
        </div>
        <div className="col-2 text-end">
          <button onClick={handleEdit} className="btn btn-sm text-primary">
            <i className="fa fa-edit"></i>
          </button>
        </div>
        {renderError()}
      </div>
      <div className="container-fluid">
        <div className="row pb-2 mb-2 border-bottom">
          <div className="col-8 px-0">Name:</div>
          <div className="col-4">{getValue(client, "name")}</div>
        </div>
        <div className="row pb-2 mb-2 border-bottom">
          <div className="col-8 px-0">Email:</div>
          <div className="col-4">{getValue(client, "email")}</div>
        </div>
        <div className="row pb-2 mb-2 border-bottom">
          <div className="col-8 px-0">Phone:</div>
          <div className="col-4">{getValue(client, "phone")}</div>
        </div>
        <div className="row pb-2 mb-2 border-bottom">
          <div className="col-8 px-0">TAX ID:</div>
          <div className="col-4">{getValue(client, "tax_id")}</div>
        </div>
        <div className="row pb-2 mb-2 border-bottom">
          <div className="col-8 px-0">Country:</div>
          <div className="col-4">{getValue(client, "country")}</div>
        </div>
      </div>
    </div>
  );
};
export default ClientData;
