import React, { useState, useContext, useEffect } from "react";
import { RequisitionsContext } from "../../context/RequisitionsContext";
import { OrdersContext } from "../../context/OrdersContext";
import ProductPicker from "../products/ProductPicker";
import { useIonAlert } from "@ionic/react";
import { getValue } from "../../utils";

const RequisitionForm = ({
  project_id,
  handleCancel,
  handleDelete,
  handleCallback,
  supplier_order_id,
}) => {
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isPercent, setIsPercent] = useState(false);
  const [percent, setPercent] = useState(100);
  const { order } = useContext(OrdersContext);
  const [presentAlert] = useIonAlert();

  const { requisition, saveRequisition, setPropertyRequisition } =
    useContext(RequisitionsContext);

  useEffect(() => {
    if (percent > 100) {
      setPercent(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]);

  useEffect(() => {
    if (isPercent && percent > 0) {
      setPropertyRequisition(
        "amount",
        ((parseFloat(percent) / 100) * parseFloat(order.amount)).toFixed(2)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPercent, percent]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (supplier_order_id && supplier_order_id !== null) {
      requisition.supplier_order_id = supplier_order_id;
    }
    if (project_id && project_id !== null) {
      requisition.project_id = project_id;
    }
    requisition.amount =
      parseFloat(requisition.product_price) *
      parseInt(requisition.product_amount);
    saveRequisition(requisition, handleCallback);
  };

  const handleAlert = () => {
    presentAlert({
      header: "Alert!",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Confirm",
          role: "confirm",
          handler: () => {
            handleDelete(requisition);
          },
        },
      ],
    });
  };

  return (
    <form onSubmit={handleSubmit} className="container px-0">
      <ProductPicker
        selected={currentProduct}
        modifier={(product) => {
          setCurrentProduct(product);
          setPropertyRequisition("product", product);
          setPropertyRequisition("product_id", product.product_id);
        }}
      />
      <label>Unit Price</label>
      <input
        type="number"
        className="form-control mb-3"
        value={getValue(requisition, "product_price")}
        onChange={(e) =>
          setPropertyRequisition("product_price", e.target.value)
        }
      />
      <label>Product Amount</label>
      <input
        type="number"
        className="form-control mb-3"
        value={getValue(requisition, "product_amount")}
        onChange={(e) =>
          setPropertyRequisition("product_amount", e.target.value)
        }
      />
      <label>Currency</label>
      <select
        className="form-control mb-3"
        value={getValue(requisition, "currency")}
        onChange={(e) => setPropertyRequisition("currency", e.target.value)}
      >
        <option>MXN</option>
        <option>USD</option>
        <option>GBP</option>
        <option>EUR</option>
      </select>
      <label>Status</label>
      <select
        value={getValue(requisition, "status")}
        className="form-control mb-3"
        onChange={(e) => setPropertyRequisition("status", e.target.value)}
      >
        <option value="pending">Pending</option>
        <option value="completed">Completed</option>
        <option value="cancelled">Cancelled</option>
        <option value="refunded">Cancelled</option>
      </select>
      <label>Due Date</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(requisition, "max_date")}
        onChange={(e) => setPropertyRequisition("max_date", e.target.value)}
      />
      <button className="btn btn-primary mb-4 w-100">Save</button>
      <button
        className="btn text-accent h6 text-center d-block w-100"
        onClick={handleCancel}
        type="button"
      >
        Cancel
      </button>
      {requisition.requisition_id !== "" && (
        <button
          type="button"
          className="btn mt-5 text-danger h6 text-center d-block w-100"
          onClick={handleAlert}
        >
          Delete Requisition
        </button>
      )}
    </form>
  );
};

export default RequisitionForm;
