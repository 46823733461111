import React from "react";
import { useContext } from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import IconItem from "../global/IconItem";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ProductsContext } from "../../context/ProductsContext";
import ProductForm from "../products/ProductForm";

const ProductItem = ({ product, showPrice, project_id, hideButtons }) => {
  const { user } = useContext(AuthContext);
  const { setProduct } = useContext(ProductsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleEdit = (product) => {
    setProduct({ ...product, ...product.product_prices[0] });
    modalComponent(
      "Edit Product",
      <ProductForm project_id={project_id} handleCancel={clearModal} />
    );
  };

  const renderCost = () => {
    if (product.cost !== null) {
      return (
        <span className="small mb-0">
          - ${formatMonto(product.cost)} {product.currency}
        </span>
      );
    }
  };

  const renderEdit = () => {
    if (user.user_type_id > 3 && !hideButtons) {
      return (
        <button
          className="btn btn-sm text-accent"
          onClick={() => handleEdit(product)}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  const renderPrice = () => {
    if (user.user_type_id > 3 || showPrice) {
      return (
        <p className="mb-0">
          ${formatMonto(product.price)} {product.currency}
          {renderCost()}
        </p>
      );
    }
  };

  const renderView = () => {
    if (
      !window.location.pathname.includes("product/") &&
      user.user_type_id > 3
    ) {
      return (
        <Link
          to={`/project/${project_id}/product/${product.product_id}`}
          className="btn btn-sm text-primary"
        >
          <i className="fa fa-eye"></i>
        </Link>
      );
    }
  };

  return (
    <div className="card px-3 py-2 mb-2 shadow-sm border-0">
      <div className="row align-items-center">
        <div className="col-10">
          <IconItem name={product.name}>
            <div className="row mt-2">
              <div className="col-6">
                <p className="bold small">Stock</p>
                <p className="mb-0">{product.stock}</p>
              </div>
              <div className="col-6">
                <p className="bold small">Pending</p>
                <p
                  className={`mb-0 ${product.pending > 0 ? "text-danger" : ""}`}
                >
                  {product.pending === null ? 0 : product.pending}
                </p>
              </div>
            </div>
          </IconItem>
        </div>
        <div className="col-2">
          {renderEdit()}
          {renderView()}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
