import React, { useContext, useState } from "react";
import { ProjectTemplatesContext } from "../../context/ProjectTemplatesContext";

const ConfirmTemplate = ({ project, handleCancel }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const { postProjectTemplate } = useContext(ProjectTemplatesContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postProjectTemplate(project.project_id, name);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>
        Confirm you want to save {project.name}'s structure as a template for
        future projects
      </p>
      <label>Template Name</label>
      <input
        type="text"
        value={name}
        className="form-control mb-3"
        onChange={(e) => setName(e.target.value)}
      />
      <label>Description</label>
      <textarea
        rows="3"
        value={description}
        className="form-control mb-3"
        onChange={(e) => setDescription(e.target.value)}
      />
      <button type="submit" className="btn btn-primary w-100">
        Save
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 mt-3"
      >
        Cancel
      </button>
    </form>
  );
};

export default ConfirmTemplate;
