import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import CheckoutReducer from "../reducers/CheckoutReducer";
import CheckoutService from "../services/CheckoutService";
import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_DESCUENTO,
  SET_DISCOUNT_CODE,
  SET_PAYMENT_SOURCE,
} from "../types";
import { PAYPAL_URL } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  paquete: null,
  discountCode: "",
  product: null,
  payment_source_id: null,
};

export const CheckoutContext = createContext(initialState);

export const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CheckoutReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const createOrder = (product_id, payment_source_id, discountCode) => {
    dispatch({ type: SHOW_SPINNER });
    CheckoutService.postCheckout(product_id, payment_source_id, discountCode)
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        handleCheckoutSuccess(res);
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        handleCheckoutError(error);
      });
  };

  const handleCheckoutSuccess = (res) => {
    const { purchase_id } = res.data;
    navigate(`/thankyou/${purchase_id}`);
  };

  const handleCheckoutError = (error) => {
    let message;
    if (error.response) {
      const { status } = error.response;
      if (status === 400) {
        message = "No has seleccionado un médoto de pago.";
      }
      if (status === 412) {
        message =
          "Lo sentimos, se ha alcanzado el límite de personas para este paquete.";
      } else if (status === 409) {
        message =
          "Lo sentimos, ya has alcanzado el límite de compras de este paquete.";
      } else {
        message =
          "Lo sentimos. Nuestro procesador de pagos ha rechazado tu tarjeta. Puedes pagar por PayPal o Transferencia/Depósito.";
      }
      return alert(message);
    }
  };

  const setPaymentSource = (payment_source_id) => {
    dispatch({ type: SET_PAYMENT_SOURCE, payload: payment_source_id });
  };

  const setPayPal = (class_package_id, discountCode) => {
    const paypalButton = document.getElementById("paypal-button");
    if (paypalButton.innerHTML !== "") {
      paypalButton.innerHTML = "";
    }
    if (document.getElementById("paypal-checkout") === null) {
      const script = document.createElement("script");
      script.src = "https://www.paypalobjects.com/api/checkout.js";
      script.id = "paypal-checkout";
      document.body.appendChild(script);

      script.onload = (result) => {
        window.paypal.Button.render(
          {
            env: "production",
            payment: (data, actions) => {
              return CheckoutService.postPayPal(class_package_id, discountCode)
                .then((res) => {
                  return res.data.orderID;
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.status === 412) {
                      return alert(
                        "Lo sentimos, se ha alcanzado el límite de personas para este paquete."
                      );
                    } else if (error.response.status === 409) {
                      return alert(
                        "Lo sentimos, ya has alcanzado el límite de compras de este paquete."
                      );
                    }
                  }
                });
            },
            onApprove: (data, actions) => {
              return CheckoutService.capturePayPal(data).then(function (res) {
                const { purchase_id } = res.data;
                success("¡Pago exitoso!");
                const paypalCheckout =
                  document.getElementById("paypal-checkout");
                if (paypalCheckout !== null) {
                  paypalCheckout.remove();
                }
                paypalButton.innerHTML = "";
                navigate(`/gracias/${purchase_id}`);
              });
            },
          },
          "#paypal-button"
        );
      };
    }
  };

  const showSpinner = () => {
    dispatch({ type: SHOW_SPINNER });
  };

  const hideSpinner = () => {
    dispatch({ type: HIDE_SPINNER });
  };

  const setDiscountCode = (code) => {
    dispatch({ type: SET_DISCOUNT_CODE, payload: code });
  };

  const setDescuento = (descuento) => {
    dispatch({ type: SET_DESCUENTO, payload: descuento });
  };

  const setPayPalSubscription = (class_package_id, discountCode) => {
    const script = document.createElement("script");
    script.src = PAYPAL_URL;
    script.id = "paypal-subscription";
    document.body.appendChild(script);
    script.onload = (result) => {
      const paypalButton = document.getElementById("paypal-button");
      if (paypalButton.innerHTML !== "") {
        paypalButton.innerHTML = "";
      }
      window.paypal
        .Buttons({
          createSubscription: function (data, actions) {
            return CheckoutService.postPayPal(
              class_package_id,
              discountCode
            ).then((res) => {
              const { plan_id } = res.data;
              return actions.subscription.create({
                plan_id,
              });
            });
          },
          onApprove: function (data, actions) {
            return CheckoutService.capturePayPal({ ...data }).then((res) => {
              const { purchase_id } = res.data;
              success("¡Pago exitoso!");
              const paypalSubscription = document.getElementById(
                "paypal-subscription"
              );
              if (paypalSubscription !== null) {
                paypalSubscription.remove();
              }
              paypalButton.innerHTML = "";
              navigate(`/gracias/${purchase_id}`);
            });
          },
          onError: function (err) {
            console.log(err);
            console.log(JSON.stringify(err));
          },
        })
        .render("#paypal-button");
    };
  };

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        setPayPal,
        createOrder,
        showSpinner,
        hideSpinner,
        setDescuento,
        setDiscountCode,
        setPaymentSource,
        setPayPalSubscription,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
