import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  ACTIONS_RECEIVED,
  CREATE_ACTION,
  SET_PROPERTY_ACTION,
  SET_SINGLE_ACTION,
  TOGGLE_ACTION_DEPENDENCE,
} from "../types/actions";

const schema = {
  action_id: "",
  name: "",
};

const ActionsReducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_ACTION_DEPENDENCE:
      let dependences = state.action.dependences;
      if (!Array.isArray(dependences)) {
        dependences = [];
      }
      dependences = [...dependences];
      const index = dependences.findIndex(
        ({ action_id }) => action_id === payload.action_id
      );
      if (index === -1) {
        dependences.push(payload);
      } else {
        dependences.splice(index, 1);
      }
      return { ...state, action: { ...state.action, dependences } };
    case SET_SINGLE_ACTION:
      return { ...state, action: payload };
    case ACTIONS_RECEIVED:
      return { ...state, actions: payload };
    case SET_PROPERTY_ACTION:
      const action = { ...state.action };
      const { key, value } = payload;
      action[key] = value;
      return { ...state, action };
    case CREATE_ACTION:
      return { ...state, action: schema };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default ActionsReducer;
