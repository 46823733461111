import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Pagination from "./Pagination";

const PDFViewer = ({ src }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Document
      file={src}
      className="mw-100 w-100"
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page className="w-100 mw-100" pageNumber={pageNumber} />
      <Pagination pages={numPages} modifier={setPageNumber} />
    </Document>
  );
};

export default PDFViewer;
