import React, { useContext, useEffect } from "react";
import Cart from "./Cart";
import Admin from "./Admin";
import Orders from "./Orders";
import Profile from "./Profile";
import Invoices from "./Invoices";
import Products from "./Products";
import Analytics from "./Analytics";
import { Router } from "@reach/router";
import SingleOrder from "./SingleOrder";
import EditProfile from "./EditProfile";
import BankAccounts from "./BankAccounts";
import ImportProject from "./ImportProject";
import SingleProject from "./SingleProject";
import SelectProject from "./SelectProject";
import InventoryList from "./InventoryList";
import Navbar from "../components/navbar/Navbar";
import ProjectTemplates from "./ProjectTemplates";
import { AuthContext } from "../context/AuthContext";
import SingleProjectTemplate from "./SingleProjectTemplate";
import Notifications from "./Notifications";
import { hasNotch } from "../utils";
import { CapacitorContext } from "../context/CapacitorContext";
import { UserDevicesContext } from "../context/UserDevicesContext";
import { ModalContext } from "../context/ModalContext";
import DeviceForm from "../components/devices/DeviceForm";
import { findUserDeviceIndexByToken } from "../utils/user_devices";
import { IonContent } from "@ionic/react";

const Panel = () => {
  const { user } = useContext(AuthContext);
  const { modalComponent } = useContext(ModalContext);
  const { device, platform, device_token, registerNotifications } =
    useContext(CapacitorContext);
  const { user_devices, setUserDevice, getUserDevices } =
    useContext(UserDevicesContext);

  useEffect(() => {
    getUserDevices();
  }, []);

  useEffect(() => {
    registerNotifications(platform);
  }, [platform]);

  useEffect(() => {
    if (Array.isArray(user_devices) && device_token !== null) {
      handleUserDevice(user_devices, device_token);
    }
  }, [device_token, user_devices]);

  const handleEditDevice = (current_device) => {
    setUserDevice(current_device);
    modalComponent("Register Device", <DeviceForm />);
  };

  const handleUserDevice = (user_devices, device_token) => {
    const device_index = findUserDeviceIndexByToken(user_devices, device_token);
    if (device_index === -1) {
      const current_device = user_devices[device_index];
      handleEditDevice(current_device);
    }
  };

  return (
    <div className="content">
      <Navbar path="/*" />
      <IonContent>
        <div
          className={`main-panel px-1 px-lg-4 ${
            hasNotch(device) ? "pt-notch" : "pt-5"
          }`}
        >
          <Router>
            <Cart path="/cart" />
            <Orders path="/orders" />
            <SelectProject path="/" />
            <Profile path="/profile" />
            <Products path="/products" />
            <Invoices path="/invoices" />
            <BankAccounts path="/banks" />
            <ImportProject path="/import" />
            <InventoryList path="/inventory" />
            <EditProfile path="/profile/edit" />
            <ProjectTemplates path="/templates" />
            <SingleOrder path="/order/:order_id" />
            <Notifications path="/notifications" />
            <SingleProject path="/project/:project_id/*" />
            <SingleProjectTemplate path="/template/:project_template_id" />
            {user !== null && user.user_type_id > 3 && (
              <Admin path="/admin/*" />
            )}
            {user !== null && user.user_type_id > 4 && (
              <Analytics path="/analytics/*" />
            )}
          </Router>
        </div>
      </IonContent>
    </div>
  );
};

export default Panel;
