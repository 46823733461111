import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../utils";
import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { spinner, signIn } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  return (
    <div className="container-fluid mt-3 py-5">
      <img
        style={{ maxWidth: 200 }}
        src={`${S3_ENDPOINT}/agavebrands-logo.png`}
        className="m-auto d-block mb-5 hide-mobile"
      />
      <form id="auth-form" className="card shadow" onSubmit={handleSubmit}>
        <label className="d-block mb-2">Email</label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="email-addon">
            <i className="fa fa-envelope text-primary"></i>
          </span>
          <input
            type="text"
            placeholder="Email"
            className="form-control"
            autoCapitalize="none"
            alia-aria-describedby="email-addon"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <label className="d-block mb-2">Password</label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="password-addon">
            <i className="fa fa-lock text-primary"></i>
          </span>
          <input
            className="form-control"
            placeholder="Password"
            alia-aria-describedby="password-addon"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="input-group-text">
            <button
              type="button"
              className="btn px-0 py-0"
              onClick={() => setShowPassword(!showPassword)}
            >
              <i className={`fas fa-eye${showPassword ? "" : "-slash"}`}></i>
            </button>
          </span>
        </div>
        <div className="container-fluid px-0 my-4">
          <button
            disabled={spinner}
            className="btn btn-primary w-100 d-block m-auto my-4"
          >
            {spinner ? <div className="spinner-border"></div> : "Login"}
          </button>
        </div>
        <div className="container-fluid px-0 my-4">
          <Link
            to="/recovery"
            className="text-accent h6 text-center d-block w-100"
          >
            Fogot password?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
