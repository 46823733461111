import React from "react";

const DeleteConfirm = ({
  item,
  spinner,
  itemName,
  description,
  handleDelete,
}) => {
  return (
    <div>
      <p>
        Are you sure you want to delete the {itemName} {item.name}?
      </p>
      {description && <p>{description}</p>}
      <p>
        This action <b>cannot be unCompleted.</b>
      </p>
      <button
        disabled={spinner}
        onClick={() => handleDelete(item)}
        className="btn btn-danger text-capitalize w-100 my-3"
      >
        <i className="fa fa-trash me-2"></i>
        {spinner ? (
          <div className="spinner-border"></div>
        ) : (
          `Delete ${itemName}`
        )}
      </button>
    </div>
  );
};

export default DeleteConfirm;
