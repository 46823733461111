import { Link } from "@reach/router";
import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";

const Recovery = () => {
  const [email, setEmail] = useState("");

  const { recoverPassword } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    recoverPassword(email);
  };
  return (
    <div className="container-fluid mt-5 py-5">
      <form onSubmit={handleSubmit}>
        <label className="d-block mb-2">Email</label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="email-addon">
            <i className="fa fa-envelope text-primary"></i>
          </span>
          <input
            type="text"
            placeholder="Email"
            className="form-control"
            alia-aria-describedby="email-addon"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="container-fluid px-0 my-4">
          <button className="btn btn-primary w-100 d-block m-auto my-4">
            Send Reset Link
          </button>
        </div>
        <div className="container-fluid px-0 my-4">
          <Link
            to="/recovery"
            className="text-accent h6 text-center d-block w-100"
          >
            Fogot password?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Recovery;
