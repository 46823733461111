import React from "react";

const AttachmentConfirmDelete = ({
  activity,
  attachment,
  deleteActivityFile,
}) => {
  return (
    <div>
      <p>
        Are you sure you want to delete the attachment "{attachment.name}"? This
        action CANNOT be undone.
      </p>
      <div className="row">
        <div className="col-6">
          <button className="btn text-muted w-100">Cancel</button>
        </div>
        <div className="col-6">
          <button
            onClick={() =>
              deleteActivityFile(
                activity.activity_id,
                attachment.activity_file_id
              )
            }
            className="btn btn-danger w-100"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttachmentConfirmDelete;
