import React, { useState, useContext, useEffect } from "react";
import { UsersContext } from "../../context/UsersContext";
import { ProjectsContext } from "../../context/ProjectsContext";
import SelectUserType from "../global/SelectUserType";
import UserPicker from "./UserPicker";

const UserProjectPicker = ({ project_id, handleCancel }) => {
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const { users, getUsers } = useContext(UsersContext);
  const { saveUserProject } = useContext(ProjectsContext);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentUser = { user_id: user };
    if (project_id && project_id !== null) {
      currentUser.project_id = project_id;
    }
    currentUser.user_type_id = userType;
    saveUserProject(currentUser);
  };

  return (
    <form onSubmit={handleSubmit}>
      <UserPicker users={users} value={user} modifier={setUser} />
      <SelectUserType
        title="Permissions"
        value={userType}
        modifier={setUserType}
      />
      <button type="submit" className="btn btn-primary w-100 my-3">
        Save
      </button>
      <button
        className="btn btn-link text-secondary w-100"
        onClick={handleCancel}
      >
        Cancel
      </button>
    </form>
  );
};

export default UserProjectPicker;
