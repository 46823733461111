import React, { useContext } from "react";
import { CartContext } from "../../context/CartContext";
import { OrdersContext } from "../../context/OrdersContext";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import OrderForm from "./OrderForm";
import BottomAction from "../global/BottomAction";

const OrderButton = ({ project_id, disabled }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { saveOrder, createOrder } = useContext(OrdersContext);
  const { products, clearCart } = useContext(CartContext);
  const { user } = useContext(AuthContext);

  const handleCreateOrder = () => {
    if (user.user_type < 3) {
      saveOrder(project_id, { products });
      return clearCart();
    }
    createOrder();
    modalComponent(
      "Create Order",
      <OrderForm project_id={project_id} handleCancel={clearModal} />
    );
  };

  const renderButton = () => {
    if (products.length > 0) {
      return (
        <BottomAction
          disabled={disabled}
          onClick={handleCreateOrder}
          title="Create Order"
        />
      );
    }
  };

  return <div>{renderButton()}</div>;
};

export default OrderButton;
