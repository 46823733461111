import React, { createContext, useReducer, useContext } from "react";
import RequisitionsService from "../services/RequisitionsService";
import RequisitionsReducer from "../reducers/RequisitionsReducer";
import {
  REQUISITIONS_RECEIVED,
  SET_REQUISITION,
  CREATE_REQUISITION,
  SET_PROPERTY_REQUISITION,
} from "../types/requisitions";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import FilesService from "../services/FilesService";

const initialState = {
  requisitions: null,
  requisition: null,
};

export const RequisitionsContext = createContext(initialState);

export const RequisitionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RequisitionsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getRequisitions = () => {
    dispatch({ type: SET_REQUISITION, payload: null });
    RequisitionsService.getRequisitions()
      .then((response) => {
        const { requisitions } = response.data;
        dispatch({ type: REQUISITIONS_RECEIVED, payload: requisitions });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleRequisition = (requisition_id) => {
    RequisitionsService.getSingleRequisition(requisition_id)
      .then((response) => {
        const { requisition } = response.data;
        dispatch({ type: SET_REQUISITION, payload: requisition });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setRequisition = (requisition) => {
    dispatch({ type: SET_REQUISITION, payload: requisition });
  };

  const createRequisition = () => {
    dispatch({ type: CREATE_REQUISITION });
  };

  const setPropertyRequisition = (key, value) => {
    dispatch({ type: SET_PROPERTY_REQUISITION, payload: { key, value } });
  };

  const saveRequisition = (requisition, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = RequisitionsService.putRequisition;
    if (isNaN(parseInt(requisition.requisition_id))) {
      service = RequisitionsService.postRequisition;
    }
    service(requisition)
      .then(() => {
        success("Requisition saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const saveRequisitionFile = (requisition_file, callback) => {
    if (requisition_file.file && requisition_file.file !== null) {
      const formData = FilesService.getFormData(requisition_file.file);
      FilesService.postFile(formData).then((res) => {
        const { file_id } = res.data;
        requisition_file.file_id = file_id;
        RequisitionsService.postRequisitionFile(requisition_file).then(() => {
          success("File saved.");
          clearModal();
          if (typeof callback === "function") {
            callback();
          }
        });
      });
    } else {
      RequisitionsService.postRequisitionFile(requisition_file).then(() => {
        success("File saved.");
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      });
    }
  };

  const deleteRequisition = (requisition_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    RequisitionsService.deleteRequisition(requisition_id)
      .then(() => {
        success("Requisition deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <RequisitionsContext.Provider
      value={{
        ...state,
        setRequisition,
        getRequisitions,
        saveRequisition,
        createRequisition,
        deleteRequisition,
        saveRequisitionFile,
        getSingleRequisition,
        setPropertyRequisition,
      }}
    >
      {children}
    </RequisitionsContext.Provider>
  );
};
