import React, { createContext, useContext, useReducer } from "react";
import ProductsReducer from "../reducers/ProductsReducer";
import ProductsService from "../services/ProductsServices";
import {
  CREATE_PRODUCT,
  PRODUCTS_RECEIVED,
  SET_SINGLE_PRODUCT,
  SET_PROPERTY_PRODUCT,
  INPUTS_RECEIVED,
  SET_PROPERTY_INPUT,
  SET_SINGLE_INPUT,
} from "../types/products";
import moment from "moment";
import { ModalContext } from "./ModalContext";
import FilesService from "../services/FilesService";

const initialState = {
  products: null,
  product: null,
  inputs: null,
  input: null,
};

export const ProductsContext = createContext(initialState);

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductsReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getProducts = (filters) => {
    ProductsService.getProducts(filters).then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const getProductInventory = () => {
    ProductsService.getProductInventory().then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const getProjectProductInventory = (project_id) => {
    ProductsService.getProjectProductInventory(project_id).then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const getProjectProducts = (project_id, filters) => {
    ProductsService.getProjectProducts(project_id, filters).then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const getProductInputs = (filters) => {
    ProductsService.getProductInputs(filters).then((res) => {
      const { products } = res.data;
      dispatch({ type: INPUTS_RECEIVED, payload: products });
    });
  };

  const getSingleProduct = (product_id) => {
    ProductsService.getSingleProduct(product_id).then((res) => {
      const { product } = res.data;
      dispatch({ type: SET_SINGLE_PRODUCT, payload: product });
    });
  };

  const postProduct = (product, callback) => {
    let service = ProductsService.putProduct;
    if (isNaN(parseInt(product.product_id))) {
      service = ProductsService.postProduct;
    }
    const promises = [];
    const uploaded = [];
    if (product.pictures && product.pictures !== null) {
      for (let i = 0; i < product.pictures.length; i++) {
        const currentPicture = product.pictures[i];
        const currentPromise = new Promise((resolve, reject) => {
          const formData = FilesService.getFormData(currentPicture);
          FilesService.postFile(formData)
            .then((res) => {
              const { file_id } = res.data;
              uploaded.push(file_id);
              resolve();
            })
            .catch(reject);
        });
        promises.push(currentPromise);
      }
    }
    Promise.all(promises).then(() => {
      product.files = Array.isArray(product.files) ? product.files : [];
      product.files = [...product.files, ...uploaded];
      service(product).then((res) => {
        success("Product saved.");
        if (typeof callback === "function") {
          callback(res.data.product);
        }
        clearModal();
      });
    });
  };

  const postProductPromise = (product) => {
    return ProductsService.postProduct(product);
  };

  const createProduct = () => {
    dispatch({ type: CREATE_PRODUCT });
  };

  const clearSingleProduct = () => {
    dispatch({ type: SET_SINGLE_PRODUCT, payload: null });
  };

  const setProduct = (product) => {
    dispatch({ type: SET_SINGLE_PRODUCT, payload: product });
  };

  const setInput = (product) => {
    dispatch({ type: SET_SINGLE_INPUT, payload: product });
  };

  const setPropertyProduct = (key, value) => {
    dispatch({ type: SET_PROPERTY_PRODUCT, payload: { key, value } });
  };

  const setPropertyInput = (key, value) => {
    dispatch({ type: SET_PROPERTY_INPUT, payload: { key, value } });
  };

  const saveProductInput = (parent_id, input_id, amount, handleCallback) => {
    ProductsService.postProductInput(parent_id, input_id, amount).then(() => {
      success("Product input saved.");
      if (typeof handleCallback === "function") {
        handleCallback();
      }
    });
  };

  const postDamagedProduct = (product_id, amount, comment) => {
    ProductsService.postProductDamage(product_id, amount, comment).then(() => {
      getSingleProduct(product_id);
      success("Damage saved.");
    });
  };

  const postProducts = (products, callback) => {
    const promises = [];
    products.forEach((product) => {
      promises.push(postProductPromise(product));
    });
    Promise.all(promises).then(() => {
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const exportProductsToFile = (project_id, filters) => {
    ProductsService.downloadProjectProducts(project_id, filters)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `Products_${moment().format(
          "YYYY_MM_DD_HH_mm_ss"
        )}.xlsx`;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        setInput,
        setProduct,
        getProducts,
        postProduct,
        postProducts,
        createProduct,
        getProductInputs,
        getSingleProduct,
        saveProductInput,
        setPropertyInput,
        getProjectProducts,
        setPropertyProduct,
        clearSingleProduct,
        postDamagedProduct,
        getProductInventory,
        exportProductsToFile,
        getProjectProductInventory,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
