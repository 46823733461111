import React, { useContext, useState } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import { S3_ENDPOINT, getValue } from "../../utils";
import ImageInput from "../common/ImageInput";

const ProductForm = ({ project_id, handleCancel, handleCallback }) => {
  const [error, setError] = useState(null);
  const { product, postProduct, setPropertyProduct } =
    useContext(ProductsContext);

    const getSrc = () => {
      if(product.file && product.file !== null) {
        return `${S3_ENDPOINT}/${product.file.name}.${product.file.type}`;
      }
    }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      isNaN(product.price) ||
      product.price === "" ||
      product.price === null
    ) {
      return setError("Price is invalid");
    }
    if (product.name === "") {
      return setError("Name is invalid");
    }
    product.project_id = project_id;
    postProduct(product, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      {error !== null ? <h4 className="text-danger">{error}</h4> : ""}
      <ImageInput multiple value={getSrc()} modifier={files => setPropertyProduct("pictures", files)} />
      <label>Name</label>
      <input
        type="text"
        value={getValue(product, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyProduct("name", e.target.value)}
      />
      <label>SKU</label>
      <input
        type="text"
        value={getValue(product, "SKU")}
        className="form-control mb-3"
        onChange={(e) => setPropertyProduct("SKU", e.target.value)}
      />
      <label>Description</label>
      <textarea
        rows="4"
        value={getValue(product, "description")}
        className="form-control mb-3"
        onChange={(e) => setPropertyProduct("description", e.target.value)}
      />
      <div className="row mb-3">
        <div className="col-6">
          <label>Price</label>
          <input
            type="number"
            value={getValue(product, "price")}
            className="form-control"
            onChange={(e) => setPropertyProduct("price", e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>Cost</label>
          <input
            type="number"
            value={getValue(product, "cost")}
            className="form-control"
            onChange={(e) => setPropertyProduct("cost", e.target.value)}
          />
        </div>
      </div>
      <label>Currency</label>
      <select
        value={getValue(product, "currency")}
        className="form-control mb-3"
        onChange={(e) => setPropertyProduct("currency", e.target.value)}
      >
        <option>USD</option>
        <option>MXN</option>
        <option>GBP</option>
        <option>EUR</option>
      </select>
      <button className="btn btn-primary w-100">Save</button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn text-accent h6 text-center d-block w-100 mt-3"
      >
        Cancel
      </button>
    </form>
  );
};

export default ProductForm;
